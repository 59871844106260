import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './createBrokerage.scss';
// import { Link } from 'react-router-dom';
import Dashboard from '../../component/Dashboard';
import { loadScript } from '../../helper/helper';
// import CreateForm from './createForm/createForm';
// import CreateBrokerageAccountForm from '../../component/CreateBrokerageAccountApp/CreateBrokerageAccountApp';
import CreateForm from '../../component/createForm/createForm';
import { doCreateBrokerageAccRes } from '../../actions/createBrokerageAccActions';
import { doEditprofileRes } from '../../actions/editProfileActions';
import BackIcon from '../../assets/images/images/icon-left.svg';
let isProfile = false;
class CreateBrokerageComponent extends React.PureComponent {

    constructor() {
        super();
        isProfile = false;
        this.state = {
            isLoader: false,
            profileData: '',
            brokerdealerdetails: '',
        }
    }

    componentDidMount() {
        loadScript();
        this.setState({
            brokerdealerdetails: this.props.location.state 
        },()=>{
            isProfile = true;

        });
    }

    static getDerivedStateFromProps(props, state) {
        
        if (props.editprofileRes) {
            if (props.editprofileRes.data && props.editprofileRes.data.editprofile) {
                if (props.editprofileRes.data.editprofile.message === 'Success' && isProfile) {
                    isProfile = false;
                    return {
                        profileData: props.editprofileRes.data.editprofile.data,
                    };
                }
            }
        }
        return null
    }
    handleBack = () => {
        this.props.history.goBack()
    }
    handleSubmit(data) {
        // let sendRequest = data;
        // this.props.handleFormSubmit(sendRequest);
    };
    render() {
        return (
            <Dashboard title="ClickIPO - Create Brokerage Account" pageTitle="Create Brokerage Account">
                <div className="create_brokerage_form">
                    <div className="offring_deatils_heading">
                        <div className="back_icon_text"  onClick={this.handleBack}>
                            <img src={BackIcon} alt="" className="back_icon" />
                            <span className="go_back">back to broker</span>
                        </div>
                    </div>
                    <div className="create_broker_box_inner_content pt-15px">
                        {
                            // this.state.profileData &&
                            <CreateForm {...this.props} profileDetail={this.state.profileData} brokerdealerdetails={this.state.brokerdealerdetails} handleFormSubmit={(data) => { this.handleSubmit(data) }} />
                        }
                    </div>
                </div>
            </Dashboard>
        )
    }
}

CreateBrokerageComponent.propTypes = {
    createBrokerageAccRes: PropTypes.any,
    editprofileRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    createBrokerageAccRes: doCreateBrokerageAccRes,
    editprofileRes: doEditprofileRes
});

function mapDispatchToProps(dispatch) {
    return {
        
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CreateBrokerageComponent);
