import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import { Link } from 'react-router-dom';
import './resetcode.scss';

import PropTypes from 'prop-types';
import ResetCodeForm from './resetcodeForm/resetcodeForm';
import { submitVerifyCode, doVerifyCodeRes } from '../../actions/verifyCodeActions';
import { ToastContainer } from 'react-toastify';
import { loadScript } from '../../helper/helper';
import CompanyLogo from '../../assets/images/clickipo-logo-tagline.png';
import IsoMockups from '../../assets/images/ipo-platform-mockups.png';
import IosAppImg from '../../assets/images/download_clickipo_ios.png';
import AndroidAppImg from '../../assets/images/download_clickipo_android.png';

class ResetCodeComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            code: '',
        }
    }

    componentDidMount() {
        loadScript();
    }

    static getDerivedStateFromProps(props, state) {
		if (props.verifycodeRes) {
            if (props.verifycodeRes.data.verifyCode && props.verifycodeRes.data.verifyCode.data) {
                if (props.verifycodeRes.data.verifyCode.data.message === "Success") {
                    props.history.push('/reset-password', { code: state.code });
                }
            }
        }
		return null
	}

    handleSubmit(data) {
        this.setState({
            code: data.code,
        });
        let sendRequest = {
            "code" : data.code
        }
        this.props.handleFormSubmit(sendRequest);
    }
    render() {
        return (
            <div className="resetcode-page">
                <div className="container-fluid resetcode-content">
                    <div className="row">
                        <div className="col-md-7 col-lg-8">
                            <div className="resetcode-company-logo">
                                <img src={CompanyLogo} alt="" />
                            </div>
                            <div className="resetcode-platform-mockup">
                                <img src={IsoMockups} alt="" />
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4">
                            <div className="resetcode_content_inner_body">
                                <div className="resetcode_content_inner_body_inner">
                                    <div className="top-border"></div>
                                    <div className="resetcode_heading">
                                        Verify Code
                                    </div>
                                    <div className="form_content_resetcode">
                                        <p style={{ textAlign: 'center', fontSize: '15px' }}>Enter your 6 digit code to reset the password.</p>
                                        <ResetCodeForm handleFormSubmit={(data) => { this.handleSubmit(data) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="resetcode_content_form_getapp">
                                <div className="getapp_title">
                                    <p>Get the app!</p>
                                </div>
                                <div className="row app-download-box">
                                    <div className="col-6">
                                        <div className="ios-app-image">
                                            <a href="https://itunes.apple.com/app/apple-store/id1236247345?pt=118497532&ct=cipo_desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src={IosAppImg} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="android-app-image">
                                            <a href="https://play.google.com/store/apps/details?id=com.clickipo.clickipo&utm_source=ClickIPO&utm_medium=website&utm_campaign=cipo_desktop" target="_blank" rel="noopener noreferrer"><img src={AndroidAppImg} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}


ResetCodeComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    verifycodeRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    verifycodeRes: doVerifyCodeRes
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(submitVerifyCode(data))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ResetCodeComponent);
