import { setItem } from '../utils/localStore';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class linkedinAccountApi {
    static doLinkedinAccount(data) {

        let linkedindata = {
            grant_type: 'authorization_code',
            code: data.code,
            redirect_uri: data.redirect_uri,
            client_id: data.client_id,
            client_secret: data.client_secret,
        }

        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin':'*',
            });

            var formBody = [];
            for (var property in linkedindata) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(linkedindata[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");

            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: formBody,
            }
            return fetch('https://www.linkedin.com/oauth/v2/accessToken', body).then(response => {
                    // if (response.status === 400) {
                    //     localStorage.clear();
                    //     window.location.href = '/';
                    // }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }

    static doLinkedinUserAccountData(data) {
        setItem('linkedin_access_token', data.access_token);
        let linkedinaccessToken = {
            accessToken: data.access_token
        }
        let isError = false;

        try {
            let body = {
                method: 'GET',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + linkedinaccessToken.accessToken,
                'Connection': 'Keep-Alive'
            }

            return fetch('https://api.linkedin.com/v1/people/~:(id,emailAddress)?format=json', body).then(response => {
                // if (response.status === 401) {
                //     localStorage.clear();
                //     window.location.href = '/';
                // }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        } catch (err) {
            return err;
        }
    }
}

export default linkedinAccountApi;