import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Dashboard from '../../component/Dashboard';
import './orderReconfirmation.css';
import { user_confirm, doUserConfirmRes } from '../../actions/userReconfirmActions';
import { get_accounts_buying_power, doAccountsBuyingPowerRes } from '../../actions/accountsBuyingPowerActions';
import { submit_profile, doEditprofileRes } from '../../actions/editProfileActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { numberWithCommas, loadScript, numberWithCommasAndDollar } from '../../helper/helper';

import BackIcon from '../../assets/images/images/icon-left.svg';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(),
		alignItems: 'center',
		justifyContent: 'center'
	},
}))(MuiDialogActions);
let isProfile = false;
let isAccountsBuyingPower = false;
let isUserconfirm = false;
class OrderReconfirmationComponent extends React.PureComponent {

    constructor() {
        super();
        isProfile = false;
        isAccountsBuyingPower = false;
        isUserconfirm = false;
        this.state = {
            isLoader: false,
            open: false,
            value: '',
            buyingPower: '',
            verb: '',
            amount: '',
            minAmount:'',
            minAmountModal:false,
            brokerDealerMpid: '',
            accountId: '',
            screenId: '1.1',
            isEditValue: false,
            openUnitIncErrorModal: false,
            orderConfirmMsg: '',
            openForExisteMinMaxTicket: false,
            openReconfErrorModal: false
        }
        this.goChange = this.goChange.bind(this);
    }

    componentDidMount() {
        loadScript();
        const id = this.props.match.params.id;
        isAccountsBuyingPower = true;
        this.props.accountsBuyingPower();
        isProfile = true;
        this.props.getProfile();
        const activityData = {
            'ext_id': id,
			'screen_id': this.state.screenId,
			'source': 'web'
		}
        this.props.userActivity(activityData)
        
        const offeringModify = this.props.location.state.orderData;
        if (offeringModify) {
            offeringModify.offering.unit_increment = 5;
                this.setState({
                    orderdetailsList: offeringModify,
                    requestAmount: offeringModify.requested_amount,
                    accountId: offeringModify.broker_connection.account_id,
                    brokerDealerMpid: offeringModify.broker_connection.mpid,
                }, () => {
                    // if (this.state.orderdetailsList.dsp === 1 || this.state.orderdetailsList.dsp === 2) {
                    //     let amount = this.state.orderdetailsList.offering.max_price * this.state.orderdetailsList.offering.max_shares;
                    //     this.setState({
                    //         value: this.state.orderdetailsList.offering.ordr_dollar_share === 0 ? Number(amount).toFixed(2) : Number(amount),
                    //     });
                    // } else {
                    //     this.setState({
                    //         value: this.state.orderdetailsList.offering.ordr_dollar_share === 0 ? this.state.orderdetailsList.requested_amount : this.state.orderdetailsList.requested_shares
                    //     });
                    // }
                    this.setState({
                        value: this.state.orderdetailsList.offering.ordr_dollar_share === 0 ? this.state.orderdetailsList.requested_amount : this.state.orderdetailsList.requested_shares
                    });
                });
        }
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    handleEndEditingPassword = () => {
        this.setState( { isEditValue: true, value: '' }, () => {});
    }

    goChange(e) {
        // const re = /^\s*\d*\.?\d{0,2}\s*$/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({
        //         value: e.target.value,
        //     })
        // }
        e.persist();
        if (this.state.isEditValue) {
            const re = /^\s*\d*\.?\d{0,2}\s*$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                this.setState({
                    value: e.target.value,
                    isEditValue: false,
                }, () => {
                })
            }
        } else {
            const re = /^\s*\d*\.?\d{0,2}\s*$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                this.setState({
                    value: e.target.value,
                }, () => {
                })
            }
        }
    }

    onCloseModal = () => {
        this.setState({ open: false });
    };

    clcikOk() {
        this.setState({ open: false, minAmountModal: false });
    }

    reconfirmModifyOrder(id) {
        if (this.state.orderdetailsList.offering.unit_increment !== null || this.state.orderdetailsList.offering.unit_increment !== undefined) {
            let enterValue = Number(this.state.value);
            let checkVal = enterValue%Number(this.state.orderdetailsList.offering.unit_increment);
            if (checkVal > 0) {
                this.setState({
                    openUnitIncErrorModal: true
                })
                return false;
            }
        }
        if(Number(this.state.orderdetailsList.offering.max_ticket_size)>0){
            if (Number(this.state.value) < Number(this.state.orderdetailsList.offering.min_ticket_size)) {
                this.setState({ openForExisteMinMaxTicket: true });
                return false;
            }
            if (Number(this.state.value) > Number(this.state.orderdetailsList.offering.max_ticket_size)) {
                this.setState({ openForExisteMinMaxTicket: true });
                return false;
            }
        }
        if (this.state.value < Number(this.state.minAmount)) {
            this.setState({
                minAmountModal: true,
            });
        } else {
            this.setState({
                minAmountModal: false
            });
            if (Number(this.state.buyingPower) >= this.state.value) {
                this.setState({
                    open: false
                });
                let modifyData = {
                    "ioi_reconfirm": this.state.orderdetailsList.offering.ioi_cutoff_datetime === null ? 'reconfirm' : 'ioi',
                    "mpid": this.state.brokerDealerMpid,
                    "buying_power": Number(this.state.buyingPower),
                    "account_id": this.state.accountId,
                    "ext_id": id
                }
                if (this.state.orderdetailsList.offering.ordr_dollar_share === 0) {
                    modifyData.requested_amount = Number(this.state.value)
                } else {
                    modifyData.requested_shares = Number(this.state.value)
                }
                isUserconfirm = true;
                this.props.userConfirm(modifyData);
            } else {
                this.setState({
                    open: true,
                });
            }
        }
    }

    reconfirmOrder(id) {
        if (Number(this.state.value) !== (this.state.orderdetailsList.offering.ordr_dollar_share === 0 ? Number(this.state.orderdetailsList.requested_amount): Number(this.state.orderdetailsList.requested_shares))) {
            this.setState({
                openReconfErrorModal: true
            })
            return false;
        }
        let reconfirmOrderData = {
            "ioi_reconfirm": this.state.orderdetailsList.offering.ioi_cutoff_datetime === null ? 'reconfirm' : 'ioi',
            "mpid": this.state.brokerDealerMpid,
            "buying_power": Number(this.state.buyingPower),
            "account_id": this.state.accountId,
            "ext_id": id
        }
        if (this.state.orderdetailsList.offering.ordr_dollar_share === 0) {
            reconfirmOrderData.requested_amount = this.state.orderdetailsList.requested_amount
        } else {
            reconfirmOrderData.requested_shares = this.state.orderdetailsList.requested_shares
        }
        isUserconfirm = true;
        this.props.userConfirm(reconfirmOrderData);
        
    }

    reconfirmDeleteOrder(id) {
        let deleteOrderData = {
            "ioi_reconfirm": this.state.orderdetailsList.offering.ioi_cutoff_datetime === null ? 'reconfirm' : 'ioi',
            "mpid": this.state.brokerDealerMpid,
            "buying_power": Number(this.state.buyingPower),
            "account_id": this.state.accountId,
            "ext_id": id
        }
        if (this.state.orderdetailsList.offering.ordr_dollar_share === 0) {
            deleteOrderData.requested_amount = 0
        } else {
            deleteOrderData.requested_shares = 0
        }
        isUserconfirm = true;
        this.props.userConfirm(deleteOrderData);
    }
    static getDerivedStateFromProps(props, state) {
		if (props.accountsBuyingPowerRes) {
            if (props.accountsBuyingPowerRes.data && props.accountsBuyingPowerRes.data.accountsbuyingpower) {
                if (props.accountsBuyingPowerRes.data.accountsbuyingpower.message === 'Success' && isAccountsBuyingPower) {
                    isAccountsBuyingPower = false;
                    return {
                        accountsBuyingPower: props.accountsBuyingPowerRes.data.accountsbuyingpower.data,
                        buyingPower: Number(props.accountsBuyingPowerRes.data.accountsbuyingpower.data.buying_power).toFixed(2)
                    };
                }
            }
        }
        if (props.userconfirmRes) {
            if (props.userconfirmRes.data && props.userconfirmRes.data.userconfirm) {
                if (props.userconfirmRes.data.userconfirm.message === "Success" && isUserconfirm){
                    isUserconfirm = false;
                    props.history.push('/order')
                }
            }
        }
        if (props.EditprofileRes) {
            if (props.EditprofileRes.data && props.EditprofileRes.data.editprofile) {
                if (props.EditprofileRes.data.editprofile.message === 'Success' && isProfile) {
                    isProfile = false;
                    let minAmount = '';
                    if(props.EditprofileRes.data.editprofile.data.broker_connection){
                        minAmount = props.EditprofileRes.data.editprofile.data.broker_connection.minimum_buy_order;
                    }
                    return {
                        userList: props.EditprofileRes.data.editprofile.data,
                        brokerconnectionList: props.EditprofileRes.data.editprofile.data.broker_connection,minAmount: minAmount
                    };
                }
            }
        }
		return null
	}
    closeUnitIncErrorModal = () => {
        this.setState({ 
            openUnitIncErrorModal: false
        });
    }
    
    onCloseMinMaxModal = () => {
        this.setState({ 
            openForExisteMinMaxTicket: false,
        });
    }
    onCloseReconfErrModal =() => {
        this.setState({ 
            openReconfErrorModal: false,
        });
    }
    render() {
        const { open } = this.state;
        // const buyingPowerAmount = '$ ' + this.state.buyingPower;
        return (
            <Dashboard title="ClickIPO - Reconfirm Order" pageTitle="Reconfirm Order"  >
                <div className="place_order_content">
                    <div className="place_order_heading page_title">
                        <img src={BackIcon} alt="" onClick={this.handleBack} className="back_icon" />
                        Reconfirm Order
                    </div>
                    {
                    this.state.orderdetailsList && this.state.orderdetailsList.offering &&
                    <div>
                        <div className="place_order_inner_content">
                            <div className="row ml-mr-0px-imp">
                                <div className="col-12 col-sm-8 col-md-8 col-lg-9 reconfirm__content">
                                    <p className="reconfirm__text">
                                        Please 
                                        {
                                            this.state.orderdetailsList.offering.ioi_cutoff_datetime !== null ?
                                            <span style={{ fontWeight: '500' }}>Indication of Interest</span>
                                            :
                                            <span style={{ fontWeight: '500' }}>reconfirm</span>
                                        }
                                         your order below. You may keep the same amount, modify, or cancel your order.
                                    </p>
                                    <div className="row mt-2">
                                        <div className="col col-6">
                                            <p className="price_level_placeorder_type">Ticket size</p>
                                        </div>
                                        <div className="col col-6">
                                            {
                                                this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                                <p className="amount_placeorder">
                                                    {numberWithCommasAndDollar(this.state.orderdetailsList.offering.min_ticket_size)} - {numberWithCommasAndDollar(this.state.orderdetailsList.offering.max_ticket_size)}
                                                </p>
                                                :
                                                <p className="amount_placeorder">
                                                    {numberWithCommas(this.state.orderdetailsList.offering.min_ticket_size)} - {numberWithCommas(this.state.orderdetailsList.offering.max_ticket_size)}
                                                </p>
                                            }
                                            
                                        </div>
                                    </div>
                                    {
                                        Number(this.state.orderdetailsList.offering.unit_increment) > 1 &&
                                        <div className="row mt-2">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Unit increment</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                    {this.state.orderdetailsList.offering.unit_increment}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        Number(this.state.buyingPower) > 0 &&
                                        <div className="row mt-2">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">
                                                    IPO/Follow-on Buying Power
                                                </p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                    {numberWithCommasAndDollar(this.state.buyingPower)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    <div className="investment_content">
                                        <div className="row">
                                            <div className="col col-6">
                                                {
                                                this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                                    <p className="investment_status" style={{marginTop: 8}}>Investment Amount</p>
                                                    :
                                                    <p className="investment_status" style={{marginTop: 8}}>Investment Shares</p>
                                                }
                                            </div>
                                            <div className="col col-6">
                                                {
                                                this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                                <div className="rt-input-input investment_amount place_investment_amount">
                                                    <input type="text" className="form-control" onChange={this.goChange} onClick={this.handleEndEditingPassword } value={this.state.value} ></input>
                                                </div>
                                                :
                                                <div className="investment_amount place_investment_amount">
                                                    <input type="text" className="form-control" onChange={this.goChange} onClick={this.handleEndEditingPassword } value={this.state.value} ></input>
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row">
                                            <div className="col col-6">
                                                {
                                                this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                                    <p className="share_status">Approximate Shares</p>
                                                    :
                                                    <p className="share_status">Approximate Amount</p>
                                                }
                                            </div>
                                            <div className="col col-6">
                                                {
                                                this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                                <p className="share_amount">
                                                    
                                                    {
                                                        this.state.value ? numberWithCommas(Math.round((this.state.value)/(this.state.orderdetailsList.offering.max_price)).toFixed(0)): 0 
                                                    }
                                                </p>
                                                :
                                                <p className="share_amount">
                                                    {
                                                        this.state.value ? numberWithCommasAndDollar(Math.round((this.state.value)*(this.state.orderdetailsList.offering.max_price)).toFixed(0)): '$0.00' 
                                                    }
                                                </p>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-3 confirm_order_button_wrapper">
                                    <div className="confirm_order_button">
                                        <button type="submit" className="green_btn" onClick={() => this.reconfirmModifyOrder(this.state.orderdetailsList.offering.ext_id)} disabled={!this.state.value}>
                                            Modify
                                        </button>
                                    </div>
                                    <div className="confirm_order_button">
                                        <button type="submit" className="green_btn" onClick={() => this.reconfirmOrder(this.state.orderdetailsList.offering.ext_id)}>
                                            Reconfirm
                                        </button>
                                    </div>
                                    <div className="confirm_order_button">
                                        <button type="submit" className="green_btn" onClick={() => this.reconfirmDeleteOrder(this.state.orderdetailsList.offering.ext_id)}>
                                            Cancel Order
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Dialog
                            open={this.state.minAmountModal}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                <p className="modal_confirm_text">
                                    Minimum Investment Amount is $ {this.state.minAmount}
                                </p>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                <Button variant="contained" size="large" color="primary"onClick={() => this.clcikOk()} >Ok</Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={open}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                <p className="modal_confirm_text">
                                You have exceeded your buying power. Please modify the amount!!
                                </p>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                <Button variant="contained" size="large" color="primary"onClick={() => this.clcikOk()} >Ok</Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.openUnitIncErrorModal}
                            onClose={this.closeUnitIncErrorModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                <h5> Your value should be multiple of {this.state.orderdetailsList.offering.unit_increment}</h5>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.closeUnitIncErrorModal()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.openForExisteMinMaxTicket}
                            onClose={this.onCloseMinMaxModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                {
                                    this.state.orderdetailsList && this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                    <h5>
                                        Amount should be between {numberWithCommasAndDollar(this.state.orderdetailsList.offering.min_ticket_size)} and {numberWithCommasAndDollar(this.state.orderdetailsList.offering.max_ticket_size)}
                                    </h5>
                                    :
                                    <h5>
                                        Shares should be between {numberWithCommas(this.state.orderdetailsList.offering.min_ticket_size)} and {numberWithCommas(this.state.orderdetailsList.offering.max_ticket_size)}
                                    </h5>
                                }   
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.onCloseMinMaxModal()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.openReconfErrorModal}
                            onClose={this.onCloseReconfErrModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                {
                                    this.state.orderdetailsList && this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                    <h5>
                                        You have changed amount please do modify
                                    </h5>
                                    :
                                    <h5>
                                        You have changed shares please do modify
                                    </h5>
                                }   
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.onCloseReconfErrModal()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                    }
                </div>
            </Dashboard>
        )
    }
}

OrderReconfirmationComponent.propTypes = {
    userconfirmRes: PropTypes.any,
    accountsBuyingPowerRes: PropTypes.any,
    EditprofileRes: PropTypes.any,
    userActivityRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    userconfirmRes: doUserConfirmRes,
    accountsBuyingPowerRes: doAccountsBuyingPowerRes,
    EditprofileRes: doEditprofileRes,
    userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
    return {
        userConfirm: (data) => dispatch(user_confirm(data)),
        accountsBuyingPower: (data) => dispatch(get_accounts_buying_power(data)),
        getProfile: () => dispatch(submit_profile()),
        userActivity: (data) => dispatch(user_activity(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrderReconfirmationComponent);
