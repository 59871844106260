import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function (state = initialState.user, action) {
  switch (action.type) {
    case types.GET_SOCIAL_MEDIA_AUTH_URL_RES:
      return action.data;
    default:
      return state;
  }
}