import * as types from './actionTypes';  
import tradestationApi from '../api/tradestationApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';

export function doBrokerDealerConnect(data) {
  return {
    type: types.DO_BROKERDEALER_CONNECT, 
    data
  };
}

export function doBrokerDealerConnectRes(data) {  
  return {
    type: types.DO_BROKERDEALER_CONNECT_RES, 
    data
  };
}

export function broker_delaer_connect(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      tradestationApi.doBrokerDealerConnect(data).then(data => {
        dispatch(doBrokerDealerConnectRes(data));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}