import * as types from './actionTypes';  
import brokerDealerDisconnectionApi from '../api/brokerDealerDisconnectionApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function deleteBrokerDealerDisconnect(id) {
  return {
    type: types.DO_BROKERDEALER_DISCONNECT, 
    id
  };
}

export function doBrokerDealerDisconnectRes(data) {
  return {
    type: types.DO_BROKERDEALER_DISCONNECT_RES, 
    data
  };
}

export function delete_broker_dealer_connection(id) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      brokerDealerDisconnectionApi.deleteBrokerDealerDisconnect(id).then(data => {
        dispatch(doBrokerDealerDisconnectRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
  
}