import * as types from './actionTypes';  
import allCommentApi from '../api/allCommentApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function doAllComment(data) {

  return {
    type: types.ALL_COMMENTS_LIST, 
    data
  };
}

export function doAllCommentRes(data) {  
  return {
    type: types.ALL_COMMENTS_LIST_RES, 
    data
  };
}

export function getAllComment(data) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      allCommentApi.doAllComment(data).then(data => {
        dispatch(doAllCommentRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
  
}