import * as types from './actionTypes';  
import offeringIndustriesApi from '../api/offeringIndustriesApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';

export function doOfferingIndustries(data) {

  return {
    type: types.DO_OFFERING_INDUSTRIES, 
    data
  };
}

export function doOfferingIndustriesRes(data) {  
  return {
    type: types.DO_OFFERING_INDUSTRIES_RES, 
    data
  };
}

export function get_offering_industries(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      offeringIndustriesApi.doOfferingIndustries(data).then(data => {
        dispatch(doOfferingIndustriesRes(data));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}
