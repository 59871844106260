import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Ionicon from 'react-ionicons';
import styles from './sidebar-jss';

import ListIcon from '@material-ui/icons/List';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import EmailIcon from '@material-ui/icons/Email';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// import ShareIcon from '@material-ui/icons/Share';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import ForumIcon from '@material-ui/icons/Forum';
// import PolicyRoundedIcon from '@material-ui/icons/PolicyRounded';
// import GavelRoundedIcon from '@material-ui/icons/GavelRounded';
import { logout } from '../../helper/helper';
const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

let page="";
// eslint-disable-next-line
class MainMenu extends React.Component {
  // handleClick() {
  //   const { toggleDrawerOpen, loadTransition } = this.props;
  //   toggleDrawerOpen();
  //   loadTransition(false);
  // }
  openurl(geturl) {
    const url = geturl;
    window.open(url, '_blank');
  }
  componentDidMount() {
    page =window.location.pathname;
    page =page.split('/');
  }
  render() {
    const {
      classes
    } = this.props;
    return (
      <div>
        {/* {getMenus(dataMenu)} */}
        <ListItem
          button
          className={classNames(classes.head, page[1] === 'offering' || page[1] === 'offeringdetails' || page[1] === 'placeorder' ? ` ${classes.active}` : '')}
          component={LinkBtn}
          to={{pathname: "/offering",
          DSPView: true}}
        >
          <ListItemIcon className={classes.icon}>
            <ListIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} invariant="inset" primary={'Offerings'} />
        </ListItem>

        <ListItem
          button
          exact
          className={classNames(classes.head, page[1] === 'order' || page[1] === 'orderdeatils' || page[1] === 'modifyorder' ? ` ${classes.active}` : '')}
          component={LinkBtn}
          to={'/order'}
        >
          <ListItemIcon className={classes.icon}>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Orders'} />
        </ListItem>

        <ListItem
          button
          exact
          className={classNames(classes.head, page[1] === 'brokerdealer' || page[1] === 'brokeragefirms' || page[1] === 'broker' || page[1] === 'create-planner-account' ? ` ${classes.active}` : '')}
          component={LinkBtn}
          to={'/brokerdealer'}
        >
          <ListItemIcon className={classes.icon}>
            <BrandingWatermarkIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Broker Dealer'} />
        </ListItem>

        <ListItem
          button
          exact
          className={classes.head}
          activeClassName={classes.active}
          component={LinkBtn}
          to={'/messages'}
        >
          <ListItemIcon className={classes.icon}>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Messages'} />
        </ListItem>

        <ListItem
          button
          exact
          className={classNames(classes.head, page[1] === 'verifypassword' || page[1] === 'editprofile' ? ` ${classes.active}` : '')}
          component={LinkBtn}
          to={'/verifypassword'}
        >
          <ListItemIcon className={classes.icon}>
            <AccountCircleRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Profile'} />
        </ListItem>

        {/* <ListItem
          button
          exact
          className={classes.head}
          activeClassName={classes.active}
          component={LinkBtn}
          to={'/socialmedia'}
        >
          <ListItemIcon className={classes.icon}>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Social Media'} />
        </ListItem> */}

        <ListItem
          button
          exact
          className={classes.head}
          activeClassName={classes.active}
          component={LinkBtn}
          to={'/glossary'}
        >
          <ListItemIcon className={classes.icon}>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Glossary'} />
        </ListItem>

        <ListItem
          button
          exact
          className={classes.head}
          activeClassName={classes.active}
          component={LinkBtn}
          to={'/faq'}
        >
          <ListItemIcon className={classes.icon}>
            <ForumIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'FAQs'} />
        </ListItem>

        <ListItem
          button
          className={classes.head}
          onClick={() => this.openurl('https://help.click.markets/')}
        >
          <ListItemIcon className={classes.icon}>
            <HelpRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Help'} />
        </ListItem>

        <ListItem
          button
          className={classes.head}
          onClick={logout}
        >
          <ListItemIcon className={classes.icon}>
            <ExitToAppRoundedIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Sign Out'} />
        </ListItem>
        <ListItem
          button
          className={classes.head}
          onClick={() => this.openurl('https://click.markets/privacy-policy')}
        >
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Privacy Policy'} />
        </ListItem>
        <ListItem
          button
          className={classes.head}
          onClick={() => this.openurl('https://click.markets/terms-conditions')}
        >
          <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={'Terms & Conditions'} />
        </ListItem>
      </div>
    );
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired
};

// const openAction = (key, keyParent) => ({ type: 'OPEN_SUBMENU', key, keyParent });

const mapStateToProps = state => ({
  force: state, // force active class for sidebar menu
});

const mapDispatchToProps = dispatch => ({
  // openSubMenu: bindActionCreators(openAction, dispatch)
});

const MainMenuMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);

export default withStyles(styles)(MainMenuMapped);
