import * as types from './actionTypes';  
import brokerDealerApi from '../api/brokerDealerApi';
import editProfileApi from '../api/editProfileApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
// import tradestationApi  from '../api/tradestationApi';
// import {doGetprofileRes} from '../actions/tradestationActions';

import { doEditprofileRes } from '../actions/editProfileActions';
import { doAccountsBuyingPowerRes } from '../actions/accountsBuyingPowerActions';
import accountsbuyingpowerApi from '../api/accountsBuyingPowerApi';

export function doBrokerdelar(data) {

  return {
    type: types.DO_BROKERDEALER, 
    data
  };
}

export function doBrokerdelarRes(data) {  
  return {
    type: types.DO_BROKERDEALER_RES, 
    data
  };
}

export function broker_dealer(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      brokerDealerApi.doBrokerdelar(data).then(res => {
        dispatch(doBrokerdelarRes(res));
        dispatch(submit_profile());
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}

export function submit_profile() {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      editProfileApi.doEditprofile().then(data => {
        dispatch(doEditprofileRes(data));
        if(data.data.broker_connection.status  === "active"){
          dispatch(get_accounts_buying_power())            
        }else{
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}

export function get_accounts_buying_power(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      accountsbuyingpowerApi.getAccountsBuyingPower(data).then(res => {
        dispatch(doAccountsBuyingPowerRes(res));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}