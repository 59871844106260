
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import './privacyPolicy.css';
import Dashboard from '../../component/Dashboard';

class PrivacyPolicyComponent extends React.PureComponent {

  render() {
    return (
      <Dashboard title="ClickIPO - Privacy Policy" pageTitle="Privacy Policy" >
        <div className="privacy_policy_content">
          <div className="privacy_policy_inner_content">
            <p className="text_content">
              ClickIPO Securities, LLC (the “Firm” or “ClickIPO”) does not carry customer brokerage accounts, nor does it hold customer funds or securities. ClickIPO acts strictly as a wholesaling broker-dealer, transacting business with other contracted broker-dealers and underwriters or issuers of securities to process Conditional Offers to Buy securities on behalf of customers (“Users”) of contracted broker-dealers (“Broker-Dealer”) utilizing the ClickIPOTM mobile application (the “App”).
            </p>
            <p className="text_content">
              <strong>The Information We Collect About You</strong>
            </p>
            <p className="text_content">
              When you use the ClickIPOTM mobile application we collect Personal Information (“Information”) such as your first name, last name, and email address. With your permission, the App will connect with your on-line broker dealer to obtain certain Information such as your brokerage account number, account balance, and transaction history that we require in order to process transactions entered by your designated on-line Broker-Dealer on your behalf, through the App.
              With your permission we collect your device identifiers to enable push notifications of new offerings and certain Information related to your account.
            </p>
            <p className="text_content">
              When you connect your ClickIPOTM account to your Broker-Dealer account using the 3rd party authentication mechanisms provided by your Broker-Dealer, we require transparent tokens from your Broker-Dealer to communicate on your behalf. We collect data, such as your account balance, past transaction data, account holdings and the time of acquisition or disposition of certain securities holdings. The Information about your account and the sharing of the data complies with your Broker-Dealer’s privacy policy. Please refer to your Broker-Dealer’s privacy policy for its policy details.
            </p>
            <p className="text_content">
              The Information related to your transactions from your Broker-Dealer is collected to provide Information required for using the ClickIPO Investor ScoreTM, the machine learning algorithm utilized for allocating securities for Users Conditional Offers to Buy (“COB”) entered with ClickIPO by the User’s Broker-Dealer on the User’s behalf.
            </p>
            <p className="text_content">
              ClickIPO will not sell Personal Information about Users to anyone. ClickIPO will not share Information about Users with other companies unless the Firm needs to share the Information with them to provide a product or service a customer has requested. Unless Click IPO informs Users otherwise, such other companies do not have the right to use Information obtained from the Firm in any manner beyond what is required to provide a User’s requested product or service. The Firm limits the collection of Information to what is necessary to administer ClickIPO’s business and to deliver the Firm’s products and services to the Users of the App. It is sometimes necessary, in the conduct of the Firm’s business, that the Firm shares Information with ClickIPO’s affiliates.
            </p>
            <p className="text_content">
              ClickIPO reserves the right to disclose or report Information in limited circumstances where the Firm believes in good faith that disclosure is required under law, to cooperate with regulators or law enforcement authorities, to perform necessary credit checks or collect or report debts owed to us, to protect ClickIPO’s rights or property, or upon reasonable request by the sponsor or provider of a product in which you have chosen to invest. Listed below is the Information the Firm collects and how Click IPO uses that Information:
            </p>
            <p className="text_content">
              <strong>How ClickIPO uses this Information</strong>
            </p>
            <p className="text_content">
              Your Information is maintained in ClickIPO’s files. Your Information is utilized strictly for the purpose of conducting business via the App through your Broker-Dealer on your behalf. At no time will ClickIPO sell your Information. ClickIPO will share your Information only to the extent it is necessary to conduct business on your behalf and as described above.
            </p>
            <p className="text_content">
              <strong>Protection of Client Information</strong>
            </p>
            <p className="text_content">
              Our policies require that employees with access to confidential Personal Information may not use or disclose the Information except for business use. Access to User Information is available to employees on a “need-to-know” basis only and is restricted to certain employees, representatives and agents with a business reason for access to such Information. ClickIPO safeguards Information according to established security standards and procedures and trains ClickIPO’s employees and representatives to understand and comply with these protections.
            </p>
            <p className="text_content">
              ClickIPO’s procedures do not focus solely on the use of electronic Information, but also addresses the proper use and destruction of paper documents (including consumer report information under the recent amendments to Regulation S-P) that could raise privacy concerns. Consumer information will be shredded rather than discarded when no longer necessary to be maintained by the Firm E-mail at ClickIPO.
            </p>
            <p className="text_content">
              As the Firm updates its technology and use new and different methods of communication, whether through the use of wireless technology or allowing employees to work remotely, we will consider whether these methods necessitate updates or changes in our policies and procedures. We will consider the following, at a minimum:
            </p>
            <ul>
              <li>whether our existing policies and procedures adequately address the technology currently in use;</li>
              <li>whether we have taken appropriate technological precautions to protect User Information;</li>
              <li>whether we are providing adequate training to its employees regarding the use of available technology and the steps employees should take to ensure that customer records and Information are kept confidential; and</li>
              <li>whether we are conducting, or should conduct, periodic audits to detect potential vulnerabilities in its systems and to ensure that its systems are, in practice, protecting customer records and Information from unauthorized access.</li>
            </ul>
            <p className="text_content">
              The use of new technologies can benefit the Firm, our employees, contracted Broker-Dealers and Users; however, these new technologies can also present risks that we must consider and address appropriately. In some instances, the appropriate way to deal with these risks is not only through technological solutions, but measures may also involve changes to our training regimen and/or to our policies and procedures. We will consider whether the adoption of new technologies would necessitate changes in its compliance policies and procedures or systems before implementation so that issues can be identified and addressed in a timely way and problems can be avoided.
            </p>
            <p className="text_content">
              ClickIPO may rely on E-mail as a means of communication between the Firm and its Users and through “push” notifications via the App.
            </p>
            <p className="text_content">
              <strong>Changes to Firm Privacy Policies</strong>
            </p>
            <p className="text_content">
              From time to time the Firm may update its Privacy Policies. All new Users will receive the Firm’s most current Privacy Policy upon entering into an agreement or opening an App account with the Firm. All Users will be provided with the Firm’s most current Privacy Policies at least annually.
            </p>
            <p>
              <strong>Contacting Us</strong><br />
              You may communicate with the Firm in writing at:<br />
              ClickIPO Securities, LLC<br />
              9835 e bell rd,<br />
              Suite 110<br />
              Scottsdale, AZ 85260<br />
              Or you may send emails to Click Capital Markets Manager at: info at click.markets<br />
            </p>
            <p className="text_content">&nbsp;</p>
            <p className="text_content_last">Dated April 15, 2024</p>
          </div>
        </div>
      </Dashboard>

    );
  }
}

PrivacyPolicyComponent.propTypes = {

};

const mapStateToProps = createStructuredSelector({

});

function mapDispatchToProps(dispatch) {
  return {
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PrivacyPolicyComponent);
