import * as types from './actionTypes';
import FacebookAuthUrl from '../api/FacebookAuthUrl';
import { logout } from '../helper/helper';
import { getItem } from '../utils/localStore';
// import Swal from 'sweetalert2';

export function getFacebookAuthUrl() {
  return {
    type: types.GET_FACEBOOK_AUTH_URL
  };
}

export function getFacebookAuthUrlRes(data) {
  return {
    type: types.GET_FACEBOOK_AUTH_URL_RES,
    data
  }
}

export function get_facebook_auth_url() {
  const TOKEN = getItem('auth_token');
  if (TOKEN) {
    return function (dispatch) {
      FacebookAuthUrl.getFacebookAuthUrl().then(data => {
        dispatch(getFacebookAuthUrlRes(data));
      }).catch(error => {
        return error;
      });
    }
  } else {
    logout();
  }
}