import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { stocktwits_account, doStocktwitsAccountRes } from '../../actions/stockTwitsActions';
import { social_login, dosocialLoginRes } from '../../actions/socialLoginActions';

class StocktwitsComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            stcallbackUrl: 'https://clickipo.netlify.com/stocktwits',
            stconsumerKey: '7dbe4670fb57bffe',
            stconsumerSecret:  '2bf782c17357a4059049c0a3c1fffa2fbae9adc1',
            provider: 'stocktwits',
            access_token: '',
        }
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const code = query.get('code');
        this.setState({
            code: query.code
        })

        let sentrequest = {
            'client_id': this.state.stconsumerKey,
            'client_secret': this.state.stconsumerSecret,
            'redirect_uri': this.state.stcallbackUrl,
            'access_token': code,
        }
        this.props.stocktwitsAccount(sentrequest);
    }

    confirmstocklogin(){
        let stloginData = {
            'provider': this.state.provider,
            'uid': this.state.user_id,
            'token': this.state.access_token,
            'secret': this.state.stconsumerSecret,
            'last_refresh_time':this.state.last_refresh_time,
            'expiration_time':this.state.expiration_time
        }
        this.props.sociallogin(stloginData);
        this.props.history.push('/socialmedia');
    }

    render() {

        const Header = (<div className="offer_head">Stocktwits</div>);
        return (
            <div title="ClickIPO User" header={Header} >
                <div className="edit_profile_form_fields_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 style={{textAlign:'center'}}>Are you sure you want to add your account with stocktwits</h3>
                            </div>
                            <div className="col-md-6">
                                <button type="submit" className="green_btn add_stock_account" onClick={() => this.confirmstocklogin()}>
                                    Yes
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button type="submit" className="green_btn add_stock_account not_link">
                                    <Link to ={"/socialmedia"}>No</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

StocktwitsComponent.propTypes = {
    stocktwitsAccountRes: PropTypes.any,
    socialLoginRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    stocktwitsAccountRes: doStocktwitsAccountRes,
    socialLoginRes: dosocialLoginRes
});

function mapDispatchToProps(dispatch) {
    return {
        stocktwitsAccount: (data) => dispatch(stocktwits_account(data)),
        sociallogin: (data) => dispatch(social_login(data))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(StocktwitsComponent);