import * as types from '../actions/actionTypes'; 
import initialState from './initialState';


//all the offering related reducers here
export default function (state = initialState.user, action) { //we will change the state = {} soon
  switch(action.type) {
    case types.GET_BIZ_INSIGHTS_RES:
    let bizinsight = action.data;
    return bizinsight
      // return JSON.parse(action.data.data)
    default:
      return state
  }
}