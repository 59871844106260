import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
// import { toggleAction, openAction, playTransitionAction } from 'managedfa-redux/actions/uiActions';
import { Helmet } from 'react-helmet';
import Header from './Header/Header';
import Sidebar from './Sidebar';
import Fade from '@material-ui/core/Fade';
// import Typography from '@material-ui/core/Typography';
import decorationLight from '../assets/images/decoration_light.svg';
import spinnerImg from '../assets/images/spinner.gif';

import styles from './appStyles-jss';

class Dashboard extends React.Component {
  // Initial header style
  state = {
    openGuide: false,
    appHeight: 0,
    sidebarOpen: true,
  };

  componentDidMount = () => {
    // const { history, initialOpen, loadTransition } = this.props;

    // Adjust min height
    // this.setState({ appHeight: window.innerHeight + 112 });
    this.setState({ appHeight: window.innerHeight });

    // // Set expanded sidebar menu
    // const currentPath = history.location.pathname;
    // initialOpen(currentPath);
    // // Play page transition
    // loadTransition(true);

    // Execute all arguments when page changes
    // this.unlisten = history.listen(() => {
    //   window.scrollTo(0, 0);
    //   setTimeout(() => {
    //     // loadTransition(true);
    //   }, 500);
    // });
  }
  handleOpenGuide = () => {
    this.setState({ openGuide: true });
  };

  handleCloseGuide = () => {
    this.setState({ openGuide: false });
  };
  toggleDrawer = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    })
  }
  render() {
    const {
      classes,
      children,
      pageLoaded,
      mode,
      history,
      gradient,
      title,
      pageTitle
    } = this.props;
    const { appHeight, sidebarOpen } = this.state;
    // const titleException = ['/offering', '/app/crm-dashboard', '/app/crypto-dashboard'];
    // const parts = history.location.pathname.split('/');
    // const place = parts[parts.length - 1].replace('-', ' ');
    return (
      <div
        style={{ minHeight: appHeight }}
        className={
          classNames(
            classes.appFrameInner,
            classes.sideNav,
            mode === 'light-mode'
          )
        }
      >
        {/* <GuideSlider openGuide={openGuide} closeGuide={this.handleCloseGuide} /> */}
        <Helmet title={title} />
        <Fragment>
          <Header
            toggleDrawerOpen={this.toggleDrawer}
            margin={sidebarOpen}
            gradient={gradient}
            position="left-sidebar"
            mode={mode}
            title={pageTitle}
            history={history}
          />
          <Sidebar
            open={sidebarOpen}
            toggleDrawerOpen={this.toggleDrawer}
            leftSidebar
          />
          <main className={classNames(classes.content, !sidebarOpen ? classes.contentPaddingLeft : '')} id="mainContent">
            <div className={classes.bgWrap}>
              <div
                className={classNames(
                  classes.bgbar,
                  false && classes.highTop,
                  classes.solidBg,
                  classes.halfBg,
                )}
              >
                <img src={decorationLight} alt="decoration" className={classes.decorationWave} />
              </div>
            </div>
            <section className={classNames(classes.mainWrap, classes.sidebarLayout)}>
              {/* {titleException.indexOf(history.location.pathname) < 0 && (
                <div className={classes.pageTitle}>
                  <Typography component="h4" className={bgPosition === 'header' ? classes.darkTitle : classes.lightTitle} variant="h4">{'place'}</Typography>
                  <BreadCrumb separator=" / " theme={bgPosition === 'header' ? 'dark' : 'light'} location={history.location} />
                </div>
              )} */}
              { pageLoaded && (<img src={spinnerImg} alt="spinner" className={classes.circularProgress} />) }
              <Fade
                in={!pageLoaded}
                {...(!pageLoaded ? { timeout: 700 } : {})}
              >
                <div className={pageLoaded ? classes.hideApp : classes.appContentHeight}>
                  {/* Application content will load here */}
                  { children }
                </div>
              </Fade>
            </section>
          </main>
        </Fragment>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  history: PropTypes.object,
  initialOpen: PropTypes.func,
  // toggleDrawer: PropTypes.func,
  // loadTransition: PropTypes.func,
  // changeMode: PropTypes.func,
  // sidebarOpen: PropTypes.bool,
  pageLoaded: PropTypes.bool,
  mode: PropTypes.string,
  gradient: PropTypes.bool,
  bgPosition: PropTypes.string,
  title:PropTypes.string,
  pageTitle: PropTypes.string,
};

// const reducer = 'ui';
const mapStateToProps = state => ({
  // sidebarOpen: true,
  pageLoaded: false,
  mode: 'light',
  gradient: true,
  bgPosition: 'half',
  ...state,
});

const mapDispatchToProps = dispatch => ({
  // toggleDrawer: () => dispatch(toggleAction),
  // initialOpen: bindActionCreators(openAction, dispatch),
  // loadTransition: bindActionCreators(playTransitionAction, dispatch),
});

const DashboardMaped = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default (withStyles(styles)(DashboardMaped));