import React from 'react';
import './attestation.css';
import { loadScript } from '../../helper/helper';

import { PDF_FINRARULE_BOTH } from '../../constants';
class AttestationComponent extends React.PureComponent {

     componentDidMount() {
        loadScript();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="">
                            <div className="card panel-header-title attestation-content" style={{ height: 'auto' }}>
                                <div className="card-heading ">Rule 5130 and Rule 5131</div>
                                <div className="card-body">
                                    <div className="attestation-body-content">
                                        <iframe src={PDF_FINRARULE_BOTH} title="5130 and 5131 pdf" allowFullScreen frameBorder="0" style={{height:'100vh',width:'100%'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default AttestationComponent
