import * as types from './actionTypes';  
import tradestationApi from '../api/tradestationApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';

export function getTradeStation(data) {
  return {
    type: types.DO_TRADESTATION, 
    data
  };
}

export function doBrokerDealerConnect(data) {
  return {
    type: types.DO_BROKERDEALER_CONNECT, 
    data
  };
}

export function doGetprofile(data) {
  return {
    type: types.DO_GETPROFILE,
    data
  };
}

export function doTradeStationRes(data) {  
  return {
    type: types.DO_TRADESTATION_RES, 
    data
  };
}

export function doBrokerDealerConnectRes(data) {  
  return {
    type: types.DO_BROKERDEALER_CONNECT_RES, 
    data
  };
}


export function doGetprofileRes(data) {
  return {
    type: types.DO_GETPROFILE_RES,
    data
  };
}

export function get_tradestation(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      tradestationApi.getTradeStation(data).then(res => {
        dispatch(doTradeStationRes(res))
        dispatch(broker_delaer_connect(res));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}

export function broker_delaer_connect(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      tradestationApi.doBrokerDealerConnect(data).then(res => {
        dispatch(doBrokerDealerConnectRes(res));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}
