import * as types from './actionTypes';
import verifyPasswordApi from '../api/verifyPasswordApi';
import Swal from 'sweetalert2'

export function doVerifyPassword(data) {
  return {
    type: types.DO_VERIFYPASSWORD,
    data
  };
}

export function doVerifyPasswordRes(user) {
  return {
    type: types.DO_VERIFYPASSWORD_RES,
    user
  };
}

export function verify_password(data) {
    return function(dispatch) {
      verifyPasswordApi.doVerifyPassword(data).then(user => {
        dispatch(doVerifyPasswordRes(user));
        dispatch(doVerifyPasswordRes(null));
        if(user.error){
          Swal.fire({
            title: user.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
}
