import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';

class newBrokerDelaerConnectionApi {
    static newBrokerDelaerConnection(data) {
        const TOKEN = getItem('auth_token');
        let isError = false;

        let brokerDealerConnectiondata={
            broker_dealer_mpid : data.broker_dealer_mpid,
            refresh_token: data.refresh_token,
            uid: data.uid,
            account_id: data.account_id,
            account_name: data.account_name,
            account_type: data.account_type,
            buying_power: data.buying_power,
        }

        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN,
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body:JSON.stringify(brokerDealerConnectiondata)
            }
            return fetch(BASE_URL + '/broker_dealer_connections', body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default newBrokerDelaerConnectionApi;