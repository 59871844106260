import * as types from './actionTypes';  
import linkedinAccountApi from '../api/linkedInApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';

export function doLinkedinAccount(data) {
  return {
    type: types.DO_LINKEDIN_ACCOUNT, 
    data
  };
}

export function doLinkedinAccountRes(data) {  
  return {
    type: types.DO_LINKEDIN_ACCOUNT_RES, 
    data
  };
}

export function doLinkedinUserAccountData(data) {
  return {
    type: types.DO_LINKEDIN_USER_ACCOUNT_DATA, 
    data
  };
}

export function doLinkedinUserAccountDataRes(data) {  
  return {
    type: types.DO_LINKEDIN_USER_ACCOUNT_DATA_RES, 
    data
  };
}

export function linkedin_account(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      linkedinAccountApi.doLinkedinAccount(data).then(res => {
        dispatch(linkedin_user_account_data(res));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}

export function linkedin_user_account_data(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      linkedinAccountApi.doLinkedinUserAccountData(data).then(res => {
        dispatch(doLinkedinUserAccountDataRes(res));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}