import * as types from './actionTypes';  
import createBrokerageAccApi from '../api/createBrokerageAccApi';
import {logout} from '../helper/helper';
// import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2'

export function doCreateBrokerageAcc(data) {
  return {
    type: types.DO_CREATE_BROKERAGE_ACCOUNT, 
    data
  };
}

export function doCreateBrokerageAccRes(data) {  
  return {
    type: types.DO_CREATE_BROKERAGE_ACCOUNT_RES, 
    data
  };
}
export function loadingRes(data) {  
  return {
    type: types.LOADING_RES, 
    data
  };
}
export function submitCreateBrokerageForm(reqData, usernameData, brokerDetail, token) {
  if(token){
    return function(dispatch) {
      createBrokerageAccApi.doCreateBrokerageAccUsername(usernameData, brokerDetail).then(data => {
        if (!data.message) {
          let payload = {
            grant_type: 'password',
            client_id: brokerDetail.bd_id,
            client_secret: brokerDetail.db_secret,
            username: usernameData.username,
            password: usernameData.password
          }
          dispatch(submitCreateBrokeragePasswordForm(payload, reqData, brokerDetail, token));
        } 
        if (data.message) {
          let loading = {
            status: false
          }
          dispatch(loadingRes(loading));
          dispatch(loadingRes(null));

          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}


export function submitCreateBrokeragePasswordForm(data, reqData, broker, token) {
  if(token){
    return function(dispatch) {
      createBrokerageAccApi.doCreateBrokerageAccPassword(data, broker).then(data => {
        if (data.access_token) {
          let reqToken = data.access_token;
          dispatch(submitCreateBrokerageAccountForm(reqData, reqToken, broker, token));
        } else {
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}
export function submitCreateBrokerageAccountForm(reqData, tokenss, broker, token) {
  if(token){
    return function(dispatch) {
      createBrokerageAccApi.doCreateBrokerageAcc(reqData, tokenss, broker).then(data => {
        if (data.success === true) {
          dispatch(submitScorePriorityAccessToken(reqData, tokenss, token));
          
        } else {
          let loading = {
            status: false,
            message: data.validation_messages
          }
          dispatch(loadingRes(loading));
          dispatch(loadingRes(null));
        }
        // if(data.error){
        //   Swal.fire({
        //     title: data.message,
        //     icon: 'error',
        //     confirmButtonText: 'OK',
        //     allowOutsideClick: false,
        //     timer: 3000,
        //     heightAuto: false
        //   })
        // }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}
export function submitScorePriorityAccessToken(data, tokenss, token) {
  if(token){
    return function(dispatch) {
      createBrokerageAccApi.doScorePriorityAccessToken(data, tokenss, token).then(data => {
        
        dispatch(doCreateBrokerageAccRes(data));
        dispatch(doCreateBrokerageAccRes(null));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}