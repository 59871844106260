import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

class SocialMessagePostApi {
  static SocialMessagePostApi(data) {
    const TOKEN = getItem('auth_token');
    let isError = false;

    let socialMessageData = {
      provider: data.provider,
      content: data.content,
      ext_id: data.ext_id
    }

    try {
      const ajaxRequestHeaders = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': TOKEN
      });

      let body = {
        method: 'POST',
        headers: ajaxRequestHeaders,
        body: JSON.stringify(socialMessageData)
      }

      return fetch(BASE_URL + '/social_media/post', body).then(response => {
        if (response.ok === true) {
          Swal.fire({
            title: 'Share was successful',
            icon: 'success',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
        isError = errorHandling(response);
        return response.json();
      }).catch(error => {
        if (error && !isError) {
            errorHandler(error);
        }
        return error;
    });
    } catch (err) {
    }
  }
}

export default SocialMessagePostApi;