import { getItem } from '../utils/localStore';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class stocktwitsAccountApi {
    static doStocktwitsAccount(data) {
        const TOKEN = getItem('auth_token');

        let stocktwitsdata = {
            grant_type: 'authorization_code',
            client_id: data.client_id,
            redirect_uri: data.redirect_uri,
            code: data.access_token,
            client_secret: data.client_secret,
        }
        let isError = false;

        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(stocktwitsdata)
            }
            return fetch('https://api.stocktwits.com/api/2/oauth/token', body).then(response => {
                if(response.ok === true){
                    window.location.href = '/editprofile';
                }else{
                    window.location.href = '/';
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default stocktwitsAccountApi;