import * as types from './actionTypes';
import resendEmailApi from '../api/resendEmailApi';
import Swal from 'sweetalert2'

export function doResendEmail(data) {
  return {
    type: types.DO_RESENDEMAIL,
    data
  };
}

export function doResendEmailRes(user) {
  return {
    type: types.DO_RESENDEMAIL_RES,
    user
  };
}

export function submitResendEmail(data) {
    return function(dispatch) {
      resendEmailApi.doResendEmail(data).then(user => {
        dispatch(doResendEmailRes(user));
        dispatch(doResendEmailRes(null));
        if(user.error){
          Swal.fire({
            title: user.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
}
