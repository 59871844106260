import * as types from './actionTypes';
import TwitterAuthUrl from '../api/TwitterAuthUrl';
import { logout } from '../helper/helper';
import { getItem } from '../utils/localStore';
// import Swal from 'sweetalert2';

export function getTwitterAuthUrl() {
  return {
    type: types.GET_TWITTER_AUTH_URL
  };
}

export function getTwitterAuthUrlRes(data) {
  return {
    type: types.GET_TWITTER_AUTH_URL_RES,
    data
  }
}

export function get_twitter_auth_url() {
  const TOKEN = getItem('auth_token');
  if (TOKEN) {
    return function(dispatch) {
      TwitterAuthUrl.getTwitterAuthUrl().then(data => {
        dispatch(getTwitterAuthUrlRes(data));
      }).catch(error => {
        return error;
      });
    }
  } else {
    logout();
  }
}