
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './faq.css';
import Dashboard from '../../component/Dashboard';
import { faq_list, doFaqRes } from '../../actions/faqActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { loadScript } from '../../helper/helper';

class FaqComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            screenId: '1.1',
        }
    }

    componentDidMount() {
        loadScript();
        const data = {};
        this.props.getFaq(data);
        const activityData = {
            'screen_id': this.state.screenId,
            'source': 'web'
        }
        this.props.userActivity(activityData)
    }

    static getDerivedStateFromProps(props, state) {
		if (props.FaqRes) {
            if (props.FaqRes.data && props.FaqRes.data.faq) {
                if (props.FaqRes.data.faq.length > 0) {
                    return {
                        faqList: props.FaqRes.data.faq
                    };
                }
            }
        }
		return null
	}
    render() {
        const { faqList } = this.state;
        return (
            <Dashboard title="ClickIPO - FAQs" pageTitle="FAQs" >
                <div className="faq_content">
                    <div className="faq_inner_content">
                        {
                            faqList && faqList.length > 0 && faqList.map((faqitem, i) =>
                                <div key={i}>
                                    <section className="faq-section">
                                        <input type="checkbox" id={faqitem.id} />
                                        <label id={faqitem.id}>{faqitem.question}</label>
                                        <p>{faqitem.answer}</p>
                                    </section>
                                </div>
                            )
                        }
                    </div>
                </div>
            </Dashboard>
        )
    }

}


FaqComponent.propTypes = {
    FaqRes: PropTypes.any,
    userActivityRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    FaqRes: doFaqRes,
    userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
    return {
        getFaq: (data) => dispatch(faq_list(data)),
        userActivity: (data) => dispatch(user_activity(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FaqComponent);
