import { BASE_URL } from '../constants';
import { getItem } from '../utils/localStore';

class TwitterAuthUrl {
  static async getTwitterAuthUrl() {
    const TOKEN = getItem('auth_token');
    try {
      const ajaxRequestHeaders = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': TOKEN
      });

      let body = {
        method: 'GET',
        headers: ajaxRequestHeaders,
      }

      const response = await fetch(`${BASE_URL}/web/preauth/twitter`, body)

      if (response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
      }
      return response.json();
    } catch (err) {
    }
  }
}

export default TwitterAuthUrl;
