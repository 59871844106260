import * as types from './actionTypes';  
import messageDetailMobApi from '../api/messageDetailMobApi';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doMessageDetailMob(url, token) {

  return {
    type: types.DO_MESSAGE_DETAIL_MOB,
    url,
    token
  };
}

export function doMessageDetailMobRes(data) {  
  return {
    type: types.DO_MESSAGE_DETAIL_MOB_RES, 
    data
  };
}

export function fetchMessageDetailMob(url, token) {
  const TOKEN = token;
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      messageDetailMobApi.doMessageDetailMob(url, token).then(data => {
        dispatch(doMessageDetailMobRes(data));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }
}

