import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './securitySymbol.css';
import { dateFormat, loadScript } from '../../helper/helper';
import { get_symbol, doSymbolRes } from '../../actions/securitySymbolAction';
import { get_prospectus, doProspectusRes } from '../../actions/prospectusActions';
import PlaceHolderImg from '../../assets/images/placeholder_company_thumb.png';
import logo from '../../assets/images/clickipo_logo.png';
import DocIcon from '../../assets/images/doc-icon.svg';

import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500],
  },
}))(props => {
const { children, classes, onClose } = props;
return (
  <MuiDialogTitle disableTypography className={classes.root}>
  <Typography variant="h6">{children}</Typography>
  {onClose ? (
    <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
    <CloseIcon />
    </IconButton>
  ) : null}
  </MuiDialogTitle>
);
});

const DialogContent = withStyles(theme => ({
root: {
  margin: 0,
  padding: theme.spacing(2),
},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
root: {
  borderTop: `1px solid ${theme.palette.divider}`,
  margin: 0,
  padding: theme.spacing(),
  alignItems: 'center',
  justifyContent: 'center'
},
}))(MuiDialogActions);
class SecuritySymbolComponent extends React.PureComponent {

  constructor() {
    super();
    this.state = {
      symbolList: '',
      prospectusModal: false
    }
  }

  componentDidMount() {
    loadScript();
    const symbol = this.props.match.params.symbol;
    this.props.getsymbol(symbol);
  }

  // why is this hard coded this value should be recieved from the backend
  addDefaultSrc(ev) {
    ev.target.src = PlaceHolderImg;
  }

  onCloseModal = () => {
    this.setState({
      prospectusModal: false
    });
  }

  openProspectusModal = () => {
    this.setState({
      prospectusModal: true
    })
  }

  readProspectus(geturl) {
    const url = geturl;
    window.open(url, '_blank');
  }

  emailProspectus(id) {
    let prosid = {
      'ext_id': id
    }
    this.props.getprospectus(prosid);
  }

  cancelProspectus() {
    this.setState({
      prospectusModal: false
    });
  }

  //  ==================== Receive Props ===================

  static getDerivedStateFromProps(props, state) {
		if (props.SymbolRes) {
      if (props.SymbolRes.data && props.SymbolRes.data.symbol) {
        if (props.SymbolRes.data.symbol.message === 'Success') {
          return {
            symbolList: props.SymbolRes.data.symbol.data,
          };
        }
      }
    }
		return null
	}
  render() {
    const Header = (<div className="offer_head">Symbol</div>);
    return (
      <div title="ClickIPO User" header={Header}>
        <div className="container-fluid symbol_header">
          <div className="clickipo_ipo">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="content main_content_box_inner symbol_content">
          {
            this.state.symbolList.error &&
            <div>
              <div className="no_symbol">
                {this.state.symbolList.message}
              </div>
            </div>
          }
          {
            this.state.symbolList &&
            <div className="offring_deatils_content">
              <div className="offring_deatils_heading">
                <span className="offering_detail_title">{this.state.symbolList.name}</span>
              </div>
              <div className="offring_deatils_inner_content">
                <div className="row ml-mr-0px-imp">
                  <div className="col-sm-12 col-md-7">
                    <div className="offering_details_left_content">
                      <img src={this.state.symbolList.logo_small} onError={this.addDefaultSrc} className="details_logo" alt="" />
                      <p className="offering_name">{this.state.symbolList.name}</p>
                      <p className="description_heading">DESCRIPTION</p>
                      <p className="description_content"> {this.state.symbolList.description} </p>
                      <p className="item_link"> PROSPECTUS </p>
                      <div className="icon_text" onClick={this.openProspectusModal}>
                        <img src={DocIcon} alt="" />
                        <span>Prospectus</span>
                      </div>
                      <span className="order_status">UNDERWRITERS</span>
                        {
                            this.state.symbolList.underwriters_list && this.state.symbolList.underwriters_list.length > 0 && this.state.symbolList.underwriters_list.map((offeringUnderWriter, i) =>
                                <div key={i}>
                                    {
                                        offeringUnderWriter.executing_broker_name === null &&
                                        <span className="order_status_text">
                                            Not Available
                                        </span>
                                    }
                                    {
                                        offeringUnderWriter.executing_broker_name !== null &&
                                        <span className="order_status_text">
                                            {offeringUnderWriter.executing_broker_name}
                                        </span>
                                    }
                                </div>
                            )  
                        }
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5 offring_deatils_inner_content_middle">
                    <p className="company_offringdeatils_tag">
                      {this.state.symbolList.ticker_symbol}
                    </p>
                    <p className="company_offringdeatils_account" style={{ color: this.state.symbolList.offering_type_name === 'IPO' ? "#8DC73F" : "#44c6d2" }}>
                      {this.state.symbolList.offering_type_name}
                    </p>
                    <div className="row">
                      <div className="col col-6">
                        <p className="industries_content">Industry</p>
                      </div>
                      <div className="col col-6">
                        <span className="industries_content_text">
                          <div>
                            { this.state.symbolList.industry === null && <p className="account_open_date"> Not Available </p> }
                            {
                              this.state.symbolList.industry !== null &&
                              <p className="account_open_date" style={{ color: this.state.symbolList.offering_type_name === 'IPO' ? "#8DC73F" : "#44c6d2" }}>
                                {this.state.symbolList.industry}
                              </p>
                            }
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col col-6">
                        <p className="account_offringdeatils_type">Anticipated</p>
                      </div>
                      <div className="col col-6">
                        {this.state.symbolList.trade_date ?
                          <p className="account_open_offringdeatils_date"> {dateFormat(this.state.symbolList.trade_date)} </p> :
                          <p className="account_open_offringdeatils_date"> TBD </p>
                        }
                      </div>
                    </div>
                    {
                      this.state.symbolList.offering_type_name === 'IPO' &&
                      <div className="row">
                        <div className="col-6">
                          <p className="price_level_offering_type clearfix">Anticipated price range</p>
                        </div>
                        <div className="col-6">
                        { (this.state.symbolList.min_price && this.state.symbolList.max_price) ? 
                          <span className="amount_offring"> $ {(this.state.symbolList.min_price).toFixed(2)} - $ {(this.state.symbolList.max_price).toFixed(2)} </span> :
                          <span className="amount_offring"> TBD </span>
                        }
                        </div>
                      </div>
                    }
                    {
                      this.state.symbolList.offering_type_name === 'Follow-on' &&
                      <div className="row">
                        <div className="col-6">
                          <p className="price_level_offering_type clearfix">Last closing price</p>
                        </div>
                        <div className="col-6">
                          { this.state.symbolList.max_price ? 
                            <span className="amount_offring"> $ {(this.state.symbolList.max_price).toFixed(2)} </span> :
                            <span className="amount_offring">TBD </span>
                          }
                        </div>
                      </div>
                    }
                    {
                      this.state.symbolList.offering_type_name === 'Overnight' &&
                      <div className="row">
                        <div className="col-6">
                          <p className="price_level_offering_type clearfix">Last closing price</p>
                        </div>
                        <div className="col-6">
                          { this.state.symbolList.max_price ? 
                            <span className="amount_offring"> $ {(this.state.symbolList.max_price).toFixed(2)} </span> :
                            <span className="amount_offring"> TBD </span>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <Dialog
            open={this.state.prospectusModal}
            onClose={this.onCloseModal}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Prospectus</DialogTitle>
          <DialogContent>
              <DialogContentText>
                  Would you like to read the prospectus or email yourself a copy?
              </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div style={{textAlign: 'center'}}>
                <Button className="btns-all" onClick={() => this.readProspectus( this.state.offeringsdetailsList.prospectus_url)} color="primary">
                    Read
                </Button>
                <Button className="btns-all" onClick={() => this.emailProspectus( this.state.offeringsdetailsList.ext_id)} color="primary">
                    Email
                </Button>
                <Button className="btns-all-cancel" onClick={() => this.cancelProspectus()} color="primary">
                    Cancel
                </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

SecuritySymbolComponent.propTypes = {
  SymbolRes: PropTypes.any,
  prospectusRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  SymbolRes: doSymbolRes,
  prospectusRes: doProspectusRes,
});

function mapDispatchToProps(dispatch) {
  return {
    getsymbol: (symbol) => dispatch(get_symbol(symbol)),
    getprospectus: (data) => dispatch(get_prospectus(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SecuritySymbolComponent);
