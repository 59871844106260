export const DO_LOGIN = 'DO_LOGIN';
export const DO_LOGIN_RES = 'DO_LOGIN_RES';

export const DO_LOADING_RES = 'DO_LOADING_RES';

export const DO_SIGNUP = 'DO_SIGNUP';
export const DO_SIGNUP_RES = 'DO_SIGNUP_RES';

export const CHECK_USER = 'CHECK_USER';
export const CHECK_USER_RES = 'CHECK_USER_RES';

export const DO_FORGOTPASSWORD = 'DO_FORGOTPASSWORD';
export const DO_FORGOTPASSWORD_RES = 'DO_FORGOTPASSWORD_RES';

export const DO_VERIFYCODE = 'DO_VERIFYCODE';
export const DO_VERIFYCODE_RES = 'DO_VERIFYCODE_RES';

export const DO_RESETPASSWORD = 'DO_RESETPASSWORD';
export const DO_RESETPASSWORD_RES = 'DO_RESETPASSWORD_RES';

export const DO_VERIFYPASSWORD = 'DO_VERIFYPASSWORD';
export const DO_VERIFYPASSWORD_RES = 'DO_VERIFYPASSWORD_RES';

export const DO_EDITPROFILE = 'DO_EDITPROFILE';
export const DO_EDITPROFILE_RES = 'DO_EDITPROFILE_RES';

export const DO_UPDATEPROFILE = 'DO_UPDATEPROFILE';
export const DO_UPDATEPROFILE_RES = 'DO_UPDATEPROFILE_RES';

export const DO_OFFERING = 'DO_OFFERING';
export const DO_OFFERING_RES = 'DO_OFFERING_RES';

export const DO_OFFERING_INDUSTRIES = 'DO_OFFERING_INDUSTRIES';
export const DO_OFFERING_INDUSTRIES_RES = 'DO_OFFERING_INDUSTRIES_RES';

export const DO_OFFERING_DETAILS = 'DO_OFFERING_DETAILS';
export const DO_OFFERING_DETAILS_RES = 'DO_OFFERING_DETAILS_RES';

export const DO_OFFERING_SAVE = 'DO_OFFERING_SAVE';
export const DO_OFFERING_SAVE_RES = 'DO_OFFERING_SAVE_RES';

export const DO_ORDER = 'DO_ORDER';
export const DO_ORDER_RES = 'DO_ORDER_RES';

export const DO_ORDER_DETAILS = 'DO_ORDER_DETAILS';
export const DO_ORDER_DETAILS_RES = 'DO_ORDER_DETAILS_RES';

export const DO_UPDATE_ORDER = 'DO_UPDATE_ORDER_DETAILS';
export const DO_UPDATE_ORDER_RES = 'DO_UPDATE_ORDER_DETAILS_RES';

export const DO_BROKERDEALER = 'DO_BROKERDEALER';
export const DO_BROKERDEALER_RES = 'DO_BROKERDEALER_RES';

export const DO_BROKERDEALER_CONNECT = 'DO_BROKERDEALER_CONNECT';
export const DO_BROKERDEALER_CONNECT_RES = 'DO_BROKERDEALER_CONNECT_RES';

export const DO_BROKERDEALER_DISCONNECT = 'DO_BROKERDEALER_DISCONNECT';
export const DO_BROKERDEALER_DISCONNECT_RES = 'DO_BROKERDEALER_DISCONNECT_RES';

export const DO_BROKERDEALER_CONNECT_ACCOUNT = 'DO_BROKERDEALER_CONNECT_ACCOUNT';
export const DO_BROKERDEALER_CONNECT_ACCOUNT_RES = 'DO_BROKERDEALER_CONNECT_ACCOUNT_RES';

export const DO_FAQ = 'DO_FAQ';
export const DO_FAQ_RES = 'DO_FAQ_RES';

export const DO_GLOSSARY = 'DO_GLOSSARY';
export const DO_GLOSSARY_RES = 'DO_GLOSSARY_RES';

export const DO_ACCOUNTS = 'DO_ACCOUNTS';
export const DO_ACCOUNTS_RES = 'DO_ACCOUNTS_RES';

export const DO_ACCOUNTS_BUYING_POWER = 'DO_ACCOUNTS_BUYING_POWER';
export const DO_ACCOUNTS_BUYING_POWER_RES = 'DO_ACCOUNTS_BUYING_POWER_RES';

export const DO_TRADESTATION = 'DO_TRADESTATION';
export const DO_TRADESTATION_RES = 'DO_TRADESTATION_RES';

export const DO_SOCIAL_LOGIN = 'DO_SOCIAL_LOGIN';
export const DO_SOCIAL_LOGIN_RES = 'DO_SOCIAL_LOGIN_RES';

export const DO_DELETE_SOCIAL_ACCOUNT = 'DO_DELETE_SOCIAL_ACCOUNT';
export const DO_DELETE_SOCIAL_ACCOUNT_RES = 'DO_DELETE_SOCIAL_ACCOUNT_RES';

export const DO_GETPROFILE = 'DO_GETPROFILE';
export const DO_GETPROFILE_RES = 'DO_GETPROFILE_RES';

export const DO_DELETE_ORDER = 'DO_DELETE_ORDER';
export const DO_DELETE_ORDER_RES = 'DO_DELETE_ORDER_RES';

export const DO_ACTIVE_ORDER = 'DO_ACTIVE_ORDER';
export const DO_ACTIVE_ORDER_RES = 'DO_ACTIVE_ORDER_RES';

export const DO_PLACE_ORDER = 'DO_PLACE_ORDER';
export const DO_PLACE_ORDER_RES = 'DO_PLACE_ORDER_RES';

export const DO_READ_OFFERING = 'DO_READ_OFFERING';
export const DO_READ_OFFERING_RES = 'DO_READ_OFFERING_RES';

export const DO_USER_CONFIRM = 'DO_USER_CONFIRM';
export const DO_USER_CONFIRM_RES = 'DO_USER_CONFIRM_RES';

export const GET_SOCIAL_MEDIA_AUTH_URL = 'GET_SOCIAL_MEDIA_AUTH_URL';
export const GET_SOCIAL_MEDIA_AUTH_URL_RES = 'GET_SOCIAL_MEDIA_AUTH_URL_RES';

export const GET_TWITTER_AUTH_URL = 'GET_TWITTER_AUTH_URL';
export const GET_TWITTER_AUTH_URL_RES = 'GET_TWITTER_AUTH_URL_RES';

export const GET_FACEBOOK_AUTH_URL = 'GET_FACEBOOK_AUTH_URL';
export const GET_FACEBOOK_AUTH_URL_RES = 'GET_FACEBOOK_AUTH_URL_RES';

export const DO_STOCKTWITS_ACCOUNT = 'DO_STOCKTWITS_ACCOUNT';
export const DO_STOCKTWITS_ACCOUNT_RES = 'DO_STOCKTWITS_ACCOUNT_RES';

export const DO_LINKEDIN_ACCOUNT = 'DO_LINKEDIN_ACCOUNT';
export const DO_LINKEDIN_ACCOUNT_RES = 'DO_LINKEDIN_ACCOUNT_RES';

export const DO_LINKEDIN_USER_ACCOUNT_DATA = 'DO_LINKEDIN_USER_ACCOUNT_DATA';
export const DO_LINKEDIN_USER_ACCOUNT_DATA_RES = 'DO_LINKEDIN_USER_ACCOUNT_DATA_RES';

export const DO_PROSPECTUS = 'DO_PROSPECTUS';
export const DO_PROSPECTUS_RES = 'DO_PROSPECTUS_RES';

export const DO_SECURITY_SYMBOL = 'DO_SECURITY_SYMBOL';
export const DO_SECURITY_SYMBOL_RES = 'DO_SECURITY_SYMBOL_RES';

export const DO_MARKET_BROKERAGE = 'DO_MARKET_BROKERAGE';
export const DO_MARKET_BROKERAGE_RES = 'DO_MARKET_BROKERAGE_RES';

export const DO_RESTRICTED_PERSON = 'DO_RESTRICTED_PERSON';
export const DO_RESTRICTED_PERSON_RES = 'DO_RESTRICTED_PERSON_RES';

export const DO_SOCIAL_TOKEN = 'DO_SOCIAL_TOKEN';
export const DO_SOCIAL_TOKEN_RES = 'DO_SOCIAL_TOKEN_RES';

export const POST_SOCIAL_MEDIA_MESSAGE = 'POST_SOCIAL_MEDIA_MESSAGE';
export const POST_SOCIAL_MEDIA_MESSAGE_RES = 'POST_SOCIAL_MEDIA_MESSAGE_RES';

export const DO_NEW_BROKER_DEALER_CONNECTION = 'DO_NEW_BROKERDEALER_CONNECTION';
export const DO_NEW_BROKER_DEALER_CONNECTION_RES = 'DO_NEW_BROKERDEALER_CONNECTION_RES';

export const DO_BROKER_CONNECTION_TOKEN = 'DO_BROKER_CONNECTION_TOKEN';
export const DO_BROKER_CONNECTION_TOKEN_RES = 'DO_BROKER_CONNECTION_TOKEN_RES';

export const SHOW_LOADER_RES = 'SHOW_LOADER_RES';

export const DO_RESENDEMAIL = "DO_RESENDEMAIL";
export const DO_RESENDEMAIL_RES = "DO_RESENDEMAIL_RES";

export const DO_VERIFYEMAIL = "DO_VERIFYEMAIL";
export const DO_VERIFYEMAIL_RES = "DO_VERIFYEMAIL_RES";

export const GET_BIZ_INSIGHTS = "GET_BIZ_INSIGHTS"; 
export const GET_BIZ_INSIGHTS_RES = "GET_BIZ_INSIGHTS_RES"; 

export const USER_ACTIVITY = "USER_ACTIVITY"; 
export const USER_ACTIVITY_RES = "USER_ACTIVITY_RES";

export const DO_MESSAGE = "DO_MESSAGE"; 
export const DO_MESSAGE_RES = "DO_MESSAGE_RES";

export const DO_MESSAGE_DETAIL = "DO_MESSAGE_DETAIL"; 
export const DO_MESSAGE_DETAIL_RES = "DO_MESSAGE_DETAIL_RES";

export const DO_MESSAGE_DETAIL_MOB = "DO_MESSAGE_DETAIL_MOB"; 
export const DO_MESSAGE_DETAIL_MOB_RES = "DO_MESSAGE_DETAIL_MOB_RES";

export const DO_OFFERINGLIST = "DO_OFFERINGLIST"; 
export const DO_OFFERINGLIST_RES = "DO_OFFERINGLIST_RES";

export const ALL_COMMENTS_LIST = "ALL_COMMENTS_LIST"; 
export const ALL_COMMENTS_LIST_RES = "ALL_COMMENTS_LIST_RES";

export const DO_COMMENT_OFFERING = "DO_COMMENT_OFFERING"; 
export const DO_COMMENT_OFFERING_RES = "DO_COMMENT_OFFERING_RES";

export const DO_DELETE_COMMENT_OFFERING = "DO_DELETE_COMMENT_OFFERING"; 
export const DO_DELETE_COMMENT_OFFERING_RES = "DO_DELETE_COMMENT_OFFERING_RES";

export const DO_UPDATE_COMMENT_OFFERING = "DO_UPDATE_COMMENT_OFFERING"; 
export const DO_UPDATE_COMMENT_OFFERING_RES = "DO_UPDATE_COMMENT_OFFERING_RES";

export const DO_UPLOAD_PROFILE_PICTURE = "DO_UPLOAD_PROFILE_PICTURE"; 
export const DO_UPLOAD_PROFILE_PICTURE_RES = "DO_UPLOAD_PROFILE_PICTURE_RES";

export const DO_ATTESTATION_DSP = "DO_ATTESTATION_DSP"; 
export const DO_ATTESTATION_DSP_RES = "DO_ATTESTATION_DSP_RES";

export const DO_CREATE_BROKERAGE_ACCOUNT = "DO_CREATE_BROKERAGE_ACCOUNT"; 
export const DO_CREATE_BROKERAGE_ACCOUNT_RES = "DO_CREATE_BROKERAGE_ACCOUNT_RES";

export const DO_PROSPECTUS_FIRSTVIEW = "DO_PROSPECTUS_FIRSTVIEW"; 
export const DO_PROSPECTUS_FIRSTVIEW_RES = "DO_PROSPECTUS_FIRSTVIEW_RES";

export const LOADING_RES = "LOADING_RES";

export const DO_CHECK_PLANNER_APP = "DO_CHECK_PLANNER_APP"; 
export const DO_CHECK_PLANNER_APP_RES = "DO_CHECK_PLANNER_APP_RES";

export const CREATE_PLANNER_USER_ACCOUNT = "CREATE_PLANNER_USER_ACCOUNT"; 
export const CREATE_PLANNER_USER_ACCOUNT_RES = "CREATE_PLANNER_USER_ACCOUNT_RES";

export const CREATE_PLANNER_TRADING_ACCOUNT = "CREATE_PLANNER_TRADING_ACCOUNT"; 
export const CREATE_PLANNER_TRADING_ACCOUNT_RES = "CREATE_PLANNER_TRADING_ACCOUNT_RES";

export const UPLOAD_PLANNER_FILE_RES = "UPLOAD_PLANNER_FILE_RES";

export const DO_FIREBASE_TOKEN_RES = "DO_FIREBASE_TOKEN_RES";