
import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { sha3_512 } from 'js-sha3';

class loginApi {
    
    static doLogin(data) {
        let sendBody={
            email:data.email,
            encrypted_password:sha3_512(data.encrypted_password),
            client_ip:data.client_ip
        }
        let isError = false;

        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            });
            let body = {
                method: 'post',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(sendBody)
            }
            return fetch(BASE_URL + '/users/login', body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
    }
}  

export default loginApi;