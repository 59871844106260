import * as types from './actionTypes';  
import activeOrderApi from '../api/activeOrderApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';

export function getActiveOrder(id) {

  return {
    type: types.DO_ACTIVE_ORDER,
    id
  };
}

export function doActiveOrderRes(data) {  
  return {
    type: types.DO_ACTIVE_ORDER_RES, 
    data
  };
}

export function get_active_order(id) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      activeOrderApi.getActiveOrder(id).then(data => {
        dispatch(doActiveOrderRes(data));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }

}