
export const timeHorizonOptions = [
    {
        'name': 'Short',
        'value': 'SHORT'
    },
    {
        'name': 'Average',
        'value': 'AVERAGE'
    },
    {
        'name': 'Longest',
        'value': 'LONGEST'
    }
];
export const liquidityNeedsOptions = [
    {
        'name': 'Very Important',
        'value': 'VERY_IMPORTANT'
    },
    {
        'name': 'Some What Important',
        'value': 'SOMEWHAT_IMPORTANT'
    },
    {
        'name': 'Not Important',
        'value': 'NOT_IMPORTANT'
    }
];
export const catAccountholderTypeOptions = [
    {
        'name': 'Institutional Customer',
        'value': 'A'
    },
    {
        'name': 'Employee Account',
        'value': 'E'
    },
    {
        'name': 'Individual Customer',
        'value': 'I'
    }
];
export const accountTypeOptions = [
    {
        'name': 'Cash',
        'value': 'CASH'
    }
];
export const visaTypeOptions = [
    {
        'name': 'E1',
        'value': 'E1'
    },
    {
        'name': 'E2',
        'value': 'E2'
    },
    {
        'name': 'E3',
        'value': 'E3'
    },
    {
        'name': 'F1',
        'value': 'F1'
    },
    {
        'name': 'G4',
        'value': 'G4'
    },
    {
        'name': 'H1B',
        'value': 'H1B'
    },
    {
        'name': 'L1',
        'value': 'L1'
    },
    {
        'name': 'O1',
        'value': 'O1'
    },
    {
        'name': 'TN1',
        'value': 'TN1'
    },
    {
        'name': 'OTHER',
        'value': 'OTHER'
    }
];
export const phoneNumberTypeOptions = [
    {
        'name': 'Home',
        'value': 'HOME'
    },
    {
        'name': 'Work',
        'value': 'WORK'
    },
    {
        'name': 'Mobile',
        'value': 'MOBILE'
    },
    {
        'name': 'Fax',
        'value': 'FAX'
    },
    {
        'name': 'Other',
        'value': 'OTHER'
    }
];
export const empStatusOptions = [
    {
        'name': 'Employed',
        'value': 'EMPLOYED'
    },
    {
        'name': 'Retired',
        'value': 'RETIRED'
    },
    {
        'name': 'Student',
        'value': 'STUDENT'
    },
    {
        'name': 'Un-Employed',
        'value': 'UNEMPLOYED'
    }
];
export const customerTypeOption = [
    {
        'name': 'Individual',
        'value': 'INDIVIDUAL'
    },
    {
        'name': 'IRA',
        'value': 'IRA'
    },
    {
        'name': 'Joint',
        'value': 'JOINT'
    },
    {
        'name': 'Custodian',
        'value': 'CUSTODIAN'
    }
];
export const sweepInstructionsOption = [
    {
        'name': 'Sweep',
        'value': 'SWEEP'
    },
    {
        'name': 'No-Sweep',
        'value': 'NO_SWEEP'
    }
];
export const dividendReinvestmentOption = [
    {
        'name': 'Reinvest',
        'value': 'REINVEST'
    },
    {
        'name': 'Do not reinvest',
        'value': 'DO_NOT_REINVEST'
    }
];
export const dividendProceedsSendFrequencyOption = [
    {
        'name': 'Daily',
        'value': 'DAILY'
    },
    {
        'name': 'Weekly',
        'value': 'WEEKLY'
    },
    {
        'name': 'Monthly',
        'value': 'MONTHLY'
    }
];
export const riskToleranceOptions = [
    {
        'name': 'Low',
        'value': 'LOW',
    },
    {
        'name': 'Medium',
        'value': 'MEDIUM'
    },
    {
        'name': 'High',
        'value': 'HIGH'
    }
];
export const objectivesOptions = [
    {
        'name': 'Growth Income',
        'value': 'GROWTH_INCOME',
    },
    {
        'name': 'Growth',
        'value': 'GROWTH'
    },
    {
        'name': 'Income',
        'value': 'INCOME'
    },
    {
        'name': 'Capital Preservation',
        'value': 'CAPITAL_PRESERVATION'
    },
    {
        'name': 'Speculation',
        'value': 'SPECULATION'
    },
    {
        'name': 'Maximum Growth',
        'value': 'MAXIMUM_GROWTH'
    },
    {
        'name': 'Balanced',
        'value': 'BALANCED'
    },
    {
        'name': 'Other',
        'value': 'OTHER'
    }
];
export const maritalStatusOptions = [
    {
        'name': 'Single',
        'value': 'SINGLE'
    },
    {
        'name': 'Married',
        'value': 'MARRIED'
    },
    {
        'name': 'Divorced',
        'value': 'DIVORCED'
    },
    {
        'name': 'Widowed',
        'value': 'WIDOWED'
    }
]

export const salutationOptions = [
    {
        'name': 'NoSalutation',
        'value': 'NoSalutation'
    },
    {
        'name': 'Mr',
        'value': 'Mr'
    },
    {
        'name': 'Mrs',
        'value': 'Mrs'
    },
    {
        'name': 'Ms',
        'value': 'Ms'
    },
    {
        'name': 'Dr',
        'value': 'Dr'
    },
    {
        'name': 'Sir',
        'value': 'Sir'
    },
    {
        'name': 'Madam',
        'value': 'Madam'
    }
];
export const suffixOptions = [
    {
        'name': 'NoSuffix',
        'value': 'NoSuffix'
    },
    {
        'name': 'Jr',
        'value': 'Jr'
    },
    {
        'name': 'Sr',
        'value': 'Sr'
    },
    {
        'name': 'Ms',
        'value': 'Ms'
    },
    {
        'name': 'Second',
        'value': 'Second'
    },
    {
        'name': 'Third',
        'value': 'Third'
    },
    {
        'name': 'Fourth',
        'value': 'Fourth'
    }
];
export const statusOptions = [
    {
        'name': 'New',
        'value': 'New'
    },
    {
        'name': 'SentToClearing',
        'value': 'SentToClearing'
    },
    {
        'name': 'Approved',
        'value': 'Approved'
    },
    {
        'name': 'Rejected',
        'value': 'Rejected'
    }
];