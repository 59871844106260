import * as types from './actionTypes';  
import brokerDealerAppApi from '../api/brokerDealerAppApi';
import {logout} from '../helper/helper';

export function doBrokerdelarApp(token) {

  return {
    type: types.DO_BROKERDEALER, 
    token
  };
}

export function doBrokerdelarRes(data) {  
  return {
    type: types.DO_BROKERDEALER_RES, 
    data
  };
}

export function getBrokerDealer(token) {
  if(token){
    return function(dispatch) {
      brokerDealerAppApi.doBrokerdelarApp(token).then(res => {
        dispatch(doBrokerdelarRes(res));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}
