import * as types from './actionTypes';  
import messageApi from '../api/messageApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
// import { loadingRes } from './loadingActions';

export function doMessage(page) {

  return {
    type: types.DO_MESSAGE,
    page,
  };
}

export function doMessageRes(data) {  
  return {
    type: types.DO_MESSAGE_RES, 
    data
  };
}

export function fetchMessages(page) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      // dispatch(loadingRes(true));
      messageApi.doMessage(page).then(data => {
        dispatch(doMessageRes(data));
        dispatch(doMessageRes(null));
        // dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        // dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}

