import * as types from './actionTypes';  
import accountsbuyingpowerApi from '../api/accountsBuyingPowerApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';

export function getAccountsBuyingPower(data) {
  return {
    type: types.DO_ACCOUNTS_BUYING_POWER, 
    data
  };
}

export function doAccountsBuyingPowerRes(data) {
  return {
    type: types.DO_ACCOUNTS_BUYING_POWER_RES, 
    data
  };
}

export function get_accounts_buying_power(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      accountsbuyingpowerApi.getAccountsBuyingPower(data).then(res => {
        dispatch(doAccountsBuyingPowerRes(res));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}