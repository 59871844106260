import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';

class FaceAuthUrl {
  static getFacebookAuthUrl() {
    const TOKEN = getItem('auth_token');
    let isError = false;
    try {
      const ajaxRequestHeaders = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': TOKEN
      });

      let body = {
        method: 'GET',
        headers: ajaxRequestHeaders,
      }

      return fetch(`${BASE_URL}/web/preauth/facebook`, body).then(response => {
        isError = errorHandling(response);
        return response.json();
      }).catch(error => {
        if (error && !isError) {
            errorHandler(error);
        }
        return error;
      });
    } catch (err) {

    }
  }
}

export default FaceAuthUrl;
