
import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

class updateProfileApi {
    static doUpdateprofile(data, status) {
        const TOKEN = getItem('auth_token');
        if (status === 'profile') {

        }
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'PUT',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(data)
            }
            return fetch(BASE_URL + '/users', body).then(response => {
                if(response.ok === true){
                    if (status === 'profile') {
                        Swal.fire({
                            title: 'Profile Updated Successfully',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            allowOutsideClick: true,
                            timer: 3000,
                        })
                        setTimeout(() => {
                            localStorage.clear();
                            window.location.href = '/';
                        }, 3000);
                    } else {
                        Swal.fire({
                            title: 'Default Amount Updated Successfully',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            allowOutsideClick: true,
                            timer: 3000,
                        })
                    }
                }
                
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
    }

    static doUploadUserPicture(file) {
        const TOKEN = getItem('auth_token');
        const formData = new FormData();
        formData.append('picture', file);
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Authorization': TOKEN,
                mimeType: 'multipart/form-data',
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: formData
            }
            return fetch(BASE_URL + '/users/profile_picture', body).then(response => {
                // if(response.ok === true){
                //     toastMessage('success',"Picture Uploaded Successfully");
                //     setTimeout(() => {
                //         localStorage.clear();
                //         window.location.href = '/';
                //     }, 3000);
                // }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
    }
}

export default updateProfileApi;