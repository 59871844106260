import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Dashboard from './component/Dashboard';
import { getItem } from './utils/localStore';
import { get_tradestation, doTradeStationRes } from './actions/tradestationActions';
import { broker_delaer_connect, doBrokerDealerConnectRes } from './actions/connectBrokerageActions';
import { broker_delaer_connect_account, doDealerConnectAccountRes } from './actions/connectDealerAccountActions';
import { newBrokerDealerConnectionRes, new_broker_dealer_connection } from './actions/newBrokerDealerConnectionActions';
import { brokerConnecitonTokenRes, broker_connection_token } from './actions/authBrokerConnectionTokenActions';
import { user_activity, doUserActivityRes } from './actions/userActivityActions';
import { doEditprofileRes } from './actions/editProfileActions';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(),
		alignItems: 'center',
		justifyContent: 'center'
	},
}))(MuiDialogActions);
let isTradstation = false;
class BrokerComponent extends React.PureComponent {
    
    constructor() {
        super();
        isTradstation = false;
        this.state = {
            // brokerCallBack: 'http://localhost:3000/broker/TradeStation',
            // brokerCallBack: 'http://localhost:3000/broker/WHOT-NY',
            open: false,
            connectAcoountData: '',
            msg: '',
            brokerdealername: '',
            noAccountModal:false,
            screenId: '1.1',
            mpid: '',
            uId: '',
            refreshToken: '',
            accessToken: '',
            name: '',
        }
    }

    onOpenModal = (accountInfo) => {
        let connectAcoountData = {
            'mpid': this.state.mpid,
            'uid': this.state.uId,
            'token': this.state.accessToken,
            'refresh_token': this.state.refreshToken,
            'account_id': accountInfo.account_id,
            'account_name': accountInfo.account_name,
            'account_type': accountInfo.account_type,
            'buying_power': accountInfo.buying_power
        }
        this.setState({ open: true, connectAcoountData: connectAcoountData });
    };

    onCloseModal = () => {
        this.setState({ noAccountModal: false });
        this.props.history.push('/brokerdealer');
    };

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const code = query.get('code');
        const brokerdealerdata = getItem('broker_dealer_details');
        const tradestationdata = JSON.parse(brokerdealerdata);
        if (tradestationdata) {
            this.setState({
                mpid: tradestationdata.mpid,
                name: tradestationdata.name
            });
            let sentrequest = {
                'bd_id': tradestationdata.bd_id,
                'callbackurl' :tradestationdata.callbackurl,
                // 'callbackurl': this.state.brokerCallBack,
                'code': code,
                'db_secret': tradestationdata.db_secret,
                'base_url' : tradestationdata.base_url,
                'brokerName' : tradestationdata.name
            }
            isTradstation = true;
            this.props.gettradeStation(sentrequest);
    
            if (window.location.href.includes('?hash=')) {
                const hash = window.location.href.split('?hash=')[1];
                let getAuthToken = {
                  'hash': hash
                }
                this.props.brokerConnectionToken(getAuthToken);
            }
        }
        
    }
    static getDerivedStateFromProps(props, state) {
        if (props.tradestationRes) {
            if (props.tradestationRes.data && props.tradestationRes.data.tradestation) {
                if (props.tradestationRes.data.tradestation.access_token && isTradstation) {
                    isTradstation = false;
                    return {
                        tradesatationDataList: props.tradestationRes.data.tradestation,
                        accessToken: props.tradestationRes.data.tradestation.access_token,
                        refreshToken: props.tradestationRes.data.tradestation.refresh_token,
                        uId: props.tradestationRes.data.tradestation.userid
                    }
                }
            }
        }
        if (props.brokerdealerconnectRes) {
            if (props.brokerdealerconnectRes.data && props.brokerdealerconnectRes.data.connectbrokerage) {
                if (typeof props.brokerdealerconnectRes.data.connectbrokerage.data === 'string') {
                    return {
                        msg: props.brokerdealerconnectRes.data.connectbrokerage.data,
                        accountsList: [],
                        noAccountModal:true
                    };
                } else {
                    return {
                        accountsList: props.brokerdealerconnectRes.data.connectbrokerage.data,
                        msg: ""
                    };
                }
            }
        }
        return null;
    }
    componentWillUnmount() {
        this.props.doEditprofileRes();
    }
    cancelAcoount() {
        this.setState({ open: false });
    }

    addAccount() {
        this.props.connectaccounts(this.state.connectAcoountData);
        this.props.history.push('/brokerdealer');
    }

    render() {
        const { open } = this.state;

        return (
            <Dashboard title="ClickIPO User" pageTitle="Active Accounts List">
                <div className="brokerage_content" style={{padding: 16}}>
                    {/* <div className="condition_heading page_title">
                        Active Accounts List
                    </div> */}

                    {
                        this.state.msg &&
                        <div>
                            <Dialog
                                open={this.state.noAccountModal}
                                onClose={this.onCloseModal}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                            >
                                <DialogContent>
                                    <h4>{this.state.msg}</h4>
                                </DialogContent>
                                <DialogActions>
                                    <div style={{textAlign: 'center'}}>
                                        <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary"onClick={() => this.onCloseModal()} >ok</Button>
                                    </div>
                                </DialogActions>
                            </Dialog>
                        </div>
                    }
                    {
                        (this.state.accountsList && this.state.accountsList.length > 0 ) && 
                        <div className="brokerage_content">
                            <div className="ipowork_inner_content">
                                <p className="order_text">Please link to one of your accounts below to place orders</p>
                                {
                                    (this.state.accountsList && this.state.accountsList.length > 0 ) && this.state.accountsList.map((dealeraccount, i) =>
                                    <div className="accounts_content_box_inner" key={i}>
                                        <div className="row">
                                            <div className="col-md-3 col-xs-6 p-l-2 p-t-2">
                                                <div>
                                                    {
                                                        (this.state.name) && 
                                                        <p className="dealer_name">{this.state.name}</p>
                                                    }
                                                    
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-xs-6 p-l-2 p-t-2">
                                                <p className="dealer_accounts_name">
                                                    {dealeraccount.account_name}
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-xs-6 p-l-2 p-t-2">
                                                <p className="dealer_accounts_name">
                                                    ${dealeraccount.buying_power}
                                                </p>
                                            </div>
                                            <div className="col-md-3 col-xs-6 p-l-2 p-t-2">
                                                <div className="link_dealer_account">
                                                    <button type="submit" className="btn btn-primary" onClick={() => { this.onOpenModal(dealeraccount) }}>
                                                        Link account
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
                <Dialog
                    open={open}
                    onClose={this.onCloseModal}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogContent>
                        <h4>Are you sure you want to add {this.state.connectAcoountData.account_name}</h4>
                    </DialogContent>
                    <DialogActions>
                        <div style={{textAlign: 'center'}}>
                            <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="primary"onClick={() => this.addAccount()} >Add</Button>
                            <Button style={{ marginLeft: 10, marginRight: 10 }} variant="contained" size="large" color="secondary"onClick={() => this.cancelAcoount()} >Cancel</Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </Dashboard>
        )
    }
}

BrokerComponent.propTypes = {
    tradestationRes: PropTypes.any,
    brokerdealerconnectRes: PropTypes.any,
    dealerconnectaccountRes: PropTypes.any,
    // GetprofileRes: PropTypes.any,
    newBrokerDealerConnectionRes: PropTypes.any,
    brokerConnecitonTokenRes: PropTypes.any,
    userActivityRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    tradestationRes: doTradeStationRes,
    brokerdealerconnectRes: doBrokerDealerConnectRes,
    dealerconnectaccountRes: doDealerConnectAccountRes,
    // GetprofileRes: doGetprofileRes,
    newBrokerDealerConnectionRes: newBrokerDealerConnectionRes,
    brokerConnecitonTokenRes: brokerConnecitonTokenRes,
    userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
    return {
        gettradeStation: (data) => dispatch(get_tradestation(data)),
        brokerDealerConnect: (data) => dispatch(broker_delaer_connect(data)),
        connectaccounts: (data) => dispatch(broker_delaer_connect_account(data)),
        newBrokerDealerConnection: (data) => dispatch(new_broker_dealer_connection(data)),
        brokerConnectionToken: (data) => dispatch(broker_connection_token(data)),
        doEditprofileRes: () => dispatch(doEditprofileRes(null)),
        userActivity: (data) => dispatch(user_activity(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BrokerComponent);