import * as types from './actionTypes';
import getProfileApi from '../api/getProfileApi';
import {logout} from '../helper/helper';
// import { getItem } from '../utils/localStore';

export function doGetprofile(token) {
  return {
    type: types.DO_EDITPROFILE,
    token
  };
}

export function doEditprofileRes(data) {
  return {
    type: types.DO_EDITPROFILE_RES,
    data
  };
}

export function getProfile(token) {
  if(token){
    return function(dispatch) {
      getProfileApi.doGetprofile(token).then(data => {
        dispatch(doEditprofileRes(data));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}