import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './attestationFile.scss';
import { loadScript } from '../../helper/helper';
import { attestationDsp, doAttestationDspRes } from '../../actions/attestationDspActions';
import Button from '@material-ui/core/Button';
class AttestationFileComponent extends React.PureComponent {

	constructor() {
		super();
		this.state = {
			attestationUrl: '',
			offeringExtId: '',
			offeringName: ''

		}
	}
	componentDidMount() {
		loadScript();
		this.setState({
			attestationUrl: this.props.location.state.state.dsp_attestation_file,
			offeringExtId: this.props.location.state.state.ext_id,
			offeringName: this.props.location.state.state.name,
		}, () => {
		 });
	}
	
	static getDerivedStateFromProps(props, state) {
		if (props.attestationDspRes) {
            if (props.attestationDspRes.data && props.attestationDspRes.data.attestationDsp) {
                if (props.attestationDspRes.data.attestationDsp.message === "Success"){
					props.history.push(`/offeringdetails/${state.offeringExtId}`)
                }
            }
		}
		return null
	}
	goDetails = () => {
		let request = {
			ext_id: this.state.offeringExtId
		}
		this.props.attestationDsp(request);
	}
	render() {
		return (
			<div style={{height: '100%'}}>
				
				<div style={{textAlign: 'center', padding: 10, color: '#245776', fontSize: 24, position: 'fixed', width: '100%', top: 0, backgroundColor: '#ffffff'}}>
				
					<div>DSP Attestation of offering {this.state.offeringName}</div>
					<div style={{fontSize: 15, color: 'red'}}>(Please scroll down to agree with attestation)</div>
				</div>
				
				<div style={{height: '100%', paddingTop: 100}}>
					<iframe id="attestationid" src={this.state.attestationUrl} title="attestation pdf" frameBorder="0" style={{height:'100%',width:'100%'}} /> 
					
					<div style={{ textAlign: 'center' }} >
						<Button style={{ paddingLeft: 20, paddingRight: 20 }} variant="contained" color="primary" size="large" onClick={() => this.goDetails()}>
							I agree
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

AttestationFileComponent.propTypes = {
	attestationDspRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	attestationDspRes: doAttestationDspRes,
});

function mapDispatchToProps(dispatch) {
	return {
		attestationDsp:  (data) => dispatch(attestationDsp(data)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AttestationFileComponent);
