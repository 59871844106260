import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './header-jss';

class UserMenu extends React.Component {

  render() {
    return (
      <div>
      </div>
    );
  }
}

UserMenu.propTypes = {
};

UserMenu.defaultProps = {
  dark: false
};

export default withStyles(styles)(UserMenu);
