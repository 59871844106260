
import { getItem } from '../utils/localStore';

export const logout=() =>{
    localStorage.clear();
    window.location.href = '/';
}

export const checkLogin=() =>{
    const TOKEN = getItem('auth_token');
    if(TOKEN !== undefined || TOKEN !== null){
        return false;
    }else{
        return true;
    }
}
export function dateFormatUS(datetime) {
  if (datetime !== null && datetime !== '' && datetime !== undefined) {
    let d = new Date (datetime);
    return  ('00' + (d.getMonth() + 1)).slice(-2) + '/' +
    ('00' + d.getDate()).slice(-2) + '/' + d.getFullYear()
  } else {
    return '';
  }
}
export function dateTimeFormat(datetime) {
    if (datetime !== null && datetime !== '' && datetime !== undefined) {
      let d = new Date (datetime);
      return d.getFullYear() + '-' +
      ('00' + (d.getMonth() + 1)).slice(-2) + '-' +
      ('00' + d.getDate()).slice(-2) + ' ' +
      ('00' + d.getHours()).slice(-2) + ':' +
      ('00' + d.getMinutes()).slice(-2) + ':' +
      ('00' + d.getSeconds()).slice(-2);
    } else {
      return 'N/A';
    }
}
export function dateTimeFormatUS(datetime) {
    if (datetime !== null && datetime !== '' && datetime !== undefined) {
      let d = new Date (datetime);
      return ('00' + (d.getMonth() + 1)).slice(-2) + '/' +
      ('00' + d.getDate()).slice(-2) + '/' + d.getFullYear()
    } else {
      return 'N/A';
    }
}
export function getDate(offering) {
    if (offering.status !== 'closed' && offering.trade_date!==null ) {
    return dateFormat(offering.trade_date);
    } else if (offering.status === 'closed' && offering.trade_date!==null) {
    return dateFormat(offering.trade_date);
    }
    return 'TBD';
}

export function getOrderDate(orders) {
    if (orders.status !== 'closed' && orders.status !== 'cancelled' && orders.offering.trade_date!==null ) {
    return dateFormat(orders.offering.trade_date);
    } else if (orders.status === 'cancelled' && orders.status === 'closed' && orders.offering.trade_date !== null) {
    return dateFormat(orders.offering.trade_date);
    }
    return 'TBD';
}

export function getOrderDateModify(orders, offering) {
    if (orders.status !== 'closed' && orders.status !== 'cancelled' && offering.trade_date!==null ) {
    return dateFormat(offering.trade_date);
    } else if (orders.status === 'cancelled' && orders.status === 'closed' && offering.trade_date !== null) {
    return dateFormat(offering.trade_date);
    }
    return 'TBD';
}

export function getPrice(offering) {
    if (offering.status !== 'closed' && offering.anticipated_prices.min_price && offering.anticipated_prices.max_price !==null ) {
        return (offering.anticipated_prices.min_price && offering.anticipated_prices.max_price);
    }
    return 'TBD';
}

export function getOfferingDetailsPrice(offering) {
    if ( offering.offering_type_name === 'IPO' && offering.min_price !== null && offering.max_price !== null) {
        return `$${offering.min_price.toFixed(2).replace(/[.,]00$/, '.00').toLocaleString()} - $${offering.max_price.toFixed(2).replace(/[.,]00$/, '.00').toLocaleString()}`;
    } else if (offering.offering_type_name !== 'IPO' && offering.max_price !== null) {
        return `$${offering.max_price.toFixed(2).replace(/[.,]00$/, '.00').toLocaleString()}`;
    }
    return 'TBD';
}

export function getOrderPrice(order) {
    if ( order.offering.offering_type_name === 'IPO' && order.offering.offering_type_name) {
        return `$${order.offering.min_price.toFixed(2).replace(/[.,]00$/, '.00').toLocaleString()} - $${order.offering.max_price.toFixed(2).replace(/[.,]00$/, '.00').toLocaleString()}`;
    } else if ( order.offering.offering_type_name !== 'IPO' && order.offering) {
        return `$${order.offering.max_price.toFixed(2).replace(/[.,]00$/, '.00').toLocaleString()}`;
    }
    return 'TBD';
}
export function getOrderPriceModify(order) {
    if ( order.offering_type_name === 'IPO' && order.offering_type_name) {
        return `$${order.min_price.toFixed(2).replace(/[.,]00$/, '.00').toLocaleString()} - $${order.max_price.toFixed(2).replace(/[.,]00$/, '.00').toLocaleString()}`;
    } else if ( order.offering_type_name !== 'IPO' && order) {
        return `$${order.max_price.toFixed(2).replace(/[.,]00$/, '.00').toLocaleString()}`;
    }
    return 'TBD';
}
export function approximateshares(orders) {
    var shares = orders.requested_amount / orders.offering.max_price
    return Math.round(shares); 
}
export function approximatesharesModify(orders, offering) {
    var shares = orders.requested_amount / offering.max_price
    return Math.round(shares); 
}
export function numberWithCommas(number)  {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function dateFormat(date){
    if (!date) return null;
    let dateNew = {date};
    var monthNames = [
        'Jan', 'Feb', 'Mar',
        'Apr', 'May', 'Jun', 'Jul',
        'Aug', 'Sep', 'Oct',
        'Nov', 'Dec',
    ];
    let splitTimeDate = dateNew.date.split(' ')
    let splitDate = splitTimeDate[0].split('-')
    let day = splitDate[2];
    let monthIndex = splitDate[1];
    return  monthNames[monthIndex - 1] + ' ' + day;
}

export function numberWithCommasAndDollar(number)  {
    /*
    if (number === null) {
        return '$0'
    } else {
        return '$' +String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    */
    // Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
    //(number !== null ? parseInt(number).toLocaleString('en-US', {style: 'currency', currency: 'USD'}): number)

    if (number !== null) {
        return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
    }
    else {
        return 'NA'
    }
}

export function loadScript () {    
    var tag = document.createElement('script');
    tag.async = false;
    tag.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-87376082-2');";
    document.head.appendChild(tag);
}


export function timeSince(date, ref_date, date_formats, time_units) {
    //Date Formats must be be ordered smallest -> largest and must end in a format with ceiling of null
    date_formats = date_formats || {
      past: [
        { ceiling: 60, text: "$seconds seconds ago" },
        { ceiling: 3600, text: "$minutes minutes ago" },
        { ceiling: 86400, text: "$hours hours ago" },
        { ceiling: 2629744, text: "$days days ago" },
        { ceiling: 31556926, text: "$months months ago" },
        { ceiling: null, text: "$years years ago" }      
      ],
      future: [
        { ceiling: 60, text: "in $seconds seconds" },
        { ceiling: 3600, text: "in $minutes minutes" },
        { ceiling: 86400, text: "in $hours hours" },
        { ceiling: 2629744, text: "in $days days" },
        { ceiling: 31556926, text: "in $months months" },
        { ceiling: null, text: "in $years years" }
      ]
    };
    //Time units must be be ordered largest -> smallest
    time_units = time_units || [
      [31556926, 'years'],
      [2629744, 'months'],
      [86400, 'days'],
      [3600, 'hours'],
      [60, 'minutes'],
      [1, 'seconds']
    ];
    
    date = new Date(date);
    ref_date = ref_date ? new Date(ref_date) : new Date();
    var seconds_difference = (ref_date - date) / 1000;
    
    var tense = 'past';
    if (seconds_difference < 0) {
      tense = 'future';
      seconds_difference = 0-seconds_difference;
    }
    
    function get_format() {
      for (var i=0; i<date_formats[tense].length; i++) {
        if (date_formats[tense][i].ceiling == null || seconds_difference <= date_formats[tense][i].ceiling) {
          return date_formats[tense][i];
        }
      }
      return null;
    }
    
    function get_time_breakdown() {
      var seconds = seconds_difference;
      var breakdown = {};
      for(var i=0; i<time_units.length; i++) {
        var occurences_of_unit = Math.floor(seconds / time_units[i][0]);
        seconds = seconds - (time_units[i][0] * occurences_of_unit);
        breakdown[time_units[i][1]] = occurences_of_unit;
      }
      return breakdown;
    }
  
    function render_date(date_format) {
      var breakdown = get_time_breakdown();
      var time_ago_text = date_format.text.replace(/\$(\w+)/g, function() {
        return breakdown[arguments[1]];
      });
      return depluralize_time_ago_text(time_ago_text, breakdown);
    }
    
    function depluralize_time_ago_text(time_ago_text, breakdown) {
      for(var i in breakdown) {
        if (breakdown[i] === 1) {
          var regexp = new RegExp("\\b"+i+"\\b");
          time_ago_text = time_ago_text.replace(regexp, function() {
            return arguments[0].replace(/s\b/g, '');
          });
        }
      }
      return time_ago_text;
    }
            
    return render_date(get_format());
  }
