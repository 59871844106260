import * as types from './actionTypes';  
import offeringDetailsApi from '../api/offeringDetailsApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2'

export function getOfferingDetails(id) {
  return {
    type: types.DO_OFFERING_DETAILS, 
    id
  };
}

export function doOfferingDetailsRes(data) {
  return {
    type: types.DO_OFFERING_DETAILS_RES, 
    data
  };
}

export function get_offering_details(id) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      offeringDetailsApi.getOfferingDetails(id).then(data => {
        dispatch(doOfferingDetailsRes(data));
        dispatch(doOfferingDetailsRes(null));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}
