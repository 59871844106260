import * as types from '../actions/actionTypes'; 
import initialState from './initialState';

//all the message related reducers here
export default function (state = initialState.user, action) { //we will change the state = {} soon
  switch(action.type) {
    case types.DO_MESSAGE_DETAIL_MOB_RES:
      let message = action.data.data
      return message
    default:
      return state
  }
}
