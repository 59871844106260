import * as types from './actionTypes';  
import socialLoginApi from '../api/socialLoginApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2'
 
export function socialLogin(data) {
  return {
    type: types.DO_SOCIAL_LOGIN, 
    data
  };
}

export function dosocialLoginRes(data) {
  return {
    type: types.DO_SOCIAL_LOGIN_RES, 
    data
  };
}

export function social_login(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      socialLoginApi.socialLogin(data).then(res => {
        dispatch(dosocialLoginRes(res));
        if(res.error){
          Swal.fire({
            title: res.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}