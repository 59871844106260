import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './bizinsight.css';
import Dashboard from '../../component/Dashboard';
import { loadScript } from '../../helper/helper';
import { fetchBizInsights, getBizInsightsRes } from '../../actions/bizInsightsActions';
import BackIcon from '../../assets/images/icon-left.svg';
import DOMPurify from 'dompurify';
class BizInsightComponent extends React.PureComponent {

	constructor() {
		super();
		this.state = {
			bizInsightData: null,
			offeringName: '',
		}
	}

	componentDidMount() {
		loadScript();
		const extid = this.props.match.params.id;
		this.setState({
			offeringName: this.props.location.state.name,
		});
		this.props.fetchBizInsights(extid);
	}

	static getDerivedStateFromProps(props, state) {
		if (props.getBizInsightsRes) {
			if (props.getBizInsightsRes.data && props.getBizInsightsRes.data.bizInsights) {
				if (props.getBizInsightsRes.data.bizInsights) {
					return {
						bizInsightData: props.getBizInsightsRes.data.bizInsights,
					};
				}
			}
		}
		return null
	}
	handleBack = () => {
		this.props.history.goBack();
	}
	toCommas(value) {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	render() {

		return (
			<Dashboard title="ClickIPO - BizInsight" pageTitle="BizInsight">
				<div className="offring_deatils_content">
					<div className="offring_deatils_heading">
						<div className="back_icon_text" onClick={this.handleBack}>
							<img src={BackIcon} alt="" className="back_icon" />
							<span className="go_back">back to offering detail</span>
						</div>
						<span className="offering_detail_title">{this.state.offeringName}</span>
					</div>
					<div className="offring_deatils_inner_content">
						<div className="row ml-mr-0px-imp justify-content-md-center">
							<div className="col col-12 col-sm-12 col-md-8 bizinsight-view">
							{
								(this.state.bizInsightData) &&
								<div dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(this.state.bizInsightData)} }></div>
							}
							
							</div>
						</div>
					</div>
				</div>
			</Dashboard>
		)
	}
}

BizInsightComponent.propTypes = {
	getBizInsightsRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	getBizInsightsRes: getBizInsightsRes,
});

function mapDispatchToProps(dispatch) {
	return {
		fetchBizInsights: (data) => dispatch(fetchBizInsights(data)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BizInsightComponent);
