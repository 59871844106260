import * as types from './actionTypes';  
import offeringSaveApi from '../api/offeringSaveApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import { loadingRes } from './loadingActions';

export function saveOffering(data) {
  return {
    type: types.DO_OFFERING_SAVE, 
    data
  };
}

export function dosaveOfferingRes(data) {
  return {
    type: types.DO_OFFERING_SAVE_RES, 
    data
  };
}

export function save_offering(data) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      offeringSaveApi.saveOffering(data).then(data => {
        dispatch(dosaveOfferingRes(data));
        dispatch(dosaveOfferingRes(null));
        dispatch(loadingRes(false));
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
 
}