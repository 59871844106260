import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import './modifyOrder.css';
import Dashboard from '../../component/Dashboard';
import { update_order_details, doupdateOrderRes } from '../../actions/updateOrderActions';
import { get_accounts_buying_power, doAccountsBuyingPowerRes } from '../../actions/accountsBuyingPowerActions';
import { submit_profile, doEditprofileRes } from '../../actions/editProfileActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { getOrderPrice, getOrderDate, numberWithCommas, loadScript, numberWithCommasAndDollar } from '../../helper/helper';
import PlaceHolderImg from '../../assets/images/placeholder_company_thumb.png';
import { PDF_FINRARULE_ZERO, PDF_FINRARULE_ONE } from '../../constants';
import BackIcon from '../../assets/images/images/icon-left.svg';
import SuccessIcon from '../../assets/images/success.gif';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DOMPurify from 'dompurify';
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(),
		alignItems: 'center',
		justifyContent: 'center'
	},
}))(MuiDialogActions);

let isProfile = false;
let isUpdateOrder = false;
let isAccountBuying = false;
class ModifyOrederComponent extends React.PureComponent {
    constructor(props) {
        super(props)
        isProfile = false;
        isUpdateOrder = false;
        isAccountBuying = false;
        this.state = {
            isLoader: false,
            reviewOrder: false,
            value: '',
            Ischecked: true,
            buyingPowerAmount: '',
            activeorderList: '',
            open: false,
            requestAmount: '',
            rule5130Modal: false,
            rule5131Modal: false,
            minAmountModal:false,
            buttonOrder: false,
            minAmount:'',
            brokerconnectionList:'',
            updateorderList:'',
            updateOrderAmount:'',
            sharePrice:'',
            orderModifyModal:false,
            accountId: '',
            brokerDealerMpid: '',
            orderModifyAmount: '',
            screenId: '1.1',
            isEditValue: false,
            attestation_to_rules_5130_and_5131: 1,
            openUnitIncErrorModal: false,
            orderConfirmMsg: '',
            openForExisteMinMaxTicket: false
        }
        this.goChange = this.goChange.bind(this);
        this.checkChange = this.checkChange.bind(this);
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    addDefaultSrc(ev) {
        ev.target.src = PlaceHolderImg;
    }

    componentDidMount() {
        loadScript();
        const id = this.props.match.params.id;
        isProfile = true;
        isAccountBuying = true;
        this.props.accountsbuyingpower();
        this.props.getProfile();
        const activityData = {
                'ext_id': id,
                'screen_id': this.state.screenId,
                'source': 'web'
        }
        this.props.userActivity(activityData);
        this.setState({
            isLoader: true,
        });
        const offeringModify = this.props.location.state.orderData;
        if (offeringModify) {
                
                this.setState({
                    orderdetailsList: offeringModify,
                    requestAmount: offeringModify.requested_amount,
                    accountId: offeringModify.broker_connection.account_id,
                    brokerDealerMpid: offeringModify.broker_connection.mpid,
                    orderId: offeringModify.offering.ext_id,
                    sharePrice: offeringModify.offering.max_price,
                }, () => {
                    if (this.state.orderdetailsList.dsp === 1 || this.state.orderdetailsList.dsp === 2) {
                        let amount = this.state.orderdetailsList.offering.max_price * this.state.orderdetailsList.offering.max_shares;
                        this.setState({
                            value: this.state.orderdetailsList.offering.ordr_dollar_share === 0 ? Number(amount).toFixed(2) : Number(amount),
                        });
                    } else {
                        this.setState({
                            value: this.state.orderdetailsList.offering.ordr_dollar_share === 0 ? this.state.orderdetailsList.requested_amount : this.state.orderdetailsList.requested_shares
                        });
                    }
                });
        }
        
    }

    getDetails = () => {
        this.props.history.goBack()
    }

    onCloseModal = () => {
        this.setState({ open: false });
    };

    clcikOk() {
        this.setState({ open: false, minAmountModal: false });
    }

    showReviewOrder() {
        if (this.state.orderdetailsList.offering.unit_increment !== null || this.state.orderdetailsList.offering.unit_increment !== undefined) {
            let enterValue = Number(this.state.value);
            let checkVal = enterValue%Number(this.state.orderdetailsList.offering.unit_increment);
            if (checkVal > 0) {
                this.setState({
                    openUnitIncErrorModal: true
                })
                return false;
            }
        }
        // if(Number(this.state.orderdetailsList.offering.max_ticket_size)>0){
        //     if (Number(this.state.value) < Number(this.state.orderdetailsList.offering.min_ticket_size)) {
        //         this.setState({ openForExisteMinMaxTicket: true });
        //         return false;
        //     }
        //     if (Number(this.state.value) > Number(this.state.orderdetailsList.offering.max_ticket_size)) {
        //         this.setState({ openForExisteMinMaxTicket: true });
        //         return false;
        //     }
        // }

        if(this.state.orderdetailsList.offering.max_ticket_size !== null && this.state.orderdetailsList.offering.min_ticket_size !== null) {
            if(Number(this.state.orderdetailsList.offering.max_ticket_size) > 0){
                
                if (Number(this.state.value) < Number(this.state.orderdetailsList.offering.min_ticket_size)) {
                    this.setState({ openForExisteMinMaxTicket: true });
                    return false;
                }
                if (Number(this.state.value) > Number(this.state.orderdetailsList.offering.max_ticket_size)) {
                    this.setState({ openForExisteMinMaxTicket: true });
                    return false;
                }
            }
        }
        if(this.state.orderdetailsList.offering.max_ticket_size !== null && this.state.orderdetailsList.offering.min_ticket_size === null) {
            if(Number(this.state.orderdetailsList.offering.max_ticket_size) > 0) {
                if (Number(this.state.value) > Number(this.state.orderdetailsList.offering.max_ticket_size)) {
                    this.setState({ openForExisteMinMaxTicket: true });
                    return false;
                }
            }
        }
        if(this.state.orderdetailsList.offering.max_ticket_size === null && this.state.orderdetailsList.offering.min_ticket_size !== null) {
            if(Number(this.state.orderdetailsList.offering.min_ticket_size) > 0){
                if (Number(this.state.value) < Number(this.state.orderdetailsList.offering.min_ticket_size)) {
                    this.setState({ openForExisteMinMaxTicket: true });
                    return false;
                }
            }
        }

        if (this.state.reviewOrder) {
            this.setState({ reviewOrder: false });
        } else {
            this.setState({ reviewOrder: true });
        }

        if (this.state.value < Number(this.state.minAmount)) {
                this.setState({
                    minAmountModal: true,
                    reviewOrder: false
                });
        } else {
                this.setState({
                        minAmountModal: false,
                        reviewOrder: true
                });

                if (this.state.orderdetailsList.offering.enforce_buying_power === true && this.state.orderdetailsList.offering.dsp === 0) {
                        if ((Number(this.state.buyingPowerAmount) + this.state.orderdetailsList.requested_amount) >= (this.state.orderdetailsList.offering.ordr_dollar_share === 0 ? Number(this.state.value) : Number(this.state.value) * Number(this.state.orderdetailsList.offering.max_price))) {
                            this.setState({
                                open: false,
                                reviewOrder: true
                            });
                        } else {
                            this.setState({
                                open: true,
                                reviewOrder: false
                            });
                        }
                } else {
                        this.setState({
                                open: false,
                                reviewOrder: true
                        });
                }
        }

    }

    checkChange(e) {
        if (e.target.checked === true) {
            this.setState({
                Ischecked: false
            })
        } else {
            this.setState({
                Ischecked: true
            })   
        }
    }
    handleEndEditingPassword = () => {
        this.setState( { isEditValue: true, value: '' }, () => {});
    }

    goChange(e) {
        
        e.persist();
        if (this.state.isEditValue) {
            const re = /^\s*\d*\.?\d{0,2}\s*$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                this.setState({
                    value: e.target.value,
                    isEditValue: false,
                    Ischecked: true,
                }, () => {
                })
            }

            if (this.state.value === '') {
                this.setState({ reviewOrder: false })
            } else {
                this.setState({
                    reviewOrder: false
                })
            }
        } else {
            const re = /^\s*\d*\.?\d{0,2}\s*$/;
            if (e.target.value === '' || re.test(e.target.value)) {
                this.setState({
                    value: e.target.value,
                    Ischecked: true,
                }, () => {
                })
            }

            if (this.state.value === '') {
                this.setState({ reviewOrder: false })
            } else {
                this.setState({
                    reviewOrder: false
                })
            }
        }
        
    }

    // Only check for the enforce_buying_power flag top see what should be send in the 'buying_power' field in the API
    buyingpower_helper() {
        var o = this.state.orderdetailsList.offering
        var retVal = 0.0
        if (o.enforce_buying_power === true ) {
            retVal = Number(this.state.buyingPowerAmount)
        }
        else {
            if (o.ordr_dollar_share === 0) {
                retVal = Number(this.state.value)
            } else {
                retVal = (Number(this.state.value) * Number(o.max_price))
            }            
        }

        return (retVal)
    }

    doUpdateOrder = () => {
        this.setState({ orderModifyAmount: this.state.value });

        var data = {
            "ext_id": this.state.orderId,
            "buying_power": this.buyingpower_helper(),
            "mpid": this.state.brokerDealerMpid,
            "account_id": this.state.accountId,
            "attestation_to_rules_5130_and_5131": Number(this.state.attestation_to_rules_5130_and_5131),
            "dsp": this.state.orderdetailsList.offering.dsp
        }

        // Send the key as requested_shares when the offering is taking indications in shares and requested_amount when it is taking indiciation in dollars
        if (this.state.orderdetailsList.offering.ordr_dollar_share === 0) {
            data["requested_amount"] = Number(this.state.value)
        } else {
            data["requested_shares"] = Number(this.state.value)
        }
        isUpdateOrder = true;
        this.props.updateOrder(data);
    }

    goOrders(){
        this.props.history.push('/order')
    }

    doneClick(){
        this.props.history.push('/order')
    }

    openRule5130Modal = () => {
        this.setState({ 
            rule5130Modal: true
        })
    }

    openRule5131Modal = () => {
        this.setState({ 
            rule5131Modal: true
        })
    }

    closeModal = () => {
        this.setState({ 
            rule5130Modal: false, 
            rule5131Modal: false,
            minAmountModal:false,
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.updateorderRes) {
            if (props.updateorderRes.data && props.updateorderRes.data.updateorder) {
                if(props.updateorderRes.data.updateorder.error === 0 && isUpdateOrder){
                    isUpdateOrder = false;
                    return {
                        orderModifyModal: true,
                        orderConfirmMsg: props.updateorderRes.data.updateorder.message,
                        reviewOrder: false
                    };
                }
            }
        }
        if (props.accountsBuyingPowerRes) {
            if (props.accountsBuyingPowerRes.data && props.accountsBuyingPowerRes.data.accountsbuyingpower) {
                if (props.accountsBuyingPowerRes.data.accountsbuyingpower.message === 'Success' && isAccountBuying) {
                    isAccountBuying = false;
                    return {
                        isLoader: false,
                        accountsbuyingpower: props.accountsBuyingPowerRes.data.accountsbuyingpower.data,
                        buyingPowerAmount: Number(props.accountsBuyingPowerRes.data.accountsbuyingpower.data.buying_power).toFixed(2)
                    };
                }
            }
        }
        if (props.EditprofileRes) {
            if (props.EditprofileRes.data && props.EditprofileRes.data.editprofile) {
                if (props.EditprofileRes.data.editprofile && props.EditprofileRes.data.editprofile.message === 'Success' && isProfile) {
                    isProfile = false;
                    let minAmnt = '';
                    if (props.EditprofileRes.data.editprofile.data.broker_connection) {
                        minAmnt = props.EditprofileRes.data.editprofile.data.broker_connection.minimum_buy_order;
                    }
                    return {
                        isLoader: false,
                        userList: props.EditprofileRes.data.editprofile.data,
                        brokerconnectionList: props.EditprofileRes.data.editprofile.data.broker_connection,
                        minAmount: minAmnt
                    };
                }
            }
            
        }
        return null;
    }
    
    closeUnitIncErrorModal = () => {
        this.setState({ 
            openUnitIncErrorModal: false
        });
    }
    
    onCloseMinMaxModal = () => {
        this.setState({ 
            openForExisteMinMaxTicket: false,
        });
    }

    radioChangeDsp(value) {
        this.setState({
            Ischecked: false,
            attestation_to_rules_5130_and_5131: value
        })
    }
    render() {
        const { open } = this.state;
        const buyingPower = '$ ' + this.state.buyingPowerAmount;
        // let checkDisable = this.state.orderdetailsList && this.state.orderdetailsList.offering && this.state.orderdetailsList.offering.ordr_dollar_share === 0 ? this.state.orderdetailsList.requested_amount : this.state.orderdetailsList.requested_shares;
        return (
            <Dashboard title="ClickIPO - Modify Order" pageTitle="Modify Order" >
                {
                    this.state.orderdetailsList && this.state.accountsbuyingpower && this.state.orderdetailsList.offering && this.state.brokerconnectionList &&
                    <div className="place_order_content">
                        <div className="place_order_heading page_title">
                            <img src={BackIcon} alt="" onClick={this.getDetails} className="back_icon" />
                            Modify Order
                        </div>
                        <div className="place_order_inner_content">
                            <div className="row ml-mr-0px-imp">
                                <div className="col col-3 col-md-3 col-lg-3 col-xl-3">
                                    <div className="lft_images">
                                        <img onError={this.addDefaultSrc} src={(this.state.orderdetailsList.offering.logo_small) ? 'https:' + this.state.orderdetailsList.offering.logo_small : ''} className="order_user_images details_logo" alt="" />
                                        <p className="company_placeorder_tag">
                                            Ticker Symbol: {this.state.orderdetailsList.offering.ticker_symbol}
                                        </p>
                                    </div>
                                </div>
                                <div className="col col-9 col-md-9 col-lg-9 col-xl-6  place_order_inner_content_inner">
                                {
                                    (this.state.orderdetailsList.dsp === 1 || this.state.orderdetailsList.dsp === 2) &&
                                    <div style={{color: 'red', fontWeight: 'bold', border: '1px solid red', padding: 10}}>
                                        <div>You have been invited to participate in the Directed Shares Program for the <span >{this.state.orderdetailsList.offering.name}</span> . </div>
                                        {
                                            this.state.orderdetailsList.offering.max_shares > 0 &&
                                            <div>You have been preassigned <span>{this.state.orderdetailsList.offering.max_shares}</span> shares</div>
                                        }
                                    </div>
                                }
                                    <p className="company_placeorder_name">{this.state.orderdetailsList.offering.name}
                                        <span className={"company_placeorder_account " + (this.state.orderdetailsList.offering.offering_type_name === 'IPO' ? "green_text" : this.state.orderdetailsList.offering.offering_type_name === 'Follow-on' ? "turquoise_text" : this.state.orderdetailsList.offering.offering_type_name === 'ETF' ? "etf_text" : this.state.orderdetailsList.offering.offering_type_name === 'UIT' ? "uit_text" : "turquoise_text" ) }>
                                            ({this.state.orderdetailsList.offering.offering_type_name})
                                        </span>
                                    </p>
                                    <div className="row">
                                        <div className="col col-6">
                                            <p className="account_placeorder_type">Anticipated</p>
                                        </div>
                                        <div className="col col-6">
                                            <p className="account_open_placeorder_date">{getOrderDate(this.state.orderdetailsList)}</p>
                                        </div>
                                    </div>
                                    {
                                        this.state.orderdetailsList.offering.offering_type_name === 'IPO' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Price Range</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                    {getOrderPrice(this.state.orderdetailsList)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (this.state.orderdetailsList.offering.offering_type_name === 'Follow-on' || this.state.orderdetailsList.offering.offering_type_name === 'ETF' || this.state.orderdetailsList.offering.offering_type_name === 'UIT') &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                        {getOrderPrice(this.state.orderdetailsList)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.orderdetailsList.offering.offering_type_name === 'Block' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                        {getOrderPrice(this.state.orderdetailsList)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.orderdetailsList.offering.offering_type_name === 'Spot' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                        {getOrderPrice(this.state.orderdetailsList)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.orderdetailsList.offering.offering_type_name === 'Overnight' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                        {getOrderPrice(this.state.orderdetailsList)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col col-6">
                                            <p className="price_level_placeorder_type">Requested Amount</p>
                                        </div>
                                        <div className="col col-6">
                                            <p className="amount_placeorder">
                                                ${numberWithCommas(this.state.orderdetailsList.requested_amount)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-6">
                                            {
                                                this.state.orderdetailsList.offering.min_ticket_size !== null && this.state.orderdetailsList.offering.max_ticket_size !== null &&
                                                <p className="price_level_placeorder_type">Ticket size</p>
                                            }
                                            {
                                                this.state.orderdetailsList.offering.min_ticket_size === null && this.state.orderdetailsList.offering.max_ticket_size !== null &&
                                                <p className="price_level_placeorder_type">Max ticket size</p>
                                            }
                                            {
                                                this.state.orderdetailsList.offering.min_ticket_size !== null && this.state.orderdetailsList.offering.max_ticket_size === null &&
                                                <p className="price_level_placeorder_type">Min ticket size</p>
                                            }
                                        </div>
                                        <div className="col col-6">
                                            {
                                                this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                                <div className="buying_power_amount">
                                                    {
                                                        this.state.orderdetailsList.offering.min_ticket_size !== null && this.state.orderdetailsList.offering.max_ticket_size !== null &&
                                                        <div>
                                                            {numberWithCommasAndDollar(this.state.orderdetailsList.offering.min_ticket_size)} - {numberWithCommasAndDollar(this.state.orderdetailsList.offering.max_ticket_size)}
                                                        </div>
                                                    }
                                                    {
                                                        this.state.orderdetailsList.offering.min_ticket_size === null && this.state.orderdetailsList.offering.max_ticket_size !== null &&
                                                        <div>
                                                            {numberWithCommasAndDollar(this.state.orderdetailsList.offering.max_ticket_size)}
                                                        </div>
                                                    }
                                                    {
                                                        this.state.orderdetailsList.offering.min_ticket_size !== null && this.state.orderdetailsList.offering.max_ticket_size === null &&
                                                        <div>
                                                            {numberWithCommasAndDollar(this.state.orderdetailsList.offering.min_ticket_size)}
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div className="buying_power_amount">

                                                    {
                                                        this.state.orderdetailsList.offering.min_ticket_size !== null && this.state.orderdetailsList.offering.max_ticket_size !== null &&
                                                        <div>
                                                            {numberWithCommas(this.state.orderdetailsList.offering.min_ticket_size)} - {numberWithCommas(this.state.orderdetailsList.offering.max_ticket_size)} Shares
                                                        </div>
                                                    }
                                                    {
                                                        this.state.orderdetailsList.offering.min_ticket_size === null && this.state.orderdetailsList.offering.max_ticket_size !== null &&
                                                        <div>
                                                            {numberWithCommas(this.state.orderdetailsList.offering.max_ticket_size)} Shares
                                                        </div>
                                                    }
                                                    {
                                                        this.state.orderdetailsList.offering.min_ticket_size !== null && this.state.orderdetailsList.offering.max_ticket_size === null &&
                                                        <div>
                                                            {numberWithCommas(this.state.orderdetailsList.offering.min_ticket_size)} Shares
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            
                                        </div>
                                    </div>
                                    {
                                        Number(this.state.orderdetailsList.offering.unit_increment) > 1 &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Unit increment</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                    {this.state.orderdetailsList.offering.unit_increment}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.orderdetailsList.offering.enforce_buying_power === true &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Buying Power</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                    {numberWithCommas(buyingPower)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col col-6 col-md-6 col-xl-6">
                                            {
                                                this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                                <p className="share_status" style={{marginTop: 8}}>Investment Amount</p>
                                                :
                                                <p className="share_status" style={{marginTop: 8}}>Investment Shares</p>
                                            }
                                        </div>
                                        <div className="col col-6 col-md-6 col-xl-6">
                                            {
                                                this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                                <div className="rt-input-input investment_amount place_investment_amount">
                                                    <input type="text" className="form-control" onChange={this.goChange} onClick={this.handleEndEditingPassword } value={this.state.value} ></input>
                                                </div>
                                                :
                                                <div className="investment_amount place_investment_amount">
                                                    <input type="text" className="form-control" onChange={this.goChange} onClick={this.handleEndEditingPassword } value={this.state.value} ></input>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-6 col-md-6 col-xl-6">
                                            {
                                                this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                                <p className="share_status">Approximate Shares</p>
                                                :
                                                <p className="share_status">Approximate Amount</p>
                                            }
                                        </div>
                                        <div className="col col-6 col-md-6 col-xl-6">
                                            {
                                                this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                                <p className="amount_placeorder">
                                                    
                                                    {
                                                        this.state.value ? numberWithCommas(Math.round((this.state.value)/(this.state.orderdetailsList.offering.max_price)).toFixed(0)): 0 
                                                    }
                                                </p>
                                                :
                                                <p className="amount_placeorder">
                                                    {
                                                        this.state.value ? numberWithCommasAndDollar(Math.round((this.state.value)*(this.state.orderdetailsList.offering.max_price)).toFixed(0)): '$0.00' 
                                                    }
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-md-12 col-lg-12 col-xl-3">
                                    {
                                        this.state.reviewOrder === false &&
                                        <div className="modify_btn d-none d-xl-block" onClick={() => this.showReviewOrder()}>
                                            <Button type="submit" variant="contained" size="large" color="primary" disabled={!this.state.value || ((this.state.orderdetailsList.offering.ordr_dollar_share === 0 ? this.state.orderdetailsList.requested_amount : this.state.orderdetailsList.requested_shares) === Number(this.state.value))}>
                                                Review Order
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row ml-mr-0px-imp">
                                <div className="col col-12">
                                    <div>
                                        <div className="row">
                                            <div className="col-12">
                                                {
                                                    this.state.reviewOrder === false &&
                                                    <div className="modify_btn d-xl-none" onClick={() => this.showReviewOrder()}>
                                                        <Button variant="contained" size="large" color="primary" disabled={!this.state.value || ((this.state.orderdetailsList.offering.ordr_dollar_share === 0 ? this.state.orderdetailsList.requested_amount : this.state.orderdetailsList.requested_shares) === Number(this.state.value))}>
                                                            Review Order
                                                        </Button>
                                                    </div>
                                                }                                
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.reviewOrder &&
                                        <div className="mt-25px pt-10px bdr-top-1px-solid-lightgray">
                                            <p className="review_order_text">
                                                This Order will be placed through your broker account, commission fee.
                                            </p>
                                            <p className="review_order_text_content">
                                                There is no assurance that your conditional offer to buy will receive the full requested allocation or any allocation at all.  Your order is conditional on the final share price being no greater than 20% higher or lower than the anticipated price range.  Your order will be for the dollar amount calculated regardless of the final share price.
                                            </p>
                                            {
                                                this.state.orderdetailsList.dsp === 2 ?
                                                <div style={{margin: 'auto', width: '80%'}}>
                                                    <p className="checkbox_content" style={{display: 'inline-flex', textAlign: 'left'}}>
                                                        <input type="radio" name="dsp2" value="1" className="accept_condition" onChange={() => this.radioChangeDsp(1)} ></input>
                                                        <span className="checkbox_content_inner" style={{width: '80%'}}>
                                                            I attest that I am not a "restricted person" pursuant to 
                                                                <span className="rule_name" onClick={() => this.openRule5130Modal()}>
                                                                    Rule 5130
                                                                </span> and 
                                                                <span className="rule_name" onClick={() => this.openRule5131Modal()}>
                                                                    Rule 5131.
                                                                </span>
                                                        </span>
                                                    </p>
                                                    <p className="checkbox_content" style={{display: 'inline-flex', textAlign: 'left'}}>
                                                        <input type="radio" name="dsp2" value="2" className="accept_condition" onChange={() => this.radioChangeDsp(2)} ></input>
                                                        <span className="checkbox_content_inner" style={{width: '80%'}}>
                                                            I am a restricted person as defined by FINRA rule 5130 or 5131. I understand that directed allocation programs are exempt from rule
                                                            <span className="rule_name" onClick={() => this.openRule5130Modal()}>
                                                                    5130
                                                            </span> and 
                                                            <span className="rule_name" onClick={() => this.openRule5131Modal()}>
                                                                5131
                                                            </span>
                                                            and thus I am able to participate in the offering provided my compliance department approves of my participation.
                                                        </span>
                                                    </p>
                                                </div>
                                                :
                                                <p className="checkbox_content">
                                                    <input type="checkbox" className="accept_condition" onChange={this.checkChange}></input>
                                                    <span className="checkbox_content_inner">
                                                        I attest that I am not a "restricted person" pursuant to 
                                                            <span className="rule_name" onClick={() => this.openRule5130Modal()}>
                                                                Rule 5130
                                                            </span> and 
                                                            <span className="rule_name" onClick={() => this.openRule5131Modal()}>
                                                                Rule 5131.
                                                            </span>
                                                    </span>
                                                </p>
                                            }
                                            <div className="buy_offer">
                                                <Button variant="contained" size="large" color="primary" fullWidth={true} onClick={() => this.doUpdateOrder()} disabled={this.state.Ischecked}>
                                                    Submit Conditional Offer to Buy
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <Dialog
                            open={this.state.orderModifyModal}
                            onClose={this.closeModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            fullWidth={true}
                            maxWidth={'md'}
                        >
                            <DialogTitle onClose={this.closeModal}>Order Modified</DialogTitle>
                            <DialogContent>
                                <div className="success_modal">
                                    <div className="success-modifyorder-icon"><img src={SuccessIcon} alt="" /></div>
                                    <h4 className="congrats_text">Congratulations!</h4>
                                    {
                                        (this.state.orderConfirmMsg) &&
                                        <div className="order-modify-success-message" dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(this.state.orderConfirmMsg)} }></div>
                                    }
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.goOrders()}>
                                        My Orders
                                    </Button>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.doneClick()}>
                                        Done
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.minAmountModal}
                            onClose={this.closeModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                {
                                    this.state.orderdetailsList && this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                    <h5> Minimum Investment Amount is $ {this.state.minAmount}</h5>
                                    :
                                    <h5> Minimum Investment Shares is {this.state.minAmount}</h5>
                                }
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.clcikOk()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={open}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                {
                                    this.state.orderdetailsList && this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                    <h5> You have exceeded your buying power. Please modify the amount!!</h5>
                                    :
                                    <h5> You have exceeded your buying power. Please modify the shares!!</h5>
                                }
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.clcikOk()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.openUnitIncErrorModal}
                            onClose={this.closeUnitIncErrorModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                <h5> Your value should be multiple of {this.state.orderdetailsList.offering.unit_increment}</h5>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.closeUnitIncErrorModal()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.openForExisteMinMaxTicket}
                            onClose={this.onCloseMinMaxModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                {
                                    this.state.orderdetailsList && this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                    <div>
                                        {
                                            this.state.orderdetailsList.offering.min_ticket_size !== null && this.state.orderdetailsList.offering.max_ticket_size !== null &&
                                            <h5>
                                                Amount should be between {numberWithCommasAndDollar(this.state.orderdetailsList.offering.min_ticket_size)} and {numberWithCommasAndDollar(this.state.orderdetailsList.offering.max_ticket_size)}
                                            </h5>
                                        }
                                        {
                                            this.state.orderdetailsList.offering.min_ticket_size === null && this.state.orderdetailsList.offering.max_ticket_size !== null &&
                                            <h5>
                                                Amount should be less than {numberWithCommasAndDollar(this.state.orderdetailsList.offering.max_ticket_size)}
                                            </h5>
                                        }
                                        {
                                            this.state.orderdetailsList.offering.min_ticket_size !== null && this.state.orderdetailsList.offering.max_ticket_size === null &&
                                            <h5>
                                                Amount should greater than {numberWithCommasAndDollar(this.state.orderdetailsList.offering.min_ticket_size)}
                                            </h5>
                                        }
                                    </div>
                                    :
                                    <div>
                                        {
                                            this.state.orderdetailsList.offering.min_ticket_size !== null && this.state.orderdetailsList.offering.max_ticket_size !== null &&
                                            <h5>
                                                Shares should be between {numberWithCommas(this.state.orderdetailsList.offering.min_ticket_size)} and {numberWithCommas(this.state.orderdetailsList.offering.max_ticket_size)}
                                            </h5>
                                        }
                                        {
                                            this.state.orderdetailsList.offering.min_ticket_size === null && this.state.orderdetailsList.offering.max_ticket_size !== null &&
                                            <h5>
                                                Shares should be less than {numberWithCommas(this.state.orderdetailsList.offering.max_ticket_size)}
                                            </h5>
                                        }
                                        {
                                            this.state.orderdetailsList.offering.min_ticket_size !== null && this.state.orderdetailsList.offering.max_ticket_size === null &&
                                            <h5>
                                                Shares should greater than {numberWithCommas(this.state.orderdetailsList.offering.min_ticket_size)}
                                            </h5>
                                        }
                                    </div>
                                }   
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.onCloseMinMaxModal()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
                
                <Dialog
                    open={this.state.rule5130Modal}
                    onClose={this.closeModal}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle onClose={this.closeModal}>Rule 5130</DialogTitle>
                    <DialogContent>
                        <div className="modal_css">
                            <iframe src={PDF_FINRARULE_ZERO} title="5130 pdf" allowFullScreen frameBorder="0" style={{height:'100%',width:'100%'}} />
                        </div>
                    </DialogContent>
                    
                </Dialog>
                <Dialog
                    open={this.state.rule5131Modal}
                    onClose={this.closeModal}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle onClose={this.closeModal}>Rule 5131</DialogTitle>
                    <DialogContent>
                        <div className="modal_css">
                            <iframe src={PDF_FINRARULE_ONE} title="5130 pdf" allowFullScreen frameBorder="0" style={{height:'100%',width:'100%'}} />
                        </div>
                    </DialogContent>
                </Dialog>
            </Dashboard>
        )
    }
}

ModifyOrederComponent.propTypes = {
    updateorderRes: PropTypes.any,
    accountsBuyingPowerRes: PropTypes.any,
    EditprofileRes: PropTypes.any,
    userActivityRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    updateorderRes: doupdateOrderRes,
    accountsBuyingPowerRes: doAccountsBuyingPowerRes,
    EditprofileRes: doEditprofileRes,
    userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
    return {
        updateOrder: (data) => dispatch(update_order_details(data)),
        accountsbuyingpower: (data) => dispatch(get_accounts_buying_power(data)),
        getProfile: () => dispatch(submit_profile()),
        userActivity: (data) => dispatch(user_activity(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ModifyOrederComponent);
