import * as types from './actionTypes';  
import userActivityApi from '../api/userActivityApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2'

export function doUserActivity(data) {
  return {
    type: types.USER_ACTIVITY, 
    data
  };
}

export function doUserActivityRes(data) {  
  return {
    type: types.USER_ACTIVITY_RES, 
    data
  };
}

export function user_activity(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      userActivityApi.doUserActivity(data).then(data => {
        dispatch(doUserActivityRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}