import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

class userConfirmApi {
    static doUserConfirm(data) {
        const TOKEN = getItem('auth_token');
        let isError = false;

        // let userconfirmdata={
        //     ext_id : data.ext_id,
        //     verb : data.verb,
        //     mpid: data.mpid,
        //     requested_amount: data.requested_amount,
        //     buying_power: data.buying_power,
        //     account_id: data.account_id,
        // }

        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(data)
            }
            return fetch(BASE_URL + '/orders/ioi_reconfirm', body).then(response => {
                if(response.ok === true){
                    Swal.fire({
                        title: 'Order Reconfirmed Successfully',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: true,
                        timer: 3000,
                    })
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default userConfirmApi;