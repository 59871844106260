import * as types from './actionTypes';  
import prospectusFirstViewApi from '../api/prospectusFirstViewApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2'

export function doProspectusFirstView(data) {
  return {
    type: types.DO_PROSPECTUS_FIRSTVIEW, 
    data
  };
}

export function doProspectusFirstViewRes(data) {
  return {
    type: types.DO_PROSPECTUS_FIRSTVIEW_RES, 
    data
  };
}

export function sendProspectusFirstView(request) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      prospectusFirstViewApi.doProspectusFirstView(request).then(data => {
        dispatch(doProspectusFirstViewRes(data));
        dispatch(doProspectusFirstViewRes(null));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}
