import * as types from './actionTypes';  
import loginApi from '../api/loginApi';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doLogin(data) {

  return {
    type: types.DO_LOGIN, 
    data
  };
}

export function doLoginRes(user) {  
  return {
    type: types.DO_LOGIN_RES, 
    user
  };
}

export function submit_login(data) {
    return function(dispatch) {
      dispatch(loadingRes(true));
      loginApi.doLogin(data).then(user => {
        dispatch(doLoginRes(user));
        dispatch(loadingRes(false));
        if(user.error){
          if(user.error===2032){
            dispatch(doLoginRes(user));
          }
          else{
            Swal.fire({
              title: user.message,
              icon: 'error',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              heightAuto: false
            })
          }
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
}