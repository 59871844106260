import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import './offeringsDetails.css';
import Dashboard from '../../component/Dashboard';
import { get_offering_details, doOfferingDetailsRes } from '../../actions/offeringDetailsActions';
import { get_active_order, doActiveOrderRes } from '../../actions/activeOrderActions';
import { submit_profile, doEditprofileRes } from '../../actions/editProfileActions';
import { getDate, getOfferingDetailsPrice, numberWithCommas, loadScript } from '../../helper/helper';
import { save_offering, dosaveOfferingRes } from '../../actions/offeringSaveActions';
import { get_prospectus, doProspectusRes } from '../../actions/prospectusActions';
import { social_media_post, socialMediaPostRes } from '../../actions/SocialMediaPostsAction';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { broker_dealer, doBrokerdelarRes } from '../../actions/brokerDealerActions';
import { sendProspectusFirstView, doProspectusFirstViewRes } from '../../actions/prospectusFirstViewActions';
// import { toastMessage } from '../../helper/toastMessage';
import BixInsightImg from '../../assets/images/bizinsights.png';
import PlaceHolderImg from '../../assets/images/placeholder_company_thumb.png';
import Slider from "react-slick";
import { getItem, setItem } from '../../utils/localStore';
import spinner from '../../assets/images/spinn_img.gif';
// import axios from 'axios';
import BackIcon from '../../assets/images/images/icon-left.svg';
import DocIcon from '../../assets/images/doc-icon.svg';
// import FbLogo from '../../assets/images/images/facebook_logo.png';
// import TwitterLogo from '../../assets/images/images/twitter_logo.png';
// import StockLogo from '../../assets/images/images/stock_twits_logo.png';
// import LinkedinLogo from '../../assets/images/images/linkedin_logo.png';

import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(),
		alignItems: 'center',
		justifyContent: 'center'
	},
}))(MuiDialogActions);

let isOfferingDetail = false;
let isBrokerDealer = false;
let isProfile = false;
let isActiveOrder = false;
let isProspectus = false;
let isSaveOffering = false;
let isFirstView = false;
class OfferingDetailsComponent extends React.PureComponent {
    constructor() {
        super();
        isOfferingDetail = false;
        isBrokerDealer = false;
        isProfile = false;
        isActiveOrder = false;
        isProspectus = false;
        isSaveOffering = false;
        isFirstView = false;
        this.state = {
            isLoader: false,
            open: false,
            value: '',
            buying_power: '',
            offeringname: '',
            offeringid:'',
            offeringsymbol:'',
            offeringTypeName: '',
            activeorderList: '',
            requestAmount: '',
            followed: null,
            offeringsdetailsList:'',
            windowUrl:'',
            space:', ',
            text : ', has an upcoming IPO on the @Click Capital Markets app, ',
            hashtagtext: 'IPO, invest, stockmarket, fintech',
            facebookProvider: '',
            twitterProvider: '',
            stocktwitsProvider: '',
            linkedinProvider: '', 
            userList:'',
            twconsumerKey:'bIZV7y4PT5usCkNR9hQR2ZOyy',
            twcontent:'',
            acountsStatus:'',
            isRender: false,
            prospectusModal:false,
            brochureModal: false,
            // socialShareModal: false,
            providerName:'',
            shareText: '',
            bizInsightData: null,
            screenId: '1.1',
            isDsp: 0,
			slideIndex: 0,
			updateCount: 0,
			loginCountNumber: 0,
            openOnBoardingModal: false,
            faqModal: false,
            brokerConnection: '',
            prospectusViewModal: false,
            isLoadSpinner: false,
            setShareText: '',
        }
    }

    componentDidMount() {
        const TOKEN = getItem('auth_token');
        if (!TOKEN) {
            localStorage.clear();
            window.location.href = '/';
        }
        loadScript();
        isOfferingDetail = true;
        isProfile = true;
        isActiveOrder = true;
        const id = this.props.match.params.id;
        this.props.getOfferingsDetails(id);
        this.props.getActiveOrder(id);
        this.props.getProfile();
        const activityData = {
            'ext_id': id,
			'screen_id': this.state.screenId,
			'source': 'web'
		}
        this.props.userActivity(activityData)

    }
    
    closeOnBoarding = (event, reason) => {
		if (reason !== 'backdropClick') {
			setItem('login_count', 2);
			this.setState({
				openOnBoardingModal: false,
			}, () => {});
		}
	}
    //  ==================== Offering Details Function =================== 

    addDefaultSrc(ev) {
        ev.target.src = PlaceHolderImg;
    }

    handleBack = () => {
        let data = {
            data:null
        }
        this.props.doOfferingDetailsRes(data);
        this.props.doActiveOrderRes(data);
    }

    followDetails(ext_id, followed) {
        let data = {
            "ext_id": ext_id,
            "save": followed
        }
        // if(data.save === true){
        //     toastMessage('success', `You just followed ${this.state.offeringsdetailsList.name}. This means you will get alerts on this offering as it changes and progresses.`)
        // }
        isSaveOffering = true;
        this.props.saveOfferings(data);
    }

    // openModalLinkAcoount(){
    //     this.setState({ 
    //         open: true
    //     })
    // }

    onCloseModal = () => {
        this.setState({ 
            open: false,
            prospectusModal: false,
            brochureModal: false,
            faqModal: false,
            // socialShareModal: false,
            shareText: this.state.setShareText
        });
    }

    openProspectusModal = () => {
        this.setState({
            prospectusModal: true
        })
    }

    openBizInsight() {
        this.props.history.push(`/offeringdetail/${this.state.offeringid}/biz-insight`, {name: this.state.offeringsdetailsList.name})
    }

    readProspectus(geturl) {
        var url = geturl;
        var prefix = 'https://';
        if (url.substr(0, prefix.length) !== prefix)
        {
            url = prefix + url;
        }
        window.open(url, '_blank');
        this.setState({ 
            prospectusModal: false
        });
    }

    emailProspectus(ext_id) {
        let prospectusData = {
            'ext_id' : ext_id,
            'url_type': 'prospectus'
        }
        isProspectus = true;
        this.props.getprospectus(prospectusData);
        this.setState({
            prospectusModal: false
        })
    }

    readFaq(geturl) {
        var url = geturl;
        var prefix = 'https://';
        if (url.substr(0, prefix.length) !== prefix)
        {
            url = prefix + url;
        }
        window.open(url, '_blank');
        this.setState({ 
            faqModal: false
        });
    }
    
    emailFaq(ext_id) {
        let faqData = {
            'ext_id' : ext_id,
            'url_type': 'faq'
        }
        isProspectus = true;
        this.props.getprospectus(faqData);
        this.setState({
            faqModal: false
        })
    }

    cancelProspectus() {
        this.setState({ 
            prospectusModal: false
        });
    }

    openBrochureModal = () => {
        this.setState({
            brochureModal: true,
        });
    }
    openFaqModal = () => {
        this.setState({
            faqModal: true,
        });
    }

    readBrochure(geturl) {
        var url = geturl;
        var prefix = 'https://';
        if (url.substr(0, prefix.length) !== prefix)
        {
            url = prefix + url;
        }
        window.open(url, '_blank');
        this.setState({ 
            brochureModal: false
        });
    }

    emailBrochure(ext_id) {
        let brochureData = {
            'ext_id' : ext_id,
            'url_type': 'brochure'
        }
        isProspectus = true;
        this.props.getprospectus(brochureData);
        this.setState({ 
            brochureModal: false
        });
    }

    cancelBrochure() {
        this.setState({ 
            brochureModal: false
        });
    }
    cancelFaq() {
        this.setState({ 
            faqModal: false
        });
    }
    clcikOk() {
        this.props.history.push('/socialmedia')
    }

    clcikCancel(){
        this.setState({ open: false });
    }

    // facebookShareModal(facebook){
    //     this.setState({
    //         socialShareModal: true,
    //         providerName: facebook,
    //     })
    // }

    // twitterShareModal(twitter){
    //     this.setState({
    //         socialShareModal: true,
    //         providerName: twitter,
    //     })
        
    // }

    // stoctwitsShareModal(stocktwits){
    //     this.setState({
    //         socialShareModal: true,
    //         providerName: stocktwits,
    //     })
    // }

    // linkedInShareModal(linkedin){
    //     this.setState({
    //         socialShareModal: true,
    //         providerName: linkedin,
    //     })
    // }

    // shareFacebookContent = () => {
    //     const data = {
    //         provider: 'facebook',
    //         ext_id: this.state.offeringid,
    //         content: this.state.shareText,
    //     }
    //     this.props.socialMediaPost(data);
    //     this.setState({
    //         socialShareModal: false,
    //         shareText: this.state.setShareText
    //     })
    // }

    shareTwitterContent = () => {
        const data = {
            provider: 'twitter',
            ext_id: this.state.offeringid,
            content: this.state.shareText,
        }
        this.props.socialMediaPost(data);
        this.setState({
            socialShareModal: false,
            shareText: this.state.setShareText
        })
        // let shareData = {
        //     status: this.state.offeringsdetailsList.ticker_symbol + this.state.space + this.state.offeringsdetailsList.name + this.state.space + this.state.text + 'https://click.markets/offerings/?offer_id=' + this.state.offeringsdetailsList.id + this.state.hashtagtext
        // }
        // const config = {
        //     headers: {
        //         "Content-Type": "application/json",
        //         Authorization: 'OAuth' +
        //         'include_entities=' + true + 
        //         'oauth_consumer_key=' + this.state.twiterconsumerkey + 
        //         'oauth_token=' + this.state.twitterToken + 
        //         'oauth_signature_method=HMAC-SHA1' + 
        //         'oauth_timestamp=' + this.state.twcontent + 
        //         'oauth_nonce=' + this.state.twcontent + 
        //         'oauth_version=1.0' + 
        //         'oauth_signature=' + this.state.twcontent
        //     },
        // }
        // axios.post(`https://api.twitter.com/1.1/statuses/update.json?status=${shareData}`, config)
        // .then(response => {
        //     if(response.ok === true){
        //         this.setState({
        //             socialShareModal: false,
        //         })
        //         Swal.fire({
        //             title: 'Share was successful',
        //             icon: 'success',
        //             confirmButtonText: 'OK',
        //             allowOutsideClick: false,
        //             timer: 3000
        //         })
        //     }else{
        //         Swal.fire({
        //             title: 'Share was not successful, please try again',
        //             icon: 'error',
        //             confirmButtonText: 'OK',
        //             allowOutsideClick: false,
        //             timer: 3000
        //         })
        //     }
        //     return response.json();
        // }).catch(error => {
        //     return error;
        // });
    }

    shareStocktwitsContent = () => {
        const data = {
            provider: 'stocktwits',
            ext_id: this.state.offeringid,
            content: this.state.shareText,
        }
        this.props.socialMediaPost(data);
        this.setState({
            socialShareModal: false,
            shareText: this.state.setShareText
        })
        // let shareData = {
        //     body: this.state.shareText
        // }
        // const config = {
        //     headers: {
        //         "Content-Type": "application/x-www-form-urlencoded",
        //         body: shareData,
        //     },
        // }
        // axios.post(`https://api.stocktwits.com/api/2/messages/create.json?access_token=${this.state.stToken}`, config)
        // .then(response => {
        //     if(response.ok === true){
        //         this.setState({
        //             socialShareModal: false,
        //         })
        //         Swal.fire({
        //             title: 'Share was successful',
        //             icon: 'success',
        //             confirmButtonText: 'OK',
        //             allowOutsideClick: false,
        //             timer: 3000
        //         })
        //     }else{
        //         Swal.fire({
        //             title: 'Share was not successful, please try again',
        //             icon: 'error',
        //             confirmButtonText: 'OK',
        //             allowOutsideClick: false,
        //             timer: 3000
        //         })
        //     }
        //     return response.json();
        // }).catch(error => {
        //     return error;
        // });
    }

    shareLinkedInContent = () => {
        // replace the content with the data received from the social share modal.
        const data = {
            provider: 'linkedin',
            ext_id: this.state.offeringid,
            content: this.state.shareText,
        }
        this.props.socialMediaPost(data);
        this.setState({
            socialShareModal: false,
            shareText: this.state.setShareText
        })
    }

    // shareLinkedInContent(){
    //     let shareData = {
    //         comment: "Clickipo",
    //         content: {
    //           title: "Clickipo",
    //           description: "Clickipo",
    //           submitted_url:"https://click.markets/offerings/?offer_id="
    //         },
    //         visibility :{
    //             code : "anyone"
    //         }
    //     }
    //     const config = {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: 'Bearer ' + this.state.linkedinToken
    //         },
    //     }
    //     axios.post('https://api.linkedin.com/v1/people/~/shares?format=json', shareData, config)
    //     .then(response => {
    //         if(response.ok === true){
    //             this.setState({
    //                 socialShareModal: false,
    //             })
    //             Swal.fire({
    //                 title: 'Share was successful',
    //                 icon: 'success',
    //                 confirmButtonText: 'OK',
    //                 allowOutsideClick: false,
    //                 timer: 3000
    //             })
    //         }else{
    //             Swal.fire({
    //                 title: 'Share was not successful, please try again',
    //                 icon: 'error',
    //                 confirmButtonText: 'OK',
    //                 allowOutsideClick: false,
    //                 timer: 3000
    //             })
    //         }
    //         return response.json();
    //     }).catch(error => {
    //         return error;
    //     });
    // } 

    handleOnChange(event) {
        if (this.state.shareText !== event.target.value) {
            this.setState({
                shareText: event.target.value
            })
        }
    }

    //  ==================== Receive Props ===================
    static getDerivedStateFromProps(props, state) {
        if (props.BrokerdelarRes) {
            if (props.BrokerdelarRes.data && props.BrokerdelarRes.data.brokerdealer) {
                if (props.BrokerdelarRes.data.brokerdealer.message === 'Success' && isBrokerDealer) {
                    isBrokerDealer = false;
                    if (props.BrokerdelarRes.data.brokerdealer.data.length > 0) {
                        for (let item of props.BrokerdelarRes.data.brokerdealer.data) {
                            if (item.name === 'ScorePriority') {
                                props.history.push({
                                    pathname: `/create-brokerage`,
                                    state: item
                                });
                            }
                        }
                        return {
                            isLoadSpinner: false,
                        };
                    }
                }
            }
        }
        if (props.offeringdetailsRes) {
            if (props.offeringdetailsRes.data && props.offeringdetailsRes.data.offeringdetails) {
                if (props.offeringdetailsRes.data.offeringdetails.message === 'Success' && isOfferingDetail) {
                    isOfferingDetail = false;
                    let loginCount = 0;
                    if (props.offeringdetailsRes.data.offeringdetails.data.dsp === 1 || props.offeringdetailsRes.data.offeringdetails.data.dsp === 2) {
                        loginCount = localStorage.getItem('login_count');
                    }
                    // props.offeringdetailsRes.data.offeringdetails.data.offering_type_name = "UIT"
                    return {
                        offeringsdetailsList: props.offeringdetailsRes.data.offeringdetails.data,
                        isLoader: false,
                        offeringTypeName: props.offeringdetailsRes.data.offeringdetails.data.offering_type_name,
                        followed: props.offeringdetailsRes.data.offeringdetails.data.followed,
                        offeringname: props.offeringdetailsRes.data.offeringdetails.data.name,
                        offeringid: props.offeringdetailsRes.data.offeringdetails.data.ext_id,
                        offeringsymbol: props.offeringdetailsRes.data.offeringdetails.data.ticker_symbol,
                        isDsp: props.offeringdetailsRes.data.offeringdetails.data.dsp,
                        loginCountNumber: loginCount,
                        openOnBoardingModal: Number(loginCount) === 1 ? true : false,
                    };
                }
            }
        }
        if (props.activeordersRes) {
            if (props.activeordersRes.data && props.activeordersRes.data.activeorder) {
                if (props.activeordersRes.data.activeorder.message === 'Success' && isActiveOrder) {
                    isActiveOrder = false;
                    if (props.activeordersRes.data.activeorder.data) {
                        return {
                            activeorderList: props.activeordersRes.data.activeorder.data,
                            orderId: props.activeordersRes.data.activeorder.data.id,
                            requestAmount: props.activeordersRes.data.activeorder.data.requested_amount
                        };
                    }
                    
                }
            }
        }
        if (props.prospectusRes) {
            if (props.prospectusRes.data && props.prospectusRes.data.prospectus) {
                if (props.prospectusRes.data.prospectus.message === 'Success' && isProspectus) {
                    isProspectus= false;
                    return {
                        prospectusModal: false
                    };
                }
            }
        }
        if (props.EditprofileRes) {
            if (props.EditprofileRes.data && props.EditprofileRes.data.editprofile) {
                if (props.EditprofileRes.data.editprofile.message === 'Success' && isProfile) {
                    isProfile = false;
                    let accStatus = '';
                    let bkerConnection = '';
                    let fbProvider = '';
                    let twProvider = '';
                    let stkProvider = '';
                    let lnkProvider = '';
                    if (props.EditprofileRes.data.editprofile.data.broker_connection) {
                        accStatus =  props.EditprofileRes.data.editprofile.data.broker_connection.status;
                        bkerConnection =  props.EditprofileRes.data.editprofile.data.broker_connection;
                    }
                    if (props.EditprofileRes.data.editprofile.data.user_identities) {
                        for(let identity of props.EditprofileRes.data.editprofile.data.user_identities){
                            if(identity.provider === "facebook"){
                                fbProvider = identity.provider;
                            }
                            if(identity.provider === "twitter"){
                                twProvider = identity.provider;
                            }
                            if(identity.provider === "stocktwits"){
                                stkProvider = identity.provider;
                            }
                            if(identity.provider === "linkedin"){
                                lnkProvider = identity.provider;
                            }
                        }
                    }
                    return {
                        userList: props.EditprofileRes.data.editprofile.data,
                        acountsStatus: accStatus,
                        brokerConnection: bkerConnection,
                        facebookProvider: fbProvider,
                        twitterProvider: twProvider,
                        stocktwitsProvider: stkProvider,
                        linkedinProvider: lnkProvider,
                    }
                    
                }
            }
        }
        if (props.saveOfferingsRes) {
            if (props.saveOfferingsRes.data && props.saveOfferingsRes.data.offeringsave ) {
                if (props.saveOfferingsRes.data.offeringsave.message === 'Success' && isSaveOffering) {
                    isSaveOffering = false;
                    isOfferingDetail = true;
                    props.getOfferingsDetails(state.offeringid);
                }
            }
        }
    
        if (props.prospectusFirstViewRes) {
            if (props.prospectusFirstViewRes.data && props.prospectusFirstViewRes.data.prospectusFirstView) {
                if (props.prospectusFirstViewRes.data.prospectusFirstView.message === 'Success' && isFirstView) {
                    isFirstView = false;
                    props.history.push(`/placeorder/${state.offeringsdetailsList.ext_id}`)
                    return {
                        isLoadSpinner: false,
                    };
                }
            }
        }
        return null;
    }

    toCommas(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
	next = () => {
		this.slider.slickNext();
	}
    onCloseProsModal = () => {
        this.setState({ 
            prospectusViewModal: false
        });
    }
    openProsModal = () => {
        if (this.state.offeringsdetailsList.dsp === 0) {
            // this.doPlaceOrder(this.state.offeringsdetailsList.ext_id)
            this.props.history.push(`/placeorder/${this.state.offeringsdetailsList.ext_id}`);
        } else {
            this.setState({ 
                prospectusViewModal: true
            }, () => {});
        }
    }
    goToBrokerDealer = () => {
        this.props.history.push(`/brokerdealer`);
        // isBrokerDealer = true;
        // this.props.getBrokerdealer();
        // this.setState({
        //     isLoadSpinner: true,
        // });
    }
    placeOrderView = () => {
        return <div>
            {
                this.state.offeringsdetailsList.has_order === false ?
                <div>
                    <div className="details_btn mb-15px-imp">
                        <button onClick={() => this.openProsModal()} className={ (this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "green_btn" : this.state.offeringsdetailsList.offering_type_name === 'Follow-on' ? "turquoise_btn" : this.state.offeringsdetailsList.offering_type_name === 'ETF' ? "etf_btn" : this.state.offeringsdetailsList.offering_type_name === 'UIT' ? "uit_btn" : "turquoise_btn" )}>
                            Place Order
                        </button>
                    </div>
                    {/* {
                        this.state.brokerConnection && this.state.brokerConnection.proxy === 1 && (this.state.offeringsdetailsList.dsp === 1 || this.state.offeringsdetailsList.dsp === 2) &&
                        <div className="details_btn mb-15px-imp">
                            <button onClick={()=> this.goToBrokerDealer()} className={ (this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "green_btn" : "turquoise_btn" )} disabled={this.state.isLoadSpinner}>
                                Connect a Brokerage Account
                                {
                                    (this.state.isLoadSpinner) &&
                                    <img src={spinner} alt="" style={{width: 20, paddingLeft: 10}} />
                                }
                            </button>
                        </div>
                    } */}
                </div>
                :
                <div style={{textAlign: 'center'}}>
                    <div className="details_btn mb-15px-imp">
                        {
                        (this.state.activeorderList!=='' && this.state.activeorderList!==undefined  && this.state.activeorderList.offering!==undefined) &&
                            <Link to={{ pathname: `/modifyorder/${this.state.activeorderList.offering.ext_id}`, state: { orderData: this.state.activeorderList } }}>
                                <button type="submit" className={ (this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "green_btn" : this.state.offeringsdetailsList.offering_type_name === 'Follow-on' ? "turquoise_btn" : this.state.offeringsdetailsList.offering_type_name === 'ETF' ? "etf_btn" : this.state.offeringsdetailsList.offering_type_name === 'UIT' ? "uit_btn" : "turquoise_btn" )}>
                                    Modify Order
                                </button>
                            </Link>
                        }
                    </div>
                </div>
            }
        </div>
    }
    notAvailableView = () => {
        return <div className="details_btn mb-15px-imp">
            <Button type="submit" className={ (this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "green_btn" : this.state.offeringsdetailsList.offering_type_name === 'Follow-on' ? "turquoise_btn" : this.state.offeringsdetailsList.offering_type_name === 'ETF' ? "etf_btn" : this.state.offeringsdetailsList.offering_type_name === 'UIT' ? "uit_btn" : "turquoise_btn" )} disabled>
                Not Available at this time 
            </Button>
        </div>
    }
    goOrderPage = () => {
        let request = {
            ext_id: this.state.offeringsdetailsList.ext_id
        }
        isFirstView = true;
        this.props.sendProspectusFirstView(request);
        this.setState({
            isLoadSpinner: true,
        })
    }
    render() {
        const { open } = this.state;
        var shares = {};
        if (this.state.offeringsdetailsList.final_shares === 0 || this.state.offeringsdetailsList.final_shares === undefined) {
            shares = 'TBD';
        } else {
            shares = numberWithCommas(this.state.offeringsdetailsList.final_shares);
        }
        var settings = {
			dots: true,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: false,
			afterChange: () =>
				this.setState(state => ({ updateCount: state.updateCount + 1 })),
			beforeChange: (current, next) => this.setState({ slideIndex: next })
          };
          
		// const spinner = <span><img src={LoaderSpinner} alt="" /></span>;
        return (
            <Dashboard title="Click Capital Markets - Offering Details" pageTitle="Offering Details" >

                {/* ==================== Offering Details Sceen =================== */}
                {
                    this.state.offeringsdetailsList && this.state.userList &&
                    <div className="offring_deatils_content">
                        <div className="offring_deatils_heading">
                            {(this.state.isDsp === 0) &&<div className="back_icon_text"  onClick={this.handleBack}>
                            <Link to={{
                                        pathname: "/offering",
                                        DSPView: false
                                    }}>
                                <img src={BackIcon} alt="" className="back_icon" />
                                <span className="go_back">back to offerings</span>
                            </Link>
                            </div>}
                            <span className="offering_detail_title">{this.state.offeringsdetailsList.name}</span>
                        </div>
                        <div className="offring_deatils_inner_content">
                            <div className="row ml-mr-0px-imp">
                                <div className="col col-12 col-sm-5 col-md-7">
                                    <div className="offering_details_left_content">
                                        <div className="text-center">
                                            <img src={this.state.offeringsdetailsList.logo_small} onError={this.addDefaultSrc} className="details_logo" alt="" />
                                        </div>
                                        <p className="offering_name">{this.state.offeringsdetailsList.name}</p>
                                        <p className="description_heading">DESCRIPTION</p>
                                        <p className="description_content">
                                            {this.state.offeringsdetailsList.description}
                                        </p>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <p className="item_link">PROSPECTUS</p>
                                                <div className="icon_text" onClick={this.openProspectusModal}>
                                                    <img src={DocIcon} alt="" />
                                                    <span>Prospectus</span>
                                                </div>
                                            </div>
                                            {
                                            (this.state.offeringsdetailsList.dsp_faq_file) && (this.state.offeringsdetailsList.dsp_faq_file !== null) && (this.state.offeringsdetailsList.dsp_faq_file !== '') &&
                                                <div className="col-md-4">
                                                    <p className="item_link">DSP Faq</p>
                                                    <div className="icon_text" onClick={this.openFaqModal}>
                                                        <img src={DocIcon} alt="" />
                                                        <span>DSP Faq</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                            (this.state.offeringsdetailsList.brochure_url !== null) && (this.state.offeringsdetailsList.brochure_url !== '') &&
                                                <div className="col-md-4">
                                                    <p className="item_link">Brochure</p>
                                                    <div className="icon_text" onClick={this.openBrochureModal}>
                                                        <img src={DocIcon} alt="" />
                                                        <span>Brochure</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        

                                        {
                                            (this.state.offeringsdetailsList.biz_insights === 1) &&
                                            <div className="biz_insights_box">
                                                <Button variant="contained" color="primary" size="large" onClick={() => {this.openBizInsight()} }>View Biz Insights <img src={BixInsightImg} alt={BixInsightImg} style={{width: 25, marginTop: '-3px'}} /></Button>
                                            </div> 
                                        } 

                                    </div>
                                </div>
                                <div className="col col-12 col-sm-7 col-md-5 offring_deatils_inner_content_middle">
                                    <p className="company_offringdeatils_tag">
                                    Ticker Symbol: {this.state.offeringsdetailsList.ticker_symbol}
                                    </p>
                                    <p className={"company_offringdeatils_account " + (this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "green_text" : this.state.offeringsdetailsList.offering_type_name === 'Follow-on' ? "turquoise_text" : this.state.offeringsdetailsList.offering_type_name === 'ETF' ? "etf_text" : this.state.offeringsdetailsList.offering_type_name === 'UIT' ? "uit_text" : "turquoise_text" ) }>
                                        {this.state.offeringsdetailsList.offering_type_name}
                                    </p>
                                    <div className="row">
                                        <div className="col col-6">
                                            <p className="industries_content">Industry</p>
                                        </div>
                                        <div className="col col-6">
                                            <span className="industries_content_text">
                                                {
                                                    this.state.offeringsdetailsList.industry === null &&
                                                        <p className="account_open_date">
                                                            Not Available
                                                        </p>
                                                }
                                                {
                                                    this.state.offeringsdetailsList.industry !== null &&
                                                    <p className={"account_open_date " + (this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "green_text" : this.state.offeringsdetailsList.offering_type_name === 'Follow-on' ? "turquoise_text" : this.state.offeringsdetailsList.offering_type_name === 'ETF' ? "etf_text" : this.state.offeringsdetailsList.offering_type_name === 'UIT' ? "uit_text" : "turquoise_text" )}>
                                                        {this.state.offeringsdetailsList.industry}
                                                    </p>
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-6">
                                            <p className="account_offringdeatils_type">Anticipated</p>
                                        </div>
                                        <div className="col col-6">
                                            <p className="account_open_offringdeatils_date">
                                                {getDate(this.state.offeringsdetailsList)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="price_level_offering_type clearfix">Anticipated price range</p>
                                        </div>
                                        <div className="col-6">
                                            <span className="amount_offring">
                                                {this.state.offeringsdetailsList.price_range === null ? 'N/A': this.state.offeringsdetailsList.price_range}
                                            </span>
                                        </div>                                        
                                    </div>
                                    {
                                        (this.state.offeringsdetailsList.offering_type_name === 'Follow-on' || this.state.offeringsdetailsList.offering_type_name === 'ETF' || this.state.offeringsdetailsList.offering_type_name === 'UIT') &&
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="price_level_offering_type clearfix">Last closing price</p>
                                            </div>
                                            <div className="col-6">
                                                <span className="amount_offring">
                                                    {getOfferingDetailsPrice(this.state.offeringsdetailsList)}
                                                </span>
                                            </div>                                        
                                        </div>
                                    }
                                    {
                                        this.state.offeringsdetailsList.offering_type_name === 'Block' &&
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="price_level_offering_type clearfix">Last closing price</p>
                                            </div>
                                            <div className="col-6">
                                                <span className="amount_offring">
                                                    {getOfferingDetailsPrice(this.state.offeringsdetailsList)}
                                                </span>
                                            </div>                                        
                                        </div>
                                    }
                                    {
                                        this.state.offeringsdetailsList.offering_type_name === 'Spot' &&
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="price_level_offering_type clearfix">Last closing price</p>
                                            </div>
                                            <div className="col-6">
                                                <span className="amount_offring">
                                                    {getOfferingDetailsPrice(this.state.offeringsdetailsList)}
                                                </span>
                                            </div>                                        
                                        </div>
                                    }
                                    {
                                        this.state.offeringsdetailsList.offering_type_name === 'Overnight' &&
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="price_level_offering_type clearfix">Last closing price</p>
                                            </div>
                                            <div className="col-6">
                                                <span className="amount_offring">
                                                    {getOfferingDetailsPrice(this.state.offeringsdetailsList)}
                                                </span>
                                            </div>                                        
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="price_level_offering_type clearfix">
                                                Approx shares
                                            </p>
                                        </div>
                                        <div className="col-6">
                                            <span className="amount_offring">
                                                {shares}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-12 col-lg-12 col-xl-12 offering-detail-buttons-wrapper">
                                            {
                                                (this.state.followed === true) && 
                                                <div className="follow_offering_details_button offering_details_intrested_btn has_connect_brokrage_account_btn">
                                                <button  className={(this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "ipo_interested_btn" : this.state.offeringsdetailsList.offering_type_name === 'Follow-on' ? "turquoise_interested_btn" : this.state.offeringsdetailsList.offering_type_name === 'ETF' ? "etf_interested_btn" : this.state.offeringsdetailsList.offering_type_name === 'UIT' ? "uit_interested_btn" : "ipo_interested_btn" )} onClick={() => this.followDetails(this.state.offeringsdetailsList.ext_id, false)}>
                                                    Interested
                                                </button>
                                                </div>
                                            }

                                            {
                                                (this.state.followed === false) && 
                                                <div className={"follow_offering_details_button offering_details_intrested_btn has_connect_brokrage_account_btn " + (this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "not_ipo_interested_btn" : this.state.offeringsdetailsList.offering_type_name === 'Follow-on' ? "not_follow_interested_btn" : this.state.offeringsdetailsList.offering_type_name === 'ETF' ? "not_etf_interested_btn" : this.state.offeringsdetailsList.offering_type_name === 'UIT' ? "not_uit_interested_btn" : "not_ipo_interested_btn" )}>
                                                <button onClick={() => this.followDetails(this.state.offeringsdetailsList.ext_id, true)}>
                                                    Interested?
                                                </button>
                                                </div>
                                            }
                                            {
                                                this.state.acountsStatus !=='active' && this.state.offeringsdetailsList.available_to_order === 1 &&
                                                <div className="details_btn mb-15px-imp">
                                                    <button onClick={()=> this.goToBrokerDealer()} className={ (this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "green_btn" : this.state.offeringsdetailsList.offering_type_name === 'Follow-on' ? "turquoise_btn" : this.state.offeringsdetailsList.offering_type_name === 'ETF' ? "etf_btn" : this.state.offeringsdetailsList.offering_type_name === 'UIT' ? "uit_btn" : "turquoise_btn" )} disabled={this.state.isLoadSpinner}>
                                                        Connect a Brokerage Account
                                                        {
                                                            (this.state.isLoadSpinner) &&
                                                            <img src={spinner} alt="" style={{width: 20, paddingLeft: 10}} />
                                                        }
                                                    </button>
                                                </div>
                                            }

                                            {
                                                (this.state.offeringsdetailsList.available_to_order === 1 && this.state.acountsStatus==='active') && 
                                                <div>
                                                    {
                                                        this.state.offeringsdetailsList.max_price > 0  && this.state.offeringsdetailsList.trade_date !== null ?
                                                        
                                                        <div style={{textAlign: 'center'}}>
                                                            {
                                                                this.state.offeringsdetailsList.enforce_buying_power === false ?
                                                                <div>{this.placeOrderView()}</div>
                                                                :
                                                                <div style={{textAlign: 'center'}}>
                                                                    {
                                                                        this.state.brokerConnection && this.state.brokerConnection.buying_power > 0 &&
                                                                        <div>{this.placeOrderView()}</div>
                                                                    }
                                                                </div>
                                                            }

                                                        </div>
                                                        :
                                                        <div>{this.notAvailableView()}</div>
                                                    }
                                                </div>
                                            }
                                            {
                                            this.state.offeringsdetailsList.available_to_order === 0 &&
                                                <div>{this.notAvailableView()}</div>
                                            }

                                            {
                                                this.state.offeringsdetailsList.available_to_order === 2 && this.state.offeringsdetailsList.has_order === false  &&
                                                <div className="details_btn mb-15px-imp">
                                                    <button type="submit" className={ (this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "green_btn" : this.state.offeringsdetailsList.offering_type_name === 'Follow-on' ? "turquoise_btn" : this.state.offeringsdetailsList.offering_type_name === 'ETF' ? "etf_btn" : this.state.offeringsdetailsList.offering_type_name === 'UIT' ? "uit_btn" : "turquoise_btn" )} disabled>
                                                        Order book closed
                                                    </button>
                                                </div>
                                            }
                                            
                                        </div>
                                    </div>
                                    {/* <div className="row offring_deatils_inner_content_inner align-items-center">
                                        <div className="col col-12 col-md-12 col-xl-12 text-right">
                                            <p className="share_text">Share it!</p>
                                            <div className="share_social_icons">
                                                {
                                                    this.state.facebookProvider === "facebook" &&
                                                    <div className="media_facebook_image" onClick={() => this.facebookShareModal('facebook')}>
                                                        <div id="facebook-share-button">
                                                            <img src={FbLogo} alt="" />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.facebookProvider !== "facebook" &&
                                                    <div className="media_facebook_image" onClick={() => this.openModalLinkAcoount()}>
                                                        <div id="facebook-share-button">
                                                            <img src={FbLogo} alt="" />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.twitterProvider === "twitter" &&
                                                    <div className="media_twitter_image" onClick={() => this.twitterShareModal('twitter')}>
                                                        <div id="twitter-share-button">
                                                            <img src={TwitterLogo} alt="" />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.twitterProvider !== "twitter" &&
                                                    <div className="media_twitter_image" onClick={() => this.openModalLinkAcoount()}>
                                                        <div id="twitter-share-button">
                                                            <img src={TwitterLogo} alt="" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                this.state.stocktwitsProvider === "stocktwits" &&
                                                <div className="media_stocktwits_image" onClick={() => this.stoctwitsShareModal('stocktwits')}>
                                                    <div id="stocktwits-share-button">
                                                        <img src={StockLogo} alt="" />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.stocktwitsProvider !== "stocktwits" &&
                                                <div className="media_stocktwits_image" onClick={() => this.openModalLinkAcoount()}>
                                                    <div id="stocktwits-share-button">
                                                        <img src={StockLogo} alt="" />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.linkedinProvider === "linkedin" &&
                                                <div className="media_linkedin_image" onClick={() => this.linkedInShareModal('linkedin')}>
                                                    <div id="linkedin-share-button">
                                                        <img src={LinkedinLogo} alt="" />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.linkedinProvider !== "linkedin" &&
                                                <div className="media_linkedin_image" onClick={() => this.openModalLinkAcoount()}>
                                                    <div id="linkedin-share-button">
                                                        <img src={LinkedinLogo} alt="" />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div> */}

                                    <span className="order_status" style={{ marginTop: 0 }}>UNDERWRITERS</span>
                                    {
                                        this.state.offeringsdetailsList.underwriters_list && this.state.offeringsdetailsList.underwriters_list.length > 0 && this.state.offeringsdetailsList.underwriters_list.map((offeringUnderWriter, i) =>
                                            <div key={i}>
                                                {
                                                    offeringUnderWriter.executing_broker_name === null &&
                                                    <div className="order_status_text">
                                                        Not Available
                                                    </div>
                                                }
                                                {
                                                    offeringUnderWriter.executing_broker_name !== null &&
                                                    <div className="order_status_text">
                                                        {offeringUnderWriter.executing_broker_name}
                                                    </div>
                                                }
                                            </div>
                                        )  
                                    }
                                </div>
                                {
                                    //|| this.state.isDsp === 2
                                    //For DSP 2 hiding
                                }
                                {(this.state.isDsp === 1) &&<div className="col col-12">
                                    <div className="all_offerings_button_div">
                                        <Link to={{
                                            pathname: "/offering",
                                            DSPView: true
                                                }}>
                                            <Button variant="contained" color="primary" type="button" >
                                            Go to all offerings
                                            </Button>
                                        </Link>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <Dialog
                            open={this.state.prospectusViewModal}
                            onClose={this.onCloseProsModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            maxWidth={"md"}
                            fullWidth={true}
                            scroll={'paper'}
                        >
                            <DialogTitle id="scroll-dialog-title" onClose={this.onCloseProsModal}>Prospectus</DialogTitle>
                            <DialogContent>
                                <div className="modal_css">
                                    <iframe src={this.state.offeringsdetailsList.prospectus_url} title="Prospectus" allowFullScreen frameBorder="0" style={{height:'100%',width:'100%'}} />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    {/* <Button variant="contained" size="large" color="primary" onClick={() => this.clcikCancel()} >
                                        Cancel
                                    </Button> */}
                                    {
                                        this.state.offeringsdetailsList.dsp === 0 &&
                                        <Link to={`/placeorder/${this.state.offeringsdetailsList.ext_id}`}>
                                            <Button variant="contained" size="large" color="primary">
                                                Continue
                                            </Button>
                                        </Link>
                                    }
                                    {
                                        (this.state.offeringsdetailsList.dsp === 1 || this.state.offeringsdetailsList.dsp === 2) &&
                                        <Button onClick={() => this.goOrderPage()} variant="contained" size="large" color="primary" disabled={this.state.isLoadSpinner}>
                                            Accept 
                                            {
                                                (this.state.isLoadSpinner) &&
                                                <img src={spinner} alt="" style={{width: 20, paddingLeft: 10}} />
                                            }
                                        </Button>
                                    }
                                </div>
                            </DialogActions>
                        </Dialog>
                        {/* <Dialog
                            open={this.state.socialShareModal}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                <div className="social_modal">
                                    <div className="social_modal--header">
                                        <div className="social_modal--header__left">
                                            <img src={this.state.offeringsdetailsList.logo_url} onError={this.addDefaultSrc} className="details_logo" alt="" />
                                        </div>
                                        <div className="social_modal--header__right">
                                            <p className="">$ {this.state.offeringsdetailsList.ticker_symbol}</p>
                                            <p className="">{this.state.offeringsdetailsList.name}</p>
                                        </div>
                                    </div>
                                    <div className="social_modal--body">
                                    <div className="social_modal--body__top">Join the Conversation</div>
                                        <div className="social_modal--body__bottom">
                                            <textarea rows={3} cols={56} value={this.state.shareText} onChange={(event) => this.handleOnChange(event)}>
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className="social_modal--footer">
                                        {
                                            this.state.providerName === 'facebook' &&
                                            <Button type="submit" className="social_modal--footer__button_facebook" onClick={() => this.shareFacebookContent()}>
                                                <img src={FbLogo} alt="" className="social_modal--footer__img_facebook" />
                                                Share
                                            </Button>
                                        }
                                        {
                                            this.state.providerName === 'twitter' &&
                                            <Button type="submit" className="social_modal--footer__button_twitter" onClick={() => this.shareTwitterContent()}>
                                                <img src={TwitterLogo} alt="" className="social_modal--footer__img_twitter" />
                                                Share
                                            </Button>
                                        }
                                        {
                                            this.state.providerName === 'stocktwits' &&
                                            <Button type="submit" className="social_modal--footer__button_stocktwits" onClick={() => this.shareStocktwitsContent()}>
                                                <img src={StockLogo} alt="" className="social_modal--footer__img_stocktwits" />
                                                Share
                                            </Button>
                                        }
                                        {
                                            this.state.providerName === 'linkedin' &&
                                            <Button type="submit" className="social_modal--footer__button_linked" onClick={() => this.shareLinkedInContent()}>
                                                <img src={LinkedinLogo} alt="" className="social_modal--footer__img_linkedin" />
                                                Share
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog> */}
                        <Dialog
                            open={this.state.prospectusModal}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogTitle id="scroll-dialog-title">Prospectus</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Would you like to read the prospectus or email yourself a copy?
                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <Button className="btns-all" onClick={() => this.readProspectus( this.state.offeringsdetailsList.prospectus_url)} color="primary">
                                        Read
                                    </Button>
                                    <Button className="btns-all" onClick={() => this.emailProspectus( this.state.offeringsdetailsList.ext_id)} color="primary">
                                        Email
                                    </Button>
                                    <Button className="btns-all-cancel" onClick={() => this.onCloseModal()} color="primary">
                                        Cancel
                                    </Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.brochureModal}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogTitle id="scroll-dialog-title">Brochure</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Would you like to display the brochure or email yourself a copy?
                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <Button className="btns-all" onClick={() => this.readBrochure( this.state.offeringsdetailsList.brochure_url)} color="primary">
                                        Read
                                    </Button>
                                    <Button className="btns-all" onClick={() => this.emailBrochure( this.state.offeringsdetailsList.ext_id)} color="primary">
                                        Email
                                    </Button>
                                    <Button className="btns-all-cancel" onClick={() => this.onCloseModal()} color="primary">
                                        Cancel
                                    </Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.faqModal}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogTitle id="scroll-dialog-title">Faq</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Would you like to display the faq or email yourself a copy?
                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <Button className="btns-all" onClick={() => this.readFaq( this.state.offeringsdetailsList.prospectus_url)} color="primary">
                                        Read
                                    </Button>
                                    <Button className="btns-all" onClick={() => this.emailFaq( this.state.offeringsdetailsList.ext_id)} color="primary">
                                        Email
                                    </Button>
                                    <Button className="btns-all-cancel" onClick={() => this.cancelFaq()} color="primary">
                                        Cancel
                                    </Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={open}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                <h5> Please Link Your account for share offerings in social media</h5>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.clcikOk()}>
                                        Ok
                                    </Button>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.clcikCancel()}>
                                        Cancel
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        
                        <Dialog
                            onClose={this.closeOnBoarding}
                            aria-labelledby="customized-dialog-title"
                            open={this.state.openOnBoardingModal}
                            maxWidth={"md"}
                            fullWidth={true}
                            scroll={'paper'}
                            >
                            <DialogContent>
                                <div className="onboarding-modal-style " style={{ background: '#fff', paddingTop: '1.5rem', paddingBottom: '1.5rem' }}>
                                    <div className="onboarding-modal-box">
                                        <Slider ref={c => (this.slider = c)} {...settings}>
                                            <div>
                                                <div className="slider-box">
                                                    <div className="slider-title">Research, follow, share and invest in IPO and follow-on offerings</div>
                                                    <div className="slider-caption">Click Capital Markets provides individual investors access to public offering information and the ability to invest in companies going public. You'll be able to purchase shares before they begin trading on the stock exchange!</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="slider-box">
                                                    <div className="slider-title">Offering Availability</div>
                                                    <div className="slider-caption-vailability">Not all offerings will be available to order. Offerings that Click Capital Markets is participating in will be made available to purchase once the following events have occurred...</div>
                                                    <div className="slider-caption-vailabilityone">1) An anticipated date is set</div>
                                                    <div className="slider-caption-vailability">2) A price range is set</div>
                                                    <div className="slider-caption-vailability">Click <span style={{ color: "#8DC73F", cursor: 'pointer'}}>Interested?</span> on an offering to get notified when an offering becomes available to order. You will also be notified the price and anticipated date are updated.</div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className="text_center">
                                    {
                                        this.state.slideIndex === 1 ? 
                                        <Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => this.closeOnBoarding()}>Continue</Button>
                                        :
                                        <Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => this.next()}>Continue</Button>
                                    }
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
            </Dashboard>
        )
    }
}

OfferingDetailsComponent.propTypes = {
    offeringdetailsRes: PropTypes.any,
    activeordersRes: PropTypes.any,
    EditprofileRes: PropTypes.any,
    saveOfferingsRes: PropTypes.any,
    prospectusRes: PropTypes.any,
	userActivityRes: PropTypes.any,
    BrokerdelarRes: PropTypes.any,
    prospectusFirstViewRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    offeringdetailsRes: doOfferingDetailsRes,
    activeordersRes: doActiveOrderRes,
    EditprofileRes: doEditprofileRes,
    saveOfferingsRes: dosaveOfferingRes,
    prospectusRes: doProspectusRes,
    socialMediaPostRes: socialMediaPostRes,
	userActivityRes: doUserActivityRes,
    BrokerdelarRes: doBrokerdelarRes,
    prospectusFirstViewRes: doProspectusFirstViewRes
});

function mapDispatchToProps(dispatch) {
    return {
        getOfferingsDetails: (ext_id) => dispatch(get_offering_details(ext_id)),
        getActiveOrder: (id) => dispatch(get_active_order(id)),
        getProfile: () => dispatch(submit_profile()),
        saveOfferings: (data) => dispatch(save_offering(data)),
        doOfferingDetailsRes: (data) => dispatch(doOfferingDetailsRes(data)),
        doActiveOrderRes: (data) => dispatch(doActiveOrderRes(data)),
        getprospectus: (data) => dispatch(get_prospectus(data)),
        socialMediaPost: (data) => dispatch(social_media_post(data)),
		userActivity: (data) => dispatch(user_activity(data)),
        getBrokerdealer: (data) => dispatch(broker_dealer(data)),
        sendProspectusFirstView: (data) => dispatch(sendProspectusFirstView(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingDetailsComponent);
