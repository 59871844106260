
import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { sha3_512 } from 'js-sha3';
import Swal from 'sweetalert2';

class signupApi {
    static doSignup(data) {

        let sendBody={
            first_name:data.first_name,
            last_name:data.last_name,
            email:data.email,
            encrypted_password:sha3_512(data.encrypted_password),
            client_ip:data.client_ip,
            source: 'web'
        }
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            });
            let body = {
                method: 'post',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(sendBody)
            }
            return fetch(BASE_URL + '/users/signup', body).then(response => {
                if(response.ok === true){
                    Swal.fire({
                        title: 'Sign Up Successful',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        timer: 2000,
                        heightAuto: false
                    })
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }

    static checkUserEmail(data) {
        let isError = false;

        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            });
            let body = {
                method: 'post',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(data)
            }
            return fetch(BASE_URL + '/users/signup', body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
        
    }
}

export default signupApi;