import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './signup.scss';
import publicIp from 'public-ip';
import PropTypes from 'prop-types';
import SignupForm from './signupForm/signupForm';
import { submit_signup, doSignupRes } from '../../actions/signupActions';
import { ToastContainer } from 'react-toastify';
import { loadScript } from '../../helper/helper';
import { BASE_URL } from '../../constants';
import CompanyLogo from '../../assets/images/clickipo-logo-tagline.png';
import IsoMockups from '../../assets/images/ipo-platform-mockups.png';
import IosAppImg from '../../assets/images/download_clickipo_ios.png';
import AndroidAppImg from '../../assets/images/download_clickipo_android.png';
import { Link } from 'react-router-dom';

class SignupComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            client_ip: null,
            userEmail: '',
            token:'',
            caseFirstName: '',
            caseLastName: '',
            isDspSignUp: false,
            dspOfferingLogo:'',
            dspOfferingName:'',
            dspOfferingDescription:'',
            offeringData:{}
        }
    }

    static getDerivedStateFromProps(props, state) {
		if (props.signupRes) {
            if (props.signupRes.user &&  props.signupRes.user.user) {
                if (props.signupRes.user.user.error === 1) {
                    props.history.push('/send-verify-email', {state: state.userEmail});
                }
                else if (props.signupRes.user.user.error === 0) { 
                    props.history.push(`/login?token=${state.token}`);
                }
            }
        }
		return null
	}
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');

        if (token!==null) {
            this.setState({
                isDspSignUp: true,
                token: this.state.token
            })
            fetch(BASE_URL + `/users/dsp/token_verify?token=${token}`)
            .then(response=> response.json())
            .then((data)=>{
                this.setState({
                    userEmail: data.data.email,
                    caseFirstName: data.data.first_name,
                    caseLastName: data.data.last_name,
                    offeringData: data.data.offering,
                    dspOfferingLogo: data.data.offering.logo_small,
                    dspOfferingName: data.data.offering.name,
                    dspOfferingDescription: data.data.offering.description,
                })
                
            }).catch(()=> this.props.history.push(`/invalidToken`))
            
        }
        loadScript();
        publicIp.v4().then(ip => {
            this.setState({
                client_ip: ip
            })
        });
    }

    handleSubmit(data) {
        let sendRequest = data;
        sendRequest.email = (data.email).toLowerCase()
        sendRequest.client_ip = this.state.client_ip;
        this.props.handleFormSubmit(sendRequest);
        this.setState({
            userEmail: data.email,
        });
    };

    render() {
        return (
            <div className="signup-page">
                <div className="container-fluid signup-content">
                    <div className="row">
                        <div className="col-md-7 col-lg-8">
                            {(!this.state.isDspSignUp) &&<div className="signup-company-logo">
                                <img src={CompanyLogo} alt="" />
                            </div>}
                            {(this.state.isDspSignUp) && <div className="signup-company-logo-dsp">
                                <img src={CompanyLogo} alt="" />
                            </div>}


                            { (!this.state.isDspSignUp) && <div className="signup-platform-mockup">
                                  <img src={IsoMockups} alt="" />
                            </div>}
                            { (this.state.isDspSignUp) && <div className="signup-platform-mockup-dsp">
                                <p className="signup-platform-mockup-dsp-welcome-text">Welcome to the Directed Share Program of {this.state.dspOfferingName} offering</p>
                                <img src={this.state.dspOfferingLogo} alt="" />
                                <p className="signup-platform-mockup-dsp-description">{this.state.dspOfferingDescription}</p>
                            </div>}

                        </div>
                        <div className="col-md-5 col-lg-4">
                            <div className="signup_content_inner_body">
                                <div className="signup_content_inner_body_inner">
                                    <div className="top-border"></div>
                                    <div className="signup_heading">
                                        Sign Up
                                    </div>
                                    <div className="form_content_signup">
                                        {(!this.state.isDspSignUp) && <SignupForm handleFormSubmit={(data) => { this.handleSubmit(data) }} />}
                                        {(this.state.isDspSignUp) && <SignupForm handleFormSubmit={(data) => { this.handleSubmit(data) }} initialValues={{first_name: this.state.caseFirstName, last_name: this.state.caseLastName, email: this.state.userEmail }} />}
                                    </div>
                                </div>
                            </div>
                            <div className="login_content_inner_body_bottom">
                                <div className="not_account_contnet">
                                    Already have an account? <Link to="/">
                                        <span className="signup_link"> Login in</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="signup_content_form_getapp">
                                <div className="getapp_title">
                                    <p>Get the app!</p>
                                </div>
                                <div className="row app-download-box">
                                    <div className="col-6">
                                        <div className="ios-app-image">
                                            <a href="https://itunes.apple.com/app/apple-store/id1236247345?pt=118497532&ct=cipo_desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src={IosAppImg} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="android-app-image">
                                            <a href="https://play.google.com/store/apps/details?id=com.clickipo.clickipo&utm_source=ClickIPO&utm_medium=website&utm_campaign=cipo_desktop" target="_blank" rel="noopener noreferrer"><img src={AndroidAppImg} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}


SignupComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    signupRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    signupRes: doSignupRes
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(submit_signup(data))
    };

}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SignupComponent);
