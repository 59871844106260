import * as types from '../actions/actionTypes'; 
import initialState from './initialState';
import _ from 'lodash';

//all the offering related reducers here
export default function (state = initialState.user, action) { //we will change the state = {} soon
  switch(action.type) {
    case types.DO_OFFERING_INDUSTRIES_RES:
      let offeringindustries = action.data
      if (offeringindustries !== null) {
        offeringindustries.data = _.orderBy(offeringindustries.data, ['name'], ['asc']);
      }
      return offeringindustries
    default:
      return state
  }
}
