
import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class verifyCodeApi {
    static doVerifyCode(data) {
        let sendBody={
            code:data.code
        }
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(sendBody)
            }
            return fetch(BASE_URL + '/v1/password_reset/code/verify', body).then(response => {
                // if(response.ok === true){
                //     window.location.href = '/resetpasswordnotice';
                // }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){

        }
        
    }
}

export default verifyCodeApi;