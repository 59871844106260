import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import '../createBrokerage.scss';
import './createForm.scss';
import { Field, reduxForm, change } from 'redux-form';
import { Calendar } from 'primereact/components/calendar/Calendar';
import { dateTimeFormatUS } from '../../helper/helper';
import { MultiSelect } from 'primereact/components/multiselect/MultiSelect';
import Select from 'react-select';
import validate from './createFormValidation';
import { submitCreateBrokerageForm, doCreateBrokerageAccRes, loadingRes } from '../../actions/createBrokerageAccActions';
import { getProfile, doEditprofileRes } from '../../actions/getProfileActions';
import { getBrokerDealer, doBrokerdelarRes } from '../../actions/brokerDealerAppActions';

import { getItem } from '../../utils/localStore';
import spinnImg from '../../assets/images/loader-example.gif';
import NumberFormat from 'react-number-format';
import CountryStateCity from '../../assets/countriesStatesCities.json';
import countryPhoneCodes from '../../assets/countryPhoneCodes.json';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(),
		alignItems: 'center',
		justifyContent: 'center'
	},
}))(MuiDialogActions);

const fields = ['first_name', 'last_name','business_phone', 'residential_address_street', 'residential_address_city', 'residential_address_state', 'residential_address_zip', 'residential_address_country', 'mail_address_street', 'mail_address_city', 'mail_address_state', 'mail_address_zip', 'mail_address_country', 'mobile_phone', 'primary_email', 'secondary_email', 'marital_status', 'date_of_birth', 'ssn', 'citizenship_country', 'ident_birthCountry', 'status', 'job_title', 'firm_name',  'company_years', 'employer_address_street', 'employer_address_city', 'employer_address_zip', 'employer_address_state', 'employer_address_country', 'number_of_dependents', 'annual_inc_min', 'annual_inc_max', 'tot_networth_min', 'tot_networth_max', 'liq_networth_min', 'liq_networth_max', 'tax_bracket', 'risk_tolerance', 'liquidity_needs', 'time_horizon', 'file', 'exch_finra_affiliation', 'control_person', 'politically_exposed', 'prefix', 'empl_role', 'dividend_reinvest', 'inv_experience', 'inv_objectives', 'stocks', 'options', 'short_sales', 'mutual_funds', 'margin', 'employer'];

const renderField = ({ disabled, input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <TextField  style={{width: '100%'}} {...input} type={type} disabled={disabled} />
        {touched && error && <span>{error}</span>}
    </div>
)
// const annualIncomeOptions = [
//     {
//         'name': 'Under $25,000',
//         'value': 'Under $25,000'
//     },
//     {
//         'name': '$25,000 - $74,999',
//         'value': '$25,000 - $74,999'
//     },
//     {
//         'name': '$75,000 - $199,999',
//         'value': '$75,000 - $199,999'
//     },
//     {
//         'name': '$200,000 - $499,999',
//         'value': '$200,000 - $499,999'
//     },
//     {
//         'name': '$500,000 - $999,999',
//         'value': '$500,000 - $999,999'
//     },
//     {
//         'name': '$1,000,000+',
//         'value': '$1,000,000+'
//     }
// ];
// const netWorthOptions = [
//     {
//         'name': 'Under $50,000',
//         'value': 'Under $50,000'
//     },
//     {
//         'name': '$50,000 - $149,999',
//         'value': '$50,000 - $149,999'
//     },
//     {
//         'name': '$150,000 - $499,999',
//         'value': '$150,000 - $499,999'
//     },
//     {
//         'name': '$500,000 - $999,999',
//         'value': '$500,000 - $999,999'
//     },
//     {
//         'name': '“$1,000,000 - $4,999,999',
//         'value': '“$1,000,000 - $4,999,999'
//     },
//     {
//         'name': 'Over $5,000,000',
//         'value': 'Over $5,000,000'
//     }
// ];
// const liqNetWorthOptions = [
//     {
//         'name': 'Under $50,000',
//         'value': 'Under $50,000'
//     },
//     {
//         'name': '$50,000 - $149,999',
//         'value': '$50,000 - $149,999'
//     },
//     {
//         'name': '$150,000 - $499,999',
//         'value': '$150,000 - $499,999'
//     },
//     {
//         'name': '$500,000 - $999,999',
//         'value': '$500,000 - $999,999'
//     },
//     {
//         'name': '“$1,000,000 - $4,999,999',
//         'value': '“$1,000,000 - $4,999,999'
//     },
//     {
//         'name': 'Over $5,000,000',
//         'value': 'Over $5,000,000'
//     }
// ];
const taxBracketOptions = [
    {
        'name': '0 - 15%',
        'value': '0 - 15%'
    },
    {
        'name': '16 - 25%',
        'value': '16 - 25%'
    },
    {
        'name': '26 - 30%',
        'value': '26 - 30%'
    },
    {
        'name': '31 - 35%',
        'value': '31 - 35%'
    },
    {
        'name': 'Over 35%',
        'value': 'Over 35%'
    }
];
// const fundingSourceOptions = [
//     {
//         'name': 'Earnings',
//         'value': 'earnings',
//     },
//     {
//         'name': 'Pension',
//         'value': 'pension'
//     },
//     {
//         'name': 'Gift',
//         'value': 'gift'
//     },
//     {
//         'name': 'Sale of business or property',
//         'value': 'sale_of_business_or_property'
//     },
//     {
//         'name': 'Insurance',
//         'value': 'insurance'
//     },
//     {
//         'name': 'Inheritance',
//         'value': 'inheritance'
//     },
//     {
//         'name': 'Social security benefits',
//         'value': 'social_security_benefits'
//     },
//     {
//         'name': 'Home quity line of credit',
//         'value': 'home_quity_line_of_credit'
//     },
//     {
//         'name': 'Other',
//         'value': 'other'
//     }
// ];
// const knowledgeOptions = [
//     {
//         'name': 'Limited',
//         'value': 'limited',
//     },
//     {
//         'name': 'Good',
//         'value': 'good'
//     },
//     {
//         'name': 'Excellent',
//         'value': 'excellent'
//     }
// ];
const riskToleranceOptions = [
    {
        'name': 'Low',
        'value': 'low',
    },
    {
        'name': 'Moderate',
        'value': 'moderate'
    },
    {
        'name': 'Aggressive',
        'value': 'aggressive'
    },
    {
        'name': 'Speculative',
        'value': 'speculative'
    }
];
const liquidityNeedsOptions = [
    {
        'name': 'Very Important (Less Than 1 Year)',
        'value': 'Very Important (Less Than 1 Year)',
    },
    {
        'name': 'Important (1 - 5 Years)',
        'value': 'Important (1 - 5 Years)'
    },
    {
        'name': 'Somewhat Important (5 – 15 Years)',
        'value': 'Somewhat Important (5 – 15 Years)'
    },
    {
        'name': 'Does Not Matter (Over 15 Years)',
        'value': 'Does Not Matter (Over 15 Years)'
    }
];
const objectivesOptions = [
    {
        'name': 'Capital appreciation',
        'value': 'capital_appreciation',
    },
    {
        'name': 'Preservation of capital',
        'value': 'preservation_of_capital'
    },
    {
        'name': 'Income',
        'value': 'income'
    },
    {
        'name': 'Speculation',
        'value': 'speculation'
    }
];
const timeHorizonOptions = [
    {
        'name': 'Less than 1 Year',
        'value': 'Less than 1 Year',
    },
    {
        'name': '1 to 5 Years',
        'value': '1 to 5 Years'
    },
    {
        'name': '5 to 10 Years',
        'value': '5 to 10 Years'
    },
    {
        'name': '10 to 15 Years',
        'value': '10 to 15 Years'
    },
    {
        'name': 'Over 15 Years',
        'value': 'Over 15 Years'
    }
];
const experienceOptions = [
    {
        'name': 'None',
        'value': 'None',
    },
    {
        'name': 'Less than 1 Year',
        'value': 'Less than 1 Year'
    },
    {
        'name': '1 to 5 Years',
        'value': '1 to 5 Years'
    },
    {
        'name': '6 to 10 Years',
        'value': '6 to 10 Years'
    },
    {
        'name': 'Over 10 Years',
        'value': 'Over 10 Years'
    }
];
// const identityOptions = [
//     {
//         'name': 'Passport',
//         'value': 'passport'
//     },
//     {
//         'name': 'US State Id',
//         'value': 'us_state_id'
//     },
//     {
//         'name': 'US Driving License',
//         'value': 'us_driving_license'
//     },
//     {
//         'name': 'US Green Card',
//         'value': 'us_green_card'
//     }
// ]
const maritalStatusOptions = [
    {
        'name': 'Single',
        'value': 'single'
    },
    {
        'name': 'Married',
        'value': 'married'
    },
    {
        'name': 'Divorced',
        'value': 'divorced'
    }
]
const statusOptions = [
    {
        'name': 'Employed',
        'value': 'employed'
    },
    {
        'name': 'Self Employed',
        'value': 'self_employed'
    },
    {
        'name': 'Retired',
        'value': 'retired'
    },
    {
        'name': 'Not Employed',
        'value': 'not_employed'
    },
    {
        'name': 'Student',
        'value': 'student'
    }
]
let stateList = [];
let cityList = [];
let stateEmployerList = [];
let cityEmployerList = [];
let authTokenn = '';
const TOKEN = getItem('auth_token');
class CreateFormComponent extends React.PureComponent {

    constructor() {
        super();
        const today = new Date();
        const month = today.getMonth();
        const year = today.getFullYear();
        const prevMonth = (month === 0) ? 11 : month - 1;
        const prevYear = (prevMonth === 11) ? year - 1 : year;

        const minDate = new Date();
        minDate.setMonth(prevMonth + 1);
        minDate.setFullYear(prevYear);

        this.state = {
            isLoading: false,
            profileData: '',
            brokerdealerdetails: '',
            isSubmited: false,
            first_name: '',
            last_name: '',
            residential_address_street: '',
            residential_address_city: '',
            residential_address_state: '',
            residential_address_zip: '',
            residential_address_country: '',
            mail_address_street: '',
            mail_address_city: '',
            mail_address_state: '',
            mail_address_zip: '',
            mail_address_country: '',
            business_phone: '',
            mobile_phone: '',
            primary_email: '',
            secondary_email: '',
            firm_name: '',
            marital_status: '',
            date_of_birth: '',
            date_of_birth_show: '',
            ssn: '',
            citizenship_country: '',
            ident_birthCountry: '',
            status: '',
            employer: '',
            empl_role: '',
            company_years: '',
            employer_address_street: '',
            employer_address_city: '',
            employer_address_state: '',
            employer_address_zip: '',
            employer_address_country: '',
            number_of_dependents: '',
            annual_inc_min: '',
            annual_inc_max: '',
            tot_networth_min: '',
            tot_networth_max: '',
            liq_networth_min: '',
            liq_networth_max: '',
            tax_bracket: '',
            dividend_reinvest: '',
            risk_tolerance: '',
            liquidity_needs: '',
            inv_objectives: '',
            inv_experience: '',
            time_horizon: '',
            stocks: '',
            options: '',
            short_sales: '',
            mutual_funds: '',
            margin: '',
            type: 'identity',
            file: '',
            prefix: '',
            exch_finra_affiliation: false,
            control_person: false,
            politically_exposed: false,
            fileName: '',
            selectedObjectives: [],
            selectedObjectivesArr: [],
            selectedResidentialCountry: [],
            selectedResidentialState: [],
            selectedResidentialCity: [],
            selectedMailCountry: [],
            selectedMailState: [],
            selectedMailCity: [],
            selectedEmployerCountry: [],
            selectedEmployerState: [],
            selectedEmployerCity: [],
            selectedCitizenshipCountry: [],
            selectedBirthCountry: [],
            showConnectionErrorMessageModal: false,
            errorMessages: [],
            isMailAddressSame: false
        }
    }
    componentDidMount() {

        const query = new URLSearchParams(this.props.location.search);
        authTokenn = query.get('token');
        if (authTokenn !== null) {
            this.setState({
                authToken: authTokenn,
            }, () => {
            })
            this.props.changeFieldValue('type', 'identity');
            if (CountryStateCity) {
                for (let country of CountryStateCity) {
                    if (country.name === "United States") {
                        let event = {
                            label: country.name,
                            value: country.iso2,
                            states: country.states 
                        }
                        this.handleChangeCountry(event);
                        this.handleChangeCitizenshipCountry(event);
                        this.handleChangeCountryEmployer(event);
                    }
                }
            }
        }
        if (authTokenn === null) {
            if (CountryStateCity) {
                for (let country of CountryStateCity) {
                    if (country.name === "United States") {
                        let event = {
                            label: country.name,
                            value: country.iso2,
                            states: country.states 
                        }
                        this.handleChangeCountry(event);
                        this.handleChangeCitizenshipCountry(event);
                        this.handleChangeCountryEmployer(event);
                    }
                }
            }
            this.props.changeFieldValue('type', 'identity');
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.createBrokerageAccRes) {
            if (props.createBrokerageAccRes.data && props.createBrokerageAccRes.data.createBrokerageAcc) {
                if(props.createBrokerageAccRes.data.createBrokerageAcc.error === 0) {
                    return {
                        showAccountForm: true,
                        isLoading: false
                    };
                }
                if(props.createBrokerageAccRes.data.createBrokerageAcc.error !== 0) {
                    return {
                        isLoading: false
                    };
                }
            }
        }
        if (props.loadingRes) {
            if (props.loadingRes.data && props.loadingRes.data.createBrokerLoading) {
                if(props.loadingRes.data.createBrokerLoading.status === false) {
                    if (props.loadingRes.data.createBrokerLoading.message) {
                        return {
                            showConnectionErrorMessageModal: true,
                            errorMessages: props.loadingRes.data.createBrokerLoading.message
                        };
                    } else {
                        return {
                            isLoading: false
                        }
                    }
                }
            }
        }
        return null;
    }
    onCloseErrorModal =() => {
        this.setState({
            showConnectionErrorMessageModal: false,
            errorMessages: []
        });
    }
    resetForm() {
        
        this.props.reset();
    }
    handleBack = () => {
        this.props.history.goBack()
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleChangeCheckBox = (e) => {
        if (e.target.checked === true) {
            this.setState({
                isMailAddressSame: e.target.checked,
                mail_address_street: this.state.residential_address_street,
                mail_address_city: this.state.residential_address_city,
                mail_address_state: this.state.residential_address_state,
                mail_address_zip: this.state.residential_address_zip,
                mail_address_country: this.state.residential_address_country,
                selectedMailCountry: this.state.selectedResidentialCountry,
                selectedMailState: this.state.selectedResidentialState,
                selectedMailCity: this.state.selectedResidentialCity,
            },() => {
                this.props.changeFieldValue('mail_address_street', this.state.residential_address_street);
            })
        } else {
            this.setState({
                isMailAddressSame: e.target.checked,
                mail_address_street: '',
                mail_address_city: '',
                mail_address_state: '',
                mail_address_zip: '',
                mail_address_country: '',
                selectedMailCountry: '',
                selectedMailState: '',
                selectedMailCity: '',
            },() => {
                this.props.changeFieldValue('mail_address_street', '');
            })
        }
        
    }
    handleChangeIsSameAddress = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        },() => {})
    }
    selectDOB(e) {
        if (e.value) {
            this.setState({
                date_of_birth: dateTimeFormatUS(e.value),
                date_of_birth_show: e.value,
            }, () => {
            });
        } else {
            this.setState({
                date_of_birth: '',
            }, () => { });
        }
    }
    onObjectivesToggle = (event) => {
        this.setState({ selectedObjectives: event.value });
    }
    submitCreateBroker = () => {
        this.setState({
            isSubmited: true,
        }, () => { });
        validate(this.state);
        const errors = validate(this.state);
        if (Object.keys(errors).length === 0) {
            this.setState({
                selectedObjectivesArr: this.state.selectedObjectives,
            }, () => {
                this.handleFormSubmit();
            })
        } else {
            fields.find((field) => {
                if (errors[field]) {
                    const elem = document.querySelector(`input[name=${field}]`);
                    if (elem) {
                        elem.focus();
                        return true;
                    }
                }
                return false;
            });
        }
    }
    handleFormSubmit = () => {
        // let request = {
        //     "dependents": Number(this.state.number_of_dependents),
        //     "business_phone": this.state.business_phone,
        //     "addr1": this.state.residential_address_street,
        //     "city": this.state.residential_address_city,
        //     "country": this.state.residential_address_country,
        //     "state": this.state.residential_address_state,
        //     "postal": this.state.residential_address_zip,
        //     "mail_addr1": this.state.mail_address_street,
        //     "mail_city": this.state.mail_address_city,
        //     "mail_country": this.state.mail_address_country,
        //     "mail_state": this.state.mail_address_state,
        //     "mail_postal": this.state.mail_address_zip,
        //     "mobile_number": this.state.mobile_phone,
        //     "primary_email": this.state.primary_email,
        //     "secondary_email": this.state.secondary_email,
        //     "firm_name": this.state.firm_name,
        //     "exch_finra_affiliation": this.state.exch_finra_affiliation,
        //     "control_person": this.state.control_person,
        //     "politically_exposed": this.state.politically_exposed,
        //     "biz_addr1": this.state.employer_address_street,
        //     "biz_city": this.state.employer_address_city,
        //     "biz_country": this.state.employer_address_country,
        //     "biz_state": this.state.employer_address_state,
        //     "biz_postal": this.state.employer_address_zip,
        //     "employer": this.state.employer,
        //     "employment_status": this.state.status,
        //     "empl_role": this.state.empl_role,
        //     "years_employeed": this.state.company_years,
        //     "ident_birthCountry": this.state.ident_birthCountry,
        //     "citizen": this.state.citizenship_country,
        //     "dov": this.state.date_of_birth,
        //     "first_name": this.state.first_name,
        //     "last_name": this.state.first_name,
        //     "prefix": this.state.prefix,
        //     "ssn": this.state.ssn,
        //     "marital_status": this.state.marital_status,
        //     "annual_inc_min": Number(this.state.annual_inc_min),
        //     "annual_inc_max": Number(this.state.annual_inc_max),
        //     "fed_tax_bracket": this.state.tax_bracket,
        //     "inv_experience": this.state.inv_experience,
        //     "inv_objectives": this.state.selectedObjectivesArr,
        //     "liq_networth_min": Number(this.state.liq_networth_min),
        //     "liq_networth_max": Number(this.state.liq_networth_max),
        //     "risk_tolerance": this.state.risk_tolerance,
        //     "tot_networth_min": Number(this.state.tot_networth_min),
        //     "tot_networth_max": Number(this.state.tot_networth_max),
        //     "dividend_reinvest": this.state.dividend_reinvest,
        //     "liq_needs": this.state.liquidity_needs,
        //     "time_horizon": this.state.time_horizon,
        //     "photo_id_file_pdf": this.state.file,
        // }
    }
    handleImageChange = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            var b64 = reader.result.replace(/^data:.+;base64,/, "");
            this.setState({
                fileName: file.name,
                file: b64
            });
        };
    }
    handleChangeCountry = (event) => {
        this.setState({
            residential_address_country: event.value,
            selectedResidentialCountry: event
        }, () => {
            if (event.states) {
              for (let item of event.states) {
                stateList.push({
                    label: item.name,
                    value: item.state_code,
                    cities: item.cities
                })
              }  
            }
        })
    }
    handleChangeState = (event) => {
        this.setState({
            residential_address_state: event.value,
            selectedResidentialState: event,
        }, () => {
            if (event.cities) {
                for (let item of event.cities) {
                  cityList.push({
                      label: item.name,
                      value: item.name
                  })
                }  
              }
        })
    }
    handleChangeCity = (event) => {
        this.setState({
            residential_address_city: event.label,
            selectedResidentialCity: event,
        }, () => {
        })
    }
    handleChangeMailCountry = (event) => {
        this.setState({
            mail_address_country: event.value,
            selectedMailCountry: event
        }, () => {
            if (event.states) {
              for (let item of event.states) {
                stateList.push({
                    label: item.name,
                    value: item.state_code,
                    cities: item.cities
                })
              }  
            }
        })
    }
    handleChangeMailState = (event) => {
        this.setState({
            mail_address_state: event.value,
            selectedMailState: event,
        }, () => {
            if (event.cities) {
                for (let item of event.cities) {
                  cityList.push({
                      label: item.name,
                      value: item.name
                  })
                }  
              }
        })
    }
    handleChangeMailCity = (event) => {
        this.setState({
            mail_address_city: event.label,
            selectedMailCity: event,
        }, () => {
        })
    }
    handleChangeCountryEmployer = (event) => {
        this.setState({
            employer_address_country: event.value,
            selectedEmployerCountry: event
        }, () => {
            if (event.states) {
              for (let item of event.states) {
                stateEmployerList.push({
                    label: item.name,
                    value: item.state_code,
                    cities: item.cities
                })
              }  
            }
        })
    }
    handleChangeStateEmployer = (event) => {
        this.setState({
            employer_address_state: event.value,
            selectedEmployerState: event,
        }, () => {
            if (event.cities) {
                for (let item of event.cities) {
                  cityEmployerList.push({
                      label: item.name,
                      value: item.name
                  })
                }  
              }
        })
    }
    handleChangeCityEmployer = (event) => {
        this.setState({
            employer_address_city: event.label,
            selectedEmployerCity: event,
        }, () => {
        })
    }
    handleChangeBirthCountry = (event) => {
        this.setState({
            ident_birthCountry: event.value,
            selectedBirthCountry: event,
        }, () => {
        })
    }
    handleChangeCitizenshipCountry = (event) => {
        this.setState({
            citizenship_country: event.value,
            selectedCitizenshipCountry: event,
        }, () => {
        })
    }
    handleChangeEmpStatus = (event) => {
        if (event.target.value === "employed" || event.target.value === "self_employed") {
            this.setState({
                [event.target.name] : event.target.value
            }, () => {
                if (CountryStateCity) {
                    for (let country of CountryStateCity) {
                        if (country.name === "United States") {
                            let event = {
                                label: country.name,
                                value: country.iso2,
                                states: country.states 
                            }
                            this.handleChangeCountryEmployer(event);
                        }
                    }
                }
            })
        } else {
            this.setState({
                [event.target.name] : event.target.value,
                employer_address_country: '',
                selectedEmployerCountry: [],
                employer_address_state: '',
                selectedEmployerState: [],
                employer_address_city: '',
                selectedEmployerCity: []
            }, () => {

            })
        }
    }
    
    render() {
        const errors = validate(this.state);
        let countryList = [];
        let countryEmployerList = [];
        let citizenshipCountry = [];
        if (CountryStateCity) {
            for (let country of CountryStateCity) {
                countryList.push({
                    label: country.name,
                    value: country.iso2,
                    states: country.states 
                });
                citizenshipCountry.push({
                    label: country.name,
                    value: country.iso2,
                    states: country.states 
                });
                countryEmployerList.push({
                    label: country.name,
                    value: country.iso2,
                    states: country.states 
                })
            }
        }
        let countryCodes = [];
        if (countryPhoneCodes) {
            for (let codes of countryPhoneCodes) {
                countryCodes.push({
                    label: codes.dial_code,
                    value: codes.dial_code,
                })
            }
        }
        let date =  new Date().getFullYear();
        let yearRng = date-110 +":"+ date;
        
        return (
            <div className="create_broker_box_inner_content_form">
                <div className="create_brokerage_body">
                    {
                        authTokenn !== null && TOKEN === null &&
                        <div style={{textAlign: 'center', padding: 20, fontSize: 20}}>Create Brokerage Account</div>
                    }
                    {/* <form onClick={() => this.submitCreateBroker()}> */}
                    {
                        <div>
                            <div className="row ml-mr-0px-imp">
                                <div className="col col-12 col-sm-12 col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <Field
                                                    name="first_name"
                                                    component={renderField}
                                                    label="First Name"
                                                    autoComplete="off"
                                                    type="text"
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.first_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <Field
                                                    name="last_name"
                                                    component={renderField}
                                                    label="Last Name"
                                                    autoComplete="off"
                                                    type="text"
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.last_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4 calendar">
                                                <label>Date of birth</label>
                                                <Calendar name="date_of_birth" value={this.state.date_of_birth_show} onChange={(e) => this.selectDOB(e)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" monthNavigator yearNavigator yearRange={yearRng}/>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.date_of_birth}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <label>SSN</label>
                                                <div>
                                                    <NumberFormat value={this.state.ssn} format="###-##-####" onValueChange={(values) => {
                                                        const {formattedValue} = values;
                                                        this.setState({ssn: formattedValue})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.ssn}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <div className="row ml-mr-0px-imp mt-3 pb-3" style={{border: '1px solid #ddd'}}>
                                    <div className="col col-12 col-sm-12 col-md-12" >
                                        <div className="row">                                        
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <Field
                                                        name="residential_address_street"
                                                        component={renderField}
                                                        label="Residential Street"
                                                        autoComplete="off"
                                                        type="text"
                                                        onChange={(e) => this.handleChange(e)}
                                                        disabled={false}
                                                    />
                                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.residential_address_street}</span>}
                                                </div>
                                            </div>
                
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Residential Country</label>
                                                    <Select
                                                        name="residential_address_country"
                                                        value={this.state.selectedResidentialCountry}
                                                        onChange={this.handleChangeCountry}
                                                        options={countryList}
                                                        placeholder= "Select country"
                                                        isSearchable= {true}
                                                    />
                                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.residential_address_country}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Residential State</label>
                                                    <Select
                                                        name="residential_address_state"
                                                        value={this.state.selectedResidentialState}
                                                        onChange={this.handleChangeState}
                                                        options={stateList}
                                                        placeholder= "Select state"
                                                        isSearchable= {true}
                                                    />
                                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.residential_address_state}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Residential City</label>
                                                    <Select
                                                        name="residential_address_city"
                                                        value={this.state.selectedResidentialCity}
                                                        onChange={this.handleChangeCity}
                                                        options={cityList}
                                                        placeholder= "Select city"
                                                        isSearchable= {true}
                                                    />
                                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.residential_address_city}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Residential Postal Code</label>
                                                    <div>
                                                        <NumberFormat value={this.state.residential_address_zip} thousandSeparator={false} onValueChange={(values) => {
                                                            const {value} = values;
                                                            this.setState({residential_address_zip: value})
                                                        }} className="form-control" />
                                                    </div>
                                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.residential_address_zip}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ml-mr-0px-imp" >
                                    <div className="col col-12 col-sm-12 col-md-12">
                                        <div className="mt-4">
                                            <label className="checkbox-choice">Is Mailing address same as your personal address
                                                <input type="checkbox" name="exch_finra_affiliation" onChange={(e) => this.handleChangeCheckBox(e)} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ml-mr-0px-imp mt-3 pb-3" style={{border: '1px solid #ddd'}}>
                                    <div className="col col-12 col-sm-12 col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <Field
                                                        name="mail_address_street"
                                                        component={renderField}
                                                        label="Mailing Street"
                                                        autoComplete="off"
                                                        type="text"
                                                        onChange={(e) => this.handleChange(e)}
                                                        disabled={false}
                                                    />
                                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.mail_address_street}</span>}
                                                </div>
                                            </div>
                
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Mailing Country</label>
                                                    <Select
                                                        name="mail_address_country"
                                                        value={this.state.selectedMailCountry}
                                                        onChange={this.handleChangeMailCountry}
                                                        options={countryList}
                                                        placeholder= "Select country"
                                                        isSearchable= {true}
                                                    />
                                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.mail_address_country}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Mailing State</label>
                                                    <Select
                                                        name="mail_address_state"
                                                        value={this.state.selectedMailState}
                                                        onChange={this.handleChangeMailState}
                                                        options={stateList}
                                                        placeholder= "Select state"
                                                        isSearchable= {true}
                                                    />
                                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.mail_address_state}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Mailing City</label>
                                                    <Select
                                                        name="mail_address_city"
                                                        value={this.state.selectedMailCity}
                                                        onChange={this.handleChangeMailCity}
                                                        options={cityList}
                                                        placeholder= "Select city"
                                                        isSearchable= {true}
                                                    />
                                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.mail_address_city}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Mailing Postal Code</label>
                                                    <div>
                                                        <NumberFormat value={this.state.mail_address_zip} thousandSeparator={false} onValueChange={(values) => {
                                                            const {value} = values;
                                                            this.setState({mail_address_zip: value})
                                                        }} className="form-control" />
                                                    </div>
                                                    {errors && this.state.isSubmited && <span className="errorvalidation">{errors.mail_address_zip}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row ml-mr-0px-imp">
                                <div className="col col-12 col-sm-12 col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <label>Mobile No.</label>
                                                <div>
                                                    <NumberFormat value={this.state.mobile_phone} thousandSeparator={false} decimalScale={0} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({mobile_phone: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.mobile_phone}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <label>Business Phone No.</label>
                                                <div>
                                                    <NumberFormat value={this.state.business_phone} thousandSeparator={false} decimalScale={0} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({business_phone: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.business_phone}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4" style={{position: 'relative'}}> 
                                                <div style={{width: '100%'}}>
                                                    <Field
                                                        name="primary_email"
                                                        component={renderField}
                                                        label="Primary Email"
                                                        autoComplete="off"
                                                        type="email"
                                                        onChange={(e) => this.handleChange(e)}
                                                        disabled={false}
                                                    />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.primary_email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4" style={{position: 'relative'}}> 
                                                <div style={{width: '100%'}}>
                                                    <Field
                                                        name="secondary_email"
                                                        component={renderField}
                                                        label="Secondary Email"
                                                        autoComplete="off"
                                                        type="email"
                                                        onChange={(e) => this.handleChange(e)}
                                                        disabled={false}
                                                    />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.secondary_email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Marital Status</label>
                                                <Field
                                                    style={{ height: 42, width: '100%' }}
                                                    name="marital_status"
                                                    className="form-control"
                                                    component="select"
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    <option value="">Select...</option>
                                                    {
                                                        maritalStatusOptions && maritalStatusOptions.length > 0 &&  maritalStatusOptions.map((item, i) => 
                                                            <option key={i} value={item.value}>{item.name}</option>
                                                        )
                                                    }
                                                </Field>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.marital_status}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <label>Birth country</label>
                                                <Select
                                                    name="ident_birthCountry"
                                                    value={this.state.selectedBirthCountry}
                                                    onChange={this.handleChangeBirthCountry}
                                                    options={citizenshipCountry}
                                                    placeholder= "Select birth country"
                                                    isSearchable= {true}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.ident_birthCountry}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <label>Citizenship country</label>
                                                <Select
                                                    name="citizenship_country"
                                                    value={this.state.selectedCitizenshipCountry}
                                                    onChange={this.handleChangeCitizenshipCountry}
                                                    options={citizenshipCountry}
                                                    placeholder= "Select citizenship country"
                                                    isSearchable= {true}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.citizenship_country}</span>}
                                            </div>
                                        </div>
                                        
                                        {/* <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Identity proof</label>
                                                <Field
                                                    style={{ height: 42, width: '100%' }}
                                                    name="id_type"
                                                    component="select"
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    <option value="">Select...</option>
                                                    {
                                                        identityOptions && identityOptions.length > 0 &&  identityOptions.map((item, i) => 
                                                            <option key={i} value={item.value}>{item.name}</option>
                                                        )
                                                    }
                                                </Field>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.id_type}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <label>ID number</label>
                                                <div>
                                                    <NumberFormat value={this.state.id_number} decimalScale={0} allowNegative={false} thousandSeparator={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({id_number: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.id_number}</span>}
                                            </div>
                                        </div> */}
                                    
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Status</label>
                                                <Field
                                                    style={{ height: 42, width: '100%' }}
                                                    name="status"
                                                    component="select"
                                                    onChange={(e) => this.handleChangeEmpStatus(e)}
                                                >
                                                    <option value="">Select...</option>
                                                    {
                                                        statusOptions && statusOptions.length > 0 &&  statusOptions.map((item, i) => 
                                                            <option key={i} value={item.value}>{item.name}</option>
                                                        )
                                                    }
                                                </Field>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.status}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <Field
                                                    name="employer"
                                                    component={renderField}
                                                    label="Employer"
                                                    autoComplete="off"
                                                    type="text"
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.employer}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <Field
                                                    name="firm_name"
                                                    component={renderField}
                                                    label="Company"
                                                    autoComplete="off"
                                                    type="text"
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.firm_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <Field
                                                    name="empl_role"
                                                    component={renderField}
                                                    label="Emp role"
                                                    autoComplete="off"
                                                    type="text"
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.empl_role}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>No. of yrs</label>
                                                <div>
                                                    <NumberFormat value={this.state.company_years} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({company_years: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.company_years}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <Field
                                                    name="employer_address_street"
                                                    component={renderField}
                                                    label="Emp. Street"
                                                    autoComplete="off"
                                                    type="text"
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.employer_address_street}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <label>Emp. Country</label>
                                                <Select
                                                    name="employer_address_country"
                                                    value={this.state.selectedEmployerCountry}
                                                    onChange={this.handleChangeCountryEmployer}
                                                    options={countryEmployerList}
                                                    placeholder= "Select country"
                                                    isSearchable= {true}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.employer_address_country}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <label>Emp. State</label>
                                                <Select
                                                    name="employer_address_state"
                                                    value={this.state.selectedEmployerState}
                                                    onChange={this.handleChangeStateEmployer}
                                                    options={stateEmployerList}
                                                    placeholder= "Select state"
                                                    isSearchable= {true}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.employer_address_state}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <label>Emp. City</label>
                                                <Select
                                                    name="employer_address_city"
                                                    value={this.state.selectedEmployerCity}
                                                    onChange={this.handleChangeCityEmployer}
                                                    options={cityEmployerList}
                                                    placeholder= "Select city"
                                                    isSearchable= {true}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.employer_address_city}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Emp. Postal Code</label>
                                                <div>
                                                    <NumberFormat value={this.state.employer_address_zip} thousandSeparator={false} decimalScale={0} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({employer_address_zip: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.employer_address_zip}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>No. of Dependents</label>
                                                <div>
                                                    <NumberFormat value={this.state.number_of_dependents} thousandSeparator={false} decimalScale={0} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({number_of_dependents: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.number_of_dependents}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Minimum Annual Inc</label>
                                                <div>
                                                    <NumberFormat value={this.state.annual_inc_min} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({annual_inc_min: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.annual_inc_min}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Maximum Annual Inc</label>
                                                <div>
                                                    <NumberFormat value={this.state.annual_inc_max} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({annual_inc_max: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.annual_inc_max}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Total Minimum Net Worth</label>
                                                <div>
                                                    <NumberFormat value={this.state.tot_networth_min} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({tot_networth_min: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.tot_networth_min}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Total Maximum Net Worth</label>
                                                <div>
                                                    <NumberFormat value={this.state.tot_networth_max} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({tot_networth_max: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.tot_networth_max}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Minimum Liquid Net Worth</label>
                                                <div>
                                                    <NumberFormat value={this.state.liq_networth_min} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({liq_networth_min: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.liq_networth_min}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Maximum Liquid Net Worth</label>
                                                <div>
                                                    <NumberFormat value={this.state.liq_networth_max} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({liq_networth_max: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.liq_networth_max}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Tax Bracket</label>
                                                <Field
                                                    style={{ height: 42, width: '100%' }}
                                                    name="tax_bracket"
                                                    component="select"
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    <option value="">Select...</option>
                                                    {
                                                        taxBracketOptions && taxBracketOptions.length > 0 &&  taxBracketOptions.map((item, i) => 
                                                            <option key={i} value={item.value}>{item.name}</option>
                                                        )
                                                    }
                                                </Field>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.tax_bracket}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Risk Tolerance</label>
                                                <Field
                                                    style={{ height: 42, width: '100%' }}
                                                    name="risk_tolerance"
                                                    component="select"
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    <option value="">Select...</option>
                                                    {
                                                        riskToleranceOptions && riskToleranceOptions.length > 0 &&  riskToleranceOptions.map((item, i) => 
                                                            <option key={i} value={item.value}>{item.name}</option>
                                                        )
                                                    }
                                                </Field>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.risk_tolerance}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Liquidity Needs</label>
                                                <Field
                                                    style={{ height: 42, width: '100%' }}
                                                    name="liquidity_needs"
                                                    component="select"
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    <option value="">Select...</option>
                                                    {
                                                        liquidityNeedsOptions && liquidityNeedsOptions.length > 0 &&  liquidityNeedsOptions.map((item, i) => 
                                                            <option key={i} value={item.value}>{item.name}</option>
                                                        )
                                                    }
                                                </Field>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.liquidity_needs}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Objectives</label>
                                                <MultiSelect optionLabel="name" filter value={this.state.selectedObjectives} options={objectivesOptions} onChange={this.onObjectivesToggle} style={{ width: '100%', border: '1px solid #ced4da', height: '46px' }} />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.selectedObjectives}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Time Horizon</label>
                                                <Field
                                                    style={{ height: 42, width: '100%' }}
                                                    name="time_horizon"
                                                    component="select"
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    <option value="">Select...</option>
                                                    {
                                                        timeHorizonOptions && timeHorizonOptions.length > 0 &&  timeHorizonOptions.map((item, i) => 
                                                            <option key={i} value={item.value}>{item.name}</option>
                                                        )
                                                    }
                                                </Field>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.time_horizon}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Investment Experience</label>
                                                <div>
                                                    <NumberFormat value={this.state.inv_experience} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                        const {value} = values;
                                                        this.setState({inv_experience: value})
                                                    }} className="form-control" />
                                                </div>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.inv_experience}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4"> 
                                                <Field
                                                    name="prefix"
                                                    component={renderField}
                                                    label="Prefix"
                                                    autoComplete="off"
                                                    type="text"
                                                    onChange={(e) => this.handleChange(e)}
                                                />
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.prefix}</span>}
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Margin</label>
                                                <Field
                                                    style={{ height: 42, width: '100%' }}
                                                    name="margin"
                                                    component="select"
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    <option value="">Select...</option>
                                                    {
                                                        experienceOptions && experienceOptions.length > 0 &&  experienceOptions.map((item, i) => 
                                                            <option key={i} value={item.value}>{item.name}</option>
                                                        )
                                                    }
                                                </Field>
                                                {errors && this.state.isSubmited && <span className="errorvalidation">{errors.margin}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mt-4">
                                                <div>
                                                    <label className="checkbox-choice">Exchange finra affiliation
                                                        <input type="checkbox" name="exch_finra_affiliation" onChange={(e) => this.handleChangeCheckBox(e)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className="checkbox-choice">Control person
                                                        <input type="checkbox" name="control_person" onChange={(e) => this.handleChangeCheckBox(e)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className="checkbox-choice">Politically exposed
                                                        <input type="checkbox" name="politically_exposed" onChange={(e) => this.handleChangeCheckBox(e)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row ml-mr-0px-imp">
                                <div className="col col-12 col-sm-12 col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mt-4">
                                                <div className="btn btn-file-upload">
                                                    Upload
                                                    <input type="file" name="file" onChange={(e) => this.handleImageChange(e)}/>
                                                </div>
                                                {
                                                    this.state.file !== null &&
                                                    <span style={{paddingLeft: 20, position: 'relative', top: 12}}>{this.state.fileName}</span>
                                                }
                                                <div>{errors && this.state.isSubmited && <span className="errorvalidation">{errors.file}</span>}</div>
                                            </div>
                                        </div>
                                        {
                                            this.state.file !== null &&
                                            <div className="col-md-4">
                                                <div className="mt-4">
                                                    <img src={this.state.file} alt="" style={{width: '200px',objectFit: 'cover'}} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row ml-mr-0px-imp">
                                <div className="col col-12 col-sm-12 col-md-12">
                                    <div className="mt-4 text-center">
                                        <Button className="btn btn-submit-brokerage" disabled={this.state.isLoading} onClick={() => this.submitCreateBroker()}>Submit 
                                        {
                                            (this.state.isLoading) &&
                                            <img src={spinnImg} alt="loading" style={{width: 30}} />
                                        }
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        {/* </form> */}
                        
                        </div>
                    }
                    <Dialog
                        open={this.state.showConnectionErrorMessageModal}
                        onClose={this.onCloseErrorModal}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >
                        <DialogTitle id="scroll-dialog-title">Errors</DialogTitle>
                        <DialogContent>
                            <div style={{marginTop: 20}}>
                                {
                                    this.state.errorMessages && this.state.errorMessages.map((op, i) => 
                                    <div style={{marginTop: 10}} key={i}>{i+1} {op}</div>
                                    )
                                }
                            </div>
                        </DialogContent>
                        
                        <DialogActions>
                            <div style={{textAlign: 'center'}}>
                                <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.onCloseErrorModal()}>
                                    Ok
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>

                </div>
            </div>
        )
    }
}

CreateFormComponent.propTypes = {
    editprofileRes: PropTypes.any,
    BrokerdelarRes: PropTypes.any,
    profileDetail: PropTypes.any,
    createBrokerageAccRes: PropTypes.any,
    loadingRes: PropTypes.any,
    reset: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    editprofileRes: doEditprofileRes,
    BrokerdelarRes: doBrokerdelarRes,
    createBrokerageAccRes: doCreateBrokerageAccRes,
    loadingRes: loadingRes,
});

function mapDispatchToProps(dispatch) {
    return {
        changeFieldValue: (field, value) => { dispatch(change('ReduxCreateBrokerageForm', field, value)); },
        submitCreateBrokerageForm: (data, username, broker, token) => dispatch(submitCreateBrokerageForm(data, username, broker, token)),
        getProfile: (token) => dispatch(getProfile(token)),
        getBrokerdealer: (token) => dispatch(getBrokerDealer(token)),
    };
}

const ReduxCreateBrokerageForm = reduxForm({
    form: 'ReduxCreateBrokerageForm',
    validate,
    fields,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(CreateFormComponent)

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ReduxCreateBrokerageForm);
