import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import './socialMedia.css';
import Dashboard from '../../component/Dashboard';
import { getItem, setItem } from '../../utils/localStore';
import { submit_profile, doEditprofileRes } from '../../actions/editProfileActions';
import { social_login, dosocialLoginRes } from '../../actions/socialLoginActions';
import { social_token, doSocialTokenRes } from '../../actions/socialTokenActions';
import { delete_social_account, doDeleteSocialAccountRes } from '../../actions/deleteSocialAccountActions';
import { get_twitter_auth_url, getTwitterAuthUrlRes } from '../../actions/TwitterAuthUrlActions';
import { get_facebook_auth_url, getFacebookAuthUrlRes } from '../../actions/FacebookAuthUrlActions';
import { get_social_media_auth_url, getSocialMediaAuthUrlRes } from '../../actions/SocialMediaAuthUrlActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { loadScript } from '../../helper/helper';
import FbLogo from '../../assets/images/images/facebook_logo.png';
import TwitterLogo from '../../assets/images/images/twitter_logo.png';
import StockLogo from '../../assets/images/images/stock_twits_logo.png';
import LinkedinLogo from '../../assets/images/images/linkedin_logo.png';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(),
		alignItems: 'center',
		justifyContent: 'center'
	},
}))(MuiDialogActions);

let isProfile = false;
let isDeleteSocialAcc = false;
let isAuthUrl = false;
class SocialMediaComponent extends React.PureComponent {

  constructor() {
    super();
    isProfile = false;
    isDeleteSocialAcc = false;
    isAuthUrl = false;
    this.state = {
      checkFacebookLogin: false,
      checkTwitterLogin: false,
      checkStocktwitsLogin: false,
      checkLinkedinLogin: false,
      open: false,
      unLinkProvider: '',
      provider: '',
      isExplanationModalOpen: false,
      screenId: '1.1',

    }
  }
  
  socialLogout() {
    let payload = {
      provider: this.state.unLinkProvider
    }
    isDeleteSocialAcc = true;
    this.props.deleteSocialAccount(payload);
    this.setState({ open: false });
  }

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onCloseExplanationModal = () => {
    this.setState({ isExplanationModalOpen: false });
  };

  openExplanationModal = () => {
    this.setState({ isExplanationModalOpen: true });
  }

  unLink(st) {
    this.setState({
      open: true,
      unLinkProvider: st,
    });
  }
  
  // //getFacebookAuthUrl
  fetchSocialMediaAuthUrl = (provider) => {
    setItem('provider', provider);
    isAuthUrl = true;
    this.props.getSocialMediaAuthUrl(provider)
  }

  componentDidMount = () => {
    loadScript();
    isProfile = true;
    this.props.getProfile();
    if (window.location.href.includes('?hash=')) {
      const Provider = getItem('provider');
      const hash = window.location.href.split('?hash=')[1];
      this.setState({ hashCode: hash, unLinkProvider: '' });
      let getData = {
        'provider': Provider,
        'hash': hash
      }
      this.props.socialToken(getData);
      // make the /web/user_identities call here
    } else {
      const activityData = {
        'screen_id': this.state.screenId,
        'source': 'web'
      }
      this.props.userActivity(activityData)
    }
  }

  static getDerivedStateFromProps(props, state) {

    if (props.EditprofileRes && state.unLinkProvider === '') {
      if (props.EditprofileRes.data && props.EditprofileRes.data.editprofile) {
        if (props.EditprofileRes.data.editprofile.message === 'Success' && isProfile) {
            isProfile = false;
            let checkFbLogin = false;
            let checkTwLogin = false;
            let checkStLogin = false;
            let checkLnkinLogin = false;

          if (props.EditprofileRes.data.editprofile.data.user_identities) {
            if (props.EditprofileRes.data.editprofile.data.user_identities.length > 0) {
              for (let identity of props.EditprofileRes.data.editprofile.data.user_identities) {
                if (identity.provider === "facebook") {
                    checkFbLogin = true;
                } else if (identity.provider === "twitter") {
                    checkTwLogin = true;
                } else if (identity.provider === "stocktwits") {
                    checkStLogin = true;
                } else if (identity.provider === "linkedin") {
                    checkLnkinLogin = true;
                }
              }
            }
            return {
                checkFacebookLogin: checkFbLogin,
                checkTwitterLogin: checkTwLogin,
                checkStocktwitsLogin: checkStLogin,
                checkLinkedinLogin: checkLnkinLogin,
            }
          }
        }
      }
    }

    if (props.deleteSocialAccountRes) {
      if (props.deleteSocialAccountRes.data && props.deleteSocialAccountRes.data.deletesocialaccount) {
        if (props.deleteSocialAccountRes.data.deletesocialaccount.message === "Success" && isDeleteSocialAcc) {
            isDeleteSocialAcc = false;
            if (state.unLinkProvider === 'facebook') {
                return {
                    checkFacebookLogin: false
                }
            } else if (state.unLinkProvider === 'twitter') {
                return {
                    checkTwitterLogin: false
                }
            } else if (state.unLinkProvider === 'stocktwits') {
                return {
                    checkStocktwitsLogin: false
                }
            } else if (state.unLinkProvider === 'linkedin') {
                return {
                    checkLinkedinLogin: false
                }
            }
        }
      }
    }

    if (props.socialMediaAuthRes) {
        if (props.socialMediaAuthRes.data && props.socialMediaAuthRes.data.socialMediaAuthUrl) {
            if (props.socialMediaAuthRes.data && props.socialMediaAuthRes.data.socialMediaAuthUrl.message === 'Success' && isAuthUrl) {
                isAuthUrl = false;
                if (props.socialMediaAuthRes.data.socialMediaAuthUrl.data.length !== 0) {
                    window.location.href = props.socialMediaAuthRes.data.socialMediaAuthUrl.data.auth_url;
                }
            }
        }
    }
    return state;
  }

  render() {
    const { open, isExplanationModalOpen } = this.state;
    return (
      <Dashboard title="ClickIPO - Social Media" pageTitle="Social Media" >
        <div className="socialmedia_content">
          <div className="socialmedia_inner_content edit_profile_form_fields_wrapper">
            <div className="socialmedia_score_content social_links_wrapper">
              <div className="row">
                <div className="col col-12 social_links_content_box align-self-center justify-content-between">
                  {
                    this.state.checkFacebookLogin === true &&
                    <div className="row justify-content-between align-items-center px-15px" style={{padding: '0px 16px'}}>
                      <div>
                        <img src={FbLogo} alt="" className="social_Link_icon" />
                        <span className="tw_link_account">Linked</span>
                      </div>
                      <div>
                        <Button color="primary" onClick={() => this.unLink('facebook')} className="unlink_button green_btn">Unlink</Button>
                      </div>
                    </div>
                  }
                  {
                    this.state.checkFacebookLogin === false &&
                    <div className="facebookLogin" onClick={() => this.fetchSocialMediaAuthUrl('facebook')}>
                      <img src={FbLogo} alt="" className="social_Link_icon" />
                      <span className="tw_link_account">Link Account</span>
                    </div>
                  }
                </div>
                <div className="col col-12 social_links_content_box align-self-center xl-mt-15px">
                  {
                    this.state.checkTwitterLogin === true &&
                    <div className="row justify-content-between align-items-center px-15px" style={{padding: '0px 16px'}}>
                      <div>
                        <img src={TwitterLogo} alt="" className="social_Link_icon" />
                        <span className="tw_link_account">Linked</span>
                      </div>
                      <div>
                        <Button color="primary" onClick={() => this.unLink('twitter')} className="unlink_button green_btn">Unlink</Button>
                      </div>
                    </div>
                  }
                  {
                    this.state.checkTwitterLogin === false &&
                    <div className="twitter_login" onClick={() => this.fetchSocialMediaAuthUrl('twitter')}>
                      <img src={TwitterLogo} alt="" className="social_Link_icon" />
                      <span className="tw_link_account">Link Account</span>
                    </div>
                  }
                </div>
                <div className="col col-12 social_links_content_box align-self-center xl-mt-15px">
                  {
                    this.state.checkStocktwitsLogin === true &&
                    <div className="row justify-content-between align-items-center px-15px" style={{padding: '0px 16px'}}>
                      <div>
                        <img src={StockLogo} alt="" className="social_Link_icon" />
                        Linked
                      </div>
                      <div>
                        <Button color="primary" onClick={() => this.unLink('stocktwits')} className="unlink_button green_btn">Unlink</Button>
                      </div>
                    </div>
                  }
                  {
                    this.state.checkStocktwitsLogin === false &&
                    <div className="twitter_login" onClick={() => this.fetchSocialMediaAuthUrl('stocktwits')}>
                      <img src={StockLogo} alt="" className="social_Link_icon" />
                      <span className="tw_link_account">Link Account</span>
                    </div>
                  }
                </div>
                <div className="col col-12 social_links_content_box align-self-center xl-mt-15px">
                  {
                    this.state.checkLinkedinLogin === true &&
                    <div className="row justify-content-between align-items-center px-15px" style={{padding: '0px 16px'}}>
                      <div>
                        <img src={LinkedinLogo} alt="" className="social_Link_icon" />
                        Linked
                      </div>
                      <div>
                        <Button color="primary" onClick={() => this.unLink('linkedin')} className="unlink_button green_btn">Unlink</Button>
                      </div>
                    </div>
                  }
                  {
                    this.state.checkLinkedinLogin === false &&
                    <div className="twitter_login" onClick={() => this.fetchSocialMediaAuthUrl('linkedin')}>
                      <img src={LinkedinLogo} alt="" className="social_Link_icon" />
                      <span className="tw_link_account">Link Account</span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          
          <Dialog
              open={open}
              onClose={this.onCloseModal}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
          >
              <DialogContent>
                <h5> Are you sure you want to disconnect your account ?</h5>
              </DialogContent>
              <DialogActions>
                  <div style={{textAlign: 'center'}}>
                      <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.socialLogout()}>
                        Yes
                      </Button>
                      <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.onCloseModal()}>
                        No
                      </Button>
                  </div>
              </DialogActions>
          </Dialog>
          <Dialog
              open={isExplanationModalOpen}
              onClose={this.onCloseExplanationModal}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
          >
              <DialogContent>
                <h5> By connecting to your favorite social media account, you will have the chance to share your favorite offerings with your friends.</h5>
              </DialogContent>
              <DialogActions>
                  <div style={{textAlign: 'center'}}>
                      <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.onCloseExplanationModal()}>
                        Ok
                      </Button>
                  </div>
              </DialogActions>
          </Dialog>
          <div>
            <p className="socialMediaFooter" onClick={() => this.openExplanationModal()}>Why should I connect my social media?</p>
          </div>
        </div>
      </Dashboard>
    );
  }
}

SocialMediaComponent.propTypes = {
  EditprofileRes: PropTypes.any,
  deleteSocialAccountRes: PropTypes.any,
  socialLoginRes: PropTypes.any,
  socialTokenRes: PropTypes.any,
  userActivityRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  EditprofileRes: doEditprofileRes,
  socialLoginRes: dosocialLoginRes,
  socialTokenRes: doSocialTokenRes,
  deleteSocialAccountRes: doDeleteSocialAccountRes,
  twitterAuthRes: getTwitterAuthUrlRes,
  facebookAuthRes: getFacebookAuthUrlRes,
  socialMediaAuthRes: getSocialMediaAuthUrlRes,
  userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
  return {
    getProfile: () => dispatch(submit_profile()),
    sociallogin: (data) => dispatch(social_login(data)),
    socialToken: (data) => dispatch(social_token(data)),
    deleteSocialAccount: (provider) => dispatch(delete_social_account(provider)),
    getTwitterAuthUrl: () => dispatch(get_twitter_auth_url()),
    getFacebookAuthUrl: () => dispatch(get_facebook_auth_url()),
    getSocialMediaAuthUrl: (provider) => dispatch(get_social_media_auth_url(provider)),
    userActivity: (data) => dispatch(user_activity(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SocialMediaComponent);