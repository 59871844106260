import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import './orders.css';
import Dashboard from '../../component/Dashboard';
import { get_order, doOrderRes } from '../../actions/orderActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { loadScript, numberWithCommasAndDollar, numberWithCommas } from '../../helper/helper';
import PlaceHolderImg from '../../assets/images/placeholder_company_thumb.png';
import InfoIcon from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
let isOrder = false;
class OrderComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        isOrder = false;
        this.state = {
            closeOrderList: [],
            cancelledOrderList: [],
            screenId: '1.1',
            offeringOrderList: [],
            selectedOrderList: [],
            offeringData: {},
        }
        this.submitHandler = this.submitHandler.bind(this);
        this.searchOrder = this.searchOrder.bind(this);
        this.showOrder = this.showOrder.bind(this);
    }

    componentDidMount() {
        loadScript();
        const data = {};
        this.props.getOrder(data);
        const activityData = {
			'screen_id': this.state.screenId,
			'source': 'web'
        }
        isOrder = true;
		this.props.userActivity(activityData)
    }

    addDefaultSrc(ev) {
        ev.target.src = PlaceHolderImg;
    }

    submitHandler(e) {
        e.preventDefault();
    }
    
    static getDerivedStateFromProps(props, state) {
		if (props.OrderRes) {
            if (props.OrderRes.data && props.OrderRes.data.order) {
                if (props.OrderRes.data.order.message === 'Success' && isOrder) { 
                    isOrder = false;
                    let activeorders = []
                    let cancelorders = []
                    if (props.OrderRes.data.order.data) {
                        for (let orderitem of props.OrderRes.data.order.data) {
                            if (orderitem.status === 'active') {
                                activeorders.push(orderitem)
                            }
                            if (orderitem.status === 'cancelled') {
                                cancelorders.push(orderitem)
                            }
                            if (orderitem.status === 'closed') {
                                cancelorders.push(orderitem)
                            }
                        }
                        return {
                            closeOrderList: activeorders,
                            cancelledOrderList: cancelorders,
                            offeringOrderList: props.OrderRes.data.order.data,
                        }
                    }
                }
            }
            
        }
		return null
	}
    searchOrder(event) {
        this.setState({
            searching: event.target.value
        }, () => {
        })
    }

    showOrder(item) {
        this.setState({
            selectedOrderList: item.orders,
            offeringData: item,
        });
    }
    render() {
        const { searching, offeringOrderList, selectedOrderList, offeringData } = this.state;
        let filterData = [];
        if (filterData) {
            filterData = searching ? offeringOrderList.filter(row => row.name.toLowerCase().indexOf(searching.toLowerCase()) > -1) : offeringOrderList;
        }
        return (
            <Dashboard title="ClickIPO - Orders" pageTitle="Orders" >
                <div className="order_content">
                    <div className="row header_content">
                        <div className="col-md-6 col-xs-0 p-l-2 p-t-2">
                        </div>
                        <div className="col-md-6 col-xs-12 p-l-2 p-t-2">
                            <form className="navbar-form" role="search" onSubmit={this.submitHandler}>
                                <div style={{ textAlign: 'right'}}>
                                    <TextField placeholder="Search" name="srch-term" id="srch-term" type="text" onChange={this.searchOrder} />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="order_box">
                        <div className="row">
                            <div className="col-md-6 col-xs-11 p-l-2 p-t-2">
                                <div className="active_order_box_wrapper">
                                    <div className="section_title">
                                        Orders 
                                        <span className="order-info-btn">
                                            <InfoIcon />
                                            <span className="tooltiptext">Click on the offering to see the order history</span>
                                        </span>
                                    </div>
                                    <div className="active_order_box">
                                        {
                                            filterData.length === 0 && (!isOrder) &&
                                            <p className="no_orders">NO ORDERS</p>
                                        }
                                        {
                                            filterData && filterData.length > 0 && filterData.map((offeringitem, i) =>
                                                <div onClick={()=> this.showOrder(offeringitem)} key={i} className={
                                                    (offeringitem.name === this.state.offeringData.name ? "order_box_inner_content order_box_inner_content_offering active-index-order" : "order_box_inner_content order_box_inner_content_offering")
                                                  } style={{position: 'relative'}}>
                                                    <div className="row">
                                                        <div className="col col-3">
                                                            <img src={(offeringitem.logo_small) ? 'https:' + offeringitem.logo_small : ''} onError={this.addDefaultSrc} className="order_images" alt="" />
                                                        </div>
                                                        <div className="col col-9 order_list order_list_two">
                                                            <div>
                                                                <p className="company_order_name" style={{ fontSize: 18 }}>
                                                                    {offeringitem.name}
                                                                </p>
                                                                <p className="account_order_type">
                                                                    {
                                                                        offeringitem.ordr_dollar_share === 0 ?
                                                                        
                                                                        <span className="account_open_order_date">{offeringitem.orders[0].status === 'closed'? offeringitem.orders[0].allocated_shares : numberWithCommasAndDollar(offeringitem.orders[0].requested_amount)}
                                                                        </span>
                                                                        :
                                                                        <span className="account_open_order_date">{offeringitem.orders[0].status === 'closed'? offeringitem.orders[0].allocated_shares : numberWithCommas(offeringitem.orders[0].requested_shares)}
                                                                        </span>
                                                                        
                                                                    }
                                                                </p>
                                                               
                                                                <p className="price_level_order_type">
                                                                    <span>Status:</span>
                                                                    <span className="amount_order amount_order_two">
                                                                        {offeringitem.orders[0].status === 'active'? 'Pending' : offeringitem.orders[0].status.replace(/^\w/, c => c.toUpperCase())}
                                                                    </span>
                                                                </p>

                                                                <p className="account_order_type">
                                                                    <span>Date:</span>
                                                                    <span className="account_open_order_date">{offeringitem.orders[0].created_at + " ET"}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    {
                                                        (offeringitem.orders[0].auto_cancellation_dt) && offeringitem.orders[0].status === 'active' &&
                                                    
                                                        <div style={{ position: 'absolute', height: '100%', width: '100%', top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(255 255 255 / 66%)' }}>
                                                            <div style={{ textAlign: 'center'}}>Confirm your COB</div>
                                                        </div>
                                                    }
                                                    {
                                                        (offeringitem.ioi_cutoff_datetime) && offeringitem.orders[0].status === 'active' &&
                                                    
                                                        <div style={{ position: 'absolute', height: '100%', width: '100%', top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(255 255 255 / 66%)' }}>
                                                            <div style={{ textAlign: 'center'}}>Confirm your IOI</div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-11 p-l-2 p-t-2">
                                <div className="closed_cancel_order_box">
                                    <div className="section_title">Order History</div>
                                    <div className="closed_order_box">
                                        {
                                            (selectedOrderList.length === 0) &&
                                            <p className="no_orders">Click on an offering name to see order history</p>
                                        }
                                        {
                                            selectedOrderList && selectedOrderList.length > 0 && selectedOrderList.map((orderchainitem, i) =>
                                                <div className="order_box_inner_content no-bdr" key={i}>
                                                {
                                                    orderchainitem.status === "active" &&
                                                    <div className="bdr-light active_order_tooltip">
                                                        <div className="row">
                                                            <div className="col col-3">
                                                                <img onError={this.addDefaultSrc} src={(offeringData.logo_small) ? 'https:' + offeringData.logo_small : ''} className="order_images" alt="" />
                                                            </div>
                                                            <div className="col col-9 order_list order_list_two">
                                                                <Link to={{ pathname: `/orderdeatils/${offeringData.ext_id}`, state: { orderData: orderchainitem, offering: offeringData } }}>
                                                                    <p className="company_order_name">
                                                                        {offeringData.name}
                                                                    </p>
                                                                    <p className="account_order_type">
                                                                        <span>Order</span>  
                                                                        {
                                                                            offeringData.ordr_dollar_share === 0 ? <span className="account_open_order_date">{numberWithCommasAndDollar(orderchainitem.requested_amount)}</span>
                                                                            :
                                                                            <span className="account_open_order_date">{numberWithCommas(orderchainitem.requested_shares)}</span>
                                                                        }
                                                                    </p>
                                                                    <p className="price_level_order_type">
                                                                        <span>Status</span>
                                                                        <span className="amount_order amount_order_two">
                                                                            Pending
                                                                        </span>
                                                                    </p>
                                                                    <p className="account_order_type">
                                                                        <span>Date</span>  
                                                                        <span className="account_open_order_date">{orderchainitem.created_at + " ET"}</span>
                                                                    </p>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <span className="active_order_tooltiptext">Click to view/modify/cancel order</span>
                                                    </div>
                                                }
                                                {
                                                    orderchainitem.status === "closed" &&
                                                    <div className="bdr-success closed_order_style">
                                                        <div className="row">
                                                            <div className="col col-3">
                                                                <img onError={this.addDefaultSrc} src={(offeringData.logo_small) ? 'https:' + offeringData.logo_small : ''} className="order_images" alt="" />
                                                            </div>
                                                            <div className="col col-9 order_list order_list_two">
                                                                <p className="company_order_name">
                                                                    {offeringData.name}
                                                                </p>
                                                                <p className="account_order_type">
                                                                    <span>Shares Allocated</span>
                                                                    <span className="account_open_order_date">{orderchainitem.allocated_shares}</span>
                                                                </p>
                                                                <p className="price_level_order_type">
                                                                    <span>Status</span>
                                                                    <span className="amount_order amount_order_two">
                                                                        {orderchainitem.status.replace(/^\w/, c => c.toUpperCase())}
                                                                    </span>
                                                                </p>
                                                                <p className="account_order_type">
                                                                        <span>Date</span>  
                                                                        <span className="account_open_order_date">{orderchainitem.created_at + " ET"}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    orderchainitem.status === "cancelled" &&
                                                    <div className=" bdr-danger cancelled_order_style">
                                                        <div className="row">
                                                            <div className="col col-3">
                                                                <img onError={this.addDefaultSrc} src={(offeringData.logo_small) ? 'https:' + offeringData.logo_small : ''} className="order_images" alt="" />
                                                            </div>
                                                            <div className="col col-9 order_list order_list_two">
                                                                <p className="company_order_name">
                                                                    {offeringData.name}
                                                                </p>
                                                                <p className="account_order_type">
                                                                    <span>Order</span>
                                                                    {
                                                                        offeringData.ordr_dollar_share === 0 ?
                                                                        
                                                                        <span className="account_open_order_date">{numberWithCommasAndDollar(orderchainitem.requested_amount)}</span>
                                                                        :
                                                                        <span className="account_open_order_date">{numberWithCommas(orderchainitem.requested_shares)}</span>
                                                                        
                                                                    }
                                                                </p>
                                                                <p className="price_level_order_type">
                                                                    <span>Status</span>
                                                                    <span className="amount_order">
                                                                        {orderchainitem.status.replace(/^\w/, c => c.toUpperCase())}
                                                                    </span>
                                                                </p>
                                                                <p className="account_order_type">
                                                                        <span>Date</span>  
                                                                        <span className="account_open_order_date">{orderchainitem.created_at + " ET"}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dashboard>
        )
    }

}


OrderComponent.propTypes = {
    OrderRes: PropTypes.any,
    userActivityRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    OrderRes: doOrderRes,
    userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
    return {
        getOrder: (data) => dispatch(get_order(data)),
		userActivity: (data) => dispatch(user_activity(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrderComponent);
