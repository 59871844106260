import * as types from './actionTypes';
import verifyEmailApi from '../api/verifyEmailApi';
import Swal from 'sweetalert2'

export function doVerifyEmail(data) {
  return {
    type: types.DO_VERIFYEMAIL,
    data
  };
}

export function doVerifyEmailRes(user) {
  return {
    type: types.DO_VERIFYEMAIL_RES,
    user
  };
}

export function submitVerifyEmail(data) {
    return function(dispatch) {
      verifyEmailApi.doVerifyEmail(data).then(user => {
        dispatch(doVerifyEmailRes(user));
        dispatch(doVerifyEmailRes(null));
        if(user.error){
          Swal.fire({
            title: user.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
          setTimeout(() => {
            window.location.href = '/';
          }, 3000);
        }
      }).catch(error => {
        return error
      });
    };
}
