import * as types from './actionTypes';  
import deleteSocialAccountApi from '../api/deleteSocialAccountApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function deleteSocialAccount(provider) {
  return {
    type: types.DO_DELETE_SOCIAL_ACCOUNT, 
    provider
  };
}

export function doDeleteSocialAccountRes(data) {
  return {
    type: types.DO_DELETE_SOCIAL_ACCOUNT_RES, 
    data
  };
}

export function delete_social_account(provider) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      deleteSocialAccountApi.deleteSocialAccount(provider).then(data => {
        dispatch(doDeleteSocialAccountRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}
