import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './messages.css';
import Dashboard from '../../component/Dashboard';
import { fetchMessages, doMessageRes } from '../../actions/messageActions';
import { fetchMessageDetail, doMessageDetailRes } from '../../actions/messageDetailActions';
import SpinnImg from '../../assets/images/spinn_img.gif';
import { loadScript } from '../../helper/helper';
import SearchIcon from '@material-ui/icons/Search';
import DOMPurify from 'dompurify';
let isMessageList = false;
class MessagesComponent extends React.PureComponent {

	constructor(props) {
		super(props);
		isMessageList = false;
		this.state = {
			messageList: [],
			messageDetails: '',
			messageName: '',
			messageDeliveryDate: '',
			messageType: '',
			searching: '',
			messageListLoader: false,
			page: 0,
			loadMoreHide: false,
		}

		this.searchMessage = this.searchMessage.bind(this);
		this.loadmore = this.loadmore.bind(this);
	}

	componentDidMount() {
		loadScript();
		isMessageList = true;
		this.props.getMessages(this.state.page);
	}

	static getDerivedStateFromProps(props, state) {
		if (props.getMessageListRes) {
			if (props.getMessageListRes.data) {
				if (props.getMessageListRes.data.messages) {
					if (props.getMessageListRes.data.messages.message === 'Success' && isMessageList) {
						isMessageList = false;
						let loadMoreHideVal = false;
						if (props.getMessageListRes.data.messages.data.length !== 20) {
							loadMoreHideVal = true;
						}
						return {
							loadMoreHide: loadMoreHideVal,
							messageList: state.messageList.concat(props.getMessageListRes.data.messages.data),
							messageListLoader: false,
						};
					}
					
				}
			}
		}
		if (props.getMessageDetailRes) {
			if (props.getMessageDetailRes.data && props.getMessageDetailRes.data.messageDetail) {
				if (props.getMessageDetailRes.data.messageDetail.message === 'Success') {
					return {
						messageDetails: props.getMessageDetailRes.data.messageDetail.data,
					};
				}
			}
		}
		return null
	}
	loadmore() {
		this.setState({
			page: this.state.page + 1,
			messageListLoader: true,
		}, () => {
			isMessageList = true;
			this.props.getMessages(this.state.page);
		});
	}
	searchMessage(event) {
		this.setState({
			searching: event.target.value,
		}, () => {});
	}

	doMessageDetail(item) {
		if(item.type==="email") {
			this.setState({
				messageDetails: '',
				messageName: item.title,
				messageDeliveryDate: item.created_at,
				messageType: item.type,
			});
			this.props.getMessageDetail(item.s3_file_url);
		} else if (item.type ==="push") {
			this.setState({
				messageName: item.title,
				messageDeliveryDate: item.created_at,
				messageType: item.type,
				messageDetails: item.body
			});
		}
	}

	render() {
		const {messageList, messageDetails, messageDeliveryDate } = this.state;
		let filterData = [];
		
		if (messageList) {
			filterData = this.state.searching ? messageList.filter((row) => row.title.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1) : messageList;
		}
		
		return (
			<Dashboard title="ClickIPO - Messages" pageTitle="Messages" >
				<div className="message_content">
					<div className="message_box">
						<div className="active_message_box">
							<div className="row justify-content-center h-100">
								<div className="col-md-4 col-xl-4 chat"><div className="card mb-sm-3 mb-md-0 contacts_card">
									<div className="card-header">
										<div className="input-group">
											<input type="text" placeholder="Search..." name="" className="form-control search"  onChange={this.searchMessage} defaultValue={this.state.searching} />
											<div className="input-group-prepend">
												<span className="input-group-text search_btn"><SearchIcon /></span>
											</div>
										</div>
										
									</div>
									<div className="card-body contacts_body">
										<ul className="contacts" id="messagelistid">
											
											{
												(filterData && filterData.length > 0) && filterData.map((item, i) => (
													<li key={i} className={(item.created_at === messageDeliveryDate ? " active-index" : "")} onClick={() => this.doMessageDetail(item)}>
														<div className="bd-highlight">
															<div className="user_info">
																<span>{item.title}</span>
																<p>{item.created_at}</p>
																<div style={{ fontSize: 14, color: '#999' }}>Type: <b className="email-push-indication"> {item.type}</b></div>
															</div>
														</div>
													</li>
												))
											}
											{
												(filterData && filterData.length > 0 && !this.state.loadMoreHide) &&
												<div>
													
													<div className="loadmore-option">
														<button className="btn loadmorebtn" disabled={this.state.messageListLoader === true} onClick={()=> {this.loadmore()}}>Load more
														{
															(this.state.messageListLoader === true) && 
															<img src={SpinnImg} alt="spinner" style={{ width: 22, marginLeft: 20 }} />
														}
														</button>
													</div>
												</div>
											}
											{
												(filterData && filterData.length === 0) &&  (!isMessageList) &&
													<li>
														<div style={{ textAlign: 'center' }}>
															No data found
														</div>
													</li>
												}
											
											{/* <li>
												<div className="d-flex bd-highlight"> */}
													{/* <div className="img_cont">
														<img src="https://i.pinimg.com/originals/54/6e/6d/546e6d4c6ce4322e6aa3b2f8ca73ac28.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon offline"></span>
													</div> */}
													{/* <div className="user_info">
														<span>Purchase order received</span>
														<p>Purchase order received</p>
													</div>
												</div>
											</li> */}
											
										</ul>
									</div>
									<div className="card-footer"></div>
								</div></div>
								<div className="col-md-8 col-xl-8 chat">
									<div className="card">
										<div className="card-header msg_head">
											<div className="d-flex bd-highlight">
												<div className="user_info_detail">
													<span>{this.state.messageName}</span>
													<p>{this.state.messageDeliveryDate}</p>
													{
														(this.state.messageType) &&
														<div style={{ fontSize: 14, color: '#999' }}>Type: <b className="email-push-indication"> {this.state.messageType}</b></div>
													}
													
												</div>
											</div>
										</div>
										<div className="card-body msg_card_body" style={{ padding: 0 }}>
											{
												(this.state.messageType==='email' && messageDetails) &&
												<div dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(messageDetails)} }></div>
											}
											{
												(this.state.messageType==='push' && messageDetails) &&
												<div style={{ padding: "20px"}} >{messageDetails}</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Dashboard>
		)
	}
}

MessagesComponent.propTypes = {
	getMessageListRes: PropTypes.any,
	getMessageDetailRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    getMessageListRes: doMessageRes,
    getMessageDetailRes: doMessageDetailRes,
});

function mapDispatchToProps(dispatch) {
	return {
        getMessages: (page) => dispatch(fetchMessages(page)),
        getMessageDetail: (data) => dispatch(fetchMessageDetail(data)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MessagesComponent);
