import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';
class bizInsightsApi {
    static getBizInsights(ext_id) {
        const TOKEN = getItem('auth_token');
        let sendBody={
            ext_id: ext_id,
        }
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(sendBody)
            }
            return fetch(BASE_URL + '/v1/offerings/biz_insights', body).then(response => {
                isError = errorHandling(response);
                return response.text();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
           
        }catch(err){
        }
    }
}

export default bizInsightsApi;