import * as types from './actionTypes';  
import updateCommentOfferingApi from '../api/updateCommentOfferingApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function doUpdateComment(data) {

  return {
    type: types.DO_UPDATE_COMMENT_OFFERING, 
    data
  };
}

export function doUpdateCommentRes(data) {  
  return {
    type: types.DO_UPDATE_COMMENT_OFFERING_RES, 
    data
  };
}

export function submitUpdateComment(data) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      updateCommentOfferingApi.doUpdateComment(data).then(data => {
        dispatch(doUpdateCommentRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
  
}