import * as types from './actionTypes';  
import plannerTradingAccountApi from '../api/plannerTradingAccountApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doCreatePlannerTradingAcc(request) {
  return {
    type: types.CREATE_PLANNER_TRADING_ACCOUNT, 
    request
  };
}

export function doCreatePlannerTradingAccRes(data) {  
  return {
    type: types.CREATE_PLANNER_TRADING_ACCOUNT_RES, 
    data
  };
}

export function createPlannerTradingAcc(request) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      plannerTradingAccountApi.doCreatePlannerTradingAcc(request).then(data => {
        dispatch(doCreatePlannerTradingAccRes(data));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}