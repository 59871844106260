import * as types from './actionTypes';
import firebaseTokenApi from '../api/firebaseTokenApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import { browserName, browserVersion } from "react-device-detect";

export function doFirebaseTokenRes(data) {
    return {
        type: types.DO_FIREBASE_TOKEN_RES,
        data
    };
}

export function sendFirebaseToken() {
    const TOKEN = getItem('auth_token');
    if (TOKEN) {
        let fcmToken = getItem('fcmToken');
        if (fcmToken) {
            let browserNm = browserName + " " + browserVersion;
            let request = { device_id: fcmToken, platform: "web", name: browserNm }
            return function (dispatch) {
                firebaseTokenApi.firebaseTokenUpdate(request).then(data => {
                    dispatch(doFirebaseTokenRes(data));
                    dispatch(doFirebaseTokenRes(null));
                }).catch(error => {
                    return error
                });
            };
        }
    } else {
        logout()
    }
}