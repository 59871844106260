import * as types from './actionTypes';  
import socialTokenApi from '../api/socialTokenApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function socialToken(data) {
  return {
    type: types.DO_SOCIAL_TOKEN, 
    data
  };
}

export function doSocialTokenRes(data) {
  return {
    type: types.DO_SOCIAL_TOKEN_RES, 
    data
  };
}

export function social_token(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      socialTokenApi.socialToken(data).then(res => {
        dispatch(doSocialTokenRes(res));
        if(res.error){
          Swal.fire({
            title: res.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}
