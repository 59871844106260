import * as types from './actionTypes';  
import restrictedPersonApi from '../api/restrictedPersonApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2'
 
export function restrictedPerson(data) {
  return {
    type: types.DO_RESTRICTED_PERSON, 
    data
  };
}

export function doRestrictedPersonRes(data) {
  return {
    type: types.DO_RESTRICTED_PERSON_RES, 
    data
  };
}

export function restricted_personApi(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      restrictedPersonApi.restrictedPerson(data).then(res => {
        dispatch(doRestrictedPersonRes(res));
        if(res.error){
          Swal.fire({
            title: res.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}