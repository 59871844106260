import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const fields = ['current_password'];
const lower = value => value && value.toLowerCase();

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div className="pb-4" style={{textAlign: 'left'}}>
        <TextField style={{ width: '100%' }} {...input} placeholder={label} type={type} />
            {touched && error && <span>{error}</span>}
    </div>
)

function validate(values) {
    const errors = {}
    if (!values.current_password) {
        errors.current_password = 'Required'
    }
    return errors
}
class VerifyPasswordForm extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            showHidePassword: false,
        }
    }
    showHidePassword = () => {
        this.setState({
            showHidePassword: !this.state.showHidePassword
        })
    }
    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const { handleSubmit, handleFormSubmit, pristine, submitting } = this.props
        return (
            <form onSubmit={handleSubmit(handleFormSubmit)} className="verifypassword-form">
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <Field
                                name="current_password"
                                component={renderField}
                                label="Current Password"
                                autoComplete="off"
                                type={(this.state.showHidePassword ? 'text':'password')} 
                                normalize={lower}
                            />
                            <i className={"fa " + (this.state.showHidePassword ? 'fa-eye' : 'fa-eye-slash')} onClick={this.showHidePassword}></i>
                        </div>
                    </div>
                    <div className="col-md-12 mt-2 text-center">
                        <Button style={{ paddingLeft: 20, paddingRight: 20 }} variant="contained" color="primary"type="submit" disabled={pristine || submitting}>
                            Submit
                        </Button>
                    </div>
                </div>
            </form>
        )
    }

}

VerifyPasswordForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleFormSubmit: PropTypes.func,
};
export default reduxForm({
    form: 'ReduxVerifypasswordForm',
    fields,
    validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(VerifyPasswordForm)