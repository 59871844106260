import * as types from '../actions/actionTypes'; 
import initialState from './initialState';
import _ from 'lodash';

//all the offering related reducers here
export default function (state = initialState.user, action) { //we will change the state = {} soon
  switch(action.type) {
    case types.DO_OFFERING_RES:
      let offering=action.data
      if (offering !== null) {
        offering.data = _.orderBy(offering.data, ['available_to_order', 'trade_date'], ['desc']);
      }
      return offering
    default:
      return state
  }
}
