import * as types from './actionTypes';
import updateProfileApi from '../api/updateProfileApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2'

export function doUpdateprofile(data, status) {
  return {
    type: types.DO_UPDATEPROFILE,
    data,
    status,
  };
}

export function doUpdateprofileRes(data) {
  return {
    type: types.DO_UPDATEPROFILE_RES,
    data
  };
}


export function update_profile(data, status) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      updateProfileApi.doUpdateprofile(data, status).then(data => {
        dispatch(doUpdateprofileRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}

export function doUploadUserPicture(data) {
  return {
    type: types.DO_UPLOAD_PROFILE_PICTURE,
    data
  };
}

export function doUploadUserPictureRes(data) {
  return {
    type: types.DO_UPLOAD_PROFILE_PICTURE_RES,
    data
  };
}

export function uploadUserProfilePic(data) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      updateProfileApi.doUploadUserPicture(data).then(data => {
        dispatch(doUploadUserPictureRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}