import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class messageDetailMobApi {  
    static doMessageDetailMob(url, token) {
        const TOKEN = token;
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'GET',
                headers: ajaxRequestHeaders,
            }
            return fetch(BASE_URL + `/messages/detail?url=${url}`, body).then(response => {
                // if (response.status === 401) {
                //     localStorage.clear();
                //     window.location.href = '/';
                // }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}  

export default messageDetailMobApi;