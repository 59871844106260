import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';

class securitySymbolApi {  
    static getSymbol(symbol) {
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Origin': window.location.href
            });
            let body = {
                method: 'GET',
                headers: ajaxRequestHeaders,
            }
            return fetch( BASE_URL + `/securityinfo/offering/${symbol}`, body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default securitySymbolApi;