import * as types from './actionTypes';
import signupApi from '../api/signupApi';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doSignup(data) {
  return {
    type: types.DO_SIGNUP,
    data
  };
}

export function doSignupRes(user) {
  return {
    type: types.DO_SIGNUP_RES,
    user
  };
}

export function checkUser(data) {
  return {
    type: types.CHECK_USER,
    data
  };
}

export function checkUserRes(user) {
  return {
    type: types.CHECK_USER_RES,
    user
  };
}

export function submit_signup(data) {
  return function(dispatch) {
    dispatch(loadingRes(true));
    signupApi.doSignup(data).then(user => {
      dispatch(doSignupRes(user));
      dispatch(doSignupRes(null));
      dispatch(loadingRes(false));
      if(user.error && user.message!=="Email verification required"){
        Swal.fire({
          title: user.message,
          icon: 'error',
          confirmButtonText: 'OK',
          heightAuto: false
        })
      }
    }).catch(error => {
      dispatch(loadingRes(false));
      return error
    });
  };
}

export function check_user_email(data) {
  return function(dispatch) {
    dispatch(loadingRes(true));
    signupApi.checkUserEmail(data).then(res => {
      dispatch(checkUserRes(res));
      dispatch(loadingRes(false));
      if(res.error){
        Swal.fire({
          title: res.message,
          icon: 'error',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          timer: 3000,
          heightAuto: false
        })
      }
    }).catch(error => {
      dispatch(loadingRes(false));
      return error
    });
  };
}