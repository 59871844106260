import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { doResetpasswordRes } from '../../../actions/resetPasswordActions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const fields = ['password', 'password_confirmation'];

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <div>
            <input className="form-control" {...input} placeholder={label} type={type} />
            {touched && error && <span>{error}</span>}
        </div>
    </div>
)

const validate = values => {
    const errors = {}

    if (!values.password) {
        errors.password = 'Required'
    }
    if (values.password) {
        if (values.password.search(/^\S+$/)) {
            errors.password = 'Your password must not contain any space.'
        }
        if (values.password.length < 10) {
            errors.password = 'Your password must be at least 10 characters'
        }
        if (values.password.search(/[a-z]/i) < 0) {
            errors.password = 'Your password must contain at least one letter.'
        }
    }
    if (!values.password_confirmation) {
        errors.password_confirmation = 'Required'
    }
    else if (values.password_confirmation !== values.password) {
        errors.password_confirmation = 'Passwords do not match';
    }
    return errors
}

class ResetPasswordForm extends React.PureComponent {

    constructor() {
        super();
        this.state = {
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

 //    static getDerivedStateFromProps(props, state) {
	// 	if (props.doResetpasswordRes) {
 //            console.log("props.doResetpasswordRes.user ", props.doResetpasswordRes.user)
 //            if (props.doResetpasswordRes.user.form.ReduxResetPasswordForm.submitSucceeded === true) {
 //                props.reset();
 //            }
 //        }
	// 	return null
	// }
    render() {
        const { handleSubmit, handleFormSubmit, pristine, submitting } = this.props
        return (
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="small-12 columns error_message form_field_wrapper password_feild_wrapper">
                    <Field
                        name="password"
                        component={renderField}
                        label="Password"
                        autoComplete="off"
                        type="password"
                    />
                </div>
                <div className="small-12 columns error_message form_field_wrapper password_feild_wrapper">
                    <Field
                        name="password_confirmation"
                        component={renderField}
                        label="Password Confirmation"
                        autoComplete="off"
                        type="password"
                    />
                </div>
                <div>
                    <button type="submit" className="btn resetpassword_button" disabled={pristine || submitting}>Submit</button>
                </div>
            </form>
        )
    }
}

ResetPasswordForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleFormSubmit: PropTypes.func,
    doResetpasswordRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    doResetpasswordRes: doResetpasswordRes
});
  
function mapDispatchToProps(dispatch,ownProps) {
    return {
        
    };
}

const ReduxResetPasswordForm = reduxForm({
    form: 'ReduxResetPasswordForm',
    validate,
    fields,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(ResetPasswordForm)

export default connect(mapStateToProps, mapDispatchToProps)(ReduxResetPasswordForm);
