import * as types from './actionTypes';  
import readOfferingApi from '../api/readOfferingApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';

export function doReadOffering(data) {
  return {
    type: types.DO_READ_OFFERING, 
    data
  };
}

export function doReadOfferingRes(data) {  
  return {
    type: types.DO_READ_OFFERING_RES, 
    data
  };
}

export function read_offering(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      readOfferingApi.doReadOffering(data).then(data => {
        dispatch(doReadOfferingRes(data));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}