import * as types from './actionTypes';
import verifyCodeApi from '../api/verifyCodeApi';
import Swal from 'sweetalert2'

export function doVerifyCode(data) {
  return {
    type: types.DO_VERIFYCODE,
    data
  };
}

export function doVerifyCodeRes(data) {
  return {
    type: types.DO_VERIFYCODE_RES,
    data
  };
}

export function submitVerifyCode(data) {
  return function(dispatch) {
    verifyCodeApi.doVerifyCode(data).then(data => {
      dispatch(doVerifyCodeRes(data));
      if(data.error){
        Swal.fire({
          title: data.message,
          icon: 'error',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          timer: 3000,
          heightAuto: false
        })
      }
    }).catch(error => {
      return error
    });
  };
}