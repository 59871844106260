import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';
class tradestationApi {
    static getTradeStation(data) {

        let data1 = {
            grant_type: 'authorization_code',
            client_id: data.bd_id,
            redirect_uri: data.callbackurl,
            code: data.code,
            client_secret: data.db_secret,
        }
        let endpoint = '';
        if(data.brokerName === 'Just2Trade') {
            endpoint = 'connect/token';
        } else {
            endpoint = 'security/authorize';
        }
        let isError = false;
        try {
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
            });

            var formBody = [];
            for (var property in data1) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(data1[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: formBody
            }
            return fetch(`${data.base_url}/${endpoint}`, body).then(response => {
                if ((response.status === 401) || (response.status === 400)) {
                    window.location.href = '/brokerdealer';
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        } catch (err) {
        }
    }

    static doBrokerDealerConnect(data) {
        const TOKEN = getItem('auth_token');
        const brokerdealerdata = getItem('broker_dealer_details');
        const dealerdata = JSON.parse(brokerdealerdata);
        let connectbrokerdata = {};
        if (dealerdata.name === 'TradeStation') {
            connectbrokerdata = {
                mpid: dealerdata.mpid,
                uid: data.userid,
                token: data.access_token,
                refresh_token: data.refresh_token,
            }
        } else if (dealerdata.name === 'Just2Trade') {
            connectbrokerdata = {
                mpid: dealerdata.mpid,
                uid: null,
                token: data.access_token,
                refresh_token: null,
            }
        }
        let isError = false;
        try {
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(connectbrokerdata)
            }
           
            return fetch(BASE_URL + '/v1/connections', body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        } catch (err) {
        }
    }
}

export default tradestationApi;