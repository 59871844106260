import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './messagedetailmob.css';
import { fetchMessageDetailMob, doMessageDetailMobRes } from '../../actions/messageDetailMobActions';
import SpinnImg from '../../assets/images/spinn_img.gif';
import DOMPurify from 'dompurify';
class MessageDetailMobileComponent extends React.PureComponent {

	constructor() {
		super();
		this.state = {
			messageDetails: '',
		}

	}

	componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);
		const token = query.get('token');
		const fileUrl = query.get('s3_file_url');
		this.props.getMessageDetailMob(fileUrl, token);
	}

	static getDerivedStateFromProps(props, state) {
		if (props.getMessageDetailMobRes) {
			if (props.getMessageDetailMobRes.data && props.getMessageDetailMobRes.data.messageDetailMob) {
				return {
					messageDetails: props.getMessageDetailMobRes.data.messageDetailMob,
				};
			}
		}
		return null
	}
	render() {
		const { messageDetails } = this.state;
		const Header = (<div className="offer_head">Message-Detail</div>);
		
		return (
			<div header={Header}>
				<div>
					<div className="row h-100">
						<div className="col-md-12 col-xl-12">
							<div>
								<div style={{ padding: 0 }}>
									{
										(messageDetails === '') &&
										<div style={{ textAlign: 'center',marginTop: 50 }}><img src={SpinnImg} alt="spinner" style={{ width: 80}} /></div>
									}
									{
										(messageDetails) &&
										<div dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(messageDetails)} }></div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

MessageDetailMobileComponent.propTypes = {
	getMessageDetailMobRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    getMessageDetailMobRes: doMessageDetailMobRes,
});

function mapDispatchToProps(dispatch) {
	return {
        getMessageDetailMob: (url, token) => dispatch(fetchMessageDetailMob(url, token)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MessageDetailMobileComponent);
