import * as types from './actionTypes';
import SocialMessagePostApi from '../api/SocialMessagePostApi';
import { logout } from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function socialMediaPost(data) {
  return {
    type: types.POST_SOCIAL_MEDIA_MESSAGE,
    data
  };
}

export function socialMediaPostRes(data) {
  return {
    type: types.POST_SOCIAL_MEDIA_MESSAGE_RES,
    data
  };
}

export function social_media_post(data) {
  const TOKEN = getItem('auth_token');
  if (TOKEN) {
    return function (dispatch) {
      SocialMessagePostApi.SocialMessagePostApi(data).then(res => {
        dispatch(socialMediaPost(res));
        if (res.error) {
          Swal.fire({
            title: res.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  } else {
    logout()
  }
}
