import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import './login.scss';
import publicIp from 'public-ip';
import LoginForm from './Form/form';
import { submit_login, doLoginRes } from '../../actions/loginActions';
// import { submit_profile } from '../../actions/editProfileActions';
import { setItem } from '../../utils/localStore';
import { ToastContainer } from 'react-toastify';
import { loadScript } from '../../helper/helper';
import { BASE_URL } from '../../constants';
import CompanyLogo from '../../assets/images/clickipo-logo-tagline.png';
import IsoMockups from '../../assets/images/ipo-platform-mockups.png';
import IosAppImg from '../../assets/images/download_clickipo_ios.png';
import AppIcon from '../../assets/images/app-icon.jpg';
import AndroidAppImg from '../../assets/images/download_clickipo_android.png';
import {
	isAndroid,
	isIOS
  } from "react-device-detect";
  import { sendFirebaseToken } from '../../actions/firebaseTokenUpdateActions';
class LoginComponent extends React.PureComponent {
    constructor() {

        
        super();
        this.state = {
            client_ip: null,
            userEmail: '',
            isDspLogin: false,
            dspOfferingLogo:'',
            dspOfferingName:'',
            dspOfferingDescription:'',
            offeringData:{},
            showBanner: true,
        }
    }
    static getDerivedStateFromProps(props, state) {
		if (props.loginRes.user.user) {
            if (props.loginRes.user.user.data) {
                if (props.loginRes.user.user.data.token) { 
                    
                    if(window.location.href.includes("?red_url=")){
                        let red_url = window.location.href.split("?red_url=");
                        setItem('auth_token', props.loginRes.user.user.data.token);
                        // props.getProfile();
                        // props.sendFirebaseToken();
                        window.location.assign(red_url[1]);
                    }else{
                        setItem('auth_token', props.loginRes.user.user.data.token);
                        setItem('last_login_time', props.loginRes.user.user.data.last_login_time);
                        setItem('login_count', props.loginRes.user.user.data.login_count);
                        // window.location.assign("/offering");
                        // props.getProfile();
                        // props.sendFirebaseToken();
                        // window.location.assign("/offering");
                        window.location.reload();
                    }
                }
            }else if(props.loginRes.user.user.error===2032){
                props.history.push('/send-verify-email', {state: state.userEmail}); 
            }
        }
		return null
	}
    componentDidMount() {
        let token=null
        if(window.location.href.includes("?token=")){
            let tokenArray = window.location.href.split("?token=");
           token = tokenArray[1];
        }
        if(this.props.location.state){
            token = this.props.location.state.state
        }

        if (token!==null) {
            this.setState({
                isDspLogin: true,
            })
            fetch(BASE_URL + `/users/dsp/token_verify?token=${token}`)
            .then(response=> response.json())
            .then((data)=>{
                this.setState({
                    userEmail: data.data.email,
                    offeringData: data.data.offering,
                    dspOfferingLogo: data.data.offering.logo_small,
                    dspOfferingName: data.data.offering.name,
                    dspOfferingDescription: data.data.offering.description
                }) 
            }).catch(()=> this.props.history.push(`/invalidToken`))
        }
        loadScript();
        publicIp.v4().then(ip => {
            this.setState({
                client_ip: ip
            })
        });
    }

    handleSubmit(data) {
        let sendRequest = data;
        sendRequest.client_ip = this.state.client_ip;
        this.props.handleFormSubmit(sendRequest);
        this.setState({
            userEmail: data.email,
        });
    };
    hideBanaer = () => {
        this.setState({
            showBanner: false,
        });
    }
    render() {
        return (
            <div className="signin-page">
                {
                    (isIOS) && 
                    <div>
                        {
                            (this.state.showBanner) &&
                            <div className="mysmartbannerios mysmartbanner-ios mysmartbanner-top">
                                <div className="smartbanner-container">
                                    <button type="button" className="smartbanner-close" aria-label="close" onClick={this.hideBanaer}>×</button><span className="smartbanner-icon" style={{ backgroundImage: 'url(' + AppIcon + ')'}}></span>
                                    <div className="smartbanner-info">
                                        <div className="smartbanner-title">ClickIPO - Invest in IPOs</div>
                                        <div className="smartbanner-author"></div>
                                        <div className="smartbanner-description">Free - On the App Store</div>
                                    </div>
                                    <div className="smartbanner-wrapper"><a href="https://apps.apple.com/us/app/clickipo-invest-in-ipos/id1236247345" className="smartbanner-button"><span className="smartbanner-button-text">View</span></a></div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    (isAndroid) && 
                    <div>
                        {
                            (this.state.showBanner) &&
                            <div className="mysmartbanner mysmartbanner-android mysmartbanner-top">
                                <div className="smartbanner-container">
                                    <button type="button" className="smartbanner-close" aria-label="close" onClick={this.hideBanaer}>×</button><span className="smartbanner-icon" style={{ backgroundImage: 'url(' + AppIcon + ')'}}></span>
                                    <div className="smartbanner-info">
                                        <div className="smartbanner-title">ClickIPO - Invest in IPOs</div>
                                        <div className="smartbanner-author"></div>
                                        <div className="smartbanner-description">Free - In Google Play</div>
                                    </div>
                                    <div className="smartbanner-wrapper"><a href="http://play.google.com/store/apps/details?id=com.clickipo.clickipo" className="smartbanner-button"><span className="smartbanner-button-text">View</span></a></div>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="container-fluid signin-content">
                    <div className="row">
                        <div className="col-md-7 col-lg-8">

                            {(!this.state.isDspLogin) &&<div className="signin-company-logo">
                                <img src={CompanyLogo} alt="" />
                            </div>}
                            {(this.state.isDspLogin) && <div className="signup-company-logo-dsp">
                                <img src={CompanyLogo} alt="" />
                            </div>}


                            { (!this.state.isDspLogin) && <div className="signin-platform-mockup">
                                  <img src={IsoMockups} alt="" />
                            </div>}
                            { (this.state.isDspLogin) && <div className="signup-platform-mockup-dsp">
                                <p className="signup-platform-mockup-dsp-welcome-text">Welcome to the Directed Share Program of {this.state.dspOfferingName} offering</p>
                                <img src={this.state.dspOfferingLogo} alt="" />
                                <p className="signup-platform-mockup-dsp-description">{this.state.dspOfferingDescription}</p>
                            </div>}

                        </div>
                        <div className="col-md-5 col-lg-4">
                            <div className="login_content_inner_body">
                                <div className="login_content_inner_body_inner">
                                    <div className="top-border"></div>
                                    <div className="login_heading">
                                        Login
                                    </div>
                                    <div className="form_content_login">
                                        {(!this.state.isDspLogin) && <LoginForm handleFormSubmit={(data) => { this.handleSubmit(data) }} />}
                                        {(this.state.isDspLogin) && <LoginForm handleFormSubmit={(data) => { this.handleSubmit(data) }} initialValues={{email: this.state.userEmail }} />}
                                    </div>
                                </div>
                            </div>
                            <div className="login_content_inner_body_bottom">
                                <div className="not_account_contnet">
                                    Don't have an account? <Link to="/signup">
                                        <span className="signup_link"> Sign up</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="login_content_form_getapp">
                                <div className="getapp_title">
                                    <p>Get the app!</p>
                                </div>
                                <div className="row app-download-box">
                                    <div className="col-6">
                                        <div className="ios-app-image">
                                            <a href="https://itunes.apple.com/app/apple-store/id1236247345?pt=118497532&ct=cipo_desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src={IosAppImg} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="android-app-image">
                                            <a href="https://play.google.com/store/apps/details?id=com.clickipo.clickipo&utm_source=ClickIPO&utm_medium=website&utm_campaign=cipo_desktop" target="_blank" rel="noopener noreferrer"><img src={AndroidAppImg} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

LoginComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    // getProfile: PropTypes.func,
    loginRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    loginRes: doLoginRes
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(submit_login(data)),
        // getProfile: () => dispatch(submit_profile()),
		sendFirebaseToken: () => dispatch(sendFirebaseToken()),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LoginComponent);
