
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDapZ2JqwzoD-XxUSnkqYBEo0dhozQrejQ",
    authDomain: "clickipo-production.firebaseapp.com",
    databaseURL: "https://clickipo-production.firebaseio.com",
    projectId: "clickipo-production",
    storageBucket: "clickipo-production.appspot.com",
    messagingSenderId: "365778517706",
    appId: "1:365778517706:web:770549d1e52adc30dabe33"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const requestForToken = async () => {
  try {
        const currentToken = await getToken(messaging, { vapidKey: 'BCuJlGDvUWxC34gd_croQ3UggrNmpNzNb7z5Y_xk18dE2rznye4nkqnVm8DKL7_EnpfKwRgJdfNeKjAlhLer8wM' });
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            localStorage.setItem('fcmToken', currentToken);
        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
        console.log("payload", payload)
      resolve(payload);
    });
});