
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './passwordReset.scss';
import { reset_password, doResetpasswordRes } from '../../actions/resetPasswordActions';
import ResetPasswordForm from './resetpasswordForm/resetpasswordForm';
import { ToastContainer } from 'react-toastify';
import CompanyLogo from '../../assets/images/clickipo-logo-tagline.png';
import IsoMockups from '../../assets/images/ipo-platform-mockups.png';
import IosAppImg from '../../assets/images/download_clickipo_ios.png';
import AndroidAppImg from '../../assets/images/download_clickipo_android.png';

class ResetPasswordComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            value:'',
            resetpasswordtoken:'',
            code: '',
        }
    }

    componentDidMount() {
        let verifycode = this.props.location.state.code;
        this.setState({
            code : verifycode,
        });
    }

    handleSubmit(data){
        let sendRequest = data;
        // sendRequest.reset_password_token = this.state.resetpasswordtoken;
        sendRequest.code = this.state.code;
        this.props.handleFormSubmit(sendRequest);
    }

    render() {
        return (
            <div className="resetpassword-page">
                <div className="container-fluid resetpassword-content">
                    <div className="row">
                        <div className="col-md-7 col-lg-8">
                            <div className="resetpassword-company-logo">
                                <img src={CompanyLogo} alt="" />
                            </div>
                            <div className="resetpassword-platform-mockup">
                                <img src={IsoMockups} alt="" />
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4">
                            <div className="resetpassword_content_inner_body">
                                <div className="resetpassword_content_inner_body_inner">
                                    <div className="top-border"></div>
                                    <div className="resetpassword_heading">
                                        Reset Password
                                    </div>
                                    <div className="form_content_resetpassword">
                                        <ResetPasswordForm handleFormSubmit={(data) => { this.handleSubmit(data) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="resetpassword_content_form_getapp">
                                <div className="getapp_title">
                                    <p>Get the app!</p>
                                </div>
                                <div className="row app-download-box">
                                    <div className="col-6">
                                        <div className="ios-app-image">
                                            <a href="https://itunes.apple.com/app/apple-store/id1236247345?pt=118497532&ct=cipo_desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src={IosAppImg} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="android-app-image">
                                            <a href="https://play.google.com/store/apps/details?id=com.clickipo.clickipo&utm_source=ClickIPO&utm_medium=website&utm_campaign=cipo_desktop" target="_blank" rel="noopener noreferrer"><img src={AndroidAppImg} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}


ResetPasswordComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    resetpasswordRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    resetpasswordRes: doResetpasswordRes
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(reset_password(data))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ResetPasswordComponent);
