import * as types from './actionTypes';  
import commentOfferingApi from '../api/commentOfferingApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function doComment(data) {

  return {
    type: types.DO_COMMENT_OFFERING, 
    data
  };
}

export function doCommentRes(data) {  
  return {
    type: types.DO_COMMENT_OFFERING_RES, 
    data
  };
}

export function submitComment(data) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      commentOfferingApi.doComment(data).then(data => {
        dispatch(doCommentRes(data));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
  
}