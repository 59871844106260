import * as types from './actionTypes';
import SocialMediaAuthUrlApi from '../api/SocialMediaAuthUrlApi';
import { logout } from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function getSocialMediaAuthUrl(provider) {
  return {
    type: types.GET_SOCIAL_MEDIA_AUTH_URL,
    provider
  };
}

export function getSocialMediaAuthUrlRes(data) {
  return {
    type: types.GET_SOCIAL_MEDIA_AUTH_URL_RES,
    data
  }
}

export function get_social_media_auth_url(provider) {
  const TOKEN = getItem('auth_token');
  if (TOKEN) {
    return function(dispatch) {
      SocialMediaAuthUrlApi.getSocialMediaAuthUrl(provider).then(data => {
        dispatch(getSocialMediaAuthUrlRes(data));
        if (data.error) {
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        return error;
      });
    }
  } else {
    logout();
  }
}