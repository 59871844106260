import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './offeringList.css';
import { loadScript } from '../../helper/helper';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { fetchOfferingList, doOfferingListRes } from '../../actions/offeringListAction';
import clickipoLogo from '../../assets/images/images/clickipo_logo.png';

class OfferingListComponent extends React.PureComponent {

	constructor() {
		super();
		this.state = {
			offeringList: [],
			globalFilterOrder: '',
		}
		this.priceRangeAction = this.priceRangeAction.bind(this);
	}

	componentDidMount() {
		loadScript();
		this.props.fetchOffering();
	}

	//  ==================== get Derived State From Props ===================

	static getDerivedStateFromProps(props, state) {
		if (props.getofferingListRes) {
			if (props.getofferingListRes.data && props.getofferingListRes.data.offeringList) {
				return {
					offeringList: props.getofferingListRes.data.offeringList,
				};
			}
		}
		return null
	}
	priceRangeAction(rowData) {

		if (rowData.offering_type_name !== 'IPO') {
			return (<div>${rowData.max_price}</div>);
		}
		if (rowData.max_price === null || rowData.max_price < 1) {
			return (<div></div>);
		} else if (rowData.min_price === null || rowData.min_price < 1) {
			return (<div></div>);
		} else {
			return (<div>${rowData.min_price} - ${rowData.max_price}</div>);
		}
	}
	render() {
		const { offeringList } = this.state;

		let offData = offeringList;
		offData = offData.sort((a, b) => (a.trade_date < b.trade_date ? -1 : a.trade_date > b.trade_date ? 1 : 0));
		const Header = (<div className="offer_head">Offering List</div>);
		return (
			<div className="offering-list_page" header={Header}>
				<div className="container-fluid offering-list_header">
					<div className="clickipo_ipo">
						<img src={clickipoLogo} alt="" />
					</div>
				</div>
				<div className="container-fluid offering-list_content">
					<div className="row">
						<div className="col-12 col-md-12 offering-list-datatable">
							<div className="row" style={{ marginBottom: 20 }}>
								<div className="col-md-3"></div>
								<div className="col-md-6 text-center"><h3 style={{ color: '#c8c8c8' }}>Offering List</h3></div>
								<div className="col-md-3">
									<div className="w-100 searchbox-offering-list">
										<input className="form-control" placeholder="Search" name="srch-term" id="srch-term" type="text" onInput={(e) => this.setState({ globalFilterOrder: e.target.value })} />
									</div>
								</div>
							</div>
							<DataTable value={offData} globalFilter={this.state.globalFilterOrder} responsive scrollable={true} scrollHeight="calc(100vh - 260px)" emptyMessage="No data found">
								<Column className="tableCols" field="name" header="Name" sortable />
								<Column className="tableCols" field="ticker_symbol" header="Ticker" sortable />
								<Column className="tableCols" field="offering_type_name" header="Type" sortable />
								<Column className="tableCols" field="trade_date" header="Trade Date" sortable />
								<Column className="tableCols" field="price_range" header="Price" sortable />
								<Column className="tableCols" field="final_shares" header="Listed Shares" sortable />
							</DataTable>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

OfferingListComponent.propTypes = {
	getofferingListRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	getofferingListRes: doOfferingListRes,
});

function mapDispatchToProps(dispatch) {
	return {
		fetchOffering: () => dispatch(fetchOfferingList()),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingListComponent);
