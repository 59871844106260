import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import './forgetPassword.scss';
import PropTypes from 'prop-types';
import ForgotpasswordForm from './forgetpasswordForm/forgetPasswordForm';
import { submit_forgotpassword, doForgotpasswordRes } from '../../actions/forgotPasswordActions';
import { ToastContainer } from 'react-toastify';
import { loadScript } from '../../helper/helper';
import CompanyLogo from '../../assets/images/clickipo-logo-tagline.png';
import IsoMockups from '../../assets/images/ipo-platform-mockups.png';
import IosAppImg from '../../assets/images/download_clickipo_ios.png';
import AndroidAppImg from '../../assets/images/download_clickipo_android.png';
class ForgetPasswordComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            userEmail: '',
        }

        this.goToCode  = this.goToCode.bind(this);
    }

    componentDidMount() {
        loadScript();
    }
    static getDerivedStateFromProps(props, state) {
		if (props.forgotpasswordRes) {
            if (props.forgotpasswordRes.data.forgotPassword && props.forgotpasswordRes.data.forgotPassword.data) {
                if (props.forgotpasswordRes.data.forgotPassword.data.message === "Success") {
                    props.history.push({
                        pathname: '/password-reset',
                        state: { state :state.userEmail }
                      });
                }
            }
        }
		return null
	}

    handleSubmit(data) {
        if (data.email) {
            this.setState({
                userEmail: data.email,
            });
        }
        let sendRequest = {
            "email" : data.email
        }
        this.props.handleFormSubmit(sendRequest);
    };
    
    goToCode() {
        this.props.history.push('/password-reset');
    }

    render() {
        return (
            <div className="forgot-page">
                <div className="container-fluid forgot-content">
                    <div className="row">
                        <div className="col-md-7 col-lg-8">
                            <div className="forgot-company-logo">
                                <img src={CompanyLogo} alt="" />
                            </div>
                            <div className="forgot-platform-mockup">
                                <img src={IsoMockups} alt="" />
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4">
                            <div className="forgot_content_inner_body">
                                <div className="forgot_content_inner_body_inner">
                                    <div className="top-border"></div>
                                    <div className="forgot_heading">
                                        Forgot Password
                                    </div>
                                    <div className="form_content_forgot">
                                        <ForgotpasswordForm handleFormSubmit={(data) => { this.handleSubmit(data) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="forgot_content_inner_body_bottom">
                                <div className="not_account_contnet">
                                Have a code?<Link to="/password-reset">
                                        <span className="signup_link"> Click here</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="forgot_content_form_getapp">
                                <div className="getapp_title">
                                    <p>Get the app!</p>
                                </div>
                                <div className="row app-download-box">
                                    <div className="col-6">
                                        <div className="ios-app-image">
                                            <a href="https://itunes.apple.com/app/apple-store/id1236247345?pt=118497532&ct=cipo_desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src={IosAppImg} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="android-app-image">
                                            <a href="https://play.google.com/store/apps/details?id=com.clickipo.clickipo&utm_source=ClickIPO&utm_medium=website&utm_campaign=cipo_desktop" target="_blank" rel="noopener noreferrer"><img src={AndroidAppImg} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}


ForgetPasswordComponent.propTypes = {
    handleFormSubmit: PropTypes.func,
    forgotpasswordRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    forgotpasswordRes: doForgotpasswordRes
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(submit_forgotpassword(data))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ForgetPasswordComponent);
