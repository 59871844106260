// export const BASE_URL = 'https://api-staging.clickipo.com';
// export const BASE_URL = 'https://api-dev.clickipo.com';
// export const BASE_URL = 'http://54.188.186.125:8000';
//export const BASE_URL = 'http://54.188.186.125:8002';
//export const BASE_URL = "https://admin-api.clickipo.com";
//export const BASE_URL = "https://adm-api2.clickipo.com";
export const BASE_URL = 'https://api.clickipo.com';
export const PDF_FINRARULE_ZERO = 'https://cdn.clickipo.com/assets/finrarules/FINRARule5130.html';
export const PDF_FINRARULE_ONE = 'https://cdn.clickipo.com/assets/finrarules/FINRARule5131.html';
export const PDF_FINRARULE_BOTH = 'https://cdn.clickipo.com/assets/finrarules/FINRARule51305131.html';
export const SCPACCOUNTOPENURL = 'https://just2trade.github.io/docs/?shell#application';
