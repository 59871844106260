import Swal from 'sweetalert2';
export const errorHandling = (response) => {
    if (response.status === 401) {
        localStorage.clear();
        window.location.href = '/';
    }
    if (response.status === 500) {
        Swal.fire({
            title: "Internal Server Error",
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
        })
        return true;
    }
    
}
export const errorHandler = (error) => {
    if (window.navigator.onLine) {
        Swal.fire({
            title: "Oops! something went wrong, we are working on it. Please try after sometime",
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
        })
    } else {
        Swal.fire({
            title: "Please check your internet connection",
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
        })
    }
}