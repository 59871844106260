import * as types from './actionTypes';  
import connectDealerAccountApi from '../api/connectDealerAccountApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';

export function doDealerConnectAccount(data) {

  return {
    type: types.DO_BROKERDEALER_CONNECT_ACCOUNT, 
    data
  };
}

export function doDealerConnectAccountRes(data) {  
  return {
    type: types.DO_BROKERDEALER_CONNECT_ACCOUNT_RES, 
    data
  };
}

export function broker_delaer_connect_account(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      connectDealerAccountApi.doDealerConnectAccount(data).then(res => {
        dispatch(doDealerConnectAccountRes(res));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}