import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { linkedin_account, doLinkedinAccountRes } from '../../actions/linkedInActions';
import { linkedin_user_account_data, doLinkedinUserAccountDataRes } from '../../actions/linkedInActions';
import { social_login, dosocialLoginRes } from '../../actions/socialLoginActions';

class LinkedInComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            linkedincallbackUrl: 'http://localhost:3000/linkedin',
            // linkedinclient_id: '81ahse79n8o48v',
            // linkedinSecret:  'yQ4k3k2aDBirX0vm',
            provider: 'linkedin',
            code: '',
            user_id:'',
            last_refresh_time: '',
            expiration_time: '',
            linkedintoken:''
        }
    }


    componentDidMount(){
        const data = {}
        this.props.linkedinuseraccountdata(data);
    }

    confirmlinkedinlogin(){
        this.props.history.push('/socialmedia');
    }

    static getDerivedStateFromProps(props, state) {
		if (props.LinkedinUserAccountDataRes) {
            if (props.LinkedinUserAccountDataRes.data && props.LinkedinUserAccountDataRes.data.linkedinuserdata) {
                return {
                    user_id: props.LinkedinUserAccountDataRes.data.linkedinuserdata.id
                };
            }
        }
		return null
	}
    render() {

        const Header = (<div className="offer_head">LinkedIn</div>);
        return (
            <div title="ClickIPO User" header={Header} >
                <div className="edit_profile_form_fields_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 style={{textAlign:'center'}}>Are you sure you want to add your account with LinkedIn</h3>
                            </div>
                            <div className="col-md-6">
                                <button type="submit" className="green_btn add_stock_account" onClick={() => this.confirmlinkedinlogin()}>
                                    Yes
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button type="submit" className="green_btn add_stock_account not_link">
                                    <Link to ={"/socialmedia"}>No</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LinkedInComponent.propTypes = {
    linkedInAccountRes: PropTypes.any,
    LinkedinUserAccountDataRes: PropTypes.any,
    socialLoginRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    linkedInAccountRes: doLinkedinAccountRes,
    LinkedinUserAccountDataRes:doLinkedinUserAccountDataRes,
    socialLoginRes: dosocialLoginRes
});

function mapDispatchToProps(dispatch) {
    return {
        linkedinAccount: (data) => dispatch(linkedin_account(data)),
        linkedinuseraccountdata: (data) => dispatch(linkedin_user_account_data(data)),
        sociallogin: (data) => dispatch(social_login(data))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LinkedInComponent);