import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import './brokerDealer.css';
import Dashboard from '../../component/Dashboard';
import { broker_dealer, doBrokerdelarRes } from '../../actions/brokerDealerActions';
import { delete_broker_dealer_connection, doBrokerDealerDisconnectRes } from '../../actions/brokerDealerDisconnectionActions';
import { get_accounts_buying_power, doAccountsBuyingPowerRes } from '../../actions/accountsBuyingPowerActions';
import { submit_profile, doEditprofileRes } from '../../actions/editProfileActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { numberWithCommas, loadScript } from '../../helper/helper';
import PlaceHolderImg from '../../assets/images/placeholder_company_thumb.png';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DOMPurify from 'dompurify';

const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(),
		alignItems: 'center',
		justifyContent: 'center'
	},
}))(MuiDialogActions);
let isBD = false;
let isProfile = false;
let isDeleteAcc = false;
let isBuyingPower = false;
class BrokerDealerComponent extends React.PureComponent {

    constructor() {
        super();
        isBD = false;
        isProfile = false;
        isDeleteAcc = false;
        isBuyingPower = false;
        this.state = {
            isLoader: true,
            brokerdealerList: '',
            isConnections: '',
            open: false,
            buying_power: '',
            brokerconnectionList: null,
            activeaccountsList:'',
            mpid: '',
            accountId: '',
            accountsBuyingPower: '',
            screenId: '1.1',
            displayText: ''
        }
    }

    componentDidMount() {
        loadScript();
        isBD = true;
        isBuyingPower = true;
        this.props.getBrokerdealer();
        setTimeout(() => {
            isProfile = true;
            // this.props.getProfile();
        }, 500);
        localStorage.removeItem('broker_dealer_details');
        const activityData = {
            'screen_id': this.state.screenId,
            'source': 'web'
        }
        this.props.userActivity(activityData)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.BrokerdelarRes) {
            if (props.BrokerdelarRes.data && props.BrokerdelarRes.data.brokerdealer) {
                if (props.BrokerdelarRes.data.brokerdealer.message === 'Success' && isBD) {
                    isBD = false;
                    if (props.BrokerdelarRes.data.brokerdealer.data.data.length > 0) {
                        return{
                            brokerdealerList: props.BrokerdelarRes.data.brokerdealer.data.data,
                            displayText: props.BrokerdelarRes.data.brokerdealer.data.display_text
                        }
                    }
                }
            }
        }
        
        // if (props.EditprofileRes) {
        //     if (props.EditprofileRes.data && props.EditprofileRes.data.editprofile) {
        //         if (props.EditprofileRes.data.editprofile.message === 'Success' && isProfile) {
        //             isProfile = false;
        //             if(props.EditprofileRes.data.editprofile.data.broker_connection !== null){
        //                 if(props.EditprofileRes.data.editprofile.data.broker_connection.broker_dealer_name){
        //                     return {
        //                         brokerconnectionList: props.EditprofileRes.data.editprofile.data.broker_connection,
        //                         mpid: props.EditprofileRes.data.editprofile.data.broker_connection.mpid,
        //                         activeaccountsList: props.EditprofileRes.data.editprofile.data.broker_connection,
        //                         accountId: props.EditprofileRes.data.editprofile.data.broker_connection.account_id,
        //                         isConnections: true,
        //                         userList: props.EditprofileRes.data.editprofile.data,
        //                     }
        //                 }
        //             } else if(props.EditprofileRes.data.editprofile.data.broker_connection === null){
        //                 return {
        //                     isConnections: false,
        //                     userList: props.EditprofileRes.data.editprofile.data,
        //                 }
        //             }
        //         }
        //     } 
        // }
        if (props.brokerdealerdeleteaccountRes) {
            if (props.brokerdealerdeleteaccountRes.data && props.brokerdealerdeleteaccountRes.data.brokerdealerdisconnect) {
                if(props.brokerdealerdeleteaccountRes.data.brokerdealerdisconnect.message === "Success" && isDeleteAcc){
                    isDeleteAcc = false;
                    return {
                        isConnections: false,
                        brokerconnectionList: null
                    }
                }
            }
        }
        if (props.accountsBuyingPowerRes) {
            if (props.accountsBuyingPowerRes.data && props.accountsBuyingPowerRes.data.accountsbuyingpower) {
                if (props.accountsBuyingPowerRes.data.accountsbuyingpower.message === 'Success' && isBuyingPower) {
                    isBuyingPower = false;
                    return {
                        accountsBuyingPower: Number(props.accountsBuyingPowerRes.data.accountsbuyingpower.data.buying_power).toFixed(2),
                    };
                }
            }
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.EditprofileRes) {
            if (prevProps.EditprofileRes.data && prevProps.EditprofileRes.data.editprofile) {
                if (prevProps.EditprofileRes.data.editprofile.message === 'Success' && isProfile) {
                    isProfile = false;
                    if(prevProps.EditprofileRes.data.editprofile.data.broker_connection !== null){
                        if(prevProps.EditprofileRes.data.editprofile.data.broker_connection.broker_dealer_name){
                            this.setState({
                                brokerconnectionList: prevProps.EditprofileRes.data.editprofile.data.broker_connection,
                                mpid: prevProps.EditprofileRes.data.editprofile.data.broker_connection.mpid,
                                activeaccountsList: prevProps.EditprofileRes.data.editprofile.data.broker_connection,
                                accountId: prevProps.EditprofileRes.data.editprofile.data.broker_connection.account_id,
                                isConnections: true,
                            })
                        }
                    } else if(prevProps.EditprofileRes.data.editprofile.data.broker_connection === null){
                        this.setState({
                            isConnections: false,
                        })
                    }
                }
            } 
        }
    }
    addDefaultSrc(ev) {
        ev.target.src = PlaceHolderImg;
    }


    onCloseModal = () => {
        this.setState({ open: false });
    };

    disconnectAccount() {
        this.setState({ open:true })
    }

    confirmDelete(){
        const deleteAccount = {
            "mpid": this.state.mpid,
            "account_id": this.state.accountId
        }
        isDeleteAcc = true;
        this.props.deleteBrokerDealerAccount(deleteAccount);
        this.setState({ open: false });
    }

    cancelDelete(){
        this.setState({ open: false });
    }
    goToCreateBroker = () => {
        this.props.history.push('/create-brokerage')
    }
    goToBrokerage = (data) => {
        // if (data.name === 'ScorePriority') {
        //     this.props.history.push({
        //         pathname: `/create-brokerage`,
        //         state: data
        //     });
        // } else {
        //     this.props.history.push({
        //         pathname: `/brokeragefirms/${data.mpid}`,
        //         state: data
        //     });
        // }
        this.props.history.push({
            pathname: `/brokeragefirms/${data.mpid}`,
            state: data
        });
    }
    render() {
        const { open } = this.state;
        const buyingPower = '$ ' + this.state.accountsBuyingPower;
        return (
            <Dashboard title="ClickIPO - Broker Dealer"  pageTitle="Broker Dealer" >
                {/* <div className="row">
                    <div className="col-md-12" style={{textAlign: 'right'}}>
                        <button className="btn btn-primary" style={{ width: 200, marginBottom: 10 }} onClick={() => this.goToCreateBroker()}>Create brokerage</button>
                    </div>
                </div> */}
                {
                    (this.state.isConnections && this.state.accountsBuyingPower && this.state.brokerconnectionList !== null) && 
                    <div className="brokerage_content">
                        <div className="ipowork_inner_content">
                            <div className="brokerage_content_box_inner">
                                <p className="broker_dealer_name">
                                    {this.state.brokerconnectionList.broker_dealer_name}
                                </p>
                                <div className="row ml-mr-0px-imp">
                                    <div className="col-xs-12 col-sm-8 col-md-8 col-xl-9 brokerage_content_box_inner_box">
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="accounts">Account Number :</p>
                                            </div>
                                            <div className="col col-6 account_type_firms">
                                                {this.state.activeaccountsList.account_name}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="accounts">Account Type :</p>
                                            </div>
                                            <div className="col col-6 account_type_firms">
                                                {this.state.activeaccountsList.account_type}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="ipo_buying_power">IPO/Follow-on Buying Power :</p>
                                            </div>
                                            <div className="col col-6 account_type_firms">
                                                {numberWithCommas(buyingPower)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-4 col-md-4 col-xl-3">
                                        <div className="broker_dealer_connect_button_wrapper">
                                            <div className="offering_button">
                                                <Link to={{
                                                    pathname: "/offering",
                                                    DSPView: true
                                                }}>
                                                
                                                    <button type="submit" className="green_btn">
                                                        Go to Offerings
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="connect_disconnect">
                                                <button type="submit" className="red_btn" onClick={() => this.disconnectAccount()}>
                                                    Disconnect
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Dialog
                            open={open}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                <h5> Disconnecting your account will cancel all pending orders!! Are you sure you want to disconnect account ?</h5>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.confirmDelete()}>
                                        Ok
                                    </Button>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.cancelDelete()}>
                                        Cancel
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
                {
                    (!this.state.isConnections) &&  (this.state.brokerconnectionList === null) &&
                    <div className="broker_dealer_content">
                        <p className="brokerage_text">To participate in offerings, you must connect with a supported brokerage account listed below.</p>
                        {
                            this.state.brokerdealerList && this.state.brokerdealerList.length > 0 && this.state.brokerdealerList.map((dealeritem, i) =>
                                <div className="left_trade_station_box_inner" key={i}>
                                    {/* <Link to={{ pathname: `/brokeragefirms/${dealeritem.mpid}`, state: dealeritem }}> */}
                                    <div onClick={() => this.goToBrokerage(dealeritem)}>
                                        <div className="row ml-mr-0px-imp">
                                            <div className="col-md-3 col-xs-6 p-l-2 p-t-2">
                                                <div className="logo_company">
                                                    <img src={dealeritem.logo_small} onError={this.addDefaultSrc} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xs-6 p-l-2 p-t-2">
                                                <p className="name_company">
                                                    {dealeritem.name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            ) 
                        }
                        {
                            this.state.displayText !== '' &&
                            <div className="display-text-style" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.displayText)}}></div>
                        }
                    </div>
                }
            </Dashboard>
        )
    }
}

BrokerDealerComponent.propTypes = {
    BrokerdelarRes: PropTypes.any,
    brokerdealerdeleteaccountRes: PropTypes.any,
    EditprofileRes: PropTypes.any,
    accountsBuyingPowerRes: PropTypes.any,
    userActivityRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    BrokerdelarRes: doBrokerdelarRes,
    brokerdealerdeleteaccountRes: doBrokerDealerDisconnectRes,
    EditprofileRes: doEditprofileRes,
    accountsBuyingPowerRes: doAccountsBuyingPowerRes,
    userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
    return {
        getBrokerdealer: (data) => dispatch(broker_dealer(data)),
        deleteBrokerDealerAccount: (id) => dispatch(delete_broker_dealer_connection(id)),
        getProfile: () => dispatch(submit_profile()),
        accountsBuyingPower: (data) => dispatch(get_accounts_buying_power(data)),
        userActivity: (data) => dispatch(user_activity(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BrokerDealerComponent);
