import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';
import { doLoginRes } from '../../../actions/loginActions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const fields = ['email', 'encrypted_password'];
const lower = value => value && value.toLowerCase();

const renderField = ({ disabled, input, label, type, meta: { touched, error } }) => (
    <div>
        <div>
            {! disabled && <input className="form-control" {...input} placeholder={label} type={type} />}


           { disabled && <input className="form-control" {...input} placeholder={label} type={type} disabled/>}
            {touched && error && <span>{error}</span>}
        </div>
    </div>
)

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Required'
    }
    if (!values.encrypted_password) {
        errors.encrypted_password = 'Required'
    }
    if (values.encrypted_password) {
        if (values.encrypted_password.search(/^\S+$/)) {
            errors.encrypted_password = 'Your password must not contain any space.'
        }
        if (values.encrypted_password.length < 10) {
            errors.encrypted_password = 'Your password must be at least 10 characters'
        }
        if (values.encrypted_password.search(/[a-z]/i) < 0) {
            errors.encrypted_password = 'Your password must contain at least one letter.'
        }
    }
    return errors
}


class LoginForm extends React.PureComponent {

    constructor() {
        super();
        this.state = {
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }


    render() {
        const { handleSubmit, handleFormSubmit, pristine, submitting, initialValues } = this.props
        let emailReceived=''
        if(initialValues){
            emailReceived=initialValues.email;
        }
        return (
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="small-12 columns error_message form_field_wrapper email_feild_wrapper">
                    {emailReceived!=='' && <Field

                        name="email"
                        component={renderField}
                        label="Enter Email"
                        autoComplete="off"
                        type="email"
                        normalize={lower}
                        disabled= {true}
                        
                    />}
                   {emailReceived==='' && <Field
                        name="email"
                        component={renderField}
                        label="Enter Email"
                        autoComplete="off"
                        type="email"
                        normalize={lower}
                    />}
                </div>
                <div className="small-12 columns error_message form_field_wrapper password_feild_wrapper">
                    <Field
                        name="encrypted_password"
                        component={renderField}
                        label="Enter Password"
                        autoComplete="off"
                        type="password"
                    />
                    <div className="password-case-sensitive">( Passwords are case sensitive )</div>
                </div>
                
                <div>
                    <button type="submit" className="btn btn-primary login_button" disabled={pristine || submitting}>Login</button>
                </div>
                <div className="forget_password">
                    <Link to="/forgetpassword">
                        <span>Forgot Password?</span>
                    </Link>
                </div>
            </form>
        )
    }
}

LoginForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleFormSubmit: PropTypes.func,
    doLoginRes: PropTypes.any
};
const mapStateToProps = createStructuredSelector({
    doLoginRes: doLoginRes
});
  
function mapDispatchToProps(dispatch,ownProps) {
    return {
        
    };
}
const ReduxLoginForm = reduxForm({
    form: 'ReduxLoginForm',
    fields,
    validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(LoginForm)

export default connect(mapStateToProps, mapDispatchToProps)(ReduxLoginForm);
