import * as types from './actionTypes';  
import glossaryApi from '../api/glossaryApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function doGlossary(data) {

  return {
    type: types.DO_GLOSSARY, 
    data
  };
}

export function doGlossaryRes(data) {  
  return {
    type: types.DO_GLOSSARY_RES, 
    data
  };
}

export function glossary_list(data) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      dispatch(loadingRes(true));
      glossaryApi.doGlossary(data).then(data => {
        dispatch(doGlossaryRes(data));
        dispatch(loadingRes(false));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          });
        }
      }).catch(error => {
        dispatch(loadingRes(false));
        return error
      });
    };
  }else{
    logout()
  }
}