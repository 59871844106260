
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import SpinnImg from '../../assets/images/spinn_img.gif';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './sendsignupemail.scss';
import { submitResendEmail, doResendEmailRes } from '../../actions/resendEmailActions';
import { ToastContainer } from 'react-toastify';
import { loadScript } from '../../helper/helper';
import CompanyLogo from '../../assets/images/clickipo-logo-tagline.png';
import IsoMockups from '../../assets/images/ipo-platform-mockups.png';
import IosAppImg from '../../assets/images/download_clickipo_ios.png';
import AndroidAppImg from '../../assets/images/download_clickipo_android.png';

class SendSignupEmailComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            userEmail:'',
            spinner: false,
        }
        this.resend = this.resend.bind(this);
    }


    componentDidMount() {
        this.setState({
            userEmail: this.props.location.state.state,
        });
        loadScript();

    }

    static getDerivedStateFromProps(props, state) {
		if (props.resendEmailRes) {
            if (props.resendEmailRes.user && props.resendEmailRes.user.user) {
                if (props.resendEmailRes.user.user.message === "Success") {
                     return {
                        userEmail: '',
                        spinner: false,
                    };
                }
            }
        }
		return null
	}
    resend() {
        let request = {
            email: this.state.userEmail,
        }
        if (request.email !== '') {
            this.props.resendEmail(request);
            this.setState({
                spinner: true,
            });
        }
    }


    render() {
        return (
            <div className="sendsignupemail-page">
                <div className="container-fluid sendsignupemail-content">
                    <div className="row">
                        <div className="col-md-7 col-lg-8">
                            <div className="sendsignupemail-company-logo">
                                <img src={CompanyLogo} alt="" />
                            </div>
                            <div className="sendsignupemail-platform-mockup">
                                <img src={IsoMockups} alt="" />
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4">
                            <div className="sendsignupemail_content_inner_body">
                                <div className="sendsignupemail_content_inner_body_inner">
                                    <div className="top-border"></div>
                                    <div className="sendsignupemail_heading">
                                        Email Verify
                                    </div>
                                    <div className="form_content_sendsignupemail">
                                        <div style={{textAlign: 'center'}}>
                                            <img src="https://cdn.clickipo.com/assets/sent-email.png" style={{ width: 90, height: 55 }} alt=""></img>
                                        </div>
                                        <div className="email-verification-title">Please check your email...</div>
                                        <div className="email-verification-caption">You're just a click away from accessing upcoming IPOs</div>
                                        <div className="email-verification-caption">Simply click the confirmation link in the message we sent you and you're all set!</div>

                                        <div className="row mt-35px">
                                            <div className="col col-12 text-center" style={{ marginBottom: 20 }}>
                                                <button type="submit" className="resend-email-btn" onClick={() => {this.resend()}}>Resend verification email</button>
                                                {
                                                    this.state.spinner === true &&
                                                    <span><img src={SpinnImg} style={{width: 35}} alt="Loading..." /></span>
                                                }
                                            </div>
                                            <div className="col col-12" style={{textAlign: 'center'}}>
                                                <button className="btn sendsignupemail_button" onClick={()=>window.location.href='/'}>Go to Login</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sendsignupemail_content_form_getapp">
                                <div className="getapp_title">
                                    <p>Get the app!</p>
                                </div>
                                <div className="row app-download-box">
                                    <div className="col-6">
                                        <div className="ios-app-image">
                                            <img src={IosAppImg} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="android-app-image">
                                            <img src={AndroidAppImg} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}


SendSignupEmailComponent.propTypes = {
    resendEmailRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    resendEmailRes: doResendEmailRes,
});

function mapDispatchToProps(dispatch) {
    return {
        resendEmail: (data) => dispatch(submitResendEmail(data))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SendSignupEmailComponent);
