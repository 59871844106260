function isValidDate(dateString)
{
    // First check for the pattern
    if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
        return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if(year < 1000 || month === 0 || month > 12)
        return false;

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
}
export default function validate(values) {
    const errors = {};
    if (!values.first_name) {
        errors.first_name = 'Please enter first name';
    }
    if (!values.last_name) {
        errors.last_name = 'Please enter first name';
    }
    
    if (!values.date_of_birth) {
        errors.date_of_birth = 'Please choose date of birth';
    }
    if (values.date_of_birth) {
        let checkDate = isValidDate(values.date_of_birth);
        if (checkDate === false) {
            errors.date_of_birth = 'Please enter date of birth in correct format';
        }
    }
    if (values.date_of_birth) {
        let patternnn = /(([0-9]{1}[0-9]{1})|([1-9]{1}))([/]{1})(([0-9]{1}[0-9]{1})|([1-9]{1}))([/]{1})([0-9]{4})/
        if (!patternnn.test(values.date_of_birth)) {
            errors.date_of_birth = 'Please enter correct date';
        }
    }
    if (!values.residential_address_street) {
        errors.residential_address_street = 'Please enter residential address street';
    }
    if (!values.residential_address_city) {
        errors.residential_address_city = 'Please enter residential address city';
    }
    if (!values.residential_address_state) {
        errors.residential_address_state = 'Please enter residential address state';
    }
    if (!values.residential_address_zip) {
        errors.residential_address_zip = 'Please enter residential address zipcode';
    }
    if (!values.residential_address_country) {
        errors.residential_address_country = 'Please enter residential address country';
    }
    if (!values.mobile_phone) {
        errors.mobile_phone = 'Please enter mobile number';
    }
    if (!values.primary_email) {
        errors.primary_email = 'Please enter email address';
    }
    if (values.primary_email) {
        const re = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i);
        if (!re.test(values.primary_email)) {
            errors.primary_email = 'Please enter correct email address';
        }
    }
    if (values.secondary_email) {
        const re = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/i);
        if (!re.test(values.secondary_email)) {
            errors.secondary_email = 'Please enter correct email address';
        }
    }
    if (!values.business_phone) {
        errors.business_phone = 'Please enter business phone number';
    }
    if (!values.marital_status) {
        errors.marital_status = 'Please select marital status';
    }
    if (!values.ssn) {
        errors.ssn = 'Please enter ssn';
    }
    if (!values.citizenship_country) {
        errors.citizenship_country = 'Please enter citizenship country';
    }
    if (!values.ident_birthCountry) {
        errors.ident_birthCountry = 'Please enter birth country';
    }
    if (!values.empl_role) {
        errors.empl_role = 'Please enter employee role';
    }
    
    // if (!values.id_type) {
    //     errors.id_type = 'Please select id proof';
    // }
    // if (!values.id_number) {
    //     errors.id_number = 'Please enter id number';
    // }
    if (!values.status) {
        errors.status = 'Please select status';
    }
    // if (values.status === "employed" || values.status === "self_employed") {
    //     if (!values.job_title) {
    //         errors.job_title = 'Please enter job title';
    //     }
    //     if (!values.firm_name) {
    //         errors.firm_name = 'Please enter company name';
    //     }
    //     if (!values.nature) {
    //         errors.nature = 'Please enter nature';
    //     }
    //     if (!values.company_years) {
    //         errors.company_years = 'Please enter company years';
    //     }
    //     if (!values.employer_address_street) {
    //         errors.employer_address_street = 'Please enter employer address street';
    //     }
    //     if (!values.employer_address_city) {
    //         errors.employer_address_city = 'Please enter employer address city';
    //     }
    //     if (!values.employer_address_state) {
    //         errors.employer_address_state = 'Please enter employer address state';
    //     }
    //     if (!values.employer_address_zip) {
    //         errors.employer_address_zip = 'Please enter employer address zipcode';
    //     }
    //     if (!values.employer_address_country) {
    //         errors.employer_address_country = 'Please enter employer address country';
    //     }
    // }
    

    if (!values.number_of_dependents) {
        errors.number_of_dependents = 'Please enter number of dependents';
    }
    
    if (!values.annual_inc_min) {
        errors.annual_inc_min = 'Please select minimum annual income';
    }
    if (!values.annual_inc_max) {
        errors.annual_inc_max = 'Please select maximum annual income';
    }
    
    if (!values.tot_networth_min) {
        errors.tot_networth_min = 'Please select minimum net worth';
    }
    if (!values.tot_networth_max) {
        errors.tot_networth_max = 'Please select maximum net worth';
    }
    if (!values.liq_networth_min) {
        errors.liq_networth_min = 'Please select minimum liquid net worth';
    }
    if (!values.liq_networth_max) {
        errors.liq_networth_max = 'Please select maximum liquid net worth';
    }
    if (!values.tax_bracket) {
        errors.tax_bracket = 'Please select tax bracket';
    }
    
    // if (values.selectedFunding !== undefined) {
    //     if (values.selectedFunding.length === 0) {
    //         errors.selectedFunding = 'Please select funding source';
    //     }
    //     if (values.selectedFunding.length > 0) {
    //         for (let found of values.selectedFunding) {
    //             if (found.value === 'other') {
    //                 if (!values.funding_source_other) {
    //                     errors.funding_source_other = 'Please enter funding source other';
    //                 }
    //             }
    //         }
    //     }
    // }
    // if (!values.funding_source_other) {
    //     errors.funding_source_other = 'Please enter funding source other';
    // }


    // if (!values.knowledge) {
    //     errors.knowledge = 'Please select knowledge';
    // }
    if (!values.risk_tolerance) {
        errors.risk_tolerance = 'Please select risk tolerance';
    }
    if (!values.liquidity_needs) {
        errors.liquidity_needs = 'Please select liquidity needs';
    }
    if (values.selectedObjectives !== undefined) {
        if (values.selectedObjectives.length === 0) {
            errors.selectedObjectives = 'Please select Objectives';
        }
    }
    if (!values.time_horizon) {
        errors.time_horizon = 'Please select time horizon';
    }
    if (!values.inv_experience) {
        errors.inv_experience = 'Please enter investment experience';
    }
    if (!values.prefix) {
        errors.prefix = 'Please enter prefix';
    }
    // if (!values.options) {
    //     errors.options = 'Please select options experience';
    // }
    if (!values.file) {
        errors.file = 'Please choose file';
    }
    // if (!values.comment) {
    //     errors.comment = 'Please enter comment';
    // }
    return errors;
}