import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/clickipo_logo.png';
import MainMenu from './MainMenu';
// import Tooltip from '@material-ui/core/Tooltip';
import styles from './sidebar-jss';
import { getItem } from '../../utils/localStore';
const package_json = require('../../../package.json');
let userName = getItem('userName');
class SidebarContent extends React.Component {
  
  state = {
    transform: 0,
    lastloginTime: null,
  };

  componentDidMount = () => {
    // Scroll content to top
    const mainContent = document.getElementById('sidebar');
    mainContent.addEventListener('scroll', this.handleScroll);
    let lastlogin = getItem('last_login_time');
    this.setState({
      lastloginTime: lastlogin,
      isLoader: true,
      // profileDetails: this.props.profileDetails !== '' ? this.props.profileDetails : userName
    })
  }

  componentWillUnmount() {
    const mainContent = document.getElementById('sidebar');
    mainContent.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    const scroll = event.target.scrollTop;
    this.setState({
      transform: scroll
    });
  }

  render() {
    const {
      classes,
      turnDarker,
      drawerPaper,
      toggleDrawerOpen,
      leftSidebar,
      isLogin,
    } = this.props;
    const { transform } = this.state;
    return (
      <div className={classNames(classes.drawerInner, !drawerPaper ? classes.drawerPaperClose : '')}>
        <div className={classes.drawerHeader}>
          <NavLink to="/app" className={classNames(classes.brand, classes.brandBar, turnDarker && classes.darker)}>
            <img src={logo} alt={'logo'} />
          </NavLink>
          {isLogin && (
            <div
              className={classNames(classes.profile, classes.user)}
              style={{ opacity: 1 - (transform / 100), marginTop: transform * -0.3 }}
            >
              
              <div style={{width: '95%'}}>
                <h4>{this.props.profileDetails !== '' ? this.props.profileDetails : userName}</h4>
                {/* <Tooltip title={this.state.advisorEmail} placement="bottom">
                  <h4 style={{cursor: 'pointer'}}>{this.state.advisorEmail}</h4>
                </Tooltip> */}
              </div>
            </div>
          )}
        </div>
        <div
          id="sidebar"
          className={
            classNames(
              classes.menuContainer,
              leftSidebar && classes.rounded,
              isLogin && classes.withProfile
            )
          }
        >
          <MainMenu toggleDrawerOpen={toggleDrawerOpen} />
          <div style={{textAlign: 'center', padding: '5px 15px'}}>Last Login : <br/> {(this.state.lastloginTime === null || this.state.lastloginTime === 'null') ? 'Never' : this.state.lastloginTime + " ET"}</div>
          <div style={{textAlign: 'center', padding: '5px 15px'}}>Version : {package_json.version}</div>
        </div>
      </div>
    );
  }
}

SidebarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerPaper: PropTypes.bool.isRequired,
  turnDarker: PropTypes.bool,
  toggleDrawerOpen: PropTypes.func,
  leftSidebar: PropTypes.bool.isRequired,
  isLogin: PropTypes.bool,
  profileDetails: PropTypes.any,
};

SidebarContent.defaultProps = {
  turnDarker: false,
  toggleDrawerOpen: () => {},
  anchorEl: null,
  isLogin: true,
  leftSidebar: true
};

export default withStyles(styles)(SidebarContent);
