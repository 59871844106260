import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import SelectReact from 'react-select';
import './createForm.scss';
import validate from './createFormValidation';
import validateFinal from './createFormValidationFinal';
import { checkPlannerApp, doCheckPlannerAppRes } from '../../../actions/checkPlannerApplicationActions';
import { createPlannerUserAcc, doCreatePlannerUserAccRes } from '../../../actions/plannerUserAccountActions';
import { createPlannerTradingAcc, doCreatePlannerTradingAccRes } from '../../../actions/plannerTradingAccountActions';
import { uploadPlannerFile, fileUploadRes } from '../../../actions/fileUploadForPlannerActions';
import { dateFormatUS } from '../../../helper/helper';
import { timeHorizonOptions, liquidityNeedsOptions, catAccountholderTypeOptions, accountTypeOptions,  customerTypeOption, sweepInstructionsOption, dividendReinvestmentOption, riskToleranceOptions, objectivesOptions, salutationOptions, suffixOptions, empStatusOptions, maritalStatusOptions } from './enum';
import { Calendar } from 'primereact/components/calendar/Calendar';
import CountryList from './countryList.json';
import UsersCountryList from './userCountryList.json';
import StateList from './stateList.json';
// import spinnImg from '../../../assets/images/loader-example.gif';
import NumberFormat from 'react-number-format';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
});
let isCheckPlannerApp = false;
let isCreatePlannerUser = false;
let isCreatePlannerTrading = false;
let isUploadFile = false;
class CreateFormComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        isCheckPlannerApp = false;
        isCreatePlannerUser = false;
        isCreatePlannerTrading = false;
        isUploadFile = false;
        const today = new Date();
        const month = today.getMonth();
        const year = today.getFullYear();
        const prevMonth = (month === 0) ? 11 : month - 1;
        const prevYear = (prevMonth === 11) ? year - 1 : year;

        const minDate = new Date();
        minDate.setMonth(prevMonth + 1);
        minDate.setFullYear(prevYear);

        this.state = {
            isLoading: false,
            profileData: '',
            brokerdealerdetails: '',
            isSubmited: false,
            isSubmitedFinal: false,
            email: '',
            password: '',
            address1: '',
            address2: '',
            country: 'UnitedStates',
            state: '',
            city: '',
            code: '',
            FirstName: '',
            Middle: '',
            LastName: '',
            Login: '',
            Salutation: '',
            Suffix: '',
            PrimaryPhone: '',
            SecondaryPhone: '',

            
            timeHorizon: '',
            liquidityNeeds: '',
            catAccountholderType: '',
            accountType: '',
            principalApprover: '',
            customerType: '',
            fdid: '',
            registeredRepApprover: '',
            sweepInstructions: '',
            householdingService: '',
            dividendReinvestment: '',
            dividendProceedsSendFrequency: '',
            securitySaleProceeds: '',
            issuerDirectCommunication: '',
            dividendProceeds: '',
            investmentExperience: '',
            investmentObjective: '',
            riskTolerance: '',
            federalTaxBracketPercent: '',
            annual_inc_max: '',
            annual_inc_min: '',
            liq_networth_max: '',
            liq_networth_min: '',
            tot_networth_max: '',
            tot_networth_min: '',
            largeTraderId: '',

            numDependents: '',
            socialSecurityNumber: '',
            birthCountry: '',
            dateOfBirth: '',
            citizenshipCountry: '',
            isForeign: '',
            prefix: '',
            
            userFirstName: '',
            userLastName: '',
            primaryEmail: "",
            secondaryEmail: "",
            mailingCountry: '',
            mailingStreetAddress: '',
            mailingCity: '',
            mailingState: '',
            mailingPostalCode: '',
            phoneNumber: '',
            homeCountry: '',
            homeStreetAddress: '',
            homeCity: '',
            homeState: '',
            homePostalCode: '',
            isPoliticallyExposed: '',
            firmName: '',
            isControlPerson: '',
            isAffiliatedExchangeOrFINRA: '',
            yearsEmployed: 0,
            employer: '',
            positionEmployed: '',
            employmentStatus: '',
            businessCountry: '',
            businessStreetAddress: '',
            businessCity: '',
            businessState: '',
            businessPostalCode: '',
            businessPhoneNumber: '',
            maritalStatus: '',
            isTradeAuthorization: '',
            showUserAccount: false,
            showTradingAccount: false,
            userdata: '',
            isMailingAddress: false,
            errormsg: null,
            showMessage: false,
            showApprovedMsg: false,
            file: null,
            fileUrl: '',
            companySymbol: '',
        }
    }
    componentDidMount() {
        isCheckPlannerApp = true;
        this.props.checkPlannerApp();

        this.setState({
            isLoading: true,
            email: this.props.profileDetail.email,
            FirstName: this.props.profileDetail.first_name,
            LastName: this.props.profileDetail.last_name,
        }, () => {
        });
    }

    static getDerivedStateFromProps(props, state) {
        
        if (props.checkPlannerAppRes) {
            if (props.checkPlannerAppRes.data && props.checkPlannerAppRes.data.checkPlannerApp) {
                if(props.checkPlannerAppRes.data.checkPlannerApp.message === "Success" && isCheckPlannerApp) {
                    isCheckPlannerApp = false;
                    if (props.checkPlannerAppRes.data.checkPlannerApp.data.applicationStatus === 'Application not started') {
                        return {
                            showUserAccount: true
                        };
                    } else if (props.checkPlannerAppRes.data.checkPlannerApp.data.applicationStatus === 'Not Submitted') {
                        if (props.checkPlannerAppRes.data.checkPlannerApp.data.user_account_info !== null) {
                            let userData = props.checkPlannerAppRes.data.checkPlannerApp.data.user_account_info;
                            var userDataStr = userData.replace(/'/g, '"');
                            var userDataPars = JSON.parse(userDataStr);
                            let userInfo = userDataPars;
                            return {
                                showTradingAccount: true,
                                showUserAccount: false,
                                userFirstName: userInfo.FirstName,
                                userLastName: userInfo.LastName,
                                primaryEmail: userInfo.Email,
                                homeStreetAddress: userInfo.AddressFirst + " " + userInfo.AddressSecond,
                                // homeCountry: userInfo.Country,
                                homeCity: userInfo.CityName,
                                homeState: userInfo.State,
                                homePostalCode: userInfo.Code,
                            };
                        } else {
                            return {
                                showTradingAccount: true,
                                showUserAccount: false
                            };
                        }
                    } else if (props.checkPlannerAppRes.data.checkPlannerApp.data.applicationStatus === 'Submitted') {
                        return {
                            // showTradingAccount: false,
                            // showUserAccount: false,
                            showMessage: true
                        };
                    } if (props.checkPlannerAppRes.data.checkPlannerApp.data.applicationStatus === 'Approved') {
                        return {
                            // showTradingAccount: false,
                            // showUserAccount: false,
                            showMessage: true,
                            showApprovedMsg: true
                        };
                    }
                }
            }
        }
        if (props.createPlannerUserAccRes) {
            if (props.createPlannerUserAccRes.data && props.createPlannerUserAccRes.data.plannerUserAccount) {
                if(props.createPlannerUserAccRes.data.plannerUserAccount.message === 'Success' && isCreatePlannerUser) {
                    isCreatePlannerUser = false;
                    return {
                        isLoading: false,
                        showUserAccount: false,
                        showTradingAccount: true,
                        userFirstName: state.FirstName,
                        userLastName: state.LastName,
                        primaryEmail: state.email,
                        homeStreetAddress: state.address1 + " " + state.address2,
                        // homeCountry: state.country,
                        homeCity: state.city,
                        homeState: state.state,
                        homePostalCode: state.code,
                    };
                }
            }
        }
        if (props.createPlannerTradingAccRes) {
            if (props.createPlannerTradingAccRes.data && props.createPlannerTradingAccRes.data.plannerTradingAccount) {
                if(props.createPlannerTradingAccRes.data.plannerTradingAccount.message === 'Success' && isCreatePlannerTrading) {
                    isCreatePlannerTrading = false;
                   props.history.push('/brokerdealer');
                }
            }
        }
        if (props.fileUploadRes) {
            if (props.fileUploadRes.data && props.fileUploadRes.data.fileUploadForPlanner) {
                if(props.fileUploadRes.data.fileUploadForPlanner.message === 'Success' && isUploadFile) {
                    isUploadFile = false;
                    return {
                        fileUrl: props.fileUploadRes.data.fileUploadForPlanner.data
                    }
                }
            }
        }
        return null;
    }
    handleBack = () => {
        this.props.history.goBack()
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleChangeSecond = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        },() => {
        })
    }
    submitCreateBroker = () => {
        this.setState({
            isSubmited: true,
        }, () => { });
        validate(this.state);
        const errors = validate(this.state);
        if (Object.keys(errors).length === 0) {
            this.handleFormSubmit();
        }
    }
    handleFormSubmit = () => {
        let request = {
            "Login": this.state.Login,
            "FirstName": this.state.FirstName,
            "MiddleName": this.state.Middle,
            "LastName": this.state.LastName,
            "Email": this.state.email,
            "Password": this.state.password,
            "AddressFirst": this.state.address1,
            "AddressSecond": this.state.address2,
            "Country": this.state.country,
            "State": this.state.state,
            "CityName": this.state.city,
            "Code": this.state.code,
            "Salutation": this.state.Salutation,
            "Suffix": this.state.Suffix,
            "PrimaryPhone": this.state.PrimaryPhone,
            "SecondaryPhone": this.state.SecondaryPhone,
        }
        isCreatePlannerUser = true;
        this.props.submitCreatePlannerUserAcc(request);
        this.setState({
            isLoading: false
        });
    }
    onPermissionsToggle = (event) => {
        this.setState({ selectedPermissions: event.value });
    }
    handleChangeCheckBox = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        })
    }
    
    submitCreateAccount = () => {
        this.setState({
            isSubmitedFinal: true,
        }, () => { });
        validateFinal(this.state);
        const errorFinal = validateFinal(this.state);
        if (Object.keys(errorFinal).length === 0) {
            this.handleFinalFormSubmit();
        }
    }
    handleFinalFormSubmit = () => {
        let dob = dateFormatUS(this.state.dateOfBirth);
        let request = {
            "formType":"new_account_form",
            "formType:accountType": this.state.accountType,
            "formType:applicants:joint:numDependents": "0",
            "formType:applicants:primary:contact:businessPhone": this.state.businessPhoneNumber,
            "formType:applicants:primary:contact:homeAddress:addr_line1": this.state.homeStreetAddress,
            "formType:applicants:primary:contact:homeAddress:city": this.state.homeCity,
            "formType:applicants:primary:contact:homeAddress:country": this.state.homeCountry,
            "formType:applicants:primary:contact:homeAddress:postal":this.state.homePostalCode,
            "formType:applicants:primary:contact:homeAddress:state": this.state.homeState,
            "formType:applicants:primary:contact:mailingAddress:addr_line1": this.state.mailingStreetAddress,
            "formType:applicants:primary:contact:mailingAddress:city": this.state.mailingCity,
            "formType:applicants:primary:contact:mailingAddress:country": this.state.mailingCountry,
            "formType:applicants:primary:contact:mailingAddress:postal": this.state.mailingPostalCode,
            "formType:applicants:primary:contact:mailingAddress:state": this.state.mailingState,
            "formType:applicants:primary:contact:mobilePhone": this.state.phoneNumber,
            "formType:applicants:primary:contact:primaryEmail": this.state.primaryEmail,
            "formType:applicants:primary:contact:secondaryEmail": this.state.secondaryEmail,
            "formType:applicants:primary:disclosures:firmName": this.state.firmName,
            "formType:applicants:primary:disclosures:isAffiliatedExchangeOrFINRA": this.state.isAffiliatedExchangeOrFINRA,
            "formType:applicants:primary:disclosures:isControlPerson": this.state.isControlPerson,
            "formType:applicants:primary:disclosures:isPoliticallyExposed": this.state.isPoliticallyExposed,
            "formType:applicants:primary:employment:businessAddress:addr_line1": this.state.businessStreetAddress,
            "formType:applicants:primary:employment:businessAddress:city": this.state.businessCity,
            "formType:applicants:primary:employment:businessAddress:country": this.state.businessCountry,
            "formType:applicants:primary:employment:businessAddress:postal": this.state.businessPostalCode,
            "formType:applicants:primary:employment:businessAddress:state": this.state.businessState,
            "formType:applicants:primary:employment:employer": this.state.employer,
            "formType:applicants:primary:employment:employmentStatus": this.state.employmentStatus,
            "formType:applicants:primary:employment:positionEmployed": this.state.positionEmployed,
            "formType:applicants:primary:employment:yearsEmployed": this.state.yearsEmployed,
            "formType:applicants:primary:identity:birthCountry": this.state.birthCountry,
            "formType:applicants:primary:identity:citizenshipCountry": this.state.citizenshipCountry,
            "formType:applicants:primary:identity:dateOfBirth": dob, //MM/DD/YYYY
            "formType:applicants:primary:identity:name:first": this.state.userFirstName,
            "formType:applicants:primary:identity:name:last": this.state.userLastName,
            "formType:applicants:primary:identity:name:prefix": this.state.prefix,
            "formType:applicants:primary:identity:socialSecurityNumber": this.state.socialSecurityNumber,
            "formType:applicants:primary:maritalStatus": this.state.maritalStatus,
            "formType:applicants:primary:numDependents": this.state.numDependents,
            "formType:catAccountholderType": this.state.catAccountholderType,
            "formType:customerType": this.state.customerType,
            "formType:investmentProfile:annualIncomeUSD:max": this.state.annual_inc_max,
            "formType:investmentProfile:annualIncomeUSD:min": this.state.annual_inc_min,
            "formType:investmentProfile:federalTaxBracketPercent": this.state.federalTaxBracketPercent,
            "formType:investmentProfile:investmentExperience": this.state.investmentExperience,
            "formType:investmentProfile:investmentObjective": this.state.investmentObjective,
            "formType:investmentProfile:liquidNetWorthUSD:max": this.state.liq_networth_max,
            "formType:investmentProfile:liquidNetWorthUSD:min": this.state.liq_networth_min,
            "formType:investmentProfile:riskTolerance": this.state.riskTolerance,
            "formType:investmentProfile:totalNetWorthUSD:max": this.state.tot_networth_max,
            "formType:investmentProfile:totalNetWorthUSD:min": this.state.tot_networth_min,
            "formType:serviceProfile:dividendReinvestment": this.state.dividendReinvestment,
            "formType:serviceProfile:householdingService": this.state.householdingService,
            "formType:serviceProfile:issuerDirectCommunication": this.state.issuerDirectCommunication,
            "formType:serviceProfile:sweepInstructions": this.state.sweepInstructions,
            "formType:suitabilityProfile:liquidityNeeds": this.state.liquidityNeeds,
            "formType:suitabilityProfile:timeHorizon": this.state.timeHorizon,
            "formType:tradeAuthorization:isTradeAuthorization": this.state.isTradeAuthorization,
            "photoIdReference:0": this.state.fileUrl,
            "primary:isForeign": this.state.isForeign
        }
        isCreatePlannerTrading = true;
        this.props.submitCreateTradingAcc(request);
    }
    handleChangeApplicantsIdentityDOB = (event) => {
        this.setState({
            dateOfBirth: event.value,
        });
    }
    handleChangeCheckBox = (e) => {
        if (e.target.checked === true) {
            this.setState({
                isMailAddressSame: e.target.checked,
                mailingStreetAddress: this.state.homeStreetAddress,
                mailingState: this.state.homeState,
                mailingCity: this.state.homeCity,
                mailingCountry: this.state.homeCountry,
                mailingPostalCode: this.state.homePostalCode,
            },() => {
            })
        } else {
            this.setState({
                isMailAddressSame: e.target.checked,
                mailingStreetAddress: '',
                mailingState: '',
                mailingCity: '',
                mailingCountry: '',
                mailingPostalCode: '',
            },() => {
            })
        }
        
    }
    selectInputFile = (e) => {
        this.setState({
            file: e.target.files,
        }, () => {
            isUploadFile = true;
            this.props.submitFileUpload(this.state.file[0]);
        });
    }
    render() {
        const errors = validate(this.state);
        const errorFinal = validateFinal(this.state);
        
        let date =  new Date().getFullYear();
        let yearRng = (Number(date)-110) +":"+ date;
        const { classes } = this.props;
        return (
            <div className="create_broker_box_inner_content_form">
                <div className="create_brokerage_body">
                    <div style={{textAlign: 'center', padding: 20, fontSize: 20}}>Create Planner Account</div>
                    {
                        (this.state.showMessage) && (!this.state.showApprovedMsg) &&
                        <div className="col col-12 col-sm-12 col-md-12 text-center mt-5 mb-5">
                            Your Account has been submitted
                        </div>
                    }
                    {
                        (this.state.showMessage) && (this.state.showApprovedMsg) &&
                        <div className="col col-12 col-sm-12 col-md-12 text-center mt-5 mb-5">
                            Your Account has been approved
                        </div>
                    }
                    
                    {
                        (this.state.showUserAccount) &&
                        <div className="row ml-mr-0px-imp justify-content-md-center">
                            <div className="col col-12 col-sm-12 col-md-12">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="mt-4">
                                            <label>Salutation</label>
                                            <div>
                                                <Select
                                                    fullWidth
                                                    name="Salutation"
                                                    value={this.state.Salutation}
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    {
                                                        salutationOptions && salutationOptions.map((item, i) => 
                                                        <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.Salutation}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4">
                                            <label>First Name</label>
                                            <TextField style={{width: '100%'}} type="text" autoComplete="off" name="FirstName" value={this.state.FirstName} onChange={(e) => this.handleChange(e)} inputProps={{ maxLength: 100 }}/>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.FirstName}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4">
                                            <label>Middle Name</label>
                                            <TextField style={{width: '100%'}} type="text" autoComplete="off" name="Middle" onChange={(e) => this.handleChange(e)} inputProps={{ maxLength: 100 }}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4"> 
                                            <label>Last Name</label>
                                            <TextField style={{width: '100%'}} type="text" autoComplete="off" name="LastName" value={this.state.LastName} onChange={(e) => this.handleChange(e)} inputProps={{ maxLength: 100 }}/>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.LastName}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4">
                                            <label>Username</label>
                                            <TextField style={{width: '100%'}} type="text" autoComplete="off" name="Login" onChange={(e) => this.handleChange(e)} inputProps={{ maxLength: 100 }}/>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.Login}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4"> 
                                            <label>Email</label>
                                            <TextField style={{width: '100%'}} type="text" autoComplete="off" name="email" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.email}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4">
                                            <label>Password</label>
                                            <TextField style={{width: '100%'}} type="password" autoComplete="off" name="password" onChange={(e) => this.handleChange(e)} inputProps={{ maxLength: 100 }}/>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.password}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4"> 
                                            <label>Address1</label>
                                            <TextField style={{width: '100%'}} type="text" autoComplete="off" name="address1" onChange={(e) => this.handleChange(e)} inputProps={{ maxLength: 100 }}/>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.address1}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4"> 
                                            <label>Address2</label>
                                            <TextField style={{width: '100%'}} type="text" autoComplete="off" name="address2" onChange={(e) => this.handleChange(e)} inputProps={{ maxLength: 100 }}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4"> 
                                            <label>Country</label>
                                            <div>
                                                <Select
                                                    fullWidth
                                                    name="country"
                                                    value={this.state.country}
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    {
                                                        UsersCountryList && UsersCountryList.map((item, i) => 
                                                        <MenuItem key={i} value={item}>{item}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.country}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4"> 
                                            <label>State</label>
                                            <div>
                                                <Select
                                                    fullWidth
                                                    name="state"
                                                    value={this.state.state}
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {
                                                        StateList && StateList.map((item, i) => 
                                                        <MenuItem key={i} value={item}>{item}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.state}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4">
                                            <label>City</label>
                                            <TextField style={{width: '100%'}} type="text" autoComplete="off" name="city" onChange={(e) => this.handleChange(e)} inputProps={{ maxLength: 100 }}/>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.city}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4"> 
                                            <label>Postal Code</label>
                                            <div>
                                                <TextField style={{width: '100%'}} type="text" autoComplete="off" name="code" value={this.state.code} onChange={(e) => this.handleChange(e)} inputProps={{ maxLength: 10 }}/>
                                            </div>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.code}</span>}
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-3">
                                        <div className="mt-4">
                                            <label>Suffix</label>
                                            <div>
                                                <Select
                                                    fullWidth
                                                    name="Suffix"
                                                    value={this.state.Suffix}
                                                    onChange={(e) => this.handleChange(e)}
                                                >
                                                    {
                                                        suffixOptions && suffixOptions.map((item, i) => 
                                                        <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.Suffix}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4"> 
                                            <label>Primary Phone Number</label>
                                            <div>
                                                <NumberFormat value={this.state.PrimaryPhone} format="+1 (###) ###-####" onValueChange={(values) => {
                                                    const {value} = values;
                                                    this.setState({PrimaryPhone: value})
                                                }} className="form-control" />
                                            </div>
                                            {errors && this.state.isSubmited && <span className="errorvalidation">{errors.PrimaryPhone}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="mt-4"> 
                                            <label>Secondary Phone Number</label>
                                            <div>
                                                <NumberFormat value={this.state.SecondaryPhone} format="+1 (###) ###-####" onValueChange={(values) => {
                                                    const {value} = values;
                                                    this.setState({SecondaryPhone: value})
                                                }} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mt-4 text-center">
                                            <Button className="btn btn-submit-brokerage"  onClick={() => this.submitCreateBroker()}>Next </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        (this.state.showTradingAccount) &&
                        <div className="row ml-mr-0px-imp">
                            <div className="col col-12 col-sm-12 col-md-12">
                            <fieldset> 
                                    <legend>User Logs</legend>
                                    <div className="col-sm-12 col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div>
                                                    <label>Logs</label>
                                                    <TextField multiline style={{width: '100%'}} type="text" autoComplete="off" name="userFirstName" value={this.state.userFirstName} onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.userFirstName}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="mt-4"> 
                                    <legend>User Info</legend>
                                    <div className="col-sm-12 col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Prefix</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="prefix"
                                                            value={this.state.prefix}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                salutationOptions && salutationOptions.map((op, j) => 
                                                                <MenuItem key={j} value={op.value}>{op.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.prefix}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>First Name</label>
                                                    <TextField style={{width: '100%'}} type="text" autoComplete="off" name="userFirstName" value={this.state.userFirstName} onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.userFirstName}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Last Name</label>
                                                    <TextField style={{width: '100%'}} type="text" autoComplete="off" name="userLastName" value={this.state.userLastName} onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.userLastName}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4 calendar">
                                                    <label>Date of birth</label>
                                                    <Calendar name="dateOfBirth" value={this.state.dateOfBirth} onChange={(e) => this.handleChangeApplicantsIdentityDOB(e)}  dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" monthNavigator yearNavigator yearRange={yearRng} />
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.dateOfBirth}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Primary Email Addresse <Tooltip title={'Email should be listed in priority order'} className="pointerCursor">
                                                        <InfoIcon />
                                                    </Tooltip></label>
                                                    <TextField style={{width: '100%'}} type="text" autoComplete="off" name="primaryEmail" value={this.state.primaryEmail} onChange={(e) => this.handleChangeSecond(e)} />
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.primaryEmail}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Secondary Email Addresse <Tooltip title={'Email should be listed in priority order'} className="pointerCursor">
                                                        <InfoIcon />
                                                    </Tooltip></label>
                                                    <TextField style={{width: '100%'}} type="text" autoComplete="off" name="secondaryEmail" onChange={(e) => this.handleChangeSecond(e)} />
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.secondaryEmail}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Phone Number</label>
                                                    <div>
                                                        <NumberFormat value={this.state.phoneNumber} format="+1 (###) ###-####" onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({ phoneNumber: value })
                                                        }} className="form-control" />
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.phoneNumber}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="mt-4"> 
                                    <legend>Address</legend>
                                    <div className="col-sm-12 col-md-12">
                                        <div className="row ml-mr-0px-imp mt-3 pb-3" style={{border: '1px solid #ddd'}}>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Home Street Address</label>
                                                    <TextField style={{width: '100%'}} type="text" autoComplete="off" name="homeStreetAddress" value={this.state.homeStreetAddress} onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.homeStreetAddress}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Home Address Country</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="homeCountry"
                                                            value={this.state.homeCountry}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                CountryList && CountryList.map((op, j) => 
                                                                <MenuItem key={j} value={op}>{op}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.homeCountry}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Home Address State</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="homeState"
                                                            value={this.state.homeState}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                StateList && StateList.map((op, i) => 
                                                                <MenuItem key={i} value={op}>{op}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Home Address City</label>
                                                    <TextField style={{width: '100%'}} type="text" autoComplete="off" name="homeCity" value={this.state.homeCity} onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.homeCity}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Home Address Postal Code</label>
                                                    <div>
                                                        <TextField style={{width: '100%'}} type="text" autoComplete="off" name="homePostalCode" value={this.state.homePostalCode} onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 10 }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="row ml-mr-0px-imp" >
                                            <div className="col col-12 col-sm-12 col-md-12">
                                                <div className="mt-4">
                                                    <label className="checkbox-choice">Is Mailing address same as your home address
                                                        <input type="checkbox" onChange={(e) => this.handleChangeCheckBox(e)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ml-mr-0px-imp mt-3 pb-3" style={{border: '1px solid #ddd'}}>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Mailing Street Address</label>
                                                    <TextField style={{width: '100%'}} type="text" autoComplete="off" name="mailingStreetAddress" value={this.state.mailingStreetAddress} onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Mailing Country</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="mailingCountry"
                                                            value={this.state.mailingCountry}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                CountryList && CountryList.map((op, j) => 
                                                                <MenuItem key={j} value={op}>{op}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Mailing State</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="mailingState"
                                                            value={this.state.mailingState}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                StateList && StateList.map((item, i) => 
                                                                <MenuItem key={i} value={item}>{item}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Mailing City</label>
                                                    <TextField style={{width: '100%'}} type="text" autoComplete="off" name="mailingCity" value={this.state.mailingCity} onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Mailing Postal Code</label>
                                                    <div>
                                                        <TextField style={{width: '100%'}} type="text" autoComplete="off" name="mailingPostalCode" value={this.state.mailingPostalCode} onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 10 }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="mt-4"> 
                                    <legend>Personal Info</legend>
                                    <div className="col-sm-12 col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Social Security Number <Tooltip title={'social security number with dashes; required for domestic and resident alien persons (domestic -\nperson citizenshipCountry = USA and RA - citizenshipCountry != USA and addressCountry = USA)'} className="pointerCursor">
                                                        <InfoIcon />
                                                    </Tooltip></label>
                                                    <div>
                                                        <NumberFormat name="socialSecurityNumber" value={this.state.socialSecurityNumber} format="###-##-####" onValueChange={(values) => {
                                                            const { formattedValue } = values;
                                                            this.setState({socialSecurityNumber: formattedValue});
                                                        }} className="form-control" />
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.socialSecurityNumber}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4 calendar">
                                                    <label>Birth Country </label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="birthCountry"
                                                            value={this.state.birthCountry}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                CountryList && CountryList.map((op, j) => 
                                                                <MenuItem key={j} value={op}>{op}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.birthCountry}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Citizenship Country</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="citizenshipCountry"
                                                            value={this.state.citizenshipCountry}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                CountryList && CountryList.map((op, j) => 
                                                                <MenuItem key={j} value={op}>{op}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.citizenshipCountry}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Is Foreign</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="isForeign"
                                                            value={this.state.isForeign}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            <MenuItem value="YES">Yes</MenuItem>
                                                            <MenuItem value="NO">No</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.isForeign}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>No. of Dependents</label>
                                                    <div>
                                                        <NumberFormat name="numDependents" value={this.state.numDependents} onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({
                                                                numDependents: value
                                                            });
                                                        }} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Marital Status</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="maritalStatus"
                                                            value={this.state.maritalStatus}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                maritalStatusOptions && maritalStatusOptions.map((item, i) => 
                                                                <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.maritalStatus}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Minimum Liquid Net Worth</label>
                                                    <div>
                                                        <NumberFormat value={this.state.liq_networth_min} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({ liq_networth_min: value })
                                                        }} className="form-control" />
                                                    </div>
                                                    {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.liq_networth_min}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Maximum Liquid Net Worth</label>
                                                    <div>
                                                        <NumberFormat value={this.state.liq_networth_max} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({ liq_networth_max: value })
                                                        }} className="form-control" />
                                                    </div>
                                                    {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.liq_networth_max}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Total Minimum Net Worth</label>
                                                    <div>
                                                        <NumberFormat value={this.state.tot_networth_min} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({ tot_networth_min: value })
                                                        }} className="form-control" />
                                                    </div>
                                                    {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.tot_networth_min}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Total Maximum Net Worth</label>
                                                    <div>
                                                        <NumberFormat value={this.state.tot_networth_max} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({ tot_networth_max: value })
                                                        }} className="form-control" />
                                                    </div>
                                                    {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.tot_networth_max}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Minimum Annual Income</label>
                                                    <div>
                                                        <NumberFormat value={this.state.annual_inc_min} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({ annual_inc_min: value })
                                                        }} className="form-control" />
                                                    </div>
                                                    {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.annual_inc_min}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Maximum Annual Income</label>
                                                    <div>
                                                        <NumberFormat value={this.state.annual_inc_max} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({ annual_inc_max: value })
                                                        }} className="form-control" />
                                                    </div>
                                                    {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.annual_inc_max}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Federal Tax Bracket Percent</label>
                                                    <div>
                                                        <NumberFormat value={this.state.federalTaxBracketPercent} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({ federalTaxBracketPercent: value })
                                                        }} className="form-control" />
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.federalTaxBracketPercent}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="mt-4"> 
                                    <legend>Occupations</legend>
                                    <div className="col-sm-12 col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Employer</label>
                                                    <div>
                                                        <TextField style={{width: '100%'}} type="text" autoComplete="off" name="employer" onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Employed position</label>
                                                    <div>
                                                        <TextField style={{width: '100%'}} type="text" autoComplete="off" name="positionEmployed" onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Employment Status</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="employmentStatus"
                                                            value={this.state.employmentStatus}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                empStatusOptions && empStatusOptions.map((item, i) => 
                                                                <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Employed years</label>
                                                    <div>
                                                        <NumberFormat value={this.state.yearsEmployed} thousandSeparator={false} allowNegative={false} onValueChange={(values) => {
                                                            const { value } = values;
                                                            this.setState({ yearsEmployed: value })
                                                        }} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Business Street Address <Tooltip title={'At most, 3 lines of streetAddress are available. For the custodian (2nd applicant) of\na CUSTODIAN account, only 2 lines are available.'} className="pointerCursor">
                                                        <InfoIcon />
                                                    </Tooltip></label>
                                                    <TextField style={{width: '100%'}} type="text" autoComplete="off" name="businessStreetAddress" onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.businessStreetAddress}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Business City</label>
                                                    <TextField style={{width: '100%'}} type="text" autoComplete="off" name="businessCity" onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.businessCity}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Business State</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="businessState"
                                                            value={this.state.businessState}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                StateList && StateList.map((item, i) => 
                                                                <MenuItem key={i} value={item}>{item}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.businessState}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Business Country</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="businessCountry"
                                                            value={this.state.businessCountry}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                CountryList && CountryList.map((op, j) => 
                                                                <MenuItem key={j} value={op}>{op}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.businessCountry}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Business Postal Code <Tooltip title={'required for domestic and resident alien persons (domestic - person citizenshipCountry = USA and RA\n - citizenshipCountry != USA and addressCountry = USA)'} className="pointerCursor">
                                                        <InfoIcon />
                                                    </Tooltip></label>
                                                    <div>
                                                        <TextField style={{width: '100%'}} type="text" autoComplete="off" name="businessPostalCode" onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 10 }} />
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.businessPostalCode}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Business Phone Number</label>
                                                    <div>
                                                        <NumberFormat name="businessPhoneNumber" value={this.state.businessPhoneNumber} thousandSeparator={false} format="+1 (###) ###-####" onValueChange={(values) => {
                                                            const {value} = values;
                                                            this.setState({businessPhoneNumber: value});
                                                        }} className="form-control" />
                                                    </div>
                                                    
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.businessPhoneNumber}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="mt-4">
                                    <legend>Brokerage Account</legend>
                                    <div className="col-sm-12 col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Account holder type <Tooltip title={'Consolidated Audit Trail Accountholder Type\nA - Institutional Customer - An institutional account as defined in FINRA Rule 4512(c)\nE - Employee Account - An employee or associated person of an Industry Member\nI - Individual Customer - An account that does not meet the definition of FINRA Rule 4512(c) and is also not a proprietary account'} className="pointerCursor">
                                                        <InfoIcon />
                                                    </Tooltip></label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="catAccountholderType"
                                                            value={this.state.catAccountholderType}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                catAccountholderTypeOptions && catAccountholderTypeOptions.map((op, i) => 
                                                                <MenuItem key={i} value={op.value}>{op.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.catAccountholderType}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Account type <Tooltip title={'for retirement account, MARGIN is not a valid accountType'} className="pointerCursor">
                                                        <InfoIcon />
                                                    </Tooltip></label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="accountType"
                                                            value={this.state.accountType}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                accountTypeOptions && accountTypeOptions.map((op, i) => 
                                                                <MenuItem key={i} value={op.value}>{op.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.accountType}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Investment Objectives</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="investmentObjective"
                                                            value={this.state.investmentObjective}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                objectivesOptions && objectivesOptions.map((item, i) => 
                                                                <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.investmentObjective}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Investment Experience</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="investmentExperience"
                                                            value={this.state.investmentExperience}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            <MenuItem value="NONE">None</MenuItem>
                                                            <MenuItem value="LIMITED">Limited</MenuItem>
                                                            <MenuItem value="GOOD">Good</MenuItem>
                                                            <MenuItem value="EXTENSIVE">Extensive</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.investmentExperience}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Risk Tolerance</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="riskTolerance"
                                                            value={this.state.riskTolerance}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                riskToleranceOptions && riskToleranceOptions.map((item, i) => 
                                                                <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.riskTolerance}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Time Horizon </label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="timeHorizon"
                                                            value={this.state.timeHorizon}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                timeHorizonOptions && timeHorizonOptions.map((op, i) => 
                                                                <MenuItem key={i} value={op.value}>{op.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.timeHorizon}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Liquidity Needs</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="liquidityNeeds"
                                                            value={this.state.liquidityNeeds}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                liquidityNeedsOptions && liquidityNeedsOptions.map((op, i) => 
                                                                <MenuItem key={i} value={op.value}>{op.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.liquidityNeeds}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Customer type</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="customerType"
                                                            value={this.state.customerType}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                customerTypeOption && customerTypeOption.map((item, i) => 
                                                                <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.customerType}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Sweep Instructions</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="sweepInstructions"
                                                            value={this.state.sweepInstructions}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                sweepInstructionsOption && sweepInstructionsOption.map((item, i) => 
                                                                <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.sweepInstructions}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Dividend Reinvestment</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="dividendReinvestment"
                                                            value={this.state.dividendReinvestment}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            {
                                                                dividendReinvestmentOption && dividendReinvestmentOption.map((item, i) => 
                                                                <MenuItem key={i} value={item.value}>{item.name}</MenuItem>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.dividendReinvestment}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Issuer Direct Communication</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="issuerDirectCommunication"
                                                            value={this.state.issuerDirectCommunication}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            <MenuItem value="OBJECT">OBJECT</MenuItem>
                                                            <MenuItem value="ACCEPT">ACCEPT</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.issuerDirectCommunication}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4">
                                                    <label>Householding Service</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="householdingService"
                                                            value={this.state.householdingService}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            <MenuItem value="YES">Yes</MenuItem>
                                                            <MenuItem value="NO">No</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.householdingService}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Is Control Person <Tooltip title={'provide if and only if isAffiliatedExchangeOrFINRA is YES'} className="pointerCursor">
                                                        <InfoIcon />
                                                    </Tooltip></label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="isControlPerson"
                                                            value={this.state.isControlPerson}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            <MenuItem value="YES">Yes</MenuItem>
                                                            <MenuItem value="NO">No</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.isControlPerson}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.isControlPerson === 'YES' &&
                                                <div className="col-md-3">
                                                    <div className="mt-4"> 
                                                        <label>Company Symbol</label>
                                                        <div>
                                                            <TextField style={{width: '100%'}} type="text" autoComplete="off" name="companySymbol" onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                        </div>
                                                        <div>
                                                            {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.companySymbol}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Is Affiliated Exchange <Tooltip title={'is the applicant affiliated with, or an employee of a member firm of a stock exchange or FINRA?'} className="pointerCursor">
                                                        <InfoIcon />
                                                    </Tooltip></label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="isAffiliatedExchangeOrFINRA"
                                                            value={this.state.isAffiliatedExchangeOrFINRA}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            <MenuItem value="YES">Yes</MenuItem>
                                                            <MenuItem value="NO">No</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.isAffiliatedExchangeOrFINRA}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.isAffiliatedExchangeOrFINRA === "YES" &&
                                                <div className="col-md-3">
                                                    <div className="mt-4">
                                                        <label>Firm Name <Tooltip title={'provide if and only if isAffiliatedExchangeOrFINRA is YES'} className="pointerCursor">
                                                        <InfoIcon />
                                                    </Tooltip></label>
                                                        <TextField style={{width: '100%'}} type="text" autoComplete="off" name="firmName" onChange={(e) => this.handleChangeSecond(e)} inputProps={{ maxLength: 100 }}/>
                                                        <div>
                                                            {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.firmName}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-3">
                                                <div className="mt-4"> 
                                                    <label>Is Politically Exposed</label>
                                                    <div>
                                                        <Select
                                                            fullWidth
                                                            name="isPoliticallyExposed"
                                                            value={this.state.isPoliticallyExposed}
                                                            onChange={(e) => this.handleChangeSecond(e)}
                                                        >
                                                            <MenuItem value="YES">Yes</MenuItem>
                                                            <MenuItem value="NO">No</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.isPoliticallyExposed}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </fieldset>
                                
                                <div className="col-sm-12 col-md-12">
                                    <div className="row">
                                        
                                        
                                        {/* <div className="col-md-3">
                                            <div className="mt-4">
                                                <label>Is Trade Authorization</label>
                                                <div>
                                                    <Select
                                                        fullWidth
                                                        name="isTradeAuthorization"
                                                        value={this.state.isTradeAuthorization}
                                                        onChange={(e) => this.handleChangeSecond(e)}
                                                    >
                                                        <MenuItem value="YES">Yes</MenuItem>
                                                        <MenuItem value="NO">No</MenuItem>
                                                    </Select>
                                                </div>
                                                <div>
                                                    {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.isTradeAuthorization}</span>}
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-12 col-md-3">
                                        <div className={classes.root}>
                                            <input
                                                className={classes.input}
                                                id="contained-button-file"
                                                type="file"
                                                accept="image/jpg,image/jpeg,image/png,application/pdf"
                                                onChange={this.selectInputFile}
                                            />
                                            <label htmlFor="contained-button-file">
                                                <Button variant="contained" color="secondary" component="span" style={{backgroundColor: '#5f5f5f'}}>File Upload </Button>
                                            </label>
                                            <div>
                                                {errorFinal && this.state.isSubmitedFinal && <span className="errorvalidation">{errorFinal.fileUrl}</span>}
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="col-12 col-md-9">
                                        {
                                            this.state.fileUrl && 
                                            <img style={{ width: 150 }} src={this.state.fileUrl} width="100" height="100" alt="" />
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mt-4 text-center">
                                        <Button className="btn btn-submit-brokerage" onClick={() => this.submitCreateAccount()}>Submit</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        )
    }
}

CreateFormComponent.propTypes = {
    profileDetail: PropTypes.any,
    checkPlannerAppRes: PropTypes.any,
    createPlannerUserAccRes: PropTypes.any,
    createPlannerTradingAccRes: PropTypes.any,
    fileUploadRes: PropTypes.any,
    reset: PropTypes.any,
    history: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    checkPlannerAppRes: doCheckPlannerAppRes,
    createPlannerUserAccRes: doCreatePlannerUserAccRes,
    createPlannerTradingAccRes: doCreatePlannerTradingAccRes,
    fileUploadRes: fileUploadRes,
});

function mapDispatchToProps(dispatch) {
    return {
        checkPlannerApp: () => dispatch(checkPlannerApp()),
        submitCreatePlannerUserAcc: (data) => dispatch(createPlannerUserAcc(data)),
        submitCreateTradingAcc: (data) => dispatch(createPlannerTradingAcc(data)),
        submitFileUpload: (file) => dispatch(uploadPlannerFile(file)),
    };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const CreateFormComp = compose(withConnect)(CreateFormComponent);

export default withStyles(styles)(CreateFormComp);