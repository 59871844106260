import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';

class orderApi {
    static doOrder() {
        const TOKEN = getItem('auth_token');
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'GET',
                headers: ajaxRequestHeaders,
            }
            return fetch(BASE_URL + '/v1/orders', body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default orderApi;