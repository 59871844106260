import * as types from './actionTypes';  
import placeOrderApi from '../api/placeOrderApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2'

export function doPlaceOrder(data) {
  return {
    type: types.DO_PLACE_ORDER, 
    data
  };
}

export function doPlaceOrderRes(data) {  
  return {
    type: types.DO_PLACE_ORDER_RES, 
    data
  };
}

export function place_order(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      placeOrderApi.doPlaceOrder(data).then(data => {
        dispatch(doPlaceOrderRes(data));
        dispatch(doPlaceOrderRes(null));
        if(data.error){
          Swal.fire({
            title: data.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}