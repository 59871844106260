// import { SCPACCOUNTOPENURL } from '../constants';
// import { getItem } from '../utils/localStore';
import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
// import Swal from 'sweetalert2';

class createBrokerageAccApi {
    static doCreateBrokerageAccUsername(data, broker) {
        let isError = false;
      
        try{
            const encodedString = window.btoa(broker.bd_id +':'+ broker.db_secret)
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Basic ' + encodedString
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(data)
            }
            return fetch(broker.base_url + '/api/register', body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }

    static doCreateBrokerageAccPassword(data, broker) {
        let isError = false;
       
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            });
            // const encodedString = window.btoa(broker.bd_id +':'+ broker.db_secret)
            // const ajaxRequestHeaders = new Headers({
            //     'Content-Type': 'application/x-www-form-urlencoded',
            //     'Accept': 'application/json',
            //     'Authorization': 'Basic ' + encodedString
            // });
            var formBody = [];
            for (var property in data) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(data[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: formBody
            }
            return fetch(broker.base_url + '/connect/token', body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }

    static doCreateBrokerageAcc(data, token, broker) {
        let isError = false;
    
        try{
            // const encodedString = window.btoa(broker.bd_id +':'+ broker.db_secret)
            // const ajaxRequestHeaders = new Headers({
            //     'Content-Type': 'application/json',
            //     'Accept': 'application/json',
            //     'Authorization': 'Basic ' + encodedString
            // });

            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(data)
            }
            return fetch('https://api.just2trade.com/application', body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }

    static doScorePriorityAccessToken(data, accessToken, token) {
        let isError = false;

        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            });
            let payload = {
                "access_token": accessToken,
                "uid": data.personal.email_address
            }
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(payload)
            }
            return fetch(BASE_URL + `/score_priority/access_token`, body).then(response => {
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default createBrokerageAccApi;