import * as types from './actionTypes';  
import MarketBrokerageApi from '../api/marketBrokerageApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';

export function doMarketBrokerage(data) {

  return {
    type: types.DO_MARKET_BROKERAGE, 
    data
  };
}

export function doMarketBrokerageRes(data) {  
  return {
    type: types.DO_MARKET_BROKERAGE_RES, 
    data
  };
}

export function market_brokerage(data) {
  const TOKEN = getItem('auth_token');

  if(TOKEN){
    return function(dispatch) {
      MarketBrokerageApi.doMarketBrokerage(data).then(data => {
        dispatch(doMarketBrokerageRes(data));
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
  
}