import * as types from './actionTypes';  
import securitySymbolApi from '../api/securitySymbolApi';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function getSymbol(symbol) {
  return {
    type: types.DO_SECURITY_SYMBOL, 
    symbol
  };
}

export function doSymbolRes(data) {
  return {
    type: types.DO_SECURITY_SYMBOL_RES, 
    data
  };
}

export function get_symbol(symbol) {
  return function(dispatch) {
    dispatch(loadingRes(true));
    securitySymbolApi.getSymbol(symbol).then(data => {
      dispatch(doSymbolRes(data));
      dispatch(loadingRes(false));
      if(data.error){
        Swal.fire({
          title: data.message,
          icon: 'error',
          confirmButtonText: 'OK',
          heightAuto: false
        })
      }
    }).catch(error => {
      dispatch(loadingRes(false));
      return error
    });
  };
}
