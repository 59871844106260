import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

class socialLoginApi {
    static socialLogin(data) {
        const TOKEN = getItem('auth_token');
        let isError = false;

        let socialdata={
            provider: data.provider,
            uid : data.uid,
            token : data.token,
            secret : data.secret,
            last_refresh_time:data.last_refresh_time,
            expiration_time : data.expiration_time,
        }

        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body:JSON.stringify(socialdata)
            }
            return fetch(BASE_URL + '/user_identities', body).then(response => {
                if (response.ok === true) {
                    Swal.fire({
                        title: 'Account linked successfully',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: true,
                        timer: 3000,
                    })
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default socialLoginApi;