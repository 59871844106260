import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './offerings.scss';
import { Link } from 'react-router-dom';
// import LayoutWrapper from '../../component/LayoutWrapper/';
import Dashboard from '../../component/Dashboard';
import { get_offering, doOfferingRes } from '../../actions/offeringActions';
import { get_offering_industries, doOfferingIndustriesRes } from '../../actions/offeringIndustriesActions';
import { save_offering, dosaveOfferingRes } from '../../actions/offeringSaveActions';
import { read_offering, doReadOfferingRes } from '../../actions/readOfferingActions';
import { doEditprofileRes, submit_profile } from '../../actions/editProfileActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { getAllComment, doAllCommentRes } from '../../actions/allCommentActions';
import { submitComment, doCommentRes } from '../../actions/commentOfferingActions';
import { submitDeleteComment, doDeleteCommentRes } from '../../actions/deleteCommentOfferingActions';
import { submitUpdateComment, doUpdateCommentRes } from '../../actions/updateCommentOfferingActions';
import { getDate, getOfferingDetailsPrice, loadScript } from '../../helper/helper';
import {MultiSelect} from 'primereact/multiselect';
import { toastMessage } from '../../helper/toastMessage';
import LazyLoad from 'react-lazyload';
import StarRatingComponent from 'react-star-rating-component';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SpinnImg from '../../assets/images/spinn_img.gif';
import _ from 'lodash';
import PlaceHolderImg from '../../assets/images/placeholder_company_thumb.png';
import dspBatchImg from '../../assets/images/images/dsp_batch.png';
import Slider from "react-slick";
import { setItem } from '../../utils/localStore';
import SendIcon from '@material-ui/icons/Send';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import TextField from '@material-ui/core/TextField';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(),
		alignItems: 'center',
		justifyContent: 'center'
	},
}))(MuiDialogActions);

// let isEditComment = false;
// let isDeleteComment = false;
// let isAddComment = false;
let isOfferingListUpdate = false;
let isOfferingList = false;
let isSaveOff = false;
let isIndustry = false;
let isReadOff = false;
let isProfile = false;
class OfferingComponent extends React.PureComponent {
	_isMounted = false;
	constructor(props) {
		super(props);
		isOfferingList = true;
		isOfferingListUpdate = false;
		isSaveOff = false;
		isIndustry = false;
		isReadOff = false;
		isProfile = false;
		// isEditComment = false;
		// isDeleteComment = false;
		// isAddComment = false;

		this.state = {
			isLoader: true,
			searching: '',
			offeringList: [],
			offeringid: '',
			isFollow: null,
			readoffering: true,
			isRead: null,
			sortby: 'default',
			acceptingorders: '',
			offeringindustriesList:[],
			screenId: '1.1',
			openCommentModal: false,
			commentRating: 0,
			comment: '',
			commentList: [],
			offeringExtId: '',
			showUpdateCommentBtn: false,
			idHash: '',
			showValidationMsg: false,
			errMsg: '',
			showCommentLoader: false,
			isSendDisable: false,
			isUpdateDisable: false,
			showDspFirst: false,
			uiRender: false,
			openOnBoardingModal: false,
			slideIndex: 0,
			updateCount: 0,
			loginCountNumber: 0,
			userList: ''
			// timmerCode: this.timmerCode.bind(this)
		}
		this.sortoffering = this.sortoffering.bind(this);
		this.filterOffering = this.filterOffering.bind(this);
		this.openComment = this.openComment.bind(this);
		this.closeComment = this.closeComment.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.submitComment = this.submitComment.bind(this);
		this.deleteComment = this.deleteComment.bind(this);
		this.updateComment = this.updateComment.bind(this);
		this.submitUpdateComment = this.submitUpdateComment.bind(this);
	}

	addDefaultSrc(ev) {
		ev.target.src = PlaceHolderImg;
	}

	componentDidMount() {
		this._isMounted = true;
		loadScript();
		// let loginCount = getItem('login_count');
		// this.setState({
		// 	loginCountNumber: loginCount
		// }, () => {
		// 	if(this.state.loginCountNumber === 1) {
		// 		this.setState({
		// 			openOnBoardingModal: true,
		// 		});
		// 	}
		// });
		let profileData = localStorage.getItem("isProfile");
		if (!profileData) {
			this.props.getProfile();
		}
		isOfferingList = true;
		const data = {};
		isOfferingListUpdate = true;
		this.props.getOfferings(data);
		this.setState({
			isLoader: true
		});
		// isIndustry = true;
		// isProfile = true;
		setTimeout(() => {
			// this.props.getOfferingIndustries();
			const activityData = {
				'screen_id': this.state.screenId,
				'source': 'web'
			}
			this.props.userActivity(activityData);
		}, 500);
		
		if (this.props.location.DSPView) {
			if (this.props.location.DSPView === true) {
				this.setState({
					showDspFirst: true,
				});
			}
		}
	}
	static getDerivedStateFromProps(props, state) {
		if (props.offeringRes) {
			if (props.offeringRes.data && props.offeringRes.data.offering) {
				if (props.offeringRes.data.offering.message === 'Success'  && isOfferingList) {
					isOfferingList = false;
					isIndustry = true;
					props.getOfferingIndustries();
					let offerings=[]
					offerings = props.offeringRes.data.offering.data;
					var userIds;
					let loginCount = 0;
					for(let items of offerings){
						if(props.location){
							if((items.dsp===1  || items.dsp===2) && !props.location.DSPView){
								if (items.dsp_attestation_flag === false) {
									props.history.push("/attestation-file", {state: items})
									return;
								} else {
									if (props.location.DSPView === undefined) {
										props.history.push(`/offeringdetails/${items.ext_id}`)
									}
									
								}
							} else if (items.dsp===0) {
								loginCount = localStorage.getItem('login_count');
								loginCount = Number(loginCount);
							}
						}
					}
					return {
						offeringList: props.offeringRes.data.offering.data,
						isLoader: false,
						industriesList: userIds,
						loginCountNumber: Number(loginCount),
						openOnBoardingModal: Number(loginCount) === 1 ? true : false
					};
				}
				
			}
		}
		if (props.saveOfferingsRes) {
			if (props.saveOfferingsRes.data && props.saveOfferingsRes.data.offeringsave) {
				if (props.saveOfferingsRes.data.offeringsave.message === 'Success' && isSaveOff) {
					isSaveOff = false;
					return {
						saveOffering: props.saveOfferingsRes.data.offeringsave.data
					}
				}
			}
		}
		if (props.readofferingRes) {
			if (props.readofferingRes.data && props.readofferingRes.data.readoffering) {
				if (props.readofferingRes.data.readoffering.message === 'Success' && isReadOff) {
					isReadOff = false;
					let offList = state.offeringList
					for (let items of offList) {
						if (Number(items.ext_id) === Number(props.readofferingRes.data.readoffering.data.id)) {
							items.read = state.isRead;
						}
					}
					return {
						offeringList: offList,
						readOffering: props.readofferingRes.data.readoffering.data
					}
				}
			}
		}
		if (props.GetprofileRes) {
			if (props.GetprofileRes.data && props.GetprofileRes.data.editprofile) {
				if (props.GetprofileRes.data.editprofile.message === 'Success' && isProfile) {
					isProfile = false;
					return {
						userList: props.GetprofileRes.data.editprofile.data,
					};
				}
			}
		}
		if(props.offeringindustriesRes){
			if(props.offeringindustriesRes.data && props.offeringindustriesRes.data.offeringindustries){
				if(props.offeringindustriesRes.data.offeringindustries.message === 'Success' && isIndustry){
					isIndustry = false;
					return {
						offeringindustriesList: props.offeringindustriesRes.data.offeringindustries.data,
						isLoader: false
					};
				}
			}
		}
		return null
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.offeringRes.data && prevProps.offeringRes.data.offering) {
			if (prevProps.offeringRes.data.offering.message === 'Success' && isOfferingListUpdate) {
				isOfferingListUpdate = false;
				for (let index = 0; index < prevProps.offeringRes.data.offering.data.length; index++) {
					if (prevProps.offeringRes.data.offering.data[index].available_to_order === 2 &&  prevProps.offeringRes.data.offering.data[index].sixty_min_mail_time !== null) {
						this.timmerCode(prevProps.offeringRes.data.offering.data[index].sixty_min_mail_time, index);
					}
				} 
			}
		}
	}
	componentWillUnmount() {
		this._isMounted = false;
		this.props.doOfferingRes(null);
		this.setState = (state,callback)=>{
			return;
		};
	}
	curruntDateTime = () => {
		var now = new Date();
		var year = "" + now.getFullYear();
		var month = "" + (now.getMonth() + 1); if (month.length === 1) { month = "0" + month; }
		var day = "" + now.getDate(); if (day.length === 1) { day = "0" + day; }
		var hour = "" + now.getHours(); if (hour.length === 1) { hour = "0" + hour; }
		var minute = "" + now.getMinutes(); if (minute.length === 1) { minute = "0" + minute; }
		var second = "" + now.getSeconds(); if (second.length === 1) { second = "0" + second; }
		return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
	}

	openComment(id) {
		this.props.getAllComments(id);
		this.setState({
			openCommentModal: true,
			offeringExtId: id,
			showCommentLoader: true,
		}, () => {});
	}
	closeComment() {
		this.setState({
			openCommentModal: false,
			commentRating: 0,
			comment: '',
			commentList: [],
			showUpdateCommentBtn: false,
			showValidationMsg: false,
			errMsg: '',
			showCommentLoader: false,
			isSendDisable: false,
			isUpdateDisable: false,
		}, () => {});
	}
	closeOnBoarding = (event, reason) => {
		if (reason !== 'backdropClick') {
			setItem('login_count', 2);
			this.setState({
				openOnBoardingModal: false,
			}, () => {});
		}
	}

	sortoffering(event) {
		this.setState({
			sortby: event.target.value
		})
	}

	filterOffering(e){
		this.setState({
			industrtyname: e.value
		})
	}

	follow(ext_id, followed, name, offeringitem) {
		if(offeringitem.followed){
			offeringitem.followed=false
		}else{
			offeringitem.followed=true
		}
		let data = {
			"ext_id": ext_id,
			"save": followed
		}
		this.setState({
			isFollow: followed
		})
		if(data.save === true){
			toastMessage('success', `You just followed ${name}. This means you will get alerts on this offering as it changes and progresses.`)
		}
		isSaveOff = true;
		this.props.saveOfferings(data);
	}

	goOfferingDetails(ext_id, read, has_order) {
		if (has_order === false) {
			if (read === false) {
				let data = {
					"ext_id": ext_id,
					"read": read
				}
				this.setState({
					isRead: read
				})
				isReadOff = true;
				this.props.readOffering(data);
				this.props.history.push(`/offeringdetails/${ext_id}`)
			} else {
				this.props.history.push(`/offeringdetails/${ext_id}`)
			}
		} else {
			this.props.history.push(`/offeringdetails/${ext_id}`)
		}
	}

	
	handleInputChange(e) {
        this.setState({
			comment: e.target.value
		});
	}
	onStarClick(nextValue, prevValue, name) {
		this.setState({commentRating: nextValue});
	}
	onStarHover(nextValue, prevValue, name) {
		// this.setState({commentRating: nextValue});
	}
	onStarHoverOut(nextValue, prevValue, name) {
		// this.setState({commentRating: this.state.commentRating});
	}
	submitComment() {
		if (!(this.state.commentRating) > 0) {
			this.setState({
				errMsg: 'Please select rating',
				showValidationMsg: true,
			});
			return true;
		} else if (this.state.comment === '') {
			this.setState({
				errMsg: 'Please enter comment',
				showValidationMsg: true,
			});
			return true;
		}
		this.setState({
			showValidationMsg: false,
			errMsg: '',
			isSendDisable: true,
		});
		// isAddComment = true;
		const reqData = {
			ext_id: this.state.offeringExtId,
			alias: this.state.userList.alias,
			rating: this.state.commentRating,
			comment: this.state.comment,
		}
		this.props.submitComments(reqData);
	}
	deleteComment(data) {
		this.setState({
			idHash: data.id_hash,
		}, () => {
			// isDeleteComment = true;
			const reqDelete = {
				ext_id: this.state.offeringExtId,
				id_hash: this.state.idHash,
			}
			this.props.submitDeleteComments(reqDelete);
		});
		
	}
	updateComment(data) {
		this.setState({
			comment: data.comment,
			commentRating: data.rating,
			showUpdateCommentBtn: true,
			idHash: data.id_hash,
		}, () => {
		})
	}
	submitUpdateComment() {
		// isEditComment = true;
		const request = {
			ext_id: this.state.offeringExtId,
			alias: this.state.userList.alias,
			rating: this.state.commentRating,
			comment: this.state.comment,
			id_hash: this.state.idHash,
		}
		this.props.submitUpdateComments(request);
		this.setState({
			isUpdateDisable: true,
		});
	}
	pad = (n) => {
		return (n < 10 ? "0" + n : n);
	}
	formatSeconds = (secs) => {
		var h = Math.floor(secs / 3600);
		var m = Math.floor(secs / 60) - (h * 60);
		var s = Math.floor(secs - h * 3600 - m * 60);
		return this.pad(h) + ":" + this.pad(m) + ":" + this.pad(s);
	}
	timmerCode = (time_shown, index) => {
		var time_chunks = time_shown.split(":");
		var seconds = (+time_chunks[0]) * 60 * 60 + (+time_chunks[1]) * 60 + (+time_chunks[2]);
		let timer;
		if (seconds > 0) {
			seconds--;
			timer = this.formatSeconds(seconds);
			const newItems = [...this.state.offeringList];
			newItems[index].timeKey = timer;
			this.setState({ offeringList:newItems });
			this.setState({
				offeringList: this.state.offeringList,
				uiRender: !this.state.uiRender
			}, () => {
				setTimeout( () => {
					this.timmerCode(timer, index);
				}, 1000);
			});
		}else if(seconds===0){
			const newItems = [...this.state.offeringList];
			newItems[index].timeKey = timer;
			newItems[index].sixty_min_mail_time = null;
			this.setState({ offeringList:newItems });
			this.setState({
				offeringList: this.state.offeringList,
				uiRender: !this.state.uiRender
			}, () => {});
		}
	}
	next = () => {
		this.slider.slickNext();
	}
	render() {
		var settings = {
			dots: true,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			autoplay: false,
			afterChange: () =>
				this.setState(state => ({ updateCount: state.updateCount + 1 })),
			beforeChange: (current, next) => this.setState({ slideIndex: next })
		  };
		let filterOfferingData = [];
		if (this.state.showDspFirst === true) {
			if (filterOfferingData) {
				var dspOfferings = this.state.offeringList.filter(item => item.dsp === 1 ||item.dsp === 2)
				var notZero = this.state.offeringList.filter(item => item.acceptingOrders !== 0);
				var notAcceptingOrders = this.state.offeringList.filter(item => item.acceptingOrders === 0);
				const sortedNotZeroData = _.orderBy(notZero, ["available_to_order", "trade_date"], ['desc']);
				const sortedOfferingsData = sortedNotZeroData.concat(notAcceptingOrders);
				const finalArrayData = dspOfferings.concat(sortedOfferingsData)
				const final = Array.from(new Set(finalArrayData))
				filterOfferingData = final
			}
		} else {
			if (filterOfferingData) {
				filterOfferingData = this.state.searching ? this.state.offeringList.filter(row => row.name.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1 || row.ticker_symbol.toLowerCase().indexOf(this.state.searching.toLowerCase()) > -1) : this.state.offeringList;
			}
		}
		

		let newtmpArray=[]
		if(this.state.industrtyname){
			for(let items of this.state.industrtyname){
				for(let item of filterOfferingData){
					if(item.industry){
						if(item.industry === items.name){
							newtmpArray.push(item)
						}
					}
				}
			}
			var filteredData = filterOfferingData;
			if(this.state.industrtyname !== newtmpArray) {
				filterOfferingData = newtmpArray
				if(this.state.industrtyname.length === 0) {
					filterOfferingData = filteredData
				}
			}
		}

		if(filterOfferingData){
			if(this.state.sortby === "name"){
				filterOfferingData = _.orderBy(filterOfferingData, ['name'] ,['asc']);
			}
			if(this.state.sortby === "acceptingorders"){
				filterOfferingData = _.filter(filterOfferingData, { 'available_to_order': 1 },['desc']);
			}
			if(this.state.sortby === "default"){
				filterOfferingData = _.orderBy(filterOfferingData, ['name'] ,['asc']);
				filterOfferingData = _.orderBy(filterOfferingData, ['available_to_order','trade_date'] ,['desc']);
			}
			if(this.state.sortby === "industry"){
				filterOfferingData.sort(function(a, b){
					if (a.industry === null) {
						return 1;
					} else if (b.industry === null) {
						return -1;
					} else if (a.industry === b.industry) {
						return 0;
					} else {
						return a.industry < b.industry ? -1 : 1;
					}
				})
			}
		}

		// const Header = (<div className="offer_head">Offerings</div>);
		const industriesname = this.state.offeringindustriesList;
		return (
			<Dashboard title="ClickIPO User" pageTitle="Offerings" >
				<div className="offering_content">
					<div className="row mb-15px pt-30px pl-pr-15px xs-pl-pr-0px offering_filters_row">
						<div className="col-sm-6 col-md-4">
							<div className="form-group mb-0">
								{/* <select className="form-control" name="selectoption" onChange={this.sortoffering}>
									<option value="default">Sort By</option>
									<option value="name">Name</option>
									<option value="industry">Industry</option>
									<option value="acceptingorders">Available to order</option>
								</select> */}
								<FormControl style={{width: '100%'}}>
									<Select
									value={this.state.sortby}
									onChange={this.sortoffering}
									>
										<MenuItem value="default">
											<em>Sort By</em>
										</MenuItem>
										<MenuItem value="name">Name</MenuItem>
										<MenuItem value="industry">Industry</MenuItem>
										<MenuItem value="acceptingorders">Available to order</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
						<div className="col-sm-6 col-md-4">
							<div className="form-group mb-0">
								<MultiSelect optionLabel="name" filter={true} value={this.state.industrtyname} options={industriesname} onChange={this.filterOffering} style={{width: "100%", border: "1px solid #ced4da",height: "38px"}} placeholder="Filter By"/>
							</div>
						</div>
						<div className="col-sm-12 col-md-4">
							<form role="search">
								<div>
									<TextField placeholder="Search" value={this.state.searching} name="srch-term" id="srch-term" type="text" onChange={(e) => this.setState({searching: e.target.value})} style={{ width: '100%' }} />
								</div>
							</form>
						</div>
					</div>
					<div className="offering_box">
						<div className="active_offering_box">
							<div className="row ml-mr-0px-imp">
								{
									filterOfferingData.length === 0 && (!isOfferingList) &&
									<div className="col-sm-12 col-md-12 col-xl-12" style={{ textAlign: 'center', marginTop: 50, fontSize: 20 }}>Currently no offerings are available</div>
								}
								{
									filterOfferingData && filterOfferingData.length > 0 && filterOfferingData.map((offeringitem, i) =>
										<div className="col-sm-6 col-md-6 col-xl-4 dsp-indication" key={i}>
											<div className="offering_box_inner_content shadow_box">
												<div className="row ml-mr-0px-imp pb-0">
													{
														(offeringitem.dsp === 1 || offeringitem.dsp === 2) &&
														<div className="dsp-indication-image"><img src={dspBatchImg} alt='dsp-indication' /></div>
													}
													{
														offeringitem.read === true ?
														<Tooltip title="Read Offering" placement="bottom">
														<div className="offering-read-indication"></div>
														</Tooltip>
														:
														<Tooltip title="Unread Offering" placement="bottom">
														<div className="offering-unread-indication"></div>
														</Tooltip>
													}
													<div className="col-12 goOfferingDetails">
														<div className="row ml-mr-0px-imp pb-0" onClick={() => this.goOfferingDetails(offeringitem.ext_id, offeringitem.read, offeringitem.has_order)}>
															<div className="col-12 text-center">
																<LazyLoad debounce={true}>
																	<img src={offeringitem.logo_small} onError={this.addDefaultSrc} className="employe_images" alt={'clickipo'} />
																</LazyLoad>
															</div>
															<div className="col-12 offering_list">
																<div style={{ cursor: 'pointer' }}>
																	<div className="offering-name-box">
																	<p className={(offeringitem.read ? "company_name_read" : "company_name") + " mb-5px"}>
																		{offeringitem.name}
																	</p>
																	</div>
																	<p className={(offeringitem.read ? "company_tag_read" : "company_tag")}>{offeringitem.ticker_symbol}</p>
																	<div className="row mb-5px">
																		<div className="col col-6">
																			<p className={"company_account " + (offeringitem.offering_type_name === 'IPO' ? "green_text" : offeringitem.offering_type_name === 'Follow-on' ? "turquoise_text" : offeringitem.offering_type_name === 'ETF' ? "etf_text" : offeringitem.offering_type_name === 'UIT' ? "uit_text" : "green_text") }>{(offeringitem.offering_type_name)}
																			{
																			(offeringitem.dsp === 1 || offeringitem.dsp === 2) &&
																			<span style={{ color: '#000', paddingLeft: '0.525rem' }}>(DSP)</span>	
																			}
																			</p>
																		</div>
																		<div className="col col-6">
																			<p className={"account_open_date account_open_date_two " +  (offeringitem.offering_type_name === 'IPO' ? "green_text" : offeringitem.offering_type_name === 'Follow-on' ? "turquoise_text" : offeringitem.offering_type_name === 'ETF' ? "etf_text" : offeringitem.offering_type_name === 'UIT' ? "uit_text" : "green_text") }>
																				{offeringitem.industry}
																				</p>
																		</div>
																	</div>
																	<div className="row mb-5px">
																		<div className="col col-6">
																			<p className="account_type">Anticipated</p>
																		</div>
																		<div className="col col-6">
																			<p className="account_open_date account_open_date_two">           {getDate(offeringitem)}
																			</p>
																		</div>
																	</div>
																	{
																		offeringitem.offering_type_name === 'IPO' &&
																		<div className="row">
																			<div className="col col-6">
																				<p className="price_level_type">
																					Price Range
																				</p>
																			</div>
																			<div className="col col-6">
																				<p className="amount">
																					{getOfferingDetailsPrice(offeringitem)}
																				</p>
																			</div>
																		</div>
																	}
																	{
																		(offeringitem.offering_type_name === 'Follow-on' || offeringitem.offering_type_name === 'ETF' || offeringitem.offering_type_name === 'UIT') &&
																		<div className="row">
																			<div className="col col-6">
																				<p className="price_level_type">
																					Last closing price
																				</p>
																			</div>
																			<div className="col col-6">
																				<p className="amount">
																					{getOfferingDetailsPrice(offeringitem)}
																				</p>
																			</div>
																		</div>
																	}
																	{
																		offeringitem.offering_type_name === 'Block' &&
																		<div className="row">
																			<div className="col col-6">
																				<p className="price_level_type">
																					Last closing price
																				</p>
																			</div>
																			<div className="col col-6">
																				<p className="amount">
																					{getOfferingDetailsPrice(offeringitem)}
																				</p>
																			</div>
																		</div>
																	}
																	{
																		offeringitem.offering_type_name === 'Spot' &&
																		<div className="row">
																			<div className="col col-6">
																				<p className="price_level_type">
																					Last closing price
																				</p>
																			</div>
																			<div className="col col-6">
																				<p className="amount">
																					{getOfferingDetailsPrice(offeringitem)}
																				</p>
																			</div>
																		</div>
																	}
																	{
																		offeringitem.offering_type_name === 'Overnight' &&
																		<div className="row">
																			<div className="col col-6">
																				<p className="price_level_type">
																					Last closing price
																				</p>
																			</div>
																			<div className="col col-6">
																				<p className="amount">
																					{getOfferingDetailsPrice(offeringitem)}
																				</p>
																			</div>
																		</div>
																	}
																	{
																		(offeringitem.active_window !== '' && offeringitem.active_window !== null) &&
																		<div className="row">
																			<div className="col col-6">
																				<p className="price_level_type">
																					Time remaining
																				</p>
																			</div>
																			<div className="col col-6">
																				<p className="amount">
																					{offeringitem.active_window}
																				</p>
																			</div>
																		</div>
																	}
																</div>
															</div>
														</div>
													</div>
													{
														offeringitem.available_to_order === 1 &&
														<div className="col col-12 button-groups">
															{
																offeringitem.has_order === false && 
																<div className="order_now_button">
																	<Button variant="contained" color="primary"  size="large" component={Link} to={`/offeringdetails/${offeringitem.ext_id}`}>
																		Order Now
																	</Button>
																</div>
															}
															{
																offeringitem.has_order === true && 
																<div className="order_now_button">
																	<Button variant="contained" color="primary" size="large" component={Link} to={`/offeringdetails/${offeringitem.ext_id}`}>
																		Modify
																	</Button>
																</div>
															}
															{
																(offeringitem.followed === true) && 
																<div className="order_now_interested_button">
																<Button 
																className={(offeringitem.offering_type_name === 'IPO' ? "ipo_interested_btn" : offeringitem.offering_type_name === 'Follow-on' ? "turquoise_interested_btn" : offeringitem.offering_type_name === 'ETF' ? "etf_interested_btn" : offeringitem.offering_type_name === 'UIT' ? "uit_interested_btn" : "ipo_interested_btn")} onClick={() => this.follow(offeringitem.ext_id, false, offeringitem.name, offeringitem)}>
																	Interested
																</Button>
																</div>
															}
															{(offeringitem.followed === false) &&
																<div className={"order_now_interested_button " +
																(offeringitem.offering_type_name === 'IPO' ? "not_ipo_interested_btn" : "" )}>
																<Button onClick={() => this.follow(offeringitem.ext_id, true, offeringitem.name, offeringitem)}>
																	Interested?
																</Button>
																</div>
															}
														</div>
													}
													{
														offeringitem.available_to_order === 0 &&
														<div className="col col-12 button-groups">
															{
																offeringitem.followed === true &&
																<div className={"follow_button order_now_interested_button order_now_interested_button_two " + 
																(offeringitem.offering_type_name !== 'IPO' ? "not_ipo_btn" : "" )}>
																<Button className="interested_btn" onClick={() => this.follow(offeringitem.ext_id, false,  offeringitem.name,offeringitem)}>
																	Interested
																</Button>
																</div>
															}
															{
																offeringitem.followed !== true &&
																<div className={"order_now_interested_button order_now_interested_button_two   " +
																(offeringitem.offering_type_name === 'IPO' ? "not_ipo_interested_btn" : "" )}>
																	<Button onClick={() => this.follow(offeringitem.ext_id, true,  offeringitem.name,offeringitem)}>Interested?</Button>
																</div>
															}
														</div>
													}
													{
														(offeringitem.available_to_order === 2 && offeringitem.sixty_min_mail_time === null) &&
														<div className="col col-12 button-groups">
															<div className="order_now_button">
																<Tooltip title="Order book closed" placement="bottom">
																	<Button variant="contained" color="primary" size="large" component={Link} to={`/offeringdetails/${offeringitem.ext_id}`}>
																		Order book closed
																	</Button>
																</Tooltip>
															</div>
															{
																(offeringitem.followed === true) && 
																<div className="order_now_interested_button">
																<Button 
																className={(offeringitem.offering_type_name === 'IPO' ?"ipo_interested_btn" : "turquoise_interested_btn")} onClick={() => this.follow(offeringitem.ext_id, false, offeringitem.name, offeringitem)}>
																	Interested
																</Button>
																</div>
															}
															{(offeringitem.followed === false) &&
																<div className={"order_now_interested_button " +
																(offeringitem.offering_type_name === 'IPO' ? "not_ipo_interested_btn" : "" )}>
																<Button onClick={() => this.follow(offeringitem.ext_id, true, offeringitem.name, offeringitem)}>
																	Interested?
																</Button>
																</div>
															}
														</div>
													}
													{
														(offeringitem.available_to_order === 2 && offeringitem.sixty_min_mail_time !== null) &&
														<div className="col col-12 button-groups">
															<div className="order_now_button">
																<Link to={`/offeringdetails/${offeringitem.ext_id}`}>
																	<button style={{background: 'red', lineHeight: '18px'}}>
																		{offeringitem.timeKey}
																	</button>
																</Link>
															</div>
															{
																(offeringitem.followed === true) && 
																<div className="order_now_interested_button">
																<Button 
																className={(offeringitem.offering_type_name === 'IPO' ?"ipo_interested_btn" : "turquoise_interested_btn")} onClick={() => this.follow(offeringitem.ext_id, false, offeringitem.name, offeringitem)}>
																	Interested
																</Button>
																</div>
															}
															{(offeringitem.followed === false) &&
																<div className={"order_now_interested_button " +
																(offeringitem.offering_type_name === 'IPO' ? "not_ipo_interested_btn" : "" )}>
																<Button onClick={() => this.follow(offeringitem.ext_id, true, offeringitem.name, offeringitem)}>
																	Interested?
																</Button>
																</div>
															}
														</div>
													}
												</div>
											</div>
										</div>
									)
								}
							</div>
							<Dialog
								onClose={this.closeComment}
								aria-labelledby="customized-dialog-title"
								open={this.state.openCommentModal}
								maxWidth={"md"}
								fullWidth={true}
								scroll={'paper'}
								>
								<DialogTitle onClose={this.closeComment}>Comments</DialogTitle>
								<DialogContent>
									<div>
										<div className="comment-modal-box">
											<div className="comment-modal-box-content">
												{
													(this.state.commentList && this.state.commentList.length > 0) && this.state.commentList.map((op, i) =>
														<div className="comment-list" key={i}>
															{
																(op.alias === this.state.userList.alias) &&
																<div className="edit-delete-btn">
																	<Button className="btn edit-btn-comment" onClick={() => this.updateComment(op)} ><EditIcon /></Button>
																	<Button className="btn delete-btn-comment" onClick={() => this.deleteComment(op)}><DeleteIcon /></Button>
																</div>
															}
															<div className="comment-list-alies">{op.alias}</div>
															<div className="comment-list-rating">
																<StarRatingComponent 
																	name="rate1" 
																	starCount={5}
																	value={op.rating}
																	editing={false}
																	renderStarIcon={(index, value) => {
																		return (
																		<span>
																			{
																				index <= value ?
																				<StarIcon />
																				:
																				<StarBorderIcon />
																			}
																		</span>
																		);
																	}}
																/>
																<span className="updated-date">{op.updated_at}</span>
															</div>
															<div className="comment-list-comment">{op.comment}</div>
														</div>
													)
												}
												{
													(this.state.showCommentLoader) &&
													<div className="comment-loading"><img src={SpinnImg} alt='loader' /></div>
												}
												{
													(this.state.commentList) && !(this.state.showCommentLoader) && (this.state.commentList.length === 0) &&
													<div style={{ marginTop: 20, fontSize: 20, textAlign: 'center' }}>No comments found</div>
												}
												
											</div>
											<div className="comment-modal-box-footer">
											
												<div className="comment-footer-rating">
													{/* <div className="comment-footer-rating-title">Rate and Comment</div> */}
													<StarRatingComponent 
														name="rate1" 
														starCount={5}
														value={this.state.commentRating}
														editing={true}
														onStarClick={this.onStarClick.bind(this)} 
														renderStarIcon={(index, value) => {
															return (
															<span>
																{
																	index <= value ?
																	<StarIcon />
																	:
																	<StarBorderIcon />
																}
															</span>
															);
														}}
													/>
												</div>
												{
													(this.state.showValidationMsg) &&
													<div style={{ color: 'red', textAlign: 'center' }}>{this.state.errMsg}</div>
												}
												<div className="input-group input-group-sm chatMessageControls">
													<textarea className="form-control" style={{ height: '50px', marginRight: '17px' }} placeholder="Type your comments here.." value={this.state.comment || ''} onChange={this.handleInputChange} />    
													<span className="input-group-btn comment-submit-btn">
														{
															this.state.showUpdateCommentBtn === false &&
															<Button id="sendMessageButton" className="btn btn-primary" disabled={this.state.isSendDisable} onClick={() => this.submitComment()}><SendIcon /></Button>
														}
														{
															this.state.showUpdateCommentBtn === true &&
															<Button id="sendMessageButton" className="btn btn-primary"  disabled={this.state.isUpdateDisable} onClick={() => this.submitUpdateComment()}><SendIcon /></Button>
														}
													</span>
												</div>
											</div>
										</div>
									</div>
								</DialogContent>
							</Dialog>
							<Dialog
								onClose={this.closeOnBoarding}
								aria-labelledby="customized-dialog-title"
								open={this.state.openOnBoardingModal}
								maxWidth={"md"}
								fullWidth={true}
								scroll={'paper'}
								>
								<DialogContent>
									<div className="onboarding-modal-style " style={{ background: '#fff', paddingTop: '1.5rem', paddingBottom: '1.5rem' }}>
										<div className="onboarding-modal-box">
											<Slider ref={c => (this.slider = c)} {...settings}>
												<div>
													<div className="slider-box">
														<div className="slider-title">Research, follow, share and invest in IPO and follow-on offerings</div>
														<div className="slider-caption">ClickIPO provides individual investors access to public offering information and the ability to invest in companies going public. You'll be able to purchase shares before they begin trading on the stock exchange!</div>
													</div>
												</div>
												<div>
													<div className="slider-box">
														<div className="slider-title">Offering Availability</div>
														<div className="slider-caption-vailability">Not all offerings will be available to order. Offerings that ClickIPO is participating in will be made available to purchase once the following events have occurred...</div>
														<div className="slider-caption-vailabilityone">1) An anticipated date is set</div>
														<div className="slider-caption-vailability">2) A price range is set</div>
														<div className="slider-caption-vailability">Click <span style={{ color: "#8DC73F", cursor: 'pointer'}}>Interested?</span> on an offering to get notified when an offering becomes available to order. You will also be notified the price and anticipated date are updated.</div>
													</div>
												</div>
											</Slider>
										</div>
									</div>
								</DialogContent>
								<DialogActions>
									<div className="text_center">
										{
											this.state.slideIndex === 1 ? 
											<Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => this.closeOnBoarding()}>Continue</Button>
											:
											<Button variant="contained" color="primary" style={{ minWidth: 100, marginLeft: 10, marginRight: 10 }} onClick={() => this.next()}>Continue</Button>
										}
									</div>
								</DialogActions>
							</Dialog>
						</div>
					</div>
				</div>
			</Dashboard>
		)
	}
}

OfferingComponent.propTypes = {
	offeringRes: PropTypes.any,
	offeringindustriesRes: PropTypes.any,
	saveOfferingsRes: PropTypes.any,
	GetprofileRes: PropTypes.any,
	readofferingRes: PropTypes.any,
	userActivityRes: PropTypes.any,
	allCommentRes: PropTypes.any,
	commentRes: PropTypes.any,
	deleteCommentRes: PropTypes.any,
	updateCommentRes: PropTypes.any,
	getOfferingIndustries: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	offeringRes: doOfferingRes,
	offeringindustriesRes: doOfferingIndustriesRes,
	saveOfferingsRes: dosaveOfferingRes,
	GetprofileRes: doEditprofileRes,
	readofferingRes: doReadOfferingRes,
	userActivityRes: doUserActivityRes,
	allCommentRes: doAllCommentRes,
	commentRes: doCommentRes,
	deleteCommentRes: doDeleteCommentRes,
	updateCommentRes: doUpdateCommentRes,
});

function mapDispatchToProps(dispatch) {
	return {
		getOfferings: (data) => dispatch(get_offering(data)),
		getOfferingIndustries: (data) => dispatch(get_offering_industries(data)),
		saveOfferings: (data) => dispatch(save_offering(data)),
		getProfile: () => dispatch(submit_profile()),
		readOffering: (data) => dispatch(read_offering(data)),
		userActivity: (data) => dispatch(user_activity(data)),
		getAllComments: (data) => dispatch(getAllComment(data)),
		submitComments: (data) => dispatch(submitComment(data)),
		submitDeleteComments: (data) => dispatch(submitDeleteComment(data)),
		submitUpdateComments: (data) => dispatch(submitUpdateComment(data)),
		doCommentRes: (data) => dispatch(doCommentRes(data)),
		doUpdateCommentRes: (data) => dispatch(doUpdateCommentRes(data)),
		doDeleteCommentRes: (data) => dispatch(doDeleteCommentRes(data)),
		doOfferingRes: (data) => dispatch(doOfferingRes(data)),
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OfferingComponent);
