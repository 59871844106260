import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function (state = initialState.user, action) { 
  switch (action.type) {
    case types.GET_TWITTER_AUTH_URL_RES:
      return action.data.data
    default:
      return state
  }
}