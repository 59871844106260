export default function validate(values) {
    const errors = {};
    if (values.email) {
        const re = new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i);
        if (!re.test(values.email)) {
            errors.email = 'Please enter correct email address';
        }
    }
    if (!values.email) {
        errors.email = 'Please enter email';
    }
    if (!values.password) {
        errors.password = 'Please enter password';
    }
    if (!values.address1) {
        errors.address1 = 'Please enter address1';
    }
    if (!values.country) {
        errors.country = 'Please select country';
    }
    if (!values.state) {
        errors.state = 'Please select state';
    }
    if (!values.city) {
        errors.city = 'Please select city';
    }
    if (!values.code) {
        errors.code = 'Please enter postal code';
    }

    if (!values.FirstName) {
        errors.FirstName = 'Please enter first name';
    }
    if (!values.LastName) {
        errors.LastName = 'Please enter last name';
    }
    if (!values.Login) {
        errors.Login = 'Please enter username';
    }
    if (!values.Salutation) {
        errors.Salutation = 'Please select salutation';
    }
    if (!values.Suffix) {
        errors.Suffix = 'Please select suffix';
    }
    if (!values.PrimaryPhone) {
        errors.PrimaryPhone = 'Please enter primary phone number';
    }
    
    return errors;
}