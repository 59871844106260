
import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

class resendEmailApi {
    static doResendEmail(data) {
        const TOKEN = getItem('auth_token');
        let sendBody={
            email: data.email,
        }
        let isError = false;

        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body: JSON.stringify(sendBody)
            }
            return fetch(BASE_URL + '/users/signup/resend_verification_email', body).then(response => {
                if (response.ok === true) {
                    Swal.fire({
                        title: 'Resend Verification successful',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: true,
                        timer: 3000,
                    })
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default resendEmailApi;
