import { combineReducers } from 'redux';
import userReducer from './userReducer';
import offeringReducer from './offeringReducer';
import orderReducer from './orderReducer';
import brokerdealerReducer from './brokerdealerReducer'
import { reducer as formReducer } from 'redux-form';
import faqReducer from './faqReducer';
import glossaryReducer from './glossaryReducer';
import offeringdetailsReducer from './offeringdetailsReducer'
import editprofileReducer from './editprofileReducer';
import loadingReducer from './loadingReducer';
import orderdetailsReducer from './orderdetailsReducer';
import updateorderReducer from './updateorderReducer';
import offeringsaveReducer from './offeringsaveReducer';
import connectbrokerageReducer from './connectbrokerageReducer';
import connectdealeraccountReducer from './connectdealeraccountReducer';
import brokerdealerdisconnectionReducer from './brokerdealerdisconnectionReducer';
import tradestationReducer from './tradestationReducer';
import socialLoginReducer from './socialLoginReducer';
import getprofileReducer from './getprofileReducer';
import deleteorderReducer from './deleteorderReducer';
import accountsbuyingpowerReducer from './accountsbuyingpowerReducer';
import activeorderReducer from './activeorderReducer';
import placeorderReducer from './placeorderReducer';
import readofferingReducer from './readofferingReducer';
import userreconfirmReducer from './userreconfirmReducer';
import stocktwitsReducer from './stocktwitsReducer';
import offeringindustriesReducer from './offeringindustriesReducer';
import linkedInReducer from './linkedInReducer';
import linkedInuserdataReducer from './linkedInuserdataReducer';
import deletesocialaccountReducer from './deletesocialaccountReducer';
import prospectusReducer from './prospectusReducer';
import securitySymbolReducer from './securitySymbolReducer';
import marketbrokerageReducer from './marketbrokerageReducer';
import restrictedPersonReducer from './restrictedPersonReducer';
import socialTokenReducer from './socialTokenReducer';
import newBrokerDealerConnectionReducer from './newBrokerDealerConnectionReducer';
import authBrokerConnectionTokenReducer from './authBrokerConnectionTokenReducer';
import TwitterAuthUrlReducer from './TwitterAuthUrlReducer';
import FacebookAuthUrlReducer from './FacebookAuthUrlReducer';
import SocialMediaAuthUrlReducer from './SocialMediaAuthUrlReducer';
import bizInsightsReducer from './bizInsightsReducer';
import userActivityReducer from './userActivityReducer';
import messageReducer from './messageReducer';
import messageDetailReducer from './messageDetailReducer';
import messageDetailMobReducer from './messageDetailMobReducer';
import offeringListReducer from './offeringListReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import verifyCodeReducer from './verifyCodeReducer';
import allCommentReducer from './allCommentReducer';
import commentOfferingReducer from './commentOfferingReducer';
import deleteCommentOfferingReducer from './deleteCommentOfferingReducer';
import updateCommentOfferingReducer from './updateCommentOfferingReducer';
import profilePictureReducer from './profilePictureReducer';
import attestationDspReducer from './attestationDspReducer';
import createBrokerageAccReducer from './createBrokerageAccReducer';
import prospectusFirstViewReducer from './prospectusFirstViewReducer';
import createBrokerLoadingReducer from './createBrokerLoadingReducer';
import checkPlannerApplicationReducer from './checkPlannerApplicationReducer';
import plannerUserAccountReducer from './plannerUserAccountReducer';
import plannerTradingAccountReducer from './plannerTradingAccountReducer';
import fileUploadForPlannerReducer from './fileUploadForPlannerReducer';
import firebaseTokenReducer from './firebaseTokenReducer';

export default combineReducers({
  form: formReducer,
  user: userReducer,
  offering: offeringReducer,
  order: orderReducer,
  brokerdealer: brokerdealerReducer,
  faq: faqReducer,
  glossary: glossaryReducer,
  offeringdetails: offeringdetailsReducer,
  offeringsave: offeringsaveReducer,
  editprofile: editprofileReducer,
  isLoading: loadingReducer,
  orderdetails: orderdetailsReducer,
  updateorder: updateorderReducer,  
  brokerdealerdisconnect: brokerdealerdisconnectionReducer,
  connectbrokerage: connectbrokerageReducer,
  connectdealeraccount: connectdealeraccountReducer,
  tradestation: tradestationReducer,
  sociallogin: socialLoginReducer,
  getprofile: getprofileReducer,
  deleteorder: deleteorderReducer,
  accountsbuyingpower: accountsbuyingpowerReducer,
  activeorder: activeorderReducer,
  placeorder: placeorderReducer,
  readoffering: readofferingReducer,
  userconfirm: userreconfirmReducer,
  stocktwits: stocktwitsReducer,
  offeringindustries: offeringindustriesReducer,
  linkedin: linkedInReducer,
  linkedinuserdata: linkedInuserdataReducer,
  deletesocialaccount: deletesocialaccountReducer,
  prospectus: prospectusReducer,
  symbol: securitySymbolReducer,
  marketbrokerage: marketbrokerageReducer,
  restrictedperson: restrictedPersonReducer,
  socialtoken: socialTokenReducer,
  newBrokerDealerConnection: newBrokerDealerConnectionReducer,
  authBrokerConnectionToken: authBrokerConnectionTokenReducer,
  twitterAuthUrl: TwitterAuthUrlReducer,
  facebookAuthUrl: FacebookAuthUrlReducer,
  socialMediaAuthUrl: SocialMediaAuthUrlReducer,
  bizInsights: bizInsightsReducer,
  userActivity: userActivityReducer,
  messages: messageReducer,
  messageDetail: messageDetailReducer,
  messageDetailMob: messageDetailMobReducer,
  offeringList: offeringListReducer,
  forgotPassword: forgotPasswordReducer,
  verifyCode: verifyCodeReducer,
  commentList: allCommentReducer,
  commentOffering: commentOfferingReducer,
  deleteComment: deleteCommentOfferingReducer,
  updateComment: updateCommentOfferingReducer,
  profilePicture: profilePictureReducer,
  attestationDsp: attestationDspReducer,
  createBrokerageAcc: createBrokerageAccReducer,
  prospectusFirstView: prospectusFirstViewReducer,
  createBrokerLoading: createBrokerLoadingReducer,
  checkPlannerApp: checkPlannerApplicationReducer,
  plannerUserAccount: plannerUserAccountReducer,
  plannerTradingAccount: plannerTradingAccountReducer,
  fileUploadForPlanner: fileUploadForPlannerReducer,
  firebaseToken: firebaseTokenReducer,
});