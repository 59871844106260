
export default function validateFinal(values) {
    const errorFinal = {};
    if (!values.dateOfBirth) {
        errorFinal.dateOfBirth = 'Please select date of birth';
    }

    if (!values.citizenshipCountry) {
        errorFinal.citizenshipCountry = 'Please select citizenship country';
    }
    if (!values.userFirstName) {
        errorFinal.userFirstName = 'Please enter first name';
    }
    
    if (!values.userLastName) {
        errorFinal.userLastName = 'Please enter last name';
    }
    if (!values.primaryEmail) {
        errorFinal.primaryEmail = 'Please enter primary email';
    }
    if (values.primaryEmail) {
        var pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!pattern.test(values.primaryEmail)) {
            errorFinal.primaryEmail = 'Please enter correct email address ';
        }
    }
    if (values.secondaryEmail) {
        var patternEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!patternEmail.test(values.secondaryEmail)) {
            errorFinal.secondaryEmail = 'Please enter correct email address ';
        }
    }
    
    if (!values.phoneNumber) {
        errorFinal.phoneNumber = 'Please enter phone number';
    }
    if (!values.homeStreetAddress) {
        errorFinal.homeStreetAddress = 'Please enter home street address';
    }
    if (!values.homeCity) {
        errorFinal.homeCity = 'Please enter home city';
    }
    if (!values.homeCountry) {
        errorFinal.homeCountry = 'Please select home country';
    }
    if (!values.isPoliticallyExposed) {
        errorFinal.isPoliticallyExposed = 'Please select Is politically exposed';
    }
    if (!values.isControlPerson) {
        errorFinal.isControlPerson = 'Please select Is control person';
    }
    if (!values.timeHorizon) {
        errorFinal.timeHorizon = 'Please select time horizon';
    }
    // if (!values.isTradeAuthorization) {
    //     errorFinal.isTradeAuthorization = 'Please select is trade authorization';
    // }
    if (!values.isForeign) {
        errorFinal.isForeign = 'Please select is foreign';
    }
    if (!values.liquidityNeeds) {
        errorFinal.liquidityNeeds = 'Please select liquidity needs';
    }
    if (!values.sweepInstructions) {
        errorFinal.sweepInstructions = 'Please select sweep instructions';
    }
    if (!values.issuerDirectCommunication) {
        errorFinal.issuerDirectCommunication = 'Please select issuer direct communication';
    }
    if (!values.householdingService) {
        errorFinal.householdingService = 'Please select house holding service';
    }
    if (!values.dividendReinvestment) {
        errorFinal.dividendReinvestment = 'Please select dividend reinvestment';
    }
    if (!values.riskTolerance) {
        errorFinal.riskTolerance = 'Please select risk tolerance';
    }
    if (!values.investmentObjective) {
        errorFinal.investmentObjective = 'Please select investment objective';
    }
    if (!values.investmentExperience) {
        errorFinal.investmentExperience = 'Please select investment experience';
    }
    if (!values.federalTaxBracketPercent) {
        errorFinal.federalTaxBracketPercent = 'Please enter federal tax bracket percent';
    }
    if (!values.customerType) {
        errorFinal.customerType = 'Please select customer type';
    }
    if (!values.catAccountholderType) {
        errorFinal.catAccountholderType = 'Please select account holder type';
    }
    if (!values.maritalStatus) {
        errorFinal.maritalStatus = 'Please select marital status';
    }
    if (!values.socialSecurityNumber) {
        errorFinal.socialSecurityNumber = 'Please enter social security number';
    }
    if (!values.prefix) {
        errorFinal.prefix = 'Please select prefix';
    }
    if (!values.birthCountry) {
        errorFinal.birthCountry = 'Please select birth country';
    }
    
    if (!values.isAffiliatedExchangeOrFINRA) {
        errorFinal.isAffiliatedExchangeOrFINRA = 'Please select Is Affiliated Exchange Or FINRA';
    }
    if (values.isAffiliatedExchangeOrFINRA) {
        if (values.isAffiliatedExchangeOrFINRA === 'YES') {
            if (!values.firmName) {
                errorFinal.firmName = 'Please enter firm name';
            }
        }
    }
    if (!values.businessStreetAddress) {
        errorFinal.businessStreetAddress = 'Please enter business street address';
    }
    if (!values.businessCity) {
        errorFinal.businessCity = 'Please enter business city';
    }
    if (!values.businessCountry) {
        errorFinal.businessCountry = 'Please select business country';
    }
    if (!values.businessState) {
        errorFinal.businessState = 'Please select business state';
    }
    if (!values.businessPostalCode) {
        errorFinal.businessPostalCode = 'Please enter business postal code';
    }
    
    if (!values.businessPhoneNumber) {
        errorFinal.businessPhoneNumber = 'Please enter business phone number';
    }
    
    if (!values.annual_inc_min) {
        errorFinal.annual_inc_min = 'Please enter minimum annual income';
    }
    if (!values.annual_inc_max) {
        errorFinal.annual_inc_max = 'Please enter maximum annual income';
    }
    if (values.annual_inc_min && values.annual_inc_max) {
        if (parseFloat(values.annual_inc_max) < parseFloat(values.annual_inc_min)) {
            errorFinal.annual_inc_max = 'Please enter maximum annual income greater than minimum annual income';
        }
    }
    if (!values.liq_networth_max) {
        errorFinal.liq_networth_max = 'Please enter maximum liquid net worth';
    }
    if (!values.liq_networth_min) {
        errorFinal.liq_networth_min = 'Please enter minimum liquid net worth';
    }
    if (values.liq_networth_min && values.liq_networth_max) {
        if (parseFloat(values.liq_networth_max) < parseFloat(values.liq_networth_min)) {
            errorFinal.liq_networth_max = 'Please enter maximum liquid net worth greater than minimum liquid net worth';
        }
    }
    if (!values.tot_networth_max) {
        errorFinal.tot_networth_max = 'Please enter maximum total net worth';
    }
    if (!values.tot_networth_min) {
        errorFinal.tot_networth_min = 'Please enter minimum total net worth';
    }
    if (values.tot_networth_min && values.tot_networth_max) {
        if (parseFloat(values.tot_networth_max) < parseFloat(values.tot_networth_min)) {
            errorFinal.tot_networth_max = 'Please enter maximum total net worth greater than minimum total net worth';
        }
    }
    if (!values.fileUrl) {
        errorFinal.fileUrl = 'Please choose file';
    }
    if (values.isControlPerson) {
        if (values.isControlPerson === 'YES') {
            if (!values.companySymbol) {
                errorFinal.companySymbol = 'Please enter company symbol';
            }
        }
    }
    return errorFinal;
}