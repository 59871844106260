import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './placeOrder.css';
import Dashboard from '../../component/Dashboard';
import PropTypes from 'prop-types';
import { get_offering_details, doOfferingDetailsRes } from '../../actions/offeringDetailsActions';
import { place_order, doPlaceOrderRes } from '../../actions/placeOrderActions';
import { submit_profile, doEditprofileRes } from '../../actions/editProfileActions';
import { get_accounts_buying_power, doAccountsBuyingPowerRes } from '../../actions/accountsBuyingPowerActions';
import { get_prospectus, doProspectusRes } from '../../actions/prospectusActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { getDate, getOfferingDetailsPrice, numberWithCommas, loadScript, numberWithCommasAndDollar } from '../../helper/helper';
import { update_profile, doUpdateprofileRes } from '../../actions/updateProfileActions';
import PlaceHolderImg from '../../assets/images/placeholder_company_thumb.png';
import { PDF_FINRARULE_ZERO, PDF_FINRARULE_ONE } from '../../constants';
import BackIcon from '../../assets/images/images/icon-left.svg';
import DocIcon from '../../assets/images/doc-icon.svg';
import SuccessIcon from '../../assets/images/success.gif';

import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DOMPurify from 'dompurify';
const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(),
		alignItems: 'center',
		justifyContent: 'center'
	},
}))(MuiDialogActions);
let isOfferingDetail = false;
let isProfile = false;
let isAccountBuying = false;
let isPlaceOrder = false;
class PlaceOrderComponent extends React.PureComponent {

    constructor() {
        super();
        isOfferingDetail = false;
        isProfile = false;
        isAccountBuying = false;
        isPlaceOrder = false;
        this.state = {
            isLoader: false,
            reviewOrder: false,
            value: '',
            Ischecked: true,
            brokerdealername: '',
            approximateshares:'',
            brokerconnectionList:'',
            rule5130Modal: false,
            rule5131Modal: false,
            defaultOrderAmount:'',
            defaultOrderAmountSet:'',
            orderAmountValue: '',
            buyingPowerAmount: '',
            open: false,
            buttonOrder: false,
            minAmount:'',
            minAmountModal:false,
            orderConfirmModal: false,
            placeorderAmount:'',
            price:'',
            placeorderList:'',
            prospectusModal:false,
            accountId: '',
            brokerDealerMpid: '',
            screenId: '1.1',
            showDefaultAmountCheckbox: false,
            isCheckDefaultAmount: false,
            isEditValue: false,
            attestation_to_rules_5130_and_5131: 1,
            openUnitIncErrorModal: false,
            orderConfirmMsg: '',
            openForExisteMinMaxTicket: false
        }
        this.goChange = this.goChange.bind(this);
        this.checkChange = this.checkChange.bind(this);
        this.checkDefaultAmountChange = this.checkDefaultAmountChange.bind(this);
    }

    componentDidMount() {
        loadScript();
        const id = this.props.match.params.id;
        isOfferingDetail = true;
        isProfile = true;
        isAccountBuying = true;
        this.props.getOfferingsDetails(id);
        this.props.accountsBuyingPower();
        this.props.getProfile();
        const activityData = {
                'ext_id': id,
                'screen_id': this.state.screenId,
                'source': 'web'
        }
        this.props.userActivity(activityData)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.offeringdetailsRes) {
            if (props.offeringdetailsRes.data && props.offeringdetailsRes.data.offeringdetails) {
                
                if (props.offeringdetailsRes.data.offeringdetails.message === 'Success' && isOfferingDetail) {
                    isOfferingDetail = false;
                    let amount = '';
                    let defaultOrderAmt = '';
                    let defaultOrderAmtSet = '';
                    if (props.offeringdetailsRes.data.offeringdetails.data.dsp === 1 || props.offeringdetailsRes.data.offeringdetails.data.dsp === 2) {
                        amount = props.offeringdetailsRes.data.offeringdetails.data.max_price * props.offeringdetailsRes.data.offeringdetails.data.max_shares;
                        
                        defaultOrderAmt = props.offeringdetailsRes.data.offeringdetails.data.ordr_dollar_share === 0 ? Number(amount).toFixed(2) : Number(amount);
                        defaultOrderAmtSet = props.offeringdetailsRes.data.offeringdetails.data.ordr_dollar_share === 0 ? Number(amount).toFixed(2) : Number(amount);
                        
                    }
                    return {
                        offeringsdetailsList: props.offeringdetailsRes.data.offeringdetails.data,
                        anticipatedprices:props.offeringdetailsRes.data.offeringdetails.data.max_price,
                        defaultOrderAmount: defaultOrderAmt,
                        defaultOrderAmountSet: defaultOrderAmtSet,
                    }
                }
            }
        }
        if (props.placeOrderRes) {
            if (props.placeOrderRes.data && props.placeOrderRes.data.placeorder) {
                if(props.placeOrderRes.data.placeorder.error === 0 && isPlaceOrder){
                    isPlaceOrder = false;
                   return {
                        orderConfirmModal: true,
                        orderConfirmMsg: props.placeOrderRes.data.placeorder.message,
                        reviewOrder:false
                    };
                }
            }
        }
        if (props.EditprofileRes) {
            if (props.EditprofileRes.data && props.EditprofileRes.data.editprofile) {
                if (props.EditprofileRes.data.editprofile.message === 'Success' && isProfile) {
                    isProfile = false;
                    let minAmt = '';
                    let bdMpid = '';
                    let accId = '';
                    if(props.EditprofileRes.data.editprofile.data.broker_connection){
                        
                        minAmt = props.EditprofileRes.data.editprofile.data.broker_connection.minimum_buy_order;
                        bdMpid = props.EditprofileRes.data.editprofile.data.broker_connection.mpid;
                        accId = props.EditprofileRes.data.editprofile.data.broker_connection.account_id;
                    }
                    return {
                        userList: props.EditprofileRes.data.editprofile.data,
                        brokerconnectionList: props.EditprofileRes.data.editprofile.data.broker_connection,
                        defaultOrderAmount: Number(props.EditprofileRes.data.editprofile.data.default_amount).toFixed(2),
                        defaultOrderAmountSet: Number(props.EditprofileRes.data.editprofile.data.default_amount).toFixed(2),
                        minAmount: minAmt,
                        brokerDealerMpid: bdMpid,
                        accountId: accId,
                    }
                }
                
            }
        }
        if (props.accountsBuyingPowerRes) {
            if (props.accountsBuyingPowerRes.data && props.accountsBuyingPowerRes.data.accountsbuyingpower) {
                if (props.accountsBuyingPowerRes.data.accountsbuyingpower.message === 'Success' && isAccountBuying) {
                    isAccountBuying = false;
                    return {
                        accountsbuyingpower: props.accountsBuyingPowerRes.data.accountsbuyingpower.data,
                        buyingPowerAmount:Number(props.accountsBuyingPowerRes.data.accountsbuyingpower.data.buying_power).toFixed(2)
                    };
                }
            }
        }
        return null;
    }

    addDefaultSrc(ev) {
        ev.target.src = PlaceHolderImg;
    }

    openurl(geturl) {
        const url = geturl;
        window.open(url, '_blank');
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    showReviewOrder() {
        if (this.state.offeringsdetailsList.unit_increment !== null || this.state.offeringsdetailsList.unit_increment !== undefined) {
            let enterValue = Number(this.state.defaultOrderAmount);
            let checkVal = enterValue%Number(this.state.offeringsdetailsList.unit_increment);
            if (checkVal > 0) {
                this.setState({
                    openUnitIncErrorModal: true
                });
                return false;
            }
        }
        if (Number(this.state.offeringsdetailsList.max_ticket_size) > 0) {
            if (Number(this.state.defaultOrderAmount) < Number(this.state.offeringsdetailsList.min_ticket_size)) {
                this.setState({ openForExisteMinMaxTicket: true });
                return false;
            }
            if (Number(this.state.defaultOrderAmount) > Number(this.state.offeringsdetailsList.max_ticket_size)) {
                this.setState({ openForExisteMinMaxTicket: true });
                return false;
            }
        }
        if(this.state.offeringsdetailsList.max_ticket_size !== null && this.state.offeringsdetailsList.min_ticket_size !== null) {
            if(Number(this.state.offeringsdetailsList.max_ticket_size) > 0){
                
                if (Number(this.state.defaultOrderAmount) < Number(this.state.offeringsdetailsList.min_ticket_size)) {
                    this.setState({ openForExisteMinMaxTicket: true });
                    return false;
                }
                if (Number(this.state.defaultOrderAmount) > Number(this.state.offeringsdetailsList.max_ticket_size)) {
                    this.setState({ openForExisteMinMaxTicket: true });
                    return false;
                }
            }
        }
        if(this.state.offeringsdetailsList.max_ticket_size !== null && this.state.offeringsdetailsList.min_ticket_size === null) {
            if(Number(this.state.offeringsdetailsList.max_ticket_size) > 0) {
                if (Number(this.state.defaultOrderAmount) > Number(this.state.offeringsdetailsList.max_ticket_size)) {
                    this.setState({ openForExisteMinMaxTicket: true });
                    return false;
                }
            }
        }
        if(this.state.offeringsdetailsList.max_ticket_size === null && this.state.offeringsdetailsList.min_ticket_size !== null) {
            if(Number(this.state.offeringsdetailsList.min_ticket_size) > 0){
                
                if (Number(this.state.defaultOrderAmount) < Number(this.state.offeringsdetailsList.min_ticket_size)) {
                    this.setState({ openForExisteMinMaxTicket: true });
                    return false;
                }
            }
        }
        if (this.state.reviewOrder) {
            this.setState({ reviewOrder: false });
        } else {
            this.setState({ reviewOrder: true });
        }

        if (this.state.defaultOrderAmount < Number(this.state.minAmount)) {  
            this.setState({
                minAmountModal: true,
                reviewOrder: false
            });
        } else {
            this.setState({
                minAmountModal: false,
                reviewOrder: true
            });
            if (this.state.offeringsdetailsList.enforce_buying_power === true && this.state.offeringsdetailsList.dsp === 0) {
                if (Number(this.state.buyingPowerAmount) >= this.state.defaultOrderAmount) {
                    this.setState({
                        open: false,
                        reviewOrder: true
                    });
                } else {
                    this.setState({
                        open: true,
                        reviewOrder: false
                    });
                }
            } else {
                this.setState({
                    open: false,
                    reviewOrder: true
                });
            }
        }
    }
    handleEndEditingPassword = () => {
        this.setState( { isEditValue: true, defaultOrderAmount: '' }, () => {});
    }
    goChange(event) {
        event.persist();
        if(this.state.isEditValue){
            const re = /^\s*\d*\.?\d{0,2}\s*$/;
            var x = event.target.value.toString();
            if (x === "0" || x === '') {
                this.setState({
                    buttonOrder: true,
                })
            } else {
                this.setState({
                    buttonOrder: false,
                })
            }
            if (this.state.defaultOrderAmountSet !== event.target.value) {
                this.setState({
                    showDefaultAmountCheckbox: true,
                });
            } else {
                this.setState({
                    showDefaultAmountCheckbox: false,
                });
            }
            if (this.state.defaultOrderAmount === '' || re.test(event.target.value)) {
                let change = {}
                change[event.target.name] = event.target.value
                this.setState(change);
                this.setState({defaultOrderAmount: x,isEditValue: false});
            } else { 
            }
    
            if (this.state.value === '') {
                this.setState({
                    reviewOrder: false
                })
            } else {
                this.setState({
                    reviewOrder: false
                })
            }
        }else{
            const re = /^\s*\d*\.?\d{0,2}\s*$/;
            var xx = event.target.value.toString();
            if (xx === "0" || xx === '') {
                this.setState({
                    buttonOrder: true,
                })
            } else {
                this.setState({
                    buttonOrder: false,
                })
            }
            if (this.state.defaultOrderAmountSet !== event.target.value) {
                this.setState({
                    showDefaultAmountCheckbox: true,
                });
            } else {
                this.setState({
                    showDefaultAmountCheckbox: false,
                });
            }
            if (this.state.defaultOrderAmount === '' || re.test(event.target.value)) {
                let change = {}
                change[event.target.name] = event.target.value
                this.setState(change);
                this.setState({defaultOrderAmount: xx});
            } else { 
            }
    
            if (this.state.value === '') {
                this.setState({
                    reviewOrder: false
                })
            } else {
                this.setState({
                    reviewOrder: false
                })
            }
        }
    }

    checkChange() {
        this.setState({
            Ischecked: !this.state.Ischecked
        })
    }
    radioChangeDsp(value) {
        this.setState({
            Ischecked: false,
            attestation_to_rules_5130_and_5131: value
        })
    }

    goOrders(){
        this.props.history.push('/order')
    }

    doneClick(){
        this.props.history.push('/order')
    }
    checkDefaultAmountChange(e) {
        if (e.target.checked === true) {
            this.setState({
                isCheckDefaultAmount: true,
            });
        } else {
            this.setState({
                isCheckDefaultAmount: false,
            });
        }
    }
    doPlaceOrder(ext_id) {
        if (this.state.isCheckDefaultAmount === true) {
            let defaultAmountData = {
                "default_amount": Number(this.state.defaultOrderAmount),
                "email": this.state.userList.email,
            }
            isProfile = true;
            this.props.updateProfile(defaultAmountData, 'amount');
        }
        
        this.setState({ orderAmountValue: Number(this.state.defaultOrderAmount) });
        if (this.state.offeringsdetailsList.ordr_dollar_share === 0) {
            let placeOrderData = {
                "ext_id": ext_id,
                "mpid": this.state.brokerDealerMpid,
                "account_id": this.state.accountId,
                "requested_amount": Number(this.state.defaultOrderAmount),
                "buying_power": (this.state.offeringsdetailsList.enforce_buying_power === true && this.state.offeringsdetailsList.dsp === 0) ? this.state.buyingPowerAmount : Number(this.state.defaultOrderAmount),
                "attestation_to_rules_5130_and_5131": Number(this.state.attestation_to_rules_5130_and_5131),
                "dsp": this.state.offeringsdetailsList.dsp
            }
            isPlaceOrder = true;
            this.props.placeOrder(placeOrderData);
            
        } else {
            let placeOrderData = {
                "ext_id": ext_id,
                "mpid": this.state.brokerDealerMpid,
                "account_id": this.state.accountId,
                "requested_shares": Number(this.state.defaultOrderAmount),
                "buying_power": (this.state.offeringsdetailsList.enforce_buying_power === true && this.state.offeringsdetailsList.dsp === 0) ? this.state.buyingPowerAmount : Number(this.state.defaultOrderAmount),
                "attestation_to_rules_5130_and_5131": Number(this.state.attestation_to_rules_5130_and_5131),
                "dsp": this.state.offeringsdetailsList.dsp
            }
            isPlaceOrder = true;
            this.props.placeOrder(placeOrderData);
        }
    }

    openRule5130Modal = () => {
        this.setState({ 
            rule5130Modal: true
        })
    }

    openRule5131Modal = () => {
        this.setState({ 
            rule5131Modal: true
        })
    }

    closeModal = () => {
        this.setState({ 
            rule5130Modal: false,
            rule5131Modal: false,
            minAmountModal: false,
        });
    }

    onCloseModal = () => {
        this.setState({ open: false, prospectusModal: false });
    };

    clcikOk() {
        this.setState({ 
                open: false, 
                minAmountModal: false
        });
    }

    openProspectusModal = () => {
        this.setState({
            prospectusModal: true
        })
    }

    readProspectus(geturl) {
        const url = geturl;
        window.open(url, '_blank');
    }

    emailProspectus(ext_id) {
        let prosid = {
            'ext_id' : ext_id,
            'url_type': 'prospectus'
        }
        this.props.getprospectus(prosid);
        this.setState({
                prospectusModal: false
            })
    }

    cancelProspectus() {
        this.setState({ 
            prospectusModal: false
        });
    }
    closeUnitIncErrorModal = () => {
        this.setState({ 
            openUnitIncErrorModal: false
        });
    }
    
    onCloseMinMaxModal = () => {
        this.setState({ 
            openForExisteMinMaxTicket: false,
        });
    }
    render() {
        const { open } = this.state;
        const buyingPower = '$ ' + this.state.buyingPowerAmount;
        return (
            <Dashboard title="ClickIPO - PlaceOrder" pageTitle="Place Order" >
                {
                    this.state.offeringsdetailsList && this.state.accountsbuyingpower && this.state.brokerconnectionList &&
                    <div className="place_order_content">
                        <div className="place_order_heading page_title">
                            <img src={BackIcon} alt="" onClick={this.handleBack} className="back_icon" />
                            Place Order
                        </div>
                        <div className="place_order_inner_content">
                            <div className="row ml-mr-0px-imp">
                                <div className="col col-3 col-md-3 col-lg-3 col-xl-3">
                                    <div className="lft_images">
                                        <img src={this.state.offeringsdetailsList.logo_small} onError={this.addDefaultSrc} className="details_logo" alt="" />
                                        <p className="company_placeorder_tag">
                                            {this.state.offeringsdetailsList.ticker_symbol}
                                        </p>
                                    </div>
                                </div>
                                <div className="col col-9 col-md-9 col-lg-9 col-xl-6 place_order_inner_content_inner">
                                {
                                    (this.state.offeringsdetailsList.dsp === 1 || this.state.offeringsdetailsList.dsp === 2) &&
                                    <div style={{color: 'red', fontWeight: 'bold', border: '1px solid red', padding: 10}}>
                                        {/* <div>DSP</div> */}
                                        <div>You have been invited to participate in the Directed Shares Program for the <span >{this.state.offeringsdetailsList.name}</span>. </div>
                                        {
                                                (this.state.offeringsdetailsList.max_shares > 0) &&
                                                <div>You have been preassigned <span> {this.state.offeringsdetailsList.max_shares}</span> shares.</div>
                                        }
                                    </div>
                                }
                                    
                                    <p className="company_placeorder_name">{this.state.offeringsdetailsList.name}
                                        <span className="company_placeorder_account" style={{ color: this.state.offeringsdetailsList.offering_type_name === 'IPO' ? "#8DC73F" : "#44c6d2" }}>
                                            ({this.state.offeringsdetailsList.offering_type_name}) </span>
                                    </p>
                                    <div className="row">
                                        <div className="col col-6">
                                            <p className="account_placeorder_type">Anticipated</p>
                                        </div>
                                        <div className="col col-6">
                                            <p className="account_open_placeorder_date">
                                                {getDate(this.state.offeringsdetailsList)}
                                            </p>
                                        </div>
                                    </div>
                                    {
                                        this.state.offeringsdetailsList.offering_type_name === 'IPO' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Price Range</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                        {getOfferingDetailsPrice(this.state.offeringsdetailsList)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.offeringsdetailsList.offering_type_name === 'Follow-on' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                        {getOfferingDetailsPrice(this.state.offeringsdetailsList)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.offeringsdetailsList.offering_type_name === 'Block' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                        {getOfferingDetailsPrice(this.state.offeringsdetailsList)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.offeringsdetailsList.offering_type_name === 'Spot' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                        {getOfferingDetailsPrice(this.state.offeringsdetailsList)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.offeringsdetailsList.offering_type_name === 'Overnight' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="price_level_placeorder_type">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="amount_placeorder">
                                                        {getOfferingDetailsPrice(this.state.offeringsdetailsList)}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col col-6">
                                            {
                                                this.state.offeringsdetailsList.min_ticket_size !== null && this.state.offeringsdetailsList.max_ticket_size !== null &&
                                                <p className="account_placeorder_type">Ticket size</p>
                                            }
                                            {
                                                this.state.offeringsdetailsList.min_ticket_size === null && this.state.offeringsdetailsList.max_ticket_size !== null &&
                                                <p className="account_placeorder_type">Max ticket size</p>
                                            }
                                            {
                                                this.state.offeringsdetailsList.min_ticket_size !== null && this.state.offeringsdetailsList.max_ticket_size === null &&
                                                <p className="account_placeorder_type">Min ticket size</p>
                                            }
                                        </div>
                                        <div className="col col-6">
                                            {
                                                this.state.offeringsdetailsList.ordr_dollar_share === 0 ?
                                                <div className="buying_power_amount">
                                                    {
                                                        this.state.offeringsdetailsList.min_ticket_size !== null && this.state.offeringsdetailsList.max_ticket_size !== null &&
                                                        <div>
                                                            {numberWithCommasAndDollar(this.state.offeringsdetailsList.min_ticket_size)} - {numberWithCommasAndDollar(this.state.offeringsdetailsList.max_ticket_size)}
                                                        </div>
                                                    }
                                                    {
                                                        this.state.offeringsdetailsList.min_ticket_size === null && this.state.offeringsdetailsList.max_ticket_size !== null &&
                                                        <div>
                                                            {numberWithCommasAndDollar(this.state.offeringsdetailsList.max_ticket_size)}
                                                        </div>
                                                    }
                                                    {
                                                        this.state.offeringsdetailsList.min_ticket_size !== null && this.state.offeringsdetailsList.max_ticket_size === null &&
                                                        <div>
                                                            {numberWithCommasAndDollar(this.state.offeringsdetailsList.min_ticket_size)}
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                <div className="buying_power_amount">

                                                    {
                                                        this.state.offeringsdetailsList.min_ticket_size !== null && this.state.offeringsdetailsList.max_ticket_size !== null &&
                                                        <div>
                                                            {numberWithCommas(this.state.offeringsdetailsList.min_ticket_size)} - {numberWithCommas(this.state.offeringsdetailsList.max_ticket_size)} Shares
                                                        </div>
                                                    }
                                                    {
                                                        this.state.offeringsdetailsList.min_ticket_size === null && this.state.offeringsdetailsList.max_ticket_size !== null &&
                                                        <div>
                                                            {numberWithCommas(this.state.offeringsdetailsList.max_ticket_size)} Shares
                                                        </div>
                                                    }
                                                    {
                                                        this.state.offeringsdetailsList.min_ticket_size !== null && this.state.offeringsdetailsList.max_ticket_size === null &&
                                                        <div>
                                                            {numberWithCommas(this.state.offeringsdetailsList.min_ticket_size)} Shares
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        Number(this.state.offeringsdetailsList.unit_increment) > 1 && 
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="account_placeorder_type">Unit increment</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="buying_power_amount">{this.state.offeringsdetailsList.unit_increment}</p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.offeringsdetailsList.enforce_buying_power === true &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="account_placeorder_type">Buying power</p>
                                            </div>
                                            <div className="col col-6">
                                                <p className="buying_power_amount">
                                                    {numberWithCommas(buyingPower)}
                                                </p>
                                            </div>
                                        </div>
                                        
                                    }
                                    <div className="row">
                                        <div className="col col-6">
                                            {
                                                this.state.offeringsdetailsList.ordr_dollar_share === 0 ?
                                                <p className="investment_status" style={{marginTop: 8}}>Requested Amount</p>
                                                :
                                                <p className="investment_status" style={{marginTop: 8}}>Requested Shares</p>
                                            }
                                        </div>
                                        <div className="col col-6">
                                            {
                                                this.state.offeringsdetailsList.ordr_dollar_share === 0 ?
                                                <div className="rt-input-input investment_amount place_investment_amount">
                                                    <input type="text" name="defaultOrderAmount" 
                                                    onChange={this.goChange} 
                                                    onClick={this.handleEndEditingPassword }
                                                    value={this.state.defaultOrderAmount}/>
                                                </div>
                                                :
                                                <div className="investment_amount place_investment_amount">
                                                    <input type="text" name="defaultOrderAmount" 
                                                    onChange={this.goChange} 
                                                    onClick={this.handleEndEditingPassword }
                                                    value={this.state.defaultOrderAmount}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-6">
                                            {
                                                this.state.offeringsdetailsList.ordr_dollar_share === 0 ?
                                                <p className="share_status">Approximate Shares</p>
                                                :
                                                <p className="share_status">Approximate Amount</p>
                                            }
                                        </div>
                                        <div className="col col-6">
                                            {
                                                this.state.offeringsdetailsList.ordr_dollar_share === 0 ?
                                                <p className="share_amount">
                                                    {
                                                        this.state.defaultOrderAmount ? numberWithCommas(Math.round((this.state.defaultOrderAmount)/(this.state.anticipatedprices)).toFixed(0)): 0 
                                                    }
                                                </p>
                                                :
                                                <p className="share_amount">
                                                {
                                                    this.state.defaultOrderAmount ? numberWithCommasAndDollar(Math.round((this.state.defaultOrderAmount)*(this.state.anticipatedprices)).toFixed(0)): '$0.00' 
                                                }
                                            </p>
                                            }
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="row ">
                                        <div className="col col-6">
                                                <p className="account_placeorder_type">Prospectus</p>
                                        </div>
                                        <div className="col col-6 place_order_prospects">
                                                <div className="icon_text" onClick={this.openProspectusModal}>
                                                        <img src={DocIcon} alt="" />
                                                        <span>Prospectus</span>
                                                </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.showDefaultAmountCheckbox &&
                                        <div className="row">
                                                <div className="col col-6">
                                                    <p className="account_placeorder_type">Make as new default amount</p>
                                                </div>
                                                <div className="col col-6 place_order_prospects">
                                                        <input type="checkbox" className="accept_condition" checked={this.state.isCheckDefaultAmount} onChange={(e) => this.checkDefaultAmountChange(e)}></input>
                                                </div>
                                        </div>
                                    }
                                    

                                </div>
                                <div className="col col-12 col-md-12 col-lg-12 col-xl-3">
                                    {
                                        this.state.reviewOrder === false &&
                                        <div className="modify_btn" onClick={() => this.showReviewOrder()}>
                                            <Button variant="contained" size="large" color="primary" disabled={this.state.buttonOrder}>
                                                Review Order
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row ml-mr-0px-imp">
                                <div className="col col-12">
                                    {
                                        this.state.reviewOrder &&
                                        <div className="mt-25px pt-10px bdr-top-1px-solid-lightgray">
                                            <p className="review_order_text">
                                                This Order will be placed through your
                                                {
                                                    this.state.brokerconnectionList &&
                                                    <span style={{ marginLeft: '3px', marginRight: '3px', fontWeight: '500' }}>{this.state.brokerconnectionList.broker_dealer_name}</span>
                                                }
                                                account, commission fee.
                                            </p>
                                            <p className="review_order_text_content">
                                                There is no assurance that your conditional offer to buy will receive the full requested allocation or any allocation at all.  Your order is conditional on the final share price being no greater than 20% higher or lower than the anticipated price range.  Your order will be for the dollar amount calculated regardless of the final share price.
                                            </p>
                                            {
                                                this.state.offeringsdetailsList.dsp === 2 ?
                                                <div style={{margin: 'auto', width: '80%'}}>
                                                    <p className="checkbox_content" style={{display: 'inline-flex', textAlign: 'left'}}>
                                                        <input type="radio" name="dsp2" value="1" className="accept_condition" onChange={() => this.radioChangeDsp(1)} ></input>
                                                        <span className="checkbox_content_inner" style={{width: '80%'}}>
                                                            I attest that I am not a "restricted person" pursuant to 
                                                                <span className="rule_name" onClick={() => this.openRule5130Modal()}>
                                                                    Rule 5130
                                                                </span> and 
                                                                <span className="rule_name" onClick={() => this.openRule5131Modal()}>
                                                                    Rule 5131.
                                                                </span>
                                                        </span>
                                                    </p>
                                                    <p className="checkbox_content" style={{display: 'inline-flex', textAlign: 'left'}}>
                                                        <input type="radio" name="dsp2" value="2" className="accept_condition" onChange={() => this.radioChangeDsp(2)} ></input>
                                                        <span className="checkbox_content_inner" style={{width: '80%'}}>
                                                            I am a restricted person as defined by FINRA rule 5130 or 5131. I understand that directed share programs are exempt from rule
                                                            <span className="rule_name" onClick={() => this.openRule5130Modal()}>
                                                                    5130
                                                            </span> and 
                                                            <span className="rule_name" onClick={() => this.openRule5131Modal()}>
                                                                5131
                                                            </span>
                                                            and thus I am able to participate in the offering provided my compliance department approves of my participation.
                                                        </span>
                                                    </p>
                                                </div>
                                                :
                                                <p className="checkbox_content">
                                                    <input type="checkbox" className="accept_condition" onChange={this.checkChange}></input>
                                                    <span className="checkbox_content_inner">
                                                        I attest that I am not a "restricted person" pursuant to 
                                                            <span className="rule_name" onClick={() => this.openRule5130Modal()}>
                                                                Rule 5130
                                                            </span> and 
                                                            <span className="rule_name" onClick={() => this.openRule5131Modal()}>
                                                                Rule 5131.
                                                            </span>
                                                    </span>
                                                </p>
                                            }
                                            <div className="buy_offer">
                                                <Button variant="contained" size="large" color="primary" fullWidth={true} onClick={() => this.doPlaceOrder(this.state.offeringsdetailsList.ext_id)} disabled={this.state.Ischecked}>
                                                    Submit Conditional Offer to Buy
                                                </Button>
                                            </div>
                                            {/* <p className="investor_score_text">
                                                This will positively affect your Investor Score
                                            </p>
                                            <div className="raise_score_image">
                                                <img src="../../../../../images/raise_score.png" alt="" />
                                            </div>
                                            <p className="review_order_text">
                                                We use a scoring system to determine future allocations. Holding shares for 30 days or longer is one way to increase your ClickIPO Investor Score.
                                            </p> */}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        
                        <Dialog
                            open={this.state.prospectusModal}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogTitle id="scroll-dialog-title">Prospectus</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Would you like to read the prospectus or email yourself a copy?
                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <Button className="btns-all" onClick={() => this.readProspectus( this.state.offeringsdetailsList.prospectus_url)} color="primary">
                                        Read
                                    </Button>
                                    <Button className="btns-all" onClick={() => this.emailProspectus( this.state.offeringsdetailsList.ext_id)} color="primary">
                                        Email
                                    </Button>
                                    <Button className="btns-all-cancel" onClick={() => this.cancelProspectus()} color="primary">
                                        Cancel
                                    </Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.orderConfirmModal}
                            onClose={this.closeModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                            fullWidth={true}
                            maxWidth={'md'}
                        >
                            <DialogTitle onClose={this.closeModal}>Order Complete</DialogTitle>
                            <DialogContent>
                                <div className="success_modal">
                                    <div className="success-placeorder-icon"><img src={SuccessIcon} alt="" /></div>
                                    <h4 className="congrats_text">Congratulations!</h4>
                                    {
                                        (this.state.orderConfirmMsg) &&
                                        <div className="order-success-message" dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(this.state.orderConfirmMsg)} }></div>
                                    }
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.goOrders()}>
                                        My Orders
                                    </Button>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.doneClick()}>
                                        Done
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openUnitIncErrorModal}
                            onClose={this.closeUnitIncErrorModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                <h5> Your value should be multiple of {this.state.offeringsdetailsList.unit_increment}</h5>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.closeUnitIncErrorModal()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.openForExisteMinMaxTicket}
                            onClose={this.onCloseMinMaxModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                {
                                    this.state.offeringsdetailsList && this.state.offeringsdetailsList.ordr_dollar_share === 0 ?
                                    <div>
                                        {
                                            this.state.offeringsdetailsList.min_ticket_size !== null && this.state.offeringsdetailsList.max_ticket_size !== null &&
                                            <h5>
                                                Amount should be between {numberWithCommasAndDollar(this.state.offeringsdetailsList.min_ticket_size)} and {numberWithCommasAndDollar(this.state.offeringsdetailsList.max_ticket_size)}
                                            </h5>
                                        }
                                        {
                                            this.state.offeringsdetailsList.min_ticket_size === null && this.state.offeringsdetailsList.max_ticket_size !== null &&
                                            <h5>
                                                Amount should be less than {numberWithCommasAndDollar(this.state.offeringsdetailsList.max_ticket_size)}
                                            </h5>
                                        }
                                        {
                                            this.state.offeringsdetailsList.min_ticket_size !== null && this.state.offeringsdetailsList.max_ticket_size === null &&
                                            <h5>
                                                Amount should greater than {numberWithCommasAndDollar(this.state.offeringsdetailsList.min_ticket_size)}
                                            </h5>
                                        }
                                    </div>
                                    :
                                    <div>
                                        {
                                            this.state.offeringsdetailsList.min_ticket_size !== null && this.state.offeringsdetailsList.max_ticket_size !== null &&
                                            <h5>
                                                Shares should be between {numberWithCommas(this.state.offeringsdetailsList.min_ticket_size)} and {numberWithCommas(this.state.offeringsdetailsList.max_ticket_size)}
                                            </h5>
                                        }
                                        {
                                            this.state.offeringsdetailsList.min_ticket_size === null && this.state.offeringsdetailsList.max_ticket_size !== null &&
                                            <h5>
                                                Shares should be less than {numberWithCommas(this.state.offeringsdetailsList.max_ticket_size)}
                                            </h5>
                                        }
                                        {
                                            this.state.offeringsdetailsList.min_ticket_size !== null && this.state.offeringsdetailsList.max_ticket_size === null &&
                                            <h5>
                                                Shares should greater than {numberWithCommas(this.state.offeringsdetailsList.min_ticket_size)}
                                            </h5>
                                        }
                                    </div>
                                }
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.onCloseMinMaxModal()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.minAmountModal}
                            onClose={this.closeModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                {
                                    this.state.offeringsdetailsList && this.state.offeringsdetailsList.ordr_dollar_share === 0 ?
                                    <h5> Minimum Investment Amount is $ {this.state.minAmount}</h5>
                                    :
                                    <h5> Minimum Investment Shares is {this.state.minAmount}</h5>
                                }  
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.clcikOk()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={open}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                {
                                    this.state.offeringsdetailsList && this.state.offeringsdetailsList.ordr_dollar_share === 0 ?
                                    <h5> You have exceeded your buying power. Please modify the amount!!</h5>
                                    :
                                    <h5> You have exceeded your buying power. Please modify the shares!!</h5>
                                } 
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.clcikOk()}>
                                        Ok
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
                <Dialog
                    open={this.state.rule5130Modal}
                    onClose={this.closeModal}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle onClose={this.closeModal}>Rule 5130</DialogTitle>
                    <DialogContent>
                        <div className="modal_css">
                            <iframe src={PDF_FINRARULE_ZERO} title="5130 pdf" allowFullScreen frameBorder="0" style={{height:'100%',width:'100%'}} />
                        </div>
                    </DialogContent>
                    
                </Dialog>
                <Dialog
                    open={this.state.rule5131Modal}
                    onClose={this.closeModal}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogTitle onClose={this.closeModal}>Rule 5131</DialogTitle>
                    <DialogContent>
                        <div className="modal_css">
                            <iframe src={PDF_FINRARULE_ONE} title="5130 pdf" allowFullScreen frameBorder="0" style={{height:'100%',width:'100%'}} />
                        </div>
                    </DialogContent>
                </Dialog>
            </Dashboard>
        )
    }

}


PlaceOrderComponent.propTypes = {
    offeringdetailsRes: PropTypes.any,
    placeOrderRes: PropTypes.any,
    EditprofileRes: PropTypes.any,
    accountsBuyingPowerRes: PropTypes.any,
    prospectusRes: PropTypes.any,
    userActivityRes: PropTypes.any,
    UpdateprofileRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    offeringdetailsRes: doOfferingDetailsRes,
    placeOrderRes: doPlaceOrderRes,
    EditprofileRes: doEditprofileRes,
    accountsBuyingPowerRes: doAccountsBuyingPowerRes,
    prospectusRes: doProspectusRes,
    userActivityRes: doUserActivityRes,
    UpdateprofileRes: doUpdateprofileRes,
});

function mapDispatchToProps(dispatch) {
    return {
        getOfferingsDetails: (id) => dispatch(get_offering_details(id)),
        placeOrder: (data) => dispatch(place_order(data)),
        getProfile: () => dispatch(submit_profile()),
        accountsBuyingPower: (data) => dispatch(get_accounts_buying_power(data)),
        getprospectus: (data) => dispatch(get_prospectus(data)),
        userActivity: (data) => dispatch(user_activity(data)),
        updateProfile: (data, status) => dispatch(update_profile(data, status)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PlaceOrderComponent);
