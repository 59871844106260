import * as types from './actionTypes';  
import brokerConnectionTokenApi from '../api/authBrokerConnectionTokenApi';
import {logout} from '../helper/helper';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

export function brokerConnecitonToken(data) {
  return {
    type: types.DO_BROKER_CONNECTION_TOKEN, 
    data
  };
}

export function brokerConnecitonTokenRes(data) {
  return {
    type: types.DO_BROKER_CONNECTION_TOKEN_RES, 
    data
  };
}

export function broker_connection_token(data) {
  const TOKEN = getItem('auth_token');
  if(TOKEN){
    return function(dispatch) {
      brokerConnectionTokenApi.brokerConnecitonToken(data).then(res => {
        dispatch(brokerConnecitonTokenRes(res));
        if(res.error){
          Swal.fire({
            title: res.message,
            icon: 'error',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            timer: 3000,
            heightAuto: false
          })
        }
      }).catch(error => {
        return error
      });
    };
  }else{
    logout()
  }
}
