import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

class prospectusApi {  
    static getProspectus(data) {
        const TOKEN = getItem('auth_token');
        const ext_id = data.ext_id
        const url_type = data.url_type
        let isError = false;

        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'GET',
                headers: ajaxRequestHeaders,
            }
            return fetch(BASE_URL + `/offerings/prospectus?ext_id=${ext_id}&url_type=${url_type}`, body).then(response => {
                if(response.ok === true){
                    const type = url_type.replace(/^\w/, c => c.toUpperCase());
                    Swal.fire({
                        title: `${type} Sent`,
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: true,
                        timer: 3000,
                    })
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}  

export default prospectusApi;