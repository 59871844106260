
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './verifyPassword.css';
import { verify_password, doVerifyPasswordRes } from '../../actions/verifyPasswordActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import Dashboard from '../../component/Dashboard';
import { loadScript } from '../../helper/helper';
import VerifyPasswordForm from './Form/form';
class VerifyPasswordComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            value:'',
            submitPass: true,
            screenId: '1.1',
        }
    }

    componentDidMount() {
        loadScript();
        const activityData = {
            'screen_id': this.state.screenId,
            'source': 'web'
        }
        this.props.userActivity(activityData)
    }

    handleSubmit(data) {
        if (data.current_password) {
            this.setState({
                userEmail: data.current_password,
            });
        }
        let sendRequest = {
            "current_password" : data.current_password
        }
        this.props.handleFormSubmit(sendRequest);
    };
    
    static getDerivedStateFromProps(props, state) {
		if (props.verifyPasswordRes) {
			if (props.verifyPasswordRes.user && props.verifyPasswordRes.user.user) {
				if(props.verifyPasswordRes.user.user.message === "Success"){
                    props.history.push('/editprofile')
                }
			}
		}
		return null
	}
    render() {
        return (
            <Dashboard title="ClickIPO - Verify Password"  pageTitle="Verify Password" >
                <div className="verify_password_content">
                    <div className="verify_password_inner_content">
                        <h5 className="enter_password">Please Enter Current Password</h5>
                        <VerifyPasswordForm handleFormSubmit={(data) => { this.handleSubmit(data) }} />
                    </div>
                </div>
            </Dashboard>
        )
    }
}


VerifyPasswordComponent.propTypes = {
    verifyPasswordRes: PropTypes.any,
    userActivityRes: PropTypes.any,
    handleFormSubmit: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    verifyPasswordRes: doVerifyPasswordRes,
    userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
    return {
        handleFormSubmit: (data) => dispatch(verify_password(data)),
        userActivity: (data) => dispatch(user_activity(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(VerifyPasswordComponent);
