import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './editProfile.scss';
import Dashboard from '../../component/Dashboard';
import PropTypes from 'prop-types';
import { submit_profile, doEditprofileRes } from '../../actions/editProfileActions';
import { update_profile, doUpdateprofileRes, uploadUserProfilePic, doUploadUserPictureRes } from '../../actions/updateProfileActions';
import { market_brokerage, doMarketBrokerageRes } from '../../actions/marketBrokerageActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { sha3_512 } from 'js-sha3';
import { loadScript } from '../../helper/helper';
import InfoIcon from '@material-ui/icons/Info';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import SpinnImg from '../../assets/images/spinn_img.gif';
// import ReactCrop from 'react-image-crop';
let isMarketBroker = false;
let isProfile = false;
class EditProfileComponent extends React.PureComponent {

    constructor() {
        super();
        isMarketBroker = false;
        isProfile = false;
        this.state = {
            firstname: '',
            lastname: '',
            useremail: '',
            selectoption: '',
            value:'',
            userid: '',
            defaultamount:'',
            confirm_new_password: '',
            new_password: '',
            fields: {},
            errors: {},
            profileFields: {},
            profileErrors: {},
            settoken:'',
            user:'',
            EmailBoxIsChecked: '',
            pushnotification:'',
            proPushBoxIsChecked: '',
            marketBrokerageList:'',
            screenId: '1.1',
            submitButton: true,
            alias: '',
            profilePic: '',
            isPhotoUpload: false,
            filePriview: '',
            crop: {
                unit: 'px', // default, can be 'px' or '%'
                width: 200,
                height: 200,
                aspect: 16 / 16,
            },
            src: null,
            showCropImg: false,
            croppedImageUrl: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailCheckChage = this.handleEmailCheckChage.bind(this);
        this.handleProPushCheckChage = this.handleProPushCheckChage.bind(this);
    }

    //  ==================== Update Password Function ===================

    handlePasswordChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value
        this.setState(fields)
        this.setState({
            submitButton: false
        });
    }

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["new_password"]) {
            formIsValid = false;
            errors["new_password"] = "Please enter new password.";
        }   
        if (!fields["confirm_new_password"]) {
            formIsValid = false;
            errors["confirm_new_password"] = "Please enter confirm password.";
        }
        
        if (fields["new_password"]) {
            if (fields["new_password"].search(/^\S+$/)) {
                formIsValid = false;
                errors["new_password"] = 'Your password must not contain any space.'
            }
            else if (fields["new_password"].length < 10) {
                formIsValid = false;
                errors["new_password"] = 'Your password must be at least 10 characters'
            }
        }
        else if (fields["new_password"] !== fields["confirm_new_password"]) {
            formIsValid = false;
            errors["confirm_new_password"] = "New password and confirm password does not match"
        }
        this.setState({
            errors: errors,
            submitButton: false
        });
        return formIsValid;
    }
    validateFormProfile() {
        let profileFields = this.state.profileFields;
        let profileErrors = {};
        let formIsValid = true;
        if (!profileFields["alias"]) {
            formIsValid = false;
            profileErrors["alias"] = "Alias cannot be empty";
        } 
        this.setState({
            profileErrors: profileErrors,
        });
        return formIsValid;
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validateForm()) {
            let fields = {};
            fields["new_password"] = "";
            fields["confirm_new_password"] = "";
            fields["new_password"] = fields["confirm_new_password"];
        } else {
            let emailCheckbox = ""
            if(!this.state.EmailBoxIsChecked) {
                emailCheckbox = 1;
            }else {
                emailCheckbox = 17;
            }

            let notificationCheckbox=""
            if(!this.state.proPushBoxIsChecked){
                notificationCheckbox=1
            }
            if(this.state.proPushBoxIsChecked){
                notificationCheckbox=17
            }
            let sendRequest = {
                first_name: this.state.firstname,
                last_name: this.state.lastname,
                email: this.state.useremail,
                marketing_brokerage: this.state.selectoption,
                default_amount: Number(this.state.defaultamount),
                can_email: emailCheckbox,
                alias: this.state.alias,
                push_notification: notificationCheckbox,
                encrypted_password: sha3_512(this.state.confirm_new_password),
            };
            this.props.updateProfile(sendRequest, 'profile');
            this.setState({
                submitButton: true,
                fields: {}
            });
        }
    }

    //  ==================== Update Profile Function ===================

    componentDidMount() {
        loadScript();
        isMarketBroker = true;
        isProfile = true;
        this.props.getProfile();
        this.props.marketbrokerage();
        const activityData = {
            'screen_id': this.state.screenId,
            'source': 'web'
        }
        this.props.userActivity(activityData)
    }

    handleEmailCheckChage(){
        this.setState({
            EmailBoxIsChecked : !this.state.EmailBoxIsChecked
        });
        if(this.state.EmailBoxIsChecked === 11){
            this.setState({
                EmailBoxIsChecked : true
            })
        }
        if(this.state.EmailBoxIsChecked === 1){
            this.setState({
                EmailBoxIsChecked : false
            })
        }
    }

    handleProPushCheckChage(){
        this.setState({
            proPushBoxIsChecked : !this.state.proPushBoxIsChecked
        });
    }

    handleAmountChange(e){
        const re = /^\s*\d*\.?\d{0,2}\s*$/;
        if (this.state.defaultamount === '' || re.test(e.target.value)) {
            let change = {}
            change[e.target.name] = e.target.value
            this.setState(change);
        }
    }

    handleInputChange(e) {
        let profileFields = this.state.profileFields;
        profileFields[e.target.name] = e.target.value
        this.setState(profileFields);
    }

    handleProfileSubmit() {
        // e.preventDefault();
        if (!this.validateFormProfile()) {
            let profileFields = {};
            profileFields["alias"] = "";
        } else {
            
            let emailCheckbox = ""
            if(!this.state.EmailBoxIsChecked) {
                emailCheckbox = 1;
            }else {
                emailCheckbox = 17;
            }

            let notificationCheckbox=""
            if(!this.state.proPushBoxIsChecked){
                notificationCheckbox=1
            }
            if(this.state.proPushBoxIsChecked){
                notificationCheckbox=17
            }

            let request = {
                first_name: this.state.firstname,
                last_name: this.state.lastname,
                email: this.state.useremail,
                marketing_brokerage: this.state.selectoption,
                default_amount: Number(this.state.defaultamount),
                can_email: emailCheckbox,
                alias: this.state.profileFields.alias,
                push_notification: notificationCheckbox
            }
            this.props.updateProfile(request, 'profile');
        }
    };

    //  ==================== Receive Props ===================

    static getDerivedStateFromProps(props, state) {
        if (props.EditprofileRes) {
            if (props.EditprofileRes.data && props.EditprofileRes.data.editprofile) {
                if (props.EditprofileRes.data.editprofile && props.EditprofileRes.data.editprofile.message === 'Success' && isProfile) {
                    isProfile = false;
                    let EmailIsChecked = '';
                    let PushIsChecked = '';
                    let selectOpt = '';
                    if(props.EditprofileRes.data.editprofile.data.can_email === 1){
                        EmailIsChecked = false;
                    }
                    if(props.EditprofileRes.data.editprofile.data.can_email === 17){
                        EmailIsChecked = true;
                    }
                    if(props.EditprofileRes.data.editprofile.data.push_notification === 1){
                        PushIsChecked = false;
                    }
                    if(props.EditprofileRes.data.editprofile.data.push_notification === 17){
                        PushIsChecked = true;
                    }
                    if (props.EditprofileRes.data.editprofile.data.marketing_brokerage != null) {
                        selectOpt = props.EditprofileRes.data.editprofile.data.marketing_brokerage;
                    } else {
                        selectOpt = '';
                    }
                    return {
                        firstname: props.EditprofileRes.data.editprofile.data.first_name,
                        lastname: props.EditprofileRes.data.editprofile.data.last_name,
                        useremail: props.EditprofileRes.data.editprofile.data.email,
                        userid: props.EditprofileRes.data.editprofile.data.id,
                        profileFields: {alias: props.EditprofileRes.data.editprofile.data.alias},
                        defaultamount: Number(props.EditprofileRes.data.editprofile.data.default_amount).toFixed(2),
                        EmailBoxIsChecked:EmailIsChecked,
                        proPushBoxIsChecked: PushIsChecked,
                        pushnotification: props.EditprofileRes.data.editprofile.data.push_notification,
                        profilePic: props.EditprofileRes.data.editprofile.data.profile_picture_url,
                        selectoption: selectOpt
                    };
                }
            }
        }
        if (props.MarketbrokerageRes) {
            if (props.MarketbrokerageRes.data && props.MarketbrokerageRes.data.marketbrokerage) {
                if (props.MarketbrokerageRes.data.marketbrokerage.message === 'Success' && isMarketBroker) {
                    isMarketBroker = false;
                    return {
                        marketBrokerageList: props.MarketbrokerageRes.data.marketbrokerage.data
                    };
                }
            }
        }
        return null;
    }
    
    chooseProfilePicture = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result, showCropImg: true })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
        const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            "newFile.jpeg"
        );
        this.setState({ croppedImageUrl: croppedImageUrl }, () => {
        });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error("Canvas is empty");
                return;
                }
                this.setState({
                    profile: blob,
                });
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }
    uploadUserImg = () => {
        this.setState({
            isPhotoUpload: true,
        }, () => {
            this.props.uploadUserProfilePic(this.state.profile);
        });
    }
    render() {
        return (
            <Dashboard title="ClickIPO - Edit Profile" pageTitle="Edit Profile" >
                <div className="edit_profile_content_wrapper">
                    <div className="editprofile_content">
                        <div className="form_content_editprofile edit_profile_form_fields_wrapper">
                            {
                                <div>
                                    <div className="row">
                                        <div className="col-12 col-lg-12 col-xl-9">
                                            <div className="row">
                                                <div className="col col-12 col-sm-6 ">
                                                    <div className="form-group user_name">
                                                        <TextField name="firstname" type="text" className="user_details" value={this.state.firstname || ''} onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col col-12 col-sm-6 ">
                                                    <div className="form-group user_name">
                                                        <TextField name="lastname" type="text" className="user_details" value={this.state.lastname || ''} onChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col col-12 col-sm-6 ">
                                                    <div className="form-group user_name">
                                                        <TextField name="useremail" type="mail" className="user_details" value={this.state.useremail || ''} onChange={this.handleInputChange} readOnly/>
                                                    </div>
                                                </div>
                                                <div className="col col-12 col-sm-6 ">
                                                    <div className="form-group choose_option">
                                                        <select name="selectoption" value={this.state.selectoption} onChange={this.handleInputChange}>
                                                            {
                                                                this.state.marketBrokerageList && this.state.marketBrokerageList.length > 0 && this.state.marketBrokerageList.map((item, i) =>
                                                                    <option key={i} onChange={this.handleInputChange} value={item.value}>{item.value}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row align-items-center mt-mb-1">
                                                        <div className="col col-6">
                                                            <p className="default_order_amount">Default order amount</p>
                                                        </div>
                                                        <div className="col col-6">
                                                            <div className="form-group default_amount mt-mb-0">
                                                                <input type="text" name="defaultamount" onChange={this.handleAmountChange} value={this.state.defaultamount || ''}className="form-control user_details" ></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="row align-items-center mt-mb-1">
                                                        <div className="col col-6">
                                                            <p className="default_order_amount">Alias</p>
                                                        </div>
                                                        <div className="col col-6">
                                                            <div className="input-group user_name alias-input-field">
                                                                <TextField name="alias" type="text" style={{width: '90%'}} value={this.state.profileFields.alias || ''} onChange={this.handleInputChange} />
                                                                <span className="input-group-btn">
                                                                    <div className="profile-info-tooltip">
                                                                        <InfoIcon />
                                                                        <span className="tooltip-alias-text">Set your alias here</span>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            <div className="errorMsg">{this.state.profileErrors.alias}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-12">
                                                    <div className="allow_email_checkbox">
                                                        <label>Allow Promotional email</label>        
                                                        <label className="switch">
                                                            <input type="checkbox" onChange={this.handleEmailCheckChage} name="can_email" checked={this.state.EmailBoxIsChecked || ''}/>
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-12">
                                                    <div className="allow_email_checkbox">
                                                        <label>Promotional Push Notification</label>
                                                        <label className="switch">
                                                            <input type="checkbox" name="promotional_push_notification" onChange={this.handleProPushCheckChage} checked={this.state.proPushBoxIsChecked || ''} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12 col-xl-3">
                                            <div>
                                                {/* Commented by Viswanath - to hide the photo upload */}
                                                {/* <div className="user-profile-upload-image">
                                                    {!this.state.croppedImageUrl && (
                                                        <div>
                                                            {
                                                                (this.state.isPhotoUpload) ? 
                                                                <img className="imgShow" src={SpinnImg} alt="" />
                                                                :
                                                                <img className="imgShow" src={this.state.profilePic} alt="" />
                                                            }
                                                        </div>
                                                    )}
                                                    {this.state.croppedImageUrl && (
                                                        <img className="imgShow" alt="Crop" style={{ maxWidth: "100%" }} src={this.state.croppedImageUrl} />
                                                    )}
                                                    {
                                                        (this.state.showCropImg) &&
                                                        <div style={{ marginTop: 20, marginBottom: 10 }}>
                                                            <ReactCrop src={this.state.src} crop={this.state.crop} onImageLoaded={this.onImageLoaded} onComplete={this.onCropComplete} onChange={this.onCropChange} />
                                                        </div>
                                                    }
                                                    {
                                                        (!this.state.showCropImg) &&
                                                        <div className="file-wrapper">
                                                            <input type="file" name="picture" accept="image/*" onChange={(e) => {this.chooseProfilePicture(e)}} />
                                                            <div className="button" >Edit photo</div>
                                                        </div>
                                                    }
                                                    {
                                                        (this.state.showCropImg) &&
                                                        <div className="upload-profile-photo-btn-wrapper">
                                                            <button className="upload-profile-photo-btn-wrapper-btn" onClick={() => this.uploadUserImg()}>Upload photo</button>
                                                        </div>
                                                    }
                                                </div> */}
                                            </div>
                                            <Button variant="contained" color="primary" size="large" onClick={()=> this.handleProfileSubmit()} >
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="changepassword_content">
                        <div className="changepassword_heading page_title">
                            Change Password
                        </div>
                        <div className="form_content_changepassword edit_profile_form_fields_wrapper">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-12 col-lg-9">
                                        <div className="row">
                                            <div className="col col-12 col-sm-6">
                                                <div className="form-group change_password_field">
                                                    <TextField name="new_password" type="password" placeholder="Password" value={this.state.fields.new_password || ''} className="user_details" onChange={this.handlePasswordChange} />
                                                    <div className="errorMsg">{this.state.errors.new_password}</div>
                                                </div>
                                            </div>
                                            <div className="col col-12 col-sm-6">
                                                <div className="form-group change_confirm_password_field">
                                                    <TextField name="confirm_new_password" type="password" placeholder="Confirm Password" value={this.state.fields.confirm_new_password || ''} className="user_details" onChange={this.handlePasswordChange} />
                                                    <div className="errorMsg">{this.state.errors.confirm_new_password}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-3">
                                        <div className="change_password_submit">
                                            <Button variant="contained" color="primary" size="large" type="submit" disabled={this.state.submitButton}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </Dashboard>
        )
    }
}

EditProfileComponent.propTypes = {
    EditprofileRes: PropTypes.any,
    UpdateprofileRes: PropTypes.any,
    handleFormSubmit: PropTypes.func,
    MarketbrokerageRes: PropTypes.any,
    userActivityRes: PropTypes.any,
    userPictureRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    EditprofileRes: doEditprofileRes,
    UpdateprofileRes: doUpdateprofileRes,
    MarketbrokerageRes: doMarketBrokerageRes,
    userActivityRes: doUserActivityRes,
    userPictureRes: doUploadUserPictureRes,
});

function mapDispatchToProps(dispatch) {
    return {
        getProfile: () => dispatch(submit_profile()),
        updateProfile: (data, status) => dispatch(update_profile(data, status)),
        marketbrokerage: (data) => dispatch(market_brokerage(data)),
        userActivity: (data) => dispatch(user_activity(data)),
        uploadUserProfilePic: (data) => dispatch(uploadUserProfilePic(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EditProfileComponent);
