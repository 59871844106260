import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './orderDetails.css';
import { Link } from 'react-router-dom';
import Dashboard from '../../component/Dashboard';
import { submit_profile, doEditprofileRes } from '../../actions/editProfileActions';
import { delete_order, dodeleteOrderRes } from '../../actions/deleteOrderActions';
import { get_prospectus, doProspectusRes } from '../../actions/prospectusActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { getOrderDate, approximateshares, getOrderPrice, loadScript, numberWithCommasAndDollar, numberWithCommas } from '../../helper/helper';
// import { FacebookShareButton } from "react-simple-share";
import PlaceHolderImg from '../../assets/images/placeholder_company_thumb.png';

import BackIcon from '../../assets/images/images/icon-left.svg';
import DocIcon from '../../assets/images/doc-icon.svg';
// import FbLogo from '../../assets/images/images/facebook_logo.png';
// import TwitterLogo from '../../assets/images/images/twitter_logo.png';
// import StockLogo from '../../assets/images/images/stock_twits_logo.png';
// import LinkedinLogo from '../../assets/images/images/linkedin_logo.png';

import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(),
      top: theme.spacing(),
      color: theme.palette.grey[500],
    },
	}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
			<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
			<CloseIcon />
			</IconButton>
		) : null}
		</MuiDialogTitle>
	);
});
  
const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing(),
		alignItems: 'center',
		justifyContent: 'center'
	},
}))(MuiDialogActions);

// import { toastMessage } from '../../helper/toastMessage';
// import axios from 'axios';
let isProspectus = false;
let isProfile = false;
let isDeleteOrder = false;
class OrdersDetailsComponent extends React.PureComponent {

    constructor() {
        super();
        isProspectus = false;
        isProfile = false;
        isDeleteOrder = false;
        this.state = {
            isLoader: false,
            open: false,
            requestAmount: '',
            orderId: '',
            value: '',
            buying_power: '',
            space:', ',
            text : 'Just placed an order for shares of',
            hashtagtext: 'IPO, invest, stockmarket, fintech',
            offeringname: '',
            offeringid:'',
            offeringsymbol:'',
            facebookprovider:'',
            twitterprovider:'',
            stocktwitsprovider:'',
            linkedinprovider:'',
            twconsumerKey:'bIZV7y4PT5usCkNR9hQR2ZOyy',
            twcontent:'',
            orderCancelModal:false,
            prospectusModal:false,
            brochureModal: false,
            accountId: '',
            brokerDealerMpid: '',
            screenId: '1.1',
            faqModal: false
        }
    }

    addDefaultSrc(ev) {
        ev.target.src = PlaceHolderImg;
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    deleteActiveOrder() {
        this.setState({
            orderCancelModal: true
        })
    }

    deleteOrder(){
        let deleteOrderData = {
            "ext_id": this.state.offeringid,
            "mpid": this.state.brokerDealerMpid,
            "account_id": this.state.accountId
        }
        isDeleteOrder = true;
        this.props.deleteOrder(deleteOrderData);
    }

    cancelOrder(){
        this.props.history.push('/order')
    }

    componentDidMount() {
        loadScript();
        const id = this.props.match.params.id;
        const offeringData = this.props.location.state.offering;
        const orderData = this.props.location.state.orderData;
        isProfile = true;
        this.props.getProfile();
        if (offeringData && orderData) {
            const allOfferingOrderData = {
                "requested_amount": orderData.requested_amount,
                "requested_shares": orderData.requested_shares,
                "allocated_amount": orderData.allocated_amount,
                "allocated_shares": orderData.allocated_shares,
                "reconfirm_email_dt": null,
                "auto_cancellation_dt": orderData.auto_cancellation_dt,
                "cancelled_at": orderData.cancelled_at,
                "buying_power_as_of": orderData.buying_power_as_of,
                "dsp": orderData.dsp,
                "status": orderData.status,
                "created_at": orderData.created_at,
                "updated_at": orderData.updated_at,
                "offering": offeringData,
                "broker_connection": {
                  "broker_dealer_name": "",
                  "mpid": orderData.broker_connection.mpid,
                  "minimum_buy_order": "",
                  "hidden_marker": "",
                  "account_id": orderData.broker_connection.account_id,
                  "account_name": "",
                  "account_type": "",
                  "status": orderData.broker_connection.status
                }
            }
            this.setState({
                orderdetailsList: allOfferingOrderData,
                orderId: allOfferingOrderData.ext_id,
                requestAmount: allOfferingOrderData.requested_amount
            })
            this.setState({
                accountId: allOfferingOrderData.broker_connection.account_id,
                brokerDealerMpid: allOfferingOrderData.broker_connection.mpid,
            })
            this.setState({
                offeringname: allOfferingOrderData.offering.name,
                offeringid: allOfferingOrderData.offering.ext_id,
                offeringsymbol: allOfferingOrderData.offering.ticker_symbol,
            })
        }
        const activityData = {
            'ext_id': id,
			'screen_id': this.state.screenId,
			'source': 'web'
		}
		this.props.userActivity(activityData)
    }

    openurl(geturl) {
        const url = geturl;
        window.open(url, '_blank');
    }

    openModalLinkAcoount(){
        this.setState({ 
            open: true
        })
    }

    onCloseModal = () => {
        this.setState({ 
            open: false,
            prospectusModal: false,
            orderCancelModal: false,
            brochureModal: false,
        });
    };

    openProspectusModal = () => {
        this.setState({
            prospectusModal: true
        })
    }
    readFaq(geturl) {
        var url = geturl;
        var prefix = 'https://';
        if (url.substr(0, prefix.length) !== prefix)
        {
            url = prefix + url;
        }
        window.open(url, '_blank');
        this.setState({ 
            faqModal: false
        });
    }
    emailFaq(ext_id) {
        let faqData = {
            'ext_id' : ext_id,
            'url_type': 'faq'
        }
        isProspectus = true;
        this.props.getprospectus(faqData);
        this.setState({
            faqModal: false
        })
    }
    readProspectus(geturl) {
        var url = geturl;
        var prefix = 'https://';
        if (url.substr(0, prefix.length) !== prefix)
        {
            url = prefix + url;
        }
        window.open(url, '_blank');
        this.setState({ 
            prospectusModal: false
        });
    }

    emailProspectus(ext_id) {
        let prospectusData = {
            'ext_id' : ext_id,
            'url_type': 'prospectus'
        }
        isProspectus = true;
        this.props.getprospectus(prospectusData);
        this.setState({
            prospectusModal: false
        })
    }

    cancelProspectus() {
        this.setState({ 
            prospectusModal: false
        });
    }

    openBrochureModal = () => {
        this.setState({
            brochureModal: true,
        });
    }
    
    openFaqModal = () => {
        this.setState({
            faqModal: true,
        });
    }
    cancelFaq() {
        this.setState({ 
            faqModal: false
        });
    }
    readBrochure(geturl) {
        var url = geturl;
        var prefix = 'https://';
        if (url.substr(0, prefix.length) !== prefix)
        {
            url = prefix + url;
        }
        window.open(url, '_blank');
        this.setState({ 
            brochureModal: false
        });
    }

    emailBrochure(ext_id) {
        let brochureData = {
            'ext_id' : ext_id,
            'url_type': 'brochure'
        }
        isProspectus = true;
        this.props.getprospectus(brochureData);
        this.setState({ 
            brochureModal: false
        });
    }

    cancelBrochure() {
        this.setState({ 
            brochureModal: false
        });
    }

    clcikOk() {
        this.props.history.push('/socialmedia')
    }

    clcikCancel(){
        this.setState({ open: false });
    }

    linkedinShareContent(){
        // let shareData = {
        //     comment: "Clickipo",
        //     content: {
        //       title: "Clickipo",
        //       description: "Clickipo",
        //       submitted_url:"https://click.markets/offerings/?offer_id="
        //     },
        //     visibility :{
        //         code : "anyone"
        //     }
        // }
        // const config = {
        //     headers: {
        //         "Content-Type": "application/json",
        //         Authorization: 'Bearer ' + this.state.linkedintoken
        //     },
        // }
        // axios.post('https://api.linkedin.com/v1/people/~/shares?format=json', shareData, config)
        // .then(response => {
        //     if(response.ok === true){
        //         toastMessage('success',"Post share successfully")
        //     }else{
        //         toastMessage('error',"Post not share")
        //     }
        //     return response.json();
        // }).catch(error => {
        //     return error;
        // });
    }

    stoctwitsShareContent(){
        // let shareData = {
        //     body: this.state.orderdetailsList.ticker_symbol + this.state.space + this.state.orderdetailsList.name + this.state.space + this.state.text + 'https://click.markets/offerings/?offer_id=' + this.state.orderdetailsList.id + this.state.hashtagtext
        // }
        // const config = {
        //     headers: {
        //         "Content-Type": "application/x-www-form-urlencoded",
        //     },
        // }
        // axios.post(`https://api.stocktwits.com/api/2/messages/create.json?access_token=${this.state.sttoken}`, config, shareData)
        // .then(response => {
        //     return response.json();
        // }).catch(error => {
        //     return error;
        // });
    }

    twitterShareContent(){
        // let shareData = {
        //     status: this.state.orderdetailsList.ticker_symbol + this.state.space + this.state.orderdetailsList.name + this.state.space + this.state.text + 'https://click.markets/offerings/?offer_id=' + this.state.orderdetailsList.id + this.state.hashtagtext
        // }
        // const config = {
        //     headers: {
        //         "Content-Type": "application/json",
        //         Authorization: 'OAuth' +
        //         'include_entities=' + true + 
        //         'oauth_consumer_key=' + this.state.twiterconsumerkey + 
        //         'oauth_token=' + this.state.twittertoken + 
        //         'oauth_signature_method=HMAC-SHA1' + 
        //         'oauth_timestamp=' + this.state.twcontent + 
        //         'oauth_nonce=' + this.state.twcontent + 
        //         'oauth_version=1.0' + 
        //         'oauth_signature=' + this.state.twcontent
        //     },
        // }
        // axios.post(`https://api.twitter.com/1.1/statuses/update.json?status=${shareData}`, config)
        // .then(response => {
        //     return response.json();
        // }).catch(error => {
        //     return error;
        // });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.deleteorderRes) {
            if (props.deleteorderRes.data && props.deleteorderRes.data.deleteorder) {
                if(props.deleteorderRes.data.deleteorder.message === "Success" && isDeleteOrder){
                    isDeleteOrder = false;
                    props.history.push('/order')
                    return {
                        orderCancelModal: false
                    }
                }
            }
        }
        if (props.EditprofileRes) {
            if (props.EditprofileRes.data && props.EditprofileRes.data.editprofile) {
                if (props.EditprofileRes.data.editprofile.message === 'Success' && isProfile) {
                    isProfile = false;
                    let fbprovider = '';
                    let twprovider = '';
                    let stprovider = '';
                    let lnkinprovider = '';
                    if (props.EditprofileRes.data.editprofile.data.user_identities) {
                        for(let identity of props.EditprofileRes.data.editprofile.data.user_identities){
                            if(identity.provider === "facebook"){
                                fbprovider = identity.provider;
                            }
                            if(identity.provider === "twitter"){
                                twprovider = identity.provider;
                            }
                            if(identity.provider === "stocktwits"){
                                stprovider = identity.provider;
                            }
                            if(identity.provider === "linkedin"){
                                lnkinprovider = identity.provider;
                            }
                        }
                    }
    
                    return {
                        userList: props.EditprofileRes.data.editprofile.data,
                        isLoader: true,
                        facebookprovider: fbprovider,
                        twitterprovider: twprovider,
                        stocktwitsprovider: stprovider,
                        linkedinprovider: lnkinprovider,
                    };
                }
            }
        }
        if (props.prospectusRes) {
            if (props.prospectusRes.data && props.prospectusRes.data.prospectus) {
                if (props.prospectusRes.data.prospectus.message === 'Success' && isProspectus) {
                    isProspectus = false;
                    return {
                        prospectusModal: false
                    };
                }
            }
        }
        return null;
    }
    render() {
        const { orderdetailsList } = this.state;
        if (orderdetailsList) {
        }
        const { open } = this.state;
        return (
            <Dashboard title="Click Capital Markets - Order Details" pageTitle="Order Details" >
                {
                    this.state.orderdetailsList && this.state.orderdetailsList.offering &&
                    <div className="order_details" key={this.state.orderdetailsList}>
                        <div className="offring_deatils_heading">
                            <div className="back_icon_text"  onClick={this.handleBack}>
                                <img src={BackIcon} alt="" className="back_icon" />
                                <span className="go_back">back to orders</span>
                            </div>
                            <span className="offering_detail_title">{this.state.orderdetailsList.offering.name}</span>
                        </div>
                        <div className="order_details_box_inner_content pt-15px">
                            <div className="row ml-mr-0px-imp">
                                <div className="col col-12 col-sm-7 col-md-7">
                                    <div className="lft_images">
                                        <img onError={this.addDefaultSrc} src={(this.state.orderdetailsList.offering.logo_small) ? 'https:' + this.state.orderdetailsList.offering.logo_small : ''} className="details_logo" alt="" />
                                        <p className="offering_name">{this.state.orderdetailsList.offering.name}</p>
                                        <p className="description_heading">DESCRIPTION</p>
                                        <p className="description_content">
                                            {this.state.orderdetailsList.offering.description}
                                        </p>
                                        <div className="row">
                                            <div className="price_level_order_type col-md-4">
                                                <p className="item_link">PROSPECTUS</p>
                                                <div className="icon_text" onClick={this.openProspectusModal}>
                                                    <img src={DocIcon} alt="" />
                                                    <span>Prospectus</span>
                                                </div>
                                            </div>
                                            {
                                                (this.state.orderdetailsList.offering.dsp_faq_file !== null) && (this.state.orderdetailsList.offering.dsp_faq_file !== '') &&
                                                <div className="col-md-4">
                                                    <p className="item_link">DSP Faq</p>
                                                    <div className="icon_text" onClick={this.openFaqModal}>
                                                        <img src={DocIcon} alt="" />
                                                        <span>DSP Faq</span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (this.state.orderdetailsList.offering.brochure_url !== null) && (this.state.orderdetailsList.offering.brochure_url !== '') &&
                                                <div className="col-md-4">
                                                    <p className="item_link">Brochure</p>
                                                    <div className="icon_text" onClick={this.openBrochureModal}>
                                                        <img src={DocIcon} alt="" />
                                                        <span>Brochure</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="price_level_order_type">
                                            <span className="order_status">UNDERWRITERS</span>
                                            {
                                                this.state.orderdetailsList.offering.underwriters_list && this.state.orderdetailsList.offering.underwriters_list.length > 0 && this.state.orderdetailsList.offering.underwriters_list.map((offeringUnderWriter, i) =>
                                                    <div key={i}>
                                                        {
                                                            offeringUnderWriter.executing_broker_name === null &&
                                                            <span className="order_status_text">
                                                                Not Available
                                                            </span>
                                                        }
                                                        {
                                                            offeringUnderWriter.executing_broker_name !== null &&
                                                            <span className="order_status_text">
                                                            {offeringUnderWriter.executing_broker_name}
                                                            </span>
                                                        }
                                                    </div>
                                                )  
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-12 col-sm-5 col-md-5">

                                    <p className="company_order_tag">
                                        Ticker Symbol: {this.state.orderdetailsList.offering.ticker_symbol}
                                    </p>
                                    <p className="company_order_account" style={{ color: this.state.orderdetailsList.offering.offering_type_name === 'IPO' ? "#8DC73F" : "#44c6d2" }}>
                                        {this.state.orderdetailsList.offering.offering_type_name}
                                    </p>
                                    <div className="row account_order_type">
                                        <div className="col col-8">
                                            <p className="industries_content">Your Conditional Purchase Order</p>
                                        </div>
                                        <div className="col col-4">
                                            <span className="account_open_order_date account_open_order_date_two ">${this.state.orderdetailsList.requested_amount}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-6">
                                            <p className="industries_content">Anticipated to be public week of</p>
                                        </div>
                                        <div className="col col-6">
                                            <span className="account_open_order_date account_open_order_date_two">
                                                {getOrderDate(this.state.orderdetailsList)}
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        this.state.orderdetailsList.offering.offering_type_name === 'IPO' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="industries_content">Anticipated price range</p>
                                            </div>
                                            <div className="col col-6 ">
                                                <span className="account_open_order_date account_open_order_date_two">{getOrderPrice(this.state.orderdetailsList)}</span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.orderdetailsList.offering.offering_type_name === 'Follow-on' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="industries_content">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <span className="account_open_order_date account_open_order_date_two">{getOrderPrice(this.state.orderdetailsList)}</span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.orderdetailsList.offering.offering_type_name === 'Block' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="industries_content">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <span className="account_open_order_date account_open_order_date_two">{getOrderPrice(this.state.orderdetailsList)}</span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.orderdetailsList.offering.offering_type_name === 'Spot' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="industries_content">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <span className="account_open_order_date account_open_order_date_two">{getOrderPrice(this.state.orderdetailsList)}</span>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.orderdetailsList.offering.offering_type_name === 'Overnight' &&
                                        <div className="row">
                                            <div className="col col-6">
                                                <p className="industries_content">Last closing price</p>
                                            </div>
                                            <div className="col col-6">
                                                <span className="account_open_order_date account_open_order_date_two">{getOrderPrice(this.state.orderdetailsList)}</span>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col col-6">
                                            <p className="industries_content">Approximate Shares</p>
                                        </div>
                                        <div className="col col-6">
                                            <span className="account_open_order_date account_open_order_date_two">{approximateshares(this.state.orderdetailsList)}</span>
                                        </div>
                                    </div>
                                    <div className="row order_details_btn">
                                        <div className="col col-12 col-md-12 col-lg-12">
                                            <div className="modify_order_button_wrapper" style={{textAlign: 'center'}}>
                                                {
                                                    this.state.orderdetailsList.auto_cancellation_dt === null && this.state.orderdetailsList.offering.ioi_cutoff_datetime === null &&
                                                    <div style={{textAlign: 'center'}}>
                                                        {/* <div className="modify_order_button" > */}
                                                            <Link style={{ margin: 20 }} className="modify_order_button" to={{ pathname: `/modifyorder/${this.state.orderdetailsList.offering.ext_id}`, state: { orderData: orderdetailsList } }}>
                                                                <button className={ (this.state.orderdetailsList.offering.offering_type_name === 'IPO' ? "green_btn" : "turquoise_btn")}>
                                                                    Modify Order
                                                                </button>
                                                            </Link>
                                                        {/* </div>
                                                        <div className="modify_order_button" onClick={() => this.deleteActiveOrder()}> */}
                                                            <button style={{ margin: 20 }} className={ (this.state.orderdetailsList.offering.offering_type_name === 'IPO' ? "green_btn modify_order_button" : "turquoise_btn modify_order_button")} onClick={() => this.deleteActiveOrder()}>
                                                                Cancel Order
                                                            </button>
                                                        {/* </div> */}
                                                    </div>
                                                }
                                                {
                                                    (this.state.orderdetailsList.auto_cancellation_dt !== null || this.state.orderdetailsList.offering.ioi_cutoff_datetime !== null) &&
                                                    // <div className="modify_order_button">
                                                        <Link className="modify_order_button" to={{ pathname: `/orderconfirmation/${this.state.orderdetailsList.offering.ext_id}`, state: { orderData: orderdetailsList } }}>
                                                            <button className={ (this.state.orderdetailsList.offering.offering_type_name === 'IPO' ? "green_btn" : "turquoise_btn")}>
                                                                Reconfirm Order
                                                            </button>
                                                        </Link>
                                                    // </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row price_level_order_type share_it">
                                        <div className="col col-12 col-md-12 col-xl-12 text-right">
                                            <p className="share_text">Share it!</p>
                                        <div className="share_social_icons">
                                            {
                                                this.state.facebookprovider === "facebook" &&
                                                <FacebookShareButton
                                                    url={'https://click.markets/offerings/?offer_id=' + this.state.orderdetailsList.offering.id + '&utm_source=Facebook&utm_campaign=Facebook_ss_app&utm_term=%24' + this.state.orderdetailsList.offering.ticker_symbol + '&ct=Facebook_ss_app' }
                                                    description="The IPO Marketplace For You"
                                                />
                                            }
                                            {
                                                this.state.facebookprovider !== "facebook" &&
                                                <div className="media_facebook_image" onClick={() => this.openModalLinkAcoount()}>
                                                    <div id="facebook-share-button">
                                                        <img src={FbLogo} alt="" />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.twitterprovider === "twitter" &&
                                                <div className="media_twitter_image" onClick={() => this.twitterShareContent()}>
                                                    <div id="twitter-share-button">
                                                        <img src={TwitterLogo} alt="" />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.twitterprovider !== "twitter" &&
                                                <div className="media_twitter_image" onClick={() => this.openModalLinkAcoount()}>
                                                    <div id="twitter-share-button">
                                                        <img src={TwitterLogo} alt="" />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.stocktwitsprovider === "stocktwits" &&
                                            <div className="media_stocktwits_image" onClick={() => this.stoctwitsShareContent()}>
                                                <div id="stocktwits-share-button">
                                                    <img src={StockLogo} alt="" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.stocktwitsprovider !== "stocktwits" &&
                                            <div className="media_stocktwits_image" onClick={() => this.openModalLinkAcoount()}>
                                                <div id="stocktwits-share-button">
                                                    <img src={StockLogo} alt="" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.linkedinprovider === "linkedin" &&
                                            <div className="media_linkedin_image" onClick={() => this.linkedinShareContent()}>
                                                <div id="linkedin-share-button">
                                                    <img src={LinkedinLogo} alt="" />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.linkedinprovider !== "linkedin" &&
                                            <div className="media_linkedin_image" onClick={() => this.openModalLinkAcoount()}>
                                                <div id="linkedin-share-button">
                                                    <img src={LinkedinLogo} alt="" />
                                                </div>
                                            </div>
                                        }
                                        </div>
                                        
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <Dialog
                            open={this.state.prospectusModal}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogTitle id="scroll-dialog-title">Prospectus</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Would you like to read the prospectus or email yourself a copy?
                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <Button className="btns-all" onClick={() => this.readProspectus( this.state.offeringsdetailsList.prospectus_url)} color="primary">
                                        Read
                                    </Button>
                                    <Button className="btns-all" onClick={() => this.emailProspectus( this.state.offeringsdetailsList.ext_id)} color="primary">
                                        Email
                                    </Button>
                                    <Button className="btns-all-cancel" onClick={() => this.cancelProspectus()} color="primary">
                                        Cancel
                                    </Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.brochureModal}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogTitle id="scroll-dialog-title">Brochure</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Would you like to display the brochure or email yourself a copy?
                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <Button className="btns-all" onClick={() => this.readBrochure( this.state.offeringsdetailsList.prospectus_url)} color="primary">
                                        Read
                                    </Button>
                                    <Button className="btns-all" onClick={() => this.emailBrochure( this.state.offeringsdetailsList.ext_id)} color="primary">
                                        Email
                                    </Button>
                                    <Button className="btns-all-cancel" onClick={() => this.cancelBrochure()} color="primary">
                                        Cancel
                                    </Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.faqModal}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogTitle id="scroll-dialog-title">Faq</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Would you like to display the faq or email yourself a copy?
                                </DialogContentText>
                                <div style={{textAlign: 'center'}}>
                                    <Button className="btns-all" onClick={() => this.readFaq( this.state.offeringsdetailsList.prospectus_url)} color="primary">
                                        Read
                                    </Button>
                                    <Button className="btns-all" onClick={() => this.emailFaq( this.state.offeringsdetailsList.ext_id)} color="primary">
                                        Email
                                    </Button>
                                    <Button className="btns-all-cancel" onClick={() => this.cancelFaq()} color="primary">
                                        Cancel
                                    </Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={open}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogContent>
                                <h5> Please Link Your account for share offerings in social media</h5>
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.clcikOk()}>
                                        Ok
                                    </Button>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.clcikCancel()}>
                                        Cancel
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={this.state.orderCancelModal}
                            onClose={this.onCloseModal}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogTitle onClose={this.onCloseModal}>Are you sure ?</DialogTitle>
                            <DialogContent>
                                {
                                    this.state.orderdetailsList && this.state.orderdetailsList.offering && this.state.orderdetailsList.offering.ordr_dollar_share === 0 ?
                                    <h5> Canceling your order for {numberWithCommasAndDollar(this.state.orderdetailsList.requested_amount)} of {this.state.orderdetailsList.offering.name}</h5>
                                    :
                                    <h5> Canceling your order for {numberWithCommas(this.state.orderdetailsList.requested_amount)} of {this.state.orderdetailsList.offering.name}</h5>
                                }
                            </DialogContent>
                            <DialogActions>
                                <div style={{textAlign: 'center'}}>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="primary" onClick={() => this.deleteOrder()}>
                                        I'm Sure, Cancel Order
                                    </Button>
                                    <Button style={{marginLeft: 10, marginRight: 10}} variant="contained" size="large" color="secondary" onClick={() => this.cancelOrder()}>
                                        Don't Cancel
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                }
            </Dashboard>
        )
    }
}

OrdersDetailsComponent.propTypes = {
    deleteorderRes: PropTypes.any,
    EditprofileRes: PropTypes.any,
    prospectusRes: PropTypes.any,
    userActivityRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    deleteorderRes: dodeleteOrderRes,
    EditprofileRes: doEditprofileRes,
    prospectusRes: doProspectusRes,
    userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
    return {
        deleteOrder: (data) => dispatch(delete_order(data)),
        getProfile: () => dispatch(submit_profile()),
        getprospectus: (data) => dispatch(get_prospectus(data)),
        userActivity: (data) => dispatch(user_activity(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OrdersDetailsComponent);
