import { BASE_URL } from '../constants';
import { errorHandling, errorHandler } from '../utils/errorHandling';
import { getItem } from '../utils/localStore';
import Swal from 'sweetalert2';

class socialTokenApi {
    static socialToken(data) {
        const TOKEN = getItem('auth_token');

        let socialTokenData={
            provider: data.provider,
            hash : data.hash,
        }
        let isError = false;
        try{
            const ajaxRequestHeaders = new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': TOKEN
            });
            let body = {
                method: 'POST',
                headers: ajaxRequestHeaders,
                body:JSON.stringify(socialTokenData)
            }
            return fetch(BASE_URL + '/web/user_identities', body).then(response => {
                if (response.ok === true) {
                    Swal.fire({
                        title: 'Account linked successfully',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        allowOutsideClick: true,
                        timer: 3000,
                    })
                    setTimeout(function() {
                        window.location.href = '/socialmedia';
                    }, 5000);
                }
                isError = errorHandling(response);
                return response.json();
            }).catch(error => {
                if (error && !isError) {
                    errorHandler(error);
                }
                return error;
            });
        }catch(err){
        }
    }
}

export default socialTokenApi;