import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

const fields = ['code'];
const lower = value => value && value.toLowerCase();

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <div>
            <input className="form-control" {...input} placeholder={label} type={type} />
            {touched && error && <span>{error}</span>}
        </div>
    </div>
)

const validate = values => {
    const errors = {}
    const re = /^[0-9]+$/;
    if (!values.code) {
        errors.code = 'Required'
    }
    if (values.code) {
        if (!re.test(values.code)) {
            errors.code = 'Only numeric value allowed'
        }
    }
    return errors
}

class ResetCodeForm extends React.PureComponent {

    constructor() {
        super();
        this.state = {
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const { handleSubmit, handleFormSubmit, pristine, submitting } = this.props
        return (
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="small-12 columns error_message form_field_wrapper">
                    <Field
                        name="code"
                        component={renderField}
                        label="Enter Verify Code"
                        autoComplete="off"
                        type="text"
                        normalize={lower}
                    />
                </div>
                <div>
                    <button type="submit" className="btn resetcode_button" disabled={pristine || submitting}>Submit</button>
                </div>
            </form>
        )
    }

}

ResetCodeForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleFormSubmit: PropTypes.func,
};
const mapStateToProps = createStructuredSelector({
});
  
function mapDispatchToProps(dispatch,ownProps) {
    return {
        
    };
}
const ReduxResetCodeForm = reduxForm({
    form: 'ReduxResetCodeForm',
    validate,
    fields,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnBlur: false,
})(ResetCodeForm)

export default connect(mapStateToProps, mapDispatchToProps)(ReduxResetCodeForm);