
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './verifyEmail.scss';
import { submitVerifyEmail, doVerifyEmailRes } from '../../actions/verifyEmailActions';
import { loadScript } from '../../helper/helper';
import CompanyLogo from '../../assets/images/clickipo-logo-tagline.png';
import IsoMockups from '../../assets/images/ipo-platform-mockups.png';
import IosAppImg from '../../assets/images/download_clickipo_ios.png';
import AndroidAppImg from '../../assets/images/download_clickipo_android.png';

class VerifyEmailComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            showSuccessMessage: false,
        }
    }


    componentDidMount() {
        loadScript();
        const query = new URLSearchParams(this.props.location.search);
        const verify_token = query.get('verify_token');
        let requestData = {
            token: verify_token,
        }
        if (requestData.token) {
            this.props.verifyEmail(requestData);
        }
    }

    static getDerivedStateFromProps(props, state) {
		if (props.verifyEmailRes) {
            if (props.verifyEmailRes.user && props.verifyEmailRes.user.user) {
                if (props.verifyEmailRes.user.user.message === "Success") {
                    return {
                        showSuccessMessage: true,
                    };
                }
            }
        }
		return null
	}
    render() {
        return (
            <div>
                {
                    (this.state.showSuccessMessage) &&

                    <div className="verifyemail-page">
                        <div className="container-fluid verifyemail-content">
                            <div className="row">
                                <div className="col-md-7 col-lg-8">
                                    <div className="verifyemail-company-logo">
                                        <img src={CompanyLogo} alt="" />
                                    </div>
                                    <div className="verifyemail-platform-mockup">
                                        <img src={IsoMockups} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-5 col-lg-4">
                                    <div className="verifyemail_content_inner_body">
                                        <div className="verifyemail_content_inner_body_inner">
                                            <div className="top-border"></div>
                                            <div className="verifyemail_heading">
                                                Email Verification
                                            </div>
                                            <div className="form_content_verifyemail">
                                                <div className="email-verification-title">Your email has been verified. <br/> Please login to continue.</div>
                                                <div className="row mt-35px">
                                                    <div className="col col-12">
                                                        <Link to={"/login"} className="text-decoration-none">
                                                            <button className="btn verifyemail_button" style={{ maxWidth: "100%" }}>Go to Login</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="verifyemail_content_form_getapp">
                                        <div className="getapp_title">
                                            <p>Get the app!</p>
                                        </div>
                                        <div className="row app-download-box">
                                            <div className="col-6">
                                                <div className="ios-app-image">
                                                    <a href="https://itunes.apple.com/app/apple-store/id1236247345?pt=118497532&ct=cipo_desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src={IosAppImg} alt="" /></a>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="android-app-image">
                                                    <a href="https://play.google.com/store/apps/details?id=com.clickipo.clickipo&utm_source=ClickIPO&utm_medium=website&utm_campaign=cipo_desktop" target="_blank" rel="noopener noreferrer"><img src={AndroidAppImg} alt="" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

        )
    }
}


VerifyEmailComponent.propTypes = {
    verifyEmailRes: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    verifyEmailRes: doVerifyEmailRes
});

function mapDispatchToProps(dispatch) {
    return {
        verifyEmail: (data) => dispatch(submitVerifyEmail(data))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(VerifyEmailComponent);
