import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './plannerAccount.scss';
// import { Link } from 'react-router-dom';
import Dashboard from '../../component/Dashboard';
import { loadScript } from '../../helper/helper';
import CreateForm from './createForm/createForm';
import { submit_profile, doEditprofileRes } from '../../actions/editProfileActions';
import BackIcon from '../../assets/images/images/icon-left.svg';
let isProfile = false;
class PlannerAccountComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        isProfile = false;
        this.state = {
            isLoader: false,
            profileData: '',
            brokerdealerdetails: '',
        }
    }

    componentDidMount() {
        loadScript();
        isProfile = true;
        // this.props.getProfile();
    }
    static getDerivedStateFromProps(props, state) {
        
        if (props.editprofileRes) {
            if (props.editprofileRes.data && props.editprofileRes.data.editprofile) {
                if (props.editprofileRes.data.editprofile.message === 'Success' && isProfile) {
                    isProfile = false;
                    return {
                        profileData: props.editprofileRes.data.editprofile.data,
                    };
                }
            }
        }
        return null
    }
    handleBack = () => {
        this.props.history.goBack()
    }
    handleSubmit(data) {
        // let sendRequest = data;
        // this.props.handleFormSubmit(sendRequest);
    };
    render() {
        return (
            <Dashboard title="ClickIPO - Create Brokerage Account" pageTitle="Create Brokerage Account">
                <div className="create_brokerage_form">
                    <div className="offring_deatils_heading">
                        <div className="back_icon_text"  onClick={this.handleBack}>
                            <img src={BackIcon} alt="" className="back_icon" />
                            <span className="go_back">Back to Brokerage Firms</span>
                        </div>
                    </div>
                    <div className="create_broker_box_inner_content pt-15px">
                        {
                            this.state.profileData && this.state.profileData.broker_connection === null &&
                            <CreateForm profileDetail={this.state.profileData} brokerdealerdetails={this.state.brokerdealerdetails} handleFormSubmit={(data) => { this.handleSubmit(data) }} history={this.props.history} />
                        }
                    </div>
                </div>
            </Dashboard>
        )
    }
}

PlannerAccountComponent.propTypes = {
    editprofileRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    editprofileRes: doEditprofileRes
});

function mapDispatchToProps(dispatch) {
    return {
        getProfile: () => dispatch(submit_profile()),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PlannerAccountComponent);
