import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ThemeWrapper from './component/LayoutWrapper';
import Loader from "react-loader";
import { loadingRes } from './actions/loadingActions';
import { doEditprofileRes } from './actions/editProfileActions';

import LoginComponent from './container/login/login';
import SignupComponent from './container/signup/signup';
import PrivacyPolicyComponent from './container/privacypolicy/privacyPolicy';
import TermConditionComponent from './container/termandcondition/termandCondition';
import OfferingComponent from './container/offerings/offerings';
import OrderComponent from './container/orders/orders';
import BrokerDealerComponent from './container/brokerdealer/brokerDealer';
import OrderReconfirmationComponent from './container/orderreconfirmation/orderReconfirmation';
import PlaceOrderComponent from './container/placeorder/placeOrder';
import ForgetPasswordComponent from './container/forgetpassword/forgetPassword';
import EditProfileComponent from './container/editprofile/editProfile';
import ModifyOrederComponent from './container/modifyorder/modifyOrder';
import OfferingDetailsComponent from './container/offeringsdetails/offeringsDetails';
import FaqComponent from './container/faq/faq';
import GlossaryComponent from './container/glossary/glossary';
import OrdersDetailsComponent from './container/orderdetails/orderDetails';
import BrokerageConnectionComponent from './container/brokerageconnection/brokerageConnections';
import BrokerComponent from './broker';
import StocktwitsComponent from './container/stocktwits/stocktwits';
import LinkedInComponent from './container/stocktwits/linkedin';
import ResetPasswordComponent from './container/passwordreset/reset-password';
import SecuritySymbolComponent from './container/securitysymbol/securitySymbol';
import VerifyPasswordComponent from './container/verifypassword/verifyPassword';
import SocialMediaComponent from './container/socialmedia/socialMedia';
import VerifyEmailComponent from './container/verifyemail/verifyEmail';
import SendSignupEmailComponent from './container/sendsignupemail/sendsignupemail';
import AttestationComponent from './container/attestation/attestation';
import MessagesComponent from './container/messages/messages';
import BizInsightComponent from './container/bizinsight/bizinsight';
import OfferingListComponent from './container/offeringList/offeringList';
import MessageDetailMobileComponent from './container/messagedetailmob/messagedetailmob';
import ResetCodeComponent from './container/resetcode/resetcode';
import AttestationFileComponent from './container/attestationFile/attestationFile';
import CreateBrokerageComponent from './container/createBrokerage/createBrokerage';
import CreateForm from './component/createForm/createForm';
import PlannerAccountComponent from './container/plannerAccount/plannerAccount';
import TokenError from './utils/invalidTokenError';
import { getItem } from './utils/localStore';
import IdleTimer from 'react-idle-timer';
import Swal from 'sweetalert2';
import { logout } from './helper/helper';
import { requestForToken, onMessageListener } from './firebase';
// import NetworkDetector from './utils/NetworkDetector';
let TOKEN = getItem('auth_token');
class App extends React.PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			timeout: 1000 * 60 * 30,
			showModal: false,
			userLoggedIn: false,
			isTimedOut: false,
			isLoader: true
		}

		this.idleTimer = null
		this.onAction = this._onAction.bind(this)
		this.onActive = this._onActive.bind(this)
		this.onIdle = this._onIdle.bind(this)
	}
	componentDidMount() {
		requestForToken();
		onMessageListener().then(payload => {
			// setNotification({title: payload.notification.title, body: payload.notification.body})
			// setShow(true);
			console.log(payload);
		}).catch(err => console.log('failed: ', err));
	}
	_onAction(e) {
		this.setState({
			isTimedOut: false
		})
	}

	_onActive(e) {
		this.setState({
			isTimedOut: false
		})
	}

	_onIdle(e) {
		const isTimedOut = this.state.isTimedOut
		if (isTimedOut) {
			logout();
		} else {
			this.setState({
				showModal: true
			})
			this.idleTimer.reset();
			this.setState({
				isTimedOut: true
			})
			Swal.fire({
				icon: 'info',
				title: '<strong style="font-size: 24px;padding-top: 20px;padding-bottom: 20px;">You are being logged out due to inactivity</strong>',
				padding: '3em',
				width: 400,
				showConfirmButton: false,
				allowOutsideClick: true,
				timer: 30000,
				heightAuto: false,
				onClose: () => {
					logout();
				}
			}).then((result) => {
				logout();
			})
		}

	}
	static getDerivedStateFromProps(props, state) {
		if (props.loadingRes) {
			if (props.loadingRes.data) {
				if (props.loadingRes.data.isLoading === true || props.loadingRes.data.isLoading === false) {
					if (props.loadingRes.data.isLoading === false) {
						return {
							isLoader: true
						};
					} else {
						return {
							isLoader: false
						};
					}
				}

			}
		}
		if (props.EditprofileRes) {
			if (props.EditprofileRes.data && props.EditprofileRes.data.editprofile) {
			  if (props.EditprofileRes.data.editprofile && props.EditprofileRes.data.editprofile.message === 'Success') {
				let userName = props.EditprofileRes.data.editprofile.data.first_name + " " + props.EditprofileRes.data.editprofile.data.last_name;
				localStorage.setItem('userName', userName);
			  }
			}
		}
		return null
	}
	render() {
		
		let redirectURL = "/?red_url=" + window.location.href;
		var options = {
			lines: 13,
			length: 10,
			width: 5,
			radius: 20,
			corners: 1,
			rotate: 0,
			direction: 1,
			color: "#fff",
			speed: 1,
			trail: 60,
			shadow: false,
			hwaccel: false,
			zIndex: 2e9,
			top: "50%",
			left: "50%",
			scale: 1.0,
			loadedClassName: "loadedContent"
		};
		return (
			<div style={{ height: '100%' }}>
				<Loader
					loaded={this.state.isLoader}
					options={options}
					className="spinner"
				></Loader>
				{
					TOKEN &&
					<IdleTimer
						ref={ref => { this.idleTimer = ref }}
						element={document}
						onActive={this.onActive}
						onIdle={this.onIdle}
						onAction={this.onAction}
						debounce={250}
						timeout={this.state.timeout} />
				}
				<ThemeWrapper>
					<React.Fragment>
						<BrowserRouter>
						{
							(!TOKEN) ?
						
								<Switch>
									<Route exact={true} path="/" component={LoginComponent} />
									<Route path="/login" component={LoginComponent} />
									<Route path="/signup" component={SignupComponent} />
									<Route path="/forgetpassword" component={ForgetPasswordComponent} />
									<Route path="/password-reset" component={ResetCodeComponent} />
									<Route path="/reset-password" component={ResetPasswordComponent} />
									<Route path="/securityinfo/:symbol" component={SecuritySymbolComponent} />
									<Route path="/send-verify-email" component={SendSignupEmailComponent} />
									<Route path="/email-verify" component={VerifyEmailComponent} />
									<Route path="/attestation.html" component={AttestationComponent} />
									<Route path="/offeringlist" component={OfferingListComponent} />
									<Route path="/message-detail" component={MessageDetailMobileComponent} />
									<Route path="/brokerdealeracc" component={CreateForm} />
									<Route path="/invalidToken" component={TokenError} />
									<Route render={() => (<Redirect to={redirectURL} />)} />
								</Switch>
								:
								<Switch>
									<Route path="/offering" component={OfferingComponent} />
									<Route path="/order" component={OrderComponent} />
									<Route path="/brokerdealer" component={BrokerDealerComponent} />
									<Route path="/brokerdealeracc" component={CreateForm} />
									<Route path="/editprofile" component={EditProfileComponent} />
									<Route path="/socialmedia" component={SocialMediaComponent} />
									<Route path="/offeringdetails/:id" component={OfferingDetailsComponent} />
									<Route path="/placeorder/:id" component={PlaceOrderComponent} />
									<Route path="/orderconfirmation/:id" component={OrderReconfirmationComponent} />
									<Route path="/orderdeatils/:id" component={OrdersDetailsComponent} />
									<Route path="/modifyorder/:id" component={ModifyOrederComponent} />
									<Route path="/brokeragefirms/:id" component={BrokerageConnectionComponent} />
									<Route path="/broker/:id" component={BrokerComponent} />
									<Route path="/glossary" component={GlossaryComponent} />
									<Route path="/faq" component={FaqComponent} />
									<Route path="/privacypolicy" component={PrivacyPolicyComponent} />
									<Route path="/termandcondition" component={TermConditionComponent} />
									<Route path="/verifypassword" component={VerifyPasswordComponent} />
									<Route path="/stocktwits" component={StocktwitsComponent} />
									<Route path="/linkedin" component={LinkedInComponent} />
									<Route path="/offeringdetail/:id/biz-insight" component={BizInsightComponent} />
									<Route path="/messages" component={MessagesComponent} />
									<Route path="/message-detail" component={MessageDetailMobileComponent} />
									<Route path="/attestation-file" component={AttestationFileComponent} />
									<Route path="/create-brokerage" component={CreateBrokerageComponent} />
									<Route path="/create-planner-account" component={PlannerAccountComponent} />
									
									<Route render={() => (<Redirect to="/offering" />)} />
								</Switch>
						}
						</BrowserRouter>
					</React.Fragment>
				</ThemeWrapper>
			</div>
		)
	}
}

App.propTypes = {
	loadingRes: PropTypes.any,
	EditprofileRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
	loadingRes: loadingRes,
	EditprofileRes: doEditprofileRes,
});

function mapDispatchToProps(dispatch) {
	return {
	};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
