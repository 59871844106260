import React from 'react'
import logoImg from '../assets/images/clickipo_logo.png';
import LazyLoad from 'react-lazyload';


export default function TokenError() {
    return (
        <div style={{backgroundColor: '#C5E29D', height:'100%', width: '100%'}}>
            <div style={{marginLeft:10}}>
                                <LazyLoad debounce={true}>
                                    <img src={logoImg}  alt={'clickipo'} />
                                </LazyLoad>
                </div>
            <div style={{textAlign:'center', paddingTop: '230px', }} >
                <p style={{color: '#245776', fontSize: 130, fontWeight: 600}}>401</p>
                <h1 style={{color: '#245776', fontSize: 35, }}>Invalid User Token</h1> 
           </div>
           <div style={{backgroundColor: '#C5E29D', height:'450px', width: '100%'}}>

           </div>
        </div>
    )
}
