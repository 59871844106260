import * as types from './actionTypes';  
import offeringListApi from '../api/offeringListApi';
import Swal from 'sweetalert2';
import { loadingRes } from './loadingActions';

export function getOfferingList() {
  return {
    type: types.DO_OFFERINGLIST
  };
}

export function doOfferingListRes(data) {
  return {
    type: types.DO_OFFERINGLIST_RES, 
    data
  };
}

export function fetchOfferingList() {
  return function(dispatch) {
    dispatch(loadingRes(true));
    offeringListApi.getOfferingList().then(data => {
      dispatch(doOfferingListRes(data));
      dispatch(loadingRes(false));
      if(data.error){
        Swal.fire({
          title: data.message,
          icon: 'error',
          confirmButtonText: 'OK',
          heightAuto: false
        })
      }
    }).catch(error => {
      dispatch(loadingRes(false));
      return error
    });
  };
}
