import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import './brokerageConnections.css';
import Dashboard from '../../component/Dashboard';
import { submit_profile, doEditprofileRes } from '../../actions/editProfileActions';
import { restricted_personApi, doRestrictedPersonRes } from '../../actions/restrictedPersonActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { query } from '../../helper/Utilities';
// import Popout from 'react-popout';
import { setItem } from '../../utils/localStore';
import { loadScript } from '../../helper/helper';
import BackIcon from '../../assets/images/images/icon-left.svg';
import { PDF_FINRARULE_BOTH } from '../../constants';

// var requestId = []

class BrokerageConnectionComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            brokerdealerdetailsData: "",
            // isConnectPoppedOut: false,
            // isCreatePoppedOut: false,
            brokerdealerList: '',
            brokerdealername: '',
            brokerdealerId: '',
            brokerSecret: '',
            // brokerCallBack: 'http://localhost:3001/broker/TradeStation',
            // windowUrl: '',
            Firstischecked: false,
            Secondischecked: false,
            IsAllChecked: true,
            screenId: '1.1',
        }
        this.firstCheck = this.firstCheck.bind(this);
        this.secondCheck = this.secondCheck.bind(this);
        this.createAccount = this.createAccount.bind(this);
        // this.createpopoutClosed = this.createpopoutClosed.bind(this);
    }

    firstCheck() {
        this.setState({
            Firstischecked: !this.state.Firstischecked
        })
        if(this.state.Secondischecked) {
            this.setState({
                IsAllChecked: !this.state.IsAllChecked   
            })
        }
    }

    secondCheck() {
        this.setState({
            Secondischecked: !this.state.Secondischecked
        });
        if(this.state.Firstischecked) {
            this.setState({
                IsAllChecked: !this.state.IsAllChecked     
            })
        }
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    componentDidMount() {
        loadScript();
        this.setState({
            brokerdealerdetailsData: this.props.location.state,
            Firstischecked: false,
            Secondischecked: false,
        },()=>{})
        const activityData = {
            'screen_id': this.state.screenId,
            'source': 'web'
        }
        this.props.userActivity(activityData)
    }

    openurl(geturl) {
        const url = geturl;
        window.open(url, '_blank');
    }

    // createpopoutClosed() {
    //     this.setState({
    //         isCreatePoppedOut: false
    //     })
    // }

    static getDerivedStateFromProps(props, state) {
		if (props.EditprofileRes) {
            if (props.EditprofileRes.data && props.EditprofileRes.data.editprofile) {
                if (props.EditprofileRes.data.editprofile && props.EditprofileRes.data.editprofile.message === 'Success') {
                    return {
                        userList: props.EditprofileRes.data.editprofile.data,
                    };
                }
            }
        }
		return null
	}
    connectBrokerDealer(name, bd_id, authurl, callbackurl) {
        setItem('broker_dealer_details', JSON.stringify(this.state.brokerdealerdetailsData));
        var authorizeUrl = [];
        // if (name === 'Tradier') {
        //     requestId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        //     authorizeUrl = authurl + '?' + query({ client_id: bd_id, scope: 'read', state: requestId })
        // }
        if (name === 'Tradier' || name === 'Just2Trade' || name === 'TradeStation') {
            // requestId = 'none'
            authorizeUrl = authurl + '?redirect_uri=' + callbackurl + '&' + query({ client_id: bd_id, response_type: 'code' })
        } else if (name === 'Planner') {
            authorizeUrl = "/create-planner-account";
        }
        window.location.href = authorizeUrl;
        // this.setState({
        //     windowUrl: authorizeUrl,
        // })
    }

    createAccount() {
        // if (this.state.isCreatePoppedOut === false) {
        //     this.setState({
        //         isCreatePoppedOut: true
        //     })
        // } else {
        //     this.setState({
        //         isCreatePoppedOut: false
        //     })
        // }
        window.open(this.state.brokerdealerdetailsData?.create_account_url, '_blank');
    }
    createBrokerageAccount = () => {
        this.props.history.push({
            pathname: `/create-brokerage`,
            state: this.state.brokerdealerdetailsData
        });
    }
    render() {
        return (
            <Dashboard title="Click Capital Markets - Brokerage Firms"  pageTitle="Brokerage Firms" >
                <div className="brokerage_content">
                    <div className="brokerage_heading page_title">
                        <img src={BackIcon} alt="" onClick={this.handleBack} className="back_arrow_icon_brokerage" />
                        Link to brokerage firm
                    </div>
                    <div className="ipowork_inner_content">
                        <iframe name="nameOfIFrame" id="idOfIFrame" src={PDF_FINRARULE_BOTH} title="5130 and 5131 pdf" allowFullScreen frameBorder="0" style={{height:'calc(100vh - 370px)',width:'100%', padding: 10}} />
                        
                        <div className="resticted_content_text">
                            <p className="heading_content" style={{fontSize: 20, fontWeight: 'bold'}}>
                                Restricted persons under FINRA rule 5130 and 5131 are not allowed to purchase new issues.
                            </p>
                            <div style={{paddingLeft: 10, paddingRight: 10}}>
                                <div style={{width: 40, float: 'left'}}>
                                    <input type="checkbox" checked={this.state.Firstischecked} className="confirm_check_restriction"
                                    onChange={this.firstCheck}></input>
                                </div>
                                <div>
                                    <p className="restriction_text">I affirm that I have read and understand the prohibitions of Rules 5130 and 5131, and I attest that I am not a “restricted person” pursuant to the Rules.</p>
                                </div>
                            </div>
                            <div style={{paddingLeft: 10, paddingRight: 10}}>
                                <div style={{width: 40, float: 'left'}}>
                                    <input type="checkbox" checked={this.state.Secondischecked} className="reconfirm_check_restriction" onChange={this.secondCheck}></input>
                                </div>
                                <div>
                                    <p className="restriction_text">
                                        I hereby give permission to my connected brokerage firm to grant Click IPO access to my brokerage account information.
                                        <span className="learn_more">
                                            <span onClick={() => this.openurl('https://click.markets/broker-permission/')}>Learn more.</span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="confirm_restriction mt-4">
                            <div className="brokerage-account-link-btns">
                                {
                                    this.state.brokerdealerdetailsData && this.state.brokerdealerdetailsData?.name !== 'Planner' ?
                                    <div className={`text-center p-5 mt-4 ${this.state.brokerdealerdetailsData && this.state.brokerdealerdetailsData?.name === 'ScorePriority' ? 'divider_line': ''}`}>
                                        <button type="submit" className="green_btn" onClick={() => this.connectBrokerDealer(this.state.brokerdealerdetailsData?.name, this.state.brokerdealerdetailsData?.bd_id,
                                            this.state.brokerdealerdetailsData?.authorizedurl,
                                            this.state.brokerdealerdetailsData?.callbackurl,
                                            // this.state.brokerCallBack,
                                            this.state.brokerdealerdetailsData?.db_secret)} disabled={this.state.IsAllChecked}> <p style={{marginBottom: 12}}></p> <span style={{fontSize: '20px'}}> Link </span> <br /> (To an existing {this.state.brokerdealerdetailsData && this.state.brokerdealerdetailsData?.name ? this.state.brokerdealerdetailsData?.name : ''} account) <p style={{marginBottom: 12}}></p></button>
                                    </div>
                                    :
                                    <div className="text-center p-4 mt-4">
                                    <button style={{paddingLeft: 15, paddingRight: 15}} className="green_btn" onClick={() => this.connectBrokerDealer(this.state.brokerdealerdetailsData?.name, this.state.brokerdealerdetailsData?.bd_id,
                                            this.state.brokerdealerdetailsData?.authorizedurl,
                                            this.state.brokerdealerdetailsData?.callbackurl,
                                            // this.state.brokerCallBack,
                                            this.state.brokerdealerdetailsData?.db_secret)} disabled={this.state.IsAllChecked}>
                                                 <p style={{marginBottom: 26}}></p>
                                                Create Planner Account
                                                <p style={{marginBottom: 26}}></p>
                                    </button>
                                    </div>
                                }
                                {
                                    (this.state?.brokerdealerdetailsData?.create_account_url !== null) &&
                                        <div className={`text-center mt-4 p-5`}>
                                            <button type="submit" className="green_btn" onClick={() => this.createAccount()} disabled={this.state.IsAllChecked} > <span style={{fontSize: '20px'}}> Create Account</span> <br /> (If you do not have a {this.state.brokerdealerdetailsData?.name} brokerage account) <br/> (On clicking here, a new tab will open) </button>
                                        </div>
                                }
                            </div>
                            {
                                this.state.brokerdealerdetailsData && this.state.brokerdealerdetailsData?.name === 'ScorePriority' &&
                                <div className="text-center">
                                <button className="green_btn" onClick={() => this.createBrokerageAccount()}>Create Brokerage Account</button>
                                </div>
                            }
                            {
                                (this.state?.brokerdealerdetailsData?.create_account_url !== null) &&
                                    <div className={`text-center divider_line mt-5}`}></div>
                            }
                            
                        </div>
                    </div>

                    {/* {
                        this.state.isCreatePoppedOut && 
                        <Popout title='Welcome' url={this.state.brokerdealerdetailsData?.create_account_url} onClosing={this.createpopoutClosed}></Popout>
                    } */}
                </div>
            </Dashboard>
        )
    }
}

BrokerageConnectionComponent.propTypes = {
    EditprofileRes: PropTypes.any,
    RestrictedPersonRes: PropTypes.any,
    userActivityRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    EditprofileRes: doEditprofileRes,
    RestrictedPersonRes: doRestrictedPersonRes,
    userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
    return {
        getProfile: () => dispatch(submit_profile()),
        restrictedperson: (data) => dispatch(restricted_personApi(data)),
        userActivity: (data) => dispatch(user_activity(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BrokerageConnectionComponent);
