
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './glossary.css';
import Dashboard from '../../component/Dashboard';
import { glossary_list, doGlossaryRes } from '../../actions/glossaryActions';
import { user_activity, doUserActivityRes } from '../../actions/userActivityActions';
import { loadScript } from '../../helper/helper';

class GlossaryComponent extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            isLoader: true,
            screenId: '1.1',
        }
    }

    componentDidMount() {
        loadScript();
        const data = {};
        this.props.getGlossary(data);
        const activityData = {
            'screen_id': this.state.screenId,
            'source': 'web'
        }
        this.props.userActivity(activityData)
    }

    static getDerivedStateFromProps(props, state) {
		if (props.GlossaryRes) {
            if (props.GlossaryRes.data.glossary && props.GlossaryRes.data.glossary.length > 0) {
                return {
                    glossaryList: props.GlossaryRes.data.glossary
                };
            }
        }
		return null
	}
    render() {
        const { glossaryList } = this.state;
        if (glossaryList) {
        }
        return (
            <Dashboard title="ClickIPO - Glossary" pageTitle="Glossary" >
                <div className="glossary_content">
                    <div className="glossary_inner_content">
                        {
                            glossaryList && glossaryList.length > 0 && glossaryList.map((glossaryitem, i) =>
                                <div key={i}>
                                    <section className="glossary-section">
                                        <input type="checkbox" id={glossaryitem.id} />
                                        <label id={glossaryitem.id}>{glossaryitem.term}</label>
                                        <p>{glossaryitem.definition}</p>
                                    </section>
                                </div>
                            )
                        }
                    </div>
                </div>
            </Dashboard>
        )
    }

}


GlossaryComponent.propTypes = {
    GlossaryRes: PropTypes.any,
    userActivityRes: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    GlossaryRes: doGlossaryRes,
    userActivityRes: doUserActivityRes,
});

function mapDispatchToProps(dispatch) {
    return {
        getGlossary: (data) => dispatch(glossary_list(data)),
        userActivity: (data) => dispatch(user_activity(data)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(GlossaryComponent);
